import React, { Component } from "react";
import { Button, Loading, Text } from '@nextui-org/react';
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import PhoneInput from "react-phone-input-2";
import FileUploadEhanced from "../../components/FileUploadEnhanced";
import { error, warning as warnNotif } from "../../lib/userAlerts";
import endpoint from "../../config/endpoint.json";

import {
  noop,
  snakeCaseObj,
  makeCancelable,
  handleCancelablePromiseErr,
} from "../../lib/utils";
import { mstore } from "../../store";
import {
  isUniqueEmail,
  fetchWithJson,
  VALID_EMAIL_REGEX,
  VALID_PHONE_REGEX,
} from "../../lib/api";
import { updateTitle } from "../../lib/utils";

const RequireLabel = props => (
  <div className="label">
    <span className="label-text">{props.lblName} <span className="fw8" style={{ color: 'red' }}>*</span></span>
  </div>
);

const RegularLabel = props => (
  <div className="label">
    <span className="label-text">{props.lblName}</span>
  </div>
);

const SectionHeader = (props) => (
  <div className="f4 ttu fw4 gray-600 col-span-3">{props.dispText}</div>
);

export default class AccountDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isActive: true,
      name: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      primaryContact: "",
      email: "",
      email_free: true,
      phoneNo: "",
      logoUrl: false,
      companyUniqueId: "",
      useEmployeesDriver: 1,
      isBroker: 1,
      scacNumber: "",
      dotNumber: "",
      mcNumber: ""
    };
    this.selectedFile = null;
    this.lastIsValidCanceller = noop;
    this.savedEmail = "";
    this.access = null;
  }

  setActive = (e) => {
    const toggle = this.state.isActive;
    this.setState({ isActive: !toggle });
  };

  setUseEmployeesDriver = (e) => {
    this.setState({ useEmployeesDriver: JSON.parse(e.target.value) ? 1 : 0 });
  };

  setBroker = (e) => {
    if( e.target.value === "false" && 
        this.access.allow_broker_carrier_driver_gps === 0 && 
        this.access.allow_broker_shipment_management === 0 && 
        this.access.allow_broker_pod_submission_free === 0 && 
        this.access.allow_broker_carrier_data_docs === 0
      ) 
    {
      this.setState({ isBroker: JSON.parse(e.target.value) ? 1 : 0 });
    } else if(e.target.value === "true") {
      this.setState({ isBroker: JSON.parse(e.target.value) ? 1 : 0 });
    } else {
      error("You cannot turn off the broker setting until all the features in the Broker management tab of the settings page is disabled.")
    }
  };

  setName = (e) => {
    this.setState({ name: e.target.value });
  };

  setScacNumber = (e) => {
    this.setState({ scacNumber: e.target.value });
  };

  setDotNumber = (e) => {
    this.setState({ dotNumber: e.target.value });
  };

  setMcNumber = (e) => {
    this.setState({ mcNumber: e.target.value });
  };

  setAddress = (e) => {
    this.setState({ address: e.target.value });
  };

  setGAddress = (address) => {
    this.setState({ address });
    geocodeByAddress(address)
    .then((results) => {
      const address_components = results[0].address_components;
      const cityObj = address_components.find(
        (a) => a.types.indexOf("locality") !== -1
      );
      const countryObj = address_components.find(
        (a) => a.types.indexOf("country") !== -1
      );
      const zipObj = address_components.find(
        (a) => a.types.indexOf("postal_code") !== -1
      );
      const stateObj = address_components.find(
        (a) => a.types.indexOf("administrative_area_level_1") !== -1
      );
      this.setState({
        country: countryObj ? countryObj.long_name : this.state.country,
        city: cityObj ? cityObj.long_name : "Not Available",
        zipCode: zipObj ? zipObj.long_name : "Not Available",
        state: stateObj ? stateObj.long_name : "Not Available",
      });
    })
    .catch(()=>{
      ;;
    });
  };
  setCity = (e) => {
    this.setState({ city: e.target.value });
  };

  setUserState = (e) => {
    this.setState({ state: e.target.value });
  };

  setZIP = (e) => {
    const zipCode = e.target.value;
    if (/^\d*$/.test(zipCode)) this.setState({ zipCode });
  };

  setCountry = (e) => {
    this.setState({ country: e.target.value });
  };

  setPrimaryContact = (e) => {
    this.setState({ primaryContact: e.target.value });
  };

  setEmailAddress = (e) => {
    const email = e.target.value;

    this.setState({ email });
    this.props.updateEmail(email);

    if (VALID_EMAIL_REGEX.test(email) && email !== this.savedEmail) {
      this.lastIsValidCanceller();
      // TODO: use abortSignal of latest FetchAPI
      const { promise, cancel } = makeCancelable(isUniqueEmail(email));
      this.lastIsValidCanceller = cancel;

      promise
        .then((isUnique) =>
          this.setState({
            email_free: isUnique,
          })
        )
        .catch(handleCancelablePromiseErr);
    }
  };

  setPhoneNumber = (e) => {
    const phoneNo = e.target.value;
    if (!phoneNo || VALID_PHONE_REGEX.test(phoneNo)) this.setState({ phoneNo });
  };

  handleSave = async () => {
    if (this.$form) {
      this.$form.reportValidity();
      if (this.$form.checkValidity() === false) return;
    }
    if (!VALID_EMAIL_REGEX.test(this.state.email)) {
      return warnNotif("Please enter a valid email");
    }
    if (this.state.zipCode.length !== 5) {
      return warnNotif("Please enter a valid Zipcode");
    }
    this.setState({ loading: true });
    let info = snakeCaseObj(this.state);
    delete info.loading;
    try {
      if (this.props.onSave) {
        if ((await this.props.onSave(info)) === false) {
          this.setState({ loading: false });
        }
      }
      this.savedEmail = this.state.email;
    } catch (ex) {
      this.setState({ loading: false });
    }
  };

  handleInitialState = (d) => {
    const newState = {
      isActive: d.client_active === 1,
      name: d.name,
      address: d.address,
      city: d.city,
      state: d.state,
      zipCode: d.zip,
      country: d.country,
      primaryContact: d.primary_contact,
      email: d.email,
      phoneNo: d.phone_no,
      loading: d.loading || this.state.loading,
      companyUniqueId: d.companyUniqueId,
      useEmployeesDriver: d.use_employees_driver,
      isBroker: d.is_broker,
      scacNumber: d.scac_code,
      dotNumber: d.dot_number,
      mcNumber: d.mc_number
    };
    this.savedEmail = d.email;
    this.setState(
      Object.keys(newState).reduce((o, k) => {
        if (newState[k] !== undefined) {
          o[k] = newState[k];
        }
        return o;
      }, {})
    );
  };

  _renderCompanyDetails = () =>{
    return(
      <div className="grid grid-cols-3 grid-cols-1 gap-4">

        <div className="col-span-3">
          <RequireLabel lblName="Company Name" />
          <input
            onChange={this.setName}
            value={this.state.name}
            type="text"
            id='companyName'
            name='compantName'
            className="h-10 border rounded w-full px-4" 
            autoComplete="off"
            required
          />
        </div>

        <div className="col-span-1">
          <RequireLabel lblName="SCAC Code" />
          <input 
              required
              type="text" 
              name="scacNumber" 
              id="scacNumber" 
              className="h-10 border rounded w-full px-4" 
              onChange={this.setScacNumber}
              value={this.state.scacNumber}
              autoComplete="off"
              placeholder="" />
        </div>
        
        <div className="col-span-1">
          <RegularLabel lblName="DOT Number" />
          <input 
            required
            type="text" 
            name="dotNumber" 
            id="dotNumber" 
            className="h-10 border rounded w-full px-4" 
            onChange={this.setDotNumber}
            value={this.state.dotNumber}
            autoComplete="off"
            placeholder="" />
        </div>

        <div className="col-span-1">
          <RegularLabel lblName="MC Number" />
          <input 
            required
            type="text" 
            name="mcNumber" 
            id="mcNumber" 
            className="h-10 border rounded w-full px-4" 
            onChange={this.setMcNumber}
            value={this.state.mcNumber}
            autoComplete="off"
            placeholder="" />
        </div>

        <div className="form-control col-span-1 mt-6">
          <label className="cursor-pointer label">
            <span className="text-lg">Company Is Active</span> 
            <input type="checkbox" className="toggle toggle-success" checked={this.state.isActive} onChange={this.setActive}/>
          </label>
        </div>

        <div role="alert" className="alert col-span-2 mt-4">
        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="#fff" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
          <span>Disabling this toggle will close your account.</span>
        </div>

      </div>
    )
  }

  _renderContactDetails = () =>{
    return(
      <div className="grid grid-cols-3 gap-4">      
        
        <div className="col-span-3">
          <RequireLabel lblName="Primary Contact" />
          <input
            type="text" 
            name="full_name" 
            id="full_name" 
            className="h-10 border mt-1 rounded px-4 w-full" 
            onChange={this.setPrimaryContact}
            value={this.state.primaryContact}
            required
          />
        </div>

        <div className="col-span-2">
          <RequireLabel lblName="Email Address" />
          <div className="gray-600 w-100">
            <input
              disabled
              aria-disabled
              value={this.state.email}
              type="email"
              name="email"
              className= 'h-10 border mt-1 rounded px-4 w-full bg-gray-100'
              required
            />
          </div>
        </div>

        <div className="col-span-1">
          <RegularLabel lblName="Phone Number" />
          <PhoneInput
            containerClass="transition-all flex items-center h-10 border mt-1 rounded w-full"
            country={"us"}
            prefix={'+'}
            inputStyle={{ height: '100%', width: '100%' }}
            preferredCountries={["us", "in"]}
            value={this.state.phoneNo}
            onChange={(phone) => {
                this.setState({ phoneNo: phone });
            }}
          />
        </div>
    </div>
    )
  }

  _renderAddressDetail = () =>{
    const placeOption = {
      types: ["address"],
      componentRestrictions: { country: "us" },
    };

    const placeInputProps = {
        value: this.state.address,
        onChange: this.setGAddress,
        required: true,
    };

    const placeCssClasses = {
        root: "w-100",
        input: "h-10 border mt-1 rounded px-4 w-full",
        autocompleteContainer: "ba bw1 rounded",
    };

    return(
      <div className="grid grid-cols-3 gap-4"> 
        
        <div className="col-span-3">
          <RequireLabel lblName="Address" />
          <PlacesAutocomplete
            classNames={placeCssClasses}
            inputProps={placeInputProps}
            debounce={500}
            options={placeOption}
          />
        </div>

        <div className="col-span-1">
          <RegularLabel lblName="City" />
          <input
            required
            type="text" 
            name="city" 
            id="city" 
            className="h-10 border mt-1 rounded px-4 w-full" 
            onChange={this.setCity}
            value={this.state.city}
          />
        </div>

        <div className="col-span-1">
          <RequireLabel lblName="Zip Code" />
          <input 
            onChange={this.setZIP}
            value={this.state.zipCode}
            type="text" 
            name="zipcode" 
            id="zipcode" 
            className="transition-all flex items-center h-10 border mt-1 rounded px-4 w-full" 
            placeholder="" 
            required
          />
        </div>

        <div className="col-span-1">
          <RequireLabel lblName="Country" />
          <select 
            onChange={this.setCountry}
            value={this.state.country}
            className="select select-bordered select-sm h-10 mt-1 rounded  w-full">
            <option disabled selected>Select Country</option>
            <option>USA</option>
            <option>India</option>
          </select>
        </div>

      </div>
    )
  }

  componentDidMount() {
    const isUserLoggedIn = mstore.get("user", "auth0ID");
    const companyId = mstore.get("user", "company_id");

    if (this.props.initialDetails) {
      this.handleInitialState(this.props.initialDetails);
    }
    if (this.props.setUrlCB) {
      this.props.setUrlCB((logoUrl) => this.setState({ logoUrl }));
    }
    if(isUserLoggedIn) {
      fetchWithJson(endpoint.dc_app_access, { company_id: companyId })
      .then(res => res.json())
      .then(data => {
        this.access = data;
      })
      .catch(err => console.log(err));
    }

    updateTitle("Account Management");
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.initialDetails && nextProps.initialDetails) {
      this.handleInitialState(nextProps.initialDetails);
    }
    updateTitle("Account Management");
  }

  componentWillUnmount() {
    this.selectedFile = null;
    this.lastIsValidCanceller();
  }

  render() {

    return (
      <form
        ref={(_ref) => {
          this.$form = _ref;
        }}
        className="flex justify-between px-32 py-8 grid grid-cols-3 gap-12 bg-gray-100"
      >
        <div className="xl:col-span-2 md:col-span-3 space-y-6 bg-white p-8 shadow-lg">
          <div className="collapse collapse-arrow">
            <input type="radio" name="my-accordion-2" defaultChecked /> 
            <div className="collapse-title">
              <SectionHeader dispText="Company Info" />
            </div>
            <div className="collapse-content"> 
              {this._renderCompanyDetails()}
            </div>
          </div>
          <div className="collapse collapse-arrow">
            <input type="radio" name="my-accordion-2" /> 
            <div className="collapse-title">
              <SectionHeader dispText="Contact Details" />
            </div>
            <div className="collapse-content"> 
              {this._renderContactDetails()}
            </div>
          </div>
          <div className="collapse collapse-arrow">
            <input type="radio" name="my-accordion-2" /> 
            <div className="collapse-title">
              <SectionHeader dispText="Address details" />
            </div>
            <div className="collapse-content"> 
              {this._renderAddressDetail()}
            </div>
          </div>
                    
          <div className="py-8 flex justify-end border-t">
            <Button
              auto
              css={{br: 0}}
              onClick={this.handleSave}
              loading={this.state.loading}
            >
              {this.state.loading ? <Loading type='points' color='primary'/> : <Text color='currentColor'>{this.props.saveBtnNm}</Text>}
            </Button>
          </div>
        </div>

        <div className="xl:col-span-1 md:col-span-3 p-6 bg-white shadow-lg">
          <div className="card p-4 glass">
            <figure><img src={this.state.logoUrl || this.props.logoUrl} alt="your logo!"/></figure>
            <div className="card-body">
              <h3 className="card-title">Change your logo?</h3>
              <div className="card-actions justify-start">
                <FileUploadEhanced
                  defaultName={"Choose your new logo .. "}
                  uploadCb={this.props.uploadCb}
                  email={this.state.email}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
