import { SET_CLIENT, SET_CLIENT_LIST, CLEAR_SESSION } from './constants'

export function setCurrentClient(clientPayload) {
  return { type: SET_CLIENT, payload: clientPayload }
}

export function setClientList(clients) {
  return { type: SET_CLIENT_LIST, payload: clients }
}

export function clearSession() {
  return { type: CLEAR_SESSION }
}
