export const Lightning = () => { 
    return(
        <span className="mr-1">
            <svg
            className="w-5 h-5 mt-px text-blue-600"
            stroke="currentColor"
            viewBox="0 0 52 52"
            >
            <polygon
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
            />
            </svg>
        </span>
    )
}