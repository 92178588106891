// @flow
import React from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
// import { StripeProvider } from 'react-stripe-elements';

import history from './history';
import { store } from './store';
import { getAllRoutes } from './routes';

export default function Root() {
  return (
    <Provider store={store}>
      {/* <StripeProvider apiKey={process.env.REACT_APP_STRIPE_APIKEY} > */}
        <Router
          history={history}
          basename={process.env.REACT_APP_BASENAME === undefined ? '/' : process.env.REACT_APP_BASENAME}>
          {getAllRoutes()}
        </Router>
      {/* </StripeProvider> */}
    </Provider>
  );
}
