import React, { Component } from "react";
import { Loading, Text, Button as NextButton, Switch, Radio, Input} from '@nextui-org/react';

import { mstore } from "../../store";
import { connect } from "react-redux";
import endpoint from "../../config/endpoint.json";
import { secureFetch } from "../../lib/api";
import { error, success } from "../../lib/userAlerts";
import FeatureSelection from "../ProductSelection/FeatureSelection";

class DetentionManagement extends Component {
  constructor(props) {
        super(props);

        this.state = {
            updating: false,
            loading: false,
            detentionManagementSettings: {
                detention_window_hrs: 4,
                use_appointment_time1 : true,
                det_calc_using_arrival_time: true,
            }
        };
    }

    componentDidMount() {
        this.getDetentionManagementSettings();
    }

    componentWillReceiveProps(newProps) {
        if (newProps.clientId !== this.props.clientId) {
        this.getDetentionManagementSettings();
        }
    }

    getDetentionManagementSettings = () => {
        
        this.setState({loading: true});

        secureFetch(endpoint.fetchDetentionManagementSettings, {
        }).then(([ok, status, json]) => {
        if (ok) {
            this.setState({detentionManagementSettings: json.detentionManagementSettings, loading: false})
        } 
        else {
            error("Oops! Unable to fetch the detention management settings.");
            this.setState({loading: false});
        }
        });
    };

    renderWindow = () => {
        return(
            <div className="flex flex-wrap mb4">
                <div className="f5 w-2/5">
                    Detention Window (hours)
                </div>
                <Input
                    type='number'
                    underlined
                    css={{width: '100px'}}
                    min={1}
                    max={23}
                    defaultValue={1}
                    value={this.state.detentionManagementSettings.detention_window_hrs}
                    onChange={(e) => {
                        let valueAsNumber = e.target.value;
                        this.setState({ detentionManagementSettings: 
                            { ...this.state.detentionManagementSettings, detention_window_hrs: valueAsNumber}
                        })
                    }}
                />
            </div>
        )
    }


    renderAppointment = () =>{
        return(
            <div className="flex flex-wrap mb4">
                <div className="f5 w-2/5">
                    Detention Appointment Time Selection
                </div>
                <Radio.Group
                    size="sm" 
                    row 
                    css={{mt: '0.75rem', w: '50%', justifyContent: 'space-between'}}
                    label="activate"
                    value={this.state.detentionManagementSettings.use_appointment_time1}
                >
                    <Radio 
                        onChange={()=>{    
                            this.setState({ detentionManagementSettings: 
                                { ...this.state.detentionManagementSettings, use_appointment_time1: 1}
                            })
                        }}
                        css={{w: '50%'}}
                        color="primary"
                        value={1}>
                        Appointment Time 1
                        <Radio.Desc>Use Appointment Time-1 for calculating detention</Radio.Desc>
                    </Radio>
                    <Radio 
                        onChange={()=>{    
                            this.setState({ detentionManagementSettings: 
                                { ...this.state.detentionManagementSettings, use_appointment_time1: 0}
                            })
                        }}
                        color="primary"
                        css={{w: '50%'}}
                        value={0}>
                        Appointment Time 2
                        <Radio.Desc>Use Appointment Time-2 for calculating detention</Radio.Desc>
                    </Radio>
                </Radio.Group>
            </div>
        )
    }

    renderArrival = () =>{
        return(
            <div className="flex flex-wrap mb4">
                <div className="f5 w-2/5">
                    Detention Calculation Method
                </div>
                <Radio.Group
                    size="sm" 
                    row 
                    css={{mt: '0.75rem', w: '50%', justifyContent: 'space-between'}}
                    label="activate"
                    value={this.state.detentionManagementSettings.det_calc_using_arrival_time}
                >
                    <Radio 
                        onChange={()=>{    
                            this.setState({ detentionManagementSettings: 
                                { ...this.state.detentionManagementSettings, det_calc_using_arrival_time: 1}
                            })
                        }}
                        css={{w: '50%'}}
                        color="primary"
                        value={1}>
                        Use Arrival Time
                        <Radio.Desc>Use Arrival Time for calculating detention</Radio.Desc>
                    </Radio>
                    <Radio 
                        onChange={()=>{    
                            this.setState({ detentionManagementSettings: 
                                { ...this.state.detentionManagementSettings, det_calc_using_arrival_time: 0}
                            })
                        }}
                        css={{w: '50%'}}
                        color="primary"
                        value={0}>
                        Use Check-In Time
                        <Radio.Desc>Use Check-In Time for calculating detention</Radio.Desc>
                    </Radio>
                </Radio.Group>
            </div>
        )
    }

    updateDetentionManagement = () =>{
        this.setState({updating: true});

        secureFetch(endpoint.updateDetentionManagementSettings, {
            ...this.state.detentionManagementSettings
        }).then(([ok, status, json]) => {
          if (ok) {
            success('Updated the detention management settings.')
            this.setState({updating: false});
          } 
          else {
            error("Oops! error occured when updating your detention management settings.");
            this.setState({updating: false});
          }
        });
    }

    renderDetentionParameters = () => {
        if(this.props.Access.allow_detention_management === 1){
            return(
                <div
                    className="ml2 my-4 p-4 border-2 border-dotted"
                 >
                    <div className="flex justify-between border-b mb-8">
                        <div className="f4 pb-4">
                            Detention Settings
                        </div>
                    </div>

                    {this.renderWindow()}
                    {this.renderAppointment()}
                    {this.renderArrival()}

                    <div className="fw2">
                        <NextButton
                            auto
                            size='sm'
                            color="primary"
                            css={{br:0 }}
                            disabled={this.state.updating}
                            onClick={this.updateDetentionManagement}
                            className="ba bw1"
                        >
                            {this.state.updating ? 
                                <Loading type="points" color="primary"></Loading> 
                                : <Text color="currentColor" size="1rem">Update Detention Parameters</Text> }
                        </NextButton>
                    </div>
                </div>
            )
        }

        return (
            <div role="alert" className="alert alert-warning w-1/2 mt-16 ">
                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="white" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <span className="">Please subscribe to <b>Detention Management</b> feature</span>
            </div>
        )
    }

    render() {
        return (
            <div className="w-100 mt3">
                {this.state.loading ? (
                    <div className="ml2 pa2 w-100 mt6 flex justify-center">
                        <Loading size="lg">Fetching your detention parameters..</Loading>
                    </div>
                ) 
                : this.renderDetentionParameters()
                }
            </div>
        );
  }
}

export default connect((state) => ({
  Access: state.navigation.Access,
  clientId: state.client
    ? state.client.id
    : mstore.getPath(["user", "company_id"]),
}))(DetentionManagement);
