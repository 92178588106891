import React, { Component } from "react";
import { Button, Modal, Text, Radio, Textarea, Card, Divider } from "@nextui-org/react";
import { secureFetch } from "../../lib/api";
import endpoint from "../../config/endpoint.json";
import { error, success } from "../../lib/userAlerts";
import { CloseSquare, Send } from "react-iconly";
import Select from "react-select";

export default class CannedMessageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchedMessages: false,
      cannedMessageType: "p",
      cannedMessageList: [],
      message: "",
      errMessage1: null,
      errMessage2: null,
      okMessage1: null,
      okMessage2: null,
      loading: false,
    };
  }

  async componentDidUpdate() {
    if(this.props.openCannedMessageModal && !this.state.fetchedMessages){
      await this.getCannedMessageList();
      await this.setState({fetchedMessages: true})
    }
  }

  getCannedMessageList = () => {
    secureFetch(endpoint.fetchCannedMessage, {
      isBrokerShipmentManagement: this.props.partnerDocument ? 1 : 0,
    }).then(([ok, status, json]) => {
      if (ok) {
        this.setState({
          cannedMessageList: json.cannedMessage,
        });
      } else {
        error("Oops! error occured during fetching message list!");
      }
    });
  };

  handleTextBoxChange = (e) => {
    this.setState({ message: e.target.value });
  };

  savePushNotificationLog = async () => {
    
    try {
      
      if (!this.state.message) {
        error("Please enter message");
        return;
      }

      this.setState({ loading: true, errMessage1: null, errMessage2: null, okMessage1: null, okMessage2: null });

      let driver1Response = false;
      let driver2Response = false;

      if (this.props.driver1Id || this.props.driver1UserId) {
        
        let [ok, status, json, err] = await this.sendNotificationToDriver1();
        
        if (ok) {
          driver1Response = true;
          this.setState({okMessage1: json.message });
          success(json.message);
        } 
        else {
          this.setState({errMessage1: err.message });
          error(err.message);
        }
      }

      if (this.props.driver2Id || this.props.driver2UserId) {

        let [ok, status, json, err] = await this.sendNotificationToDriver2();
        
        if (ok) {
          driver2Response = true;
          this.setState({okMessage2: json.message });
          success(json.message);
        } 
        else {

          this.setState({errMessage2: err.message });
          error(err.message);
        }
      }

      if (driver1Response || driver2Response) {
        this.logNotification();
      }

      this.setState({ loading: false, message: "" });

    } catch (err) {
      console.log(err)
      this.setState({ loading: false, message: "" });
      error("Oops! something went wrong!");
    }
  };

  logNotification = async () => {
    await secureFetch(endpoint.savePushNotificationLog, {
      recordId: this.props.recordId,
      orderId: this.props.orderId,
      stopId: this.props.stopId,
      message: this.state.message,
    }).then(([ok, status, json]) => {
      if (ok) {
        this.setState({ loading: false });
        success("You have successfully save push notification");
      } else {
        this.setState({ loading: false });
        error("Oops! error occured during log push notification!");
      }
    });
  };

  sendNotificationToDriver1 = async () => {
    return await secureFetch(endpoint.sendPushNotification, {
      sendUserNotification: true,
      recordId: this.props.recordId,
      clientId: this.props.clientId,
      driverId: this.props.driver1Id,
      message: this.state.message,
      orderId: this.props.orderId,
      stopId: this.props.stopId,
      driverUserId : this.props.driver1UserId,
      bolNumber: this.props.bolNumber
    });
  };

  sendNotificationToDriver2 = async () => {
    return await secureFetch(endpoint.sendPushNotification, {
      sendUserNotification: true,
      recordId: this.props.recordId,
      clientId: this.props.clientId,
      driverId: this.props.driver2Id,
      message: this.state.message,
      orderId: this.props.orderId,
      stopId: this.props.stopId,
      driverUserId : this.props.driver2UserId,
      bolNumber: this.props.bolNumber
    });
  };

  render() {

    const options = []

    this.state.cannedMessageList.map(item => {
      options.push({value: item.message, label: item.message});
    })

    return (
      <Modal
        open={this.props.openCannedMessageModal}
        onClose={this.props.closeCannedMessageModal}
        blur
        closeButton
        width="800px"
        css={{
          height: 480,
          br: 0,
        }}
      >
        <Modal.Header>
          <Text size={18} b>
           {this.state.dialogTitle}
          </Text>
        </Modal.Header>
        
        <Modal.Body>
          <div>
            <Radio.Group row css={{mt: '0.75rem'}}
              value={this.state.cannedMessageType}
            >
              <Radio
                onChange={(e) => {
                  this.setState({ cannedMessageType: 'p' });
                }}
                checked={this.state.cannedMessageType === 'p'}  
                value="p">
                  Canned (Pre-Defined) Message
                  <Radio.Desc>Pre-defined messages that were configured in the settings page</Radio.Desc>
              </Radio>
              <Radio
                onChange={(e) => {
                  this.setState({ cannedMessageType: 'c' });
                }}
                checked={this.state.cannedMessageType === 'c'}  
                value="c">
                  Ad-hoc Message
                  <Radio.Desc>A once off message that you want to send to the driver</Radio.Desc>                  
              </Radio>
            </Radio.Group>
          </div>

          <div className="mt4 w-100">
            {this.state.cannedMessageType === "p" ? (
              <div className="w-100">
                <Select
                  name="canned-message"
                  style={{ width: "100%" }}
                  placeholder="Please select the canned message"
                  value={this.state.message}
                  onChange={(item) => {this.setState({ message: item ? item.value: null }); }}
                  options={options}
                  matchPos="start"
                  filterProp="value"
                />
              </div>
            ) : (
              <div>
                <Textarea
                  onChange={this.handleTextBoxChange}
                  fullWidth
                  bordered
                  css={{br:0,br: 0}}
                  rows={10}
                  placeholder="Enter your message here"
                ></Textarea>
              </div>
            )}
          </div>

          {this.state.errMessage1 && 
            (
              <Card css={{br: 0, backgroundColor: '$red100'}} bordered>
                <Card.Header>
                  <Text b>Driver 1 - Push notification Errors</Text>
                </Card.Header>
                <Divider />
                <Card.Body>
                  <Text>
                    {this.state.errMessage1}
                  </Text>
                </Card.Body>
              </Card>
            )
          }

          {this.state.okMessage1 && 
            ( 
              <Card css={{br: 0, backgroundColor: '$green100' }} bordered>
                <Card.Header>
                  <Text b>Driver 1 - Push notification message</Text>
                </Card.Header>
                <Divider />
                <Card.Body>
                  <Text>
                    {this.state.okMessage1}
                  </Text>
                </Card.Body>
              </Card>
            )
          }

          {this.state.errMessage2 && 
            (
              <Card css={{br: 0, backgroundColor: '$red100'}} bordered>
                <Card.Header>
                  <Text b>Driver 2 - Push notification Errors</Text>
                </Card.Header>
                <Divider />
                <Card.Body>
                  <Text>
                    {this.state.errMessage2}
                  </Text>
                </Card.Body>
              </Card>
            )
          }

          {this.state.okMessage2 && 
            (
              <Card css={{br: 0, backgroundColor: '$green100' }} bordered>
                <Card.Header>
                  <Text b>Driver 2 - Push notification message</Text>
                </Card.Header>
                <Divider />
                <Card.Body>
                  <Text>
                    {this.state.okMessage2}
                  </Text>
                </Card.Body>
              </Card>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <div className="flex">
            <Button
              auto
              css={{br:0, mr:10}}
              color='success'
              loading={this.state.loading}
              icon={<Send/>}
              onClick={this.savePushNotificationLog}
            />
            <Button
              auto
              flat
              color='secondary'
              css={{br:0}}
              icon={<CloseSquare/>}
              onClick={()=>{
                this.props.closeCannedMessageModal()
              }}
            />
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
