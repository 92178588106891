import React, { Component } from "react";
import { Card, Text, Button as NextButton, Input, Divider, Radio, Row, Loading, Col } from "@nextui-org/react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { mstore } from "../../store";
import Select from "react-select";
import endpoint from "../../config/endpoint.json";
import { userPasswordReset, updateBasicUserInfo } from "../../auth0";
import SuccessErrorDialog from "../../components/SuccessErrorDialog";
import { updateTitle } from "../../lib/utils";

import {
  success as notifSuccess,
  error as notifError,
  warning as notifWarning
} from "../../lib/userAlerts";
import {
  secureFetch,
  isUniqueEmail,
  VALID_EMAIL_REGEX,
  VALID_PHONE_REGEX
} from "../../lib/api";
import {
  noop,
  makeCancelable,
  handleCancelablePromiseErr
} from "../../lib/utils";

const defaultUser = {
  auth0ID: "",
  name: "",
  email: "",
  phone: "",
  isActive: true,
  employeeID: "",

  emailFree: true,

  isAdmin: false,
  isControlAdmin: false,
  isControlUser: false,
  isDriverAdmin: false,
  isDriverMobileUser: false,
  isDriverPortalUser: false,
  isDriverCoordinator: false
};

class UserForm extends React.Component {
  state = {
    user: { ...this.props.user } // { ...defaultUser }
  };

  savedEmail = "";
  lastIsValidCanceller = noop;

  componentDidMount() {
    updateTitle("User Management");
    
    if (this.props.getFormDataCB) {
      this.props.getFormDataCB(this.formGetter);
    }
  }

  componentWillUnmount() {
    this.lastIsValidCanceller();
  }

  componentWillReceiveProps(nextProps) {
    updateTitle("User Management");

    if (this.props.user !== nextProps.user) {
      this.lastIsValidCanceller();
      this.setState({ user: nextProps.user });
      this.savedEmail = nextProps.user.email;
    }
  }

  formGetter = () => {
    return this.state.user;
  };

  handleInputChange = evt => {
    let { name, value } = evt.target;

    if (
      value &&
      name === "email" &&
      VALID_EMAIL_REGEX.test(value) &&
      value !== this.savedEmail
    ) {
      this.lastIsValidCanceller();
      // TODO: use abortSignal from latest FetchAPI
      const { promise, cancel } = makeCancelable(isUniqueEmail(value));
      this.lastIsValidCanceller = cancel;

      promise
        .then(isUnique => {
          const user = this.state.user;
          user.emailFree = isUnique;
          this.setState({
            user
          });
        })
        .catch(handleCancelablePromiseErr);
    }

    if (value && name === "phone" && !VALID_PHONE_REGEX.test(value)) {
      return; // prevent non numeric input in phone
    }

    if (value && name === "phone") {
      value = value.startsWith('+') ? value : `+${value}`; // Append plus sign into the Phone
    }

    if (value && name === "isActive") {
      value = value === "true" ? true : false;
    }

    const user = this.state.user;
    user[name] = value;
    this.setState({ user });
  };

  renderName = () => {
    return(
      <div className="flex">
        <label htmlFor="" className="fw2 f5 w-30">
          Name
        </label>
        <Input
            type="text"
            name="name"
            placeholder="Enter Name"
            onChange={this.handleInputChange}
            clearable
            css={{ $$inputBorderRadius: "0", w: '100%', h: 38, mt: 5 }}
            shadow={false}
            bordered={true}
            rounded={false}
            value={this.state.user.name}
          />
      </div>
    )
  }

  renderEmail = () => {
    return(
      <div className="w-100 mt2">
        <div className="w-100 flex">
          <label htmlFor="" className="fw2 f5 w-30">
            Email ID
          </label>
          <Input
            name="email"
            type="email"
            placeholder="Enter Email Address"
            onChange={this.handleInputChange}
            className={this.state.user.emailFree ? "" : " bg-washed-red"}
            clearable
            css={{ $$inputBorderRadius: "0", w: '100%', h: 38, mt: 5 }}
            shadow={false}
            bordered={true}
            rounded={false}
            value={this.state.user.email}
          />
        </div>
        <div className="mt2 f5 red fw6 w-100">
          {!this.state.user.emailFree &&
            "Email address is already used. Please use another email."}
        </div>
      </div>
    )
  }

  renderEmployee = () => {
    return(
      <div className="w-100 mt2">
        <div className="w-100 flex">
          <label htmlFor="" className="fw2 f5 w-30">
            Employee Id
          </label>
          <Input
            name="employeeID"
            type="text"
            placeholder="Enter Employee ID"
            onChange={this.handleInputChange}
            clearable
            css={{ $$inputBorderRadius: "0", w: '100%', h: 38, mt: 5 }}
            shadow={false}
            bordered={true}
            rounded={false}
            value={this.state.user.employeeID || ""}
          />
        </div>
        <div className="mt2 f5 red fw6 w-100">
          {!this.state.user.emailFree &&
            "Email address is already used. Please use another email."}
        </div>
      </div>
    )
  }

  renderUserStatus = () =>{
    return(
      <div className="flex w-100 mt2">
      
        <label htmlFor="" className="fw2 f5 w-30">
          User Status
        </label>
    
        <Radio.Group
            size="sm" 
            row 
            css={{mt: '0.75rem', w: '100%', justifyContent: 'space-between'}}
            label="activate"
            value={this.state.user.isActive}
          >
            <Radio 
                onChange={()=>{this.handleInputChange({target:{name: 'isActive', value: "true"}})}}
                css={{w: '50%'}}
                color="primary"
                value={true}>
                Active
                <Radio.Desc>User is active and can access the system</Radio.Desc>
            </Radio>
            <Radio 
                onChange={()=>{this.handleInputChange({target:{name: 'isActive', value: "false"}})}}
                color="primary"
                css={{w: '50%'}}
                value={false}>
                Inactive
                <Radio.Desc>Disable user's access to the system</Radio.Desc>
            </Radio>
        </Radio.Group>
      </div>
    )
  }

  renderCellPhone = () => {
    return(
      <div className="w-100 flex">
        <label htmlFor="" className="fw2 f5 w-30">
          Cell phone
        </label>
        <PhoneInput
            country={"us"}
            prefix={'+'}
            containerStyle={{ width: '100%', borderRadius: 0 }}
            inputStyle={{ width: '100%' }}
            preferredCountries={["us", "in"]}
            value={this.state.user.phone}
            onChange={(phone) => {
              this.handleInputChange({target : { name: "phone", value: phone }})
              this.setState({ phone });
            }}
          />
      </div>
    )
  }

  renderUserData = () => {
    return(
      <Card css={{ minWidth: "400px", w: '100%', br: 0, mt: 5 }}>
        <Card.Header>
          <Text className="text-lg font-semibold">User Details</Text>
        </Card.Header>

        <Divider/>

        <Card.Body css={{ py: "$10" }}>
          {this.renderName()}
          {this.renderEmail()}
          {this.renderCellPhone()}
          {this.props.showOtherUsers ? this.renderEmployee() : null}
          {this.props.showOtherUsers ? this.renderUserStatus() : null}
        </Card.Body>        
      </Card>
    )
  }

  render() {
    
    if(this.props.loading)
      return <Loading size='lg' css={{h: 400}}>Hang on...</Loading>
      
    return this.renderUserData();
  }
}

const NO_ACCESS = 0
const ADMIN = 1
const POWER_USER = 2
const REGULAR_USER = 3

class UserRoles extends Component {
  state = {
    userRole: NO_ACCESS
  };

  componentDidMount() {
    if (this.props.getRolesDataCB) {
      this.props.getRolesDataCB(this.rolesGetter);
    }

    if(this.props.user.isAdmin){
      this.setState({userRole: ADMIN})
    }

    else 
      if(this.props.user.isDriverAdmin){
        this.setState({userRole: POWER_USER})
      }

      else
        if(this.props.user.isDriverPortalUser){
          this.setState({userRole: REGULAR_USER})
        }

        else
          this.setState({userRole: NO_ACCESS})
  }

  componentWillReceiveProps (newProps) {
    if(newProps.user.isAdmin){
      this.setState({userRole: ADMIN})
    }

    else
      if(newProps.user.isDriverAdmin){
        this.setState({userRole: POWER_USER})
      }

      else
        if(newProps.user.isDriverPortalUser){
          this.setState({userRole: REGULAR_USER})
        }

        else
          this.setState({userRole: NO_ACCESS})
  }

  rolesGetter = () => {
    return this.state.user;
  };

  handleRoleChange = evt => {
    let { name, value } = evt.target;
    const user = this.props.user;

    if(name === 'userAccess'){
      this.setState({ userRole: value });

      if(value === ADMIN){
        user.isAdmin = true
        user.isDriverPortalUser = false
        user.isDriverAdmin = false
      }

      if(value === NO_ACCESS){
        user.isAdmin = false
        user.isDriverPortalUser = false
        user.isDriverAdmin = false
      }

      if(value === POWER_USER){
        user.isAdmin = false
        user.isDriverPortalUser = false
        user.isDriverAdmin = true
      }

      if(value === REGULAR_USER){
        user.isAdmin = false
        user.isDriverPortalUser = true
        user.isDriverAdmin = false
      }
    }
    else{
      value = value === "true" ? true : false;
      user[name] = value;
    }
  };

  render() {
    let options = [
        <Radio.Group
          size="sm" 
          disabled={this.props.user.isPrimaryUser}
          row 
          css={{mt: '0.75rem', w: '100%', justifyContent: 'space-between'}}
          value={this.state.userRole}
        >
          <Radio 
              onChange={()=>{this.handleRoleChange({target:{name: 'userAccess', value: ADMIN}})}}
              css={{w: '50%'}}
              color="primary"
              value={ADMIN}>
              Admin User 
              <Radio.Desc>Can access all Web-Portal Screens (including user management)</Radio.Desc>
          </Radio>
          <Radio 
              onChange={()=>{this.handleRoleChange({target:{name: 'userAccess', value: POWER_USER}})}}
              color="primary"
              css={{w: '50%'}}
              value={POWER_USER}>
              Power User  
              <Radio.Desc>Can access all Portal Screens including Settings Page (but Not User Management)</Radio.Desc>
          </Radio>
          <Radio 
              onChange={()=>{this.handleRoleChange({target:{name: 'userAccess', value: REGULAR_USER}})}}
              css={{w: '50%'}}
              color="primary"
              value={REGULAR_USER}>
              Regular User  
              <Radio.Desc>Has access to Web-Portal screens (Cannot access User Management & Settings)</Radio.Desc>
          </Radio>
          <Radio 
              onChange={()=>{this.handleRoleChange({target:{name: 'userAccess', value: NO_ACCESS}})}}
              color="error"
              css={{w: '50%'}}
              value={NO_ACCESS}>
              Disable Portal Access
              <Radio.Desc></Radio.Desc>
          </Radio>
        </Radio.Group>,
        <Radio.Group
          size="sm" 
          row 
          css={{mt: '0.75rem', w: '100%', justifyContent: 'space-between', mt: '$3'}}
          value={this.props.user.isDriverCoordinator}
        >
          <Radio 
              onChange={()=>{this.handleRoleChange({target:{name: 'isDriverCoordinator', value: "true"}})}}
              css={{w: '50%'}}
              color="primary"
              value={true}>
              Driver Coordinator
              <Radio.Desc>Set this toggle to make this user a driver co-ordinator</Radio.Desc>
          </Radio>
          <Radio 
              onChange={()=>{this.handleRoleChange({target:{name: 'isDriverCoordinator', value: "false"}})}}
              color="error"
              css={{w: '50%'}}
              value={false}>
              Not a Driver Coordinator
              <Radio.Desc>Remove driver co-ordinator access</Radio.Desc>
          </Radio>
        </Radio.Group>,
        <Radio.Group
          size="sm" 
          row 
          css={{mt: '0.75rem', w: '100%', justifyContent: 'space-between', mt: '$3'}}
          value={this.props.user.isDriverMobileUser}
        >
          <Radio 
              onChange={()=>{this.handleRoleChange({target:{name: 'isDriverMobileUser', value: "true"}})}}
              css={{w: '50%'}}
              color="primary"
              value={true}>
              MTT App Access 
              <Radio.Desc>Allow Mobile app access to the user</Radio.Desc>
          </Radio>
          <Radio 
              onChange={()=>{this.handleRoleChange({target:{name: 'isDriverMobileUser', value: "false"}})}}
              color="error"
              css={{w: '50%'}}
              value={false}>
              Disable App Access
              <Radio.Desc>Disable Mobile app access to the user</Radio.Desc>
          </Radio>
        </Radio.Group>
      ];
    

    if(this.props.loading)
      return <Loading size='lg' css={{h: 400}}/>

    else{
      return (
        <Card css={{ minWidth: "400px", w: '100%', br: 0 }}> 
          <Card.Header>
            <Text className="text-lg font-semibold">Permissions</Text>
          </Card.Header>
          
          <Divider/>    

          <Card.Body>
            {options}
          </Card.Body>  
        </Card>
      );
    }
  }
}

export default class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hadDriverConnectAccess: true,
      selectedUser: defaultUser,
      userList: [],
      showProducts: false,
      showSubmitButtonInChooseProduct: false,
      loading: true,
      showDialog: false,
      isError: false,
      errorMsg: ""
    };
  }

  sendPasswordResetEmail = () => {
    userPasswordReset(this.state.selectedUser.email)
      .then(result => {
        this.setState({
          showDialog: true
        });
      })
      .catch(err => {
        console.error(err);
        this.setState({
          showDialog: true,
          isError: true,
          errorMsg: err.message ? err.message : ""
        });
      });
  };

  handleDialogClose = () => {
    this.setState({
      showDialog: false,
      isError: false,
      errorMsg: ""
    });
  };

  renderDialog() {
    return (
      <SuccessErrorDialog
        showDialog={this.state.showDialog}
        ind={!this.state.isError}
        onDone={this.handleDialogClose}
      >
        {!this.state.isError ? (
          <b>
            <span>
              We've just sent you an email to reset your password at{" "}
              {this.state.selectedUser.email}
            </span>
            <br />
            Please check your email.
          </b>
        ) : (
          <b>
            An error occured.
            <br />(<small>{this.state.errorMsg}</small>)
          </b>
        )}
      </SuccessErrorDialog>
    );
  }

  clearuser = () => {
    this.setState({
      selectedUser: defaultUser,
      hadDriverConnectAccess: true
    });
  };

  componentDidMount() {
    
    const showOtherUsers = mstore.get("user", "isSuperAdmin") || mstore.get("user", "isAdmin");
    this.fetchUsers();

    if (showOtherUsers) {
      this.setState({ showProducts: true });
    }
  }

  fetchUsers = () => {
    const showOtherUsers =
      mstore.get("user", "isSuperAdmin") || mstore.get("user", "isAdmin");
    const currentAuth0ID = mstore.get("user", "auth0ID");

    if (!showOtherUsers) {
      const currentUser = mstore.get("user");
      currentUser.emailFree = true;
      this.setState({
        selectedUser: currentUser,
        hadDriverConnectAccess:
          (currentUser.isDriverMobileUser ||
            currentUser.isDriverAdmin ||
            currentUser.isDriverCoordinator ||
            currentUser.isDriverPortalUser) &&
          currentUser.isActive,
        loading: false
      });
      return;
    }

    secureFetch(endpoint.listUser).then(([ok, status, json]) => {
      if (ok) {
        let currentUser = this.state.selectedUser;
        json.forEach(user => {
          user.emailFree = true;
          // TODO: is this needed?? we already have user info in mstore
          if (currentUser === defaultUser && user.auth0ID === currentAuth0ID) {
            currentUser = user;
          } else if (user.auth0ID === currentUser.auth0ID) {
            currentUser = user;
          }
        });
        this.setState({
          userList: json,
          selectedUser: currentUser,
          hadDriverConnectAccess:
            (currentUser.isDriverMobileUser ||
              currentUser.isDriverAdmin ||
              currentUser.isDriverCoordinator ||
              currentUser.isDriverPortalUser) &&
            currentUser.isActive,
          loading: false
        });
      } else {
        json.text().then(console.error);
        this.setState({ loading: false });
        notifError("Oops! Couldn't fetch user list");
      }
    }); // FIXME: Forever loading in case of error
  };

  handleUserSelect = selectedOption => {
    this.savedEmail = selectedOption ? selectedOption.email : "";

    let userList = this.state.userList;
    let hadDriverConnectAccess = true;

    if (selectedOption) {
      userList.forEach(user => {
        // To be absolutely safe. State ( selectedOption ) may be modified.
        if (user.auth0ID === selectedOption.auth0ID) {
          hadDriverConnectAccess =
            (user.isDriverMobileUser ||
              user.isDriverAdmin ||
              user.isDriverCoordinator ||
              user.isDriverPortalUser) &&
            user.isActive;
        }
      });
    }

    this.setState({
      selectedUser: selectedOption || { ...defaultUser },
      showProducts: !!selectedOption,
      showSubmitButtonInChooseProduct: false,
      hadDriverConnectAccess: hadDriverConnectAccess
    });
  };

  handleAdd = () => {
    this.setState({
      selectedUser: { ...defaultUser },
      hadDriverConnectAccess: true,
      showProducts: true,
      showSubmitButtonInChooseProduct: true
    });

    this.savedEmail = "";
  };

  saveDetails = async () => {
    const user = this.state.selectedUser;
    user.hadDriverConnectAccess = this.state.hadDriverConnectAccess;
    user.hasDriverConnectAccess =
      (user.isDriverMobileUser ||
        user.isAdmin ||
        user.isDriverAdmin ||
        user.isDriverCoordinator ||
        user.isDriverPortalUser) &&
      user.isActive;

    if (!user.emailFree) {
      return notifWarning("Email address already used.");
    }
    if (!user.email) {
      return notifWarning("Please enter a valid email");
    }
    if (!user.name) {
      return notifWarning("Please enter user name!");
    }
    
    // Start loader.
    this.setState({ loading: true });

    try{
      const [ok, status, createdUser, err] = await secureFetch(endpoint.createOrUpdateuser, user);
 
      // New user
      if (!user.auth0ID && ok) { 
        userPasswordReset(createdUser.email)
          .then(_ => {
            notifSuccess("Sent a password reset email to new user.");
          })
          .catch(err => {
            console.error(err);
            notifWarning(
              "Created new user but failed to send password reset email."
            );
          });
      } 

      // Old user 
      if (user.auth0ID && ok) {
        notifSuccess("Successfully updated User Details.");
      }

      if(!ok){
        notifError(err.message || 'Unable to create / update the user at the moment.')
      }
    }
    catch(err) {
      notifError('Unable to create / update the user at the moment.')
    }
    finally{
      if (!user.auth0ID){
        this.setState({
          loading: false,
          showSubmitButtonInChooseProduct: true
        })
      }
      else{
        this.setState({
          loading: false,
          showSubmitButtonInChooseProduct: false
        })
      };
      updateBasicUserInfo().then(_ => this.fetchUsers());
    }
  };

  renderUserPicker = () => {
    return(
      <Card css={{ minWidth: "400px", w: '100%', br: 0 }}> 
        <Card.Header>
          <Text className="text-lg font-semibold">Select an existing user - Or - Create new user</Text>
        </Card.Header>
        <Divider/>    
        <Card.Body css={{minHeight: 220}}>
          <div className="flex justify-between"> 
            <Select
              maxMenuHeight={175}
              name="form-field-name"
              className="f5 w-65 render-top"
              style={{ height: '40px' }}
              placeholder="Search users"
              value={this.state.selectedUser}
              onChange={this.handleUserSelect}
              options={this.state.userList}
              labelKey="name"
            />
            <NextButton
              ghost
              css={{br: 0, ml: 10}}
              onClick={this.handleAdd}
            >
              Add a new user
            </NextButton>
          </div>

          <div className="mt4">
            <Row>
              <h5 className="w-100 red">Please Note</h5>
            </Row>
            <Row className="mt2">
              <p className="f5 fw2"> 
                Please take care while filling in the phone number field. The users will receive text messages & notifications on this phone number. 
                Any mistakes made while entering the phone number can result in the text messages being sent to the wrong person.
              </p>
            </Row>
          </div>
        </Card.Body>  
      </Card>
    )
  }

  renderButtons = () =>{
    return(
      <Card css={{mt: '$3', br: 0}}>
        <Card.Body>
          <Row justify='flex-end'>
            {!this.state.showSubmitButtonInChooseProduct ? 
              <NextButton
                css={{br: 0, mr: 10}}
                ghost
                auto
                color='primary'
                onClick={this.sendPasswordResetEmail}
              >
                Change Password
              </NextButton>
            : null
            }

            <NextButton
              auto
              css={{br:0}}
              onClick={this.saveDetails}
            >
              {this.state.showSubmitButtonInChooseProduct ? "Create User" : "Update"}
            </NextButton>
          </Row>
        </Card.Body>
      </Card>
    )
  }

  render() {
    if (this.state.showDialog) return this.renderDialog();

    let truckinPortal = sessionStorage.getItem('truckinPortal')
    let useEmployeesDriver = sessionStorage.getItem('useEmployeesDriver')

    const showOtherUsers = mstore.get("user", "isSuperAdmin") || mstore.get("user", "isAdmin");
    const driverAdmin = mstore.get("user", "isDriverAdmin");

    return (
      <div className="grid grid-cols-2 gap-4 p-8">
        <Col css={{mr: '$2'}}>
            {showOtherUsers ? this.renderUserPicker(): null}
            <UserForm
              user={this.state.selectedUser}
              showOtherUsers={showOtherUsers}
              getFormDataCB={cb => {
                this.getUserFormData = cb;
              }}
              loading={this.state.loading}
            />
        </Col>

        <Col css={{ml: '$2'}}>
            {showOtherUsers && this.state.showProducts ? 
              <UserRoles
                showSubmit={this.state.showSubmitButtonInChooseProduct}
                user={this.state.selectedUser}
                getRolesDataCB={cb => {
                  this.getUserRolesData = cb;
                }}
                handleSave={this.saveDetails}
                loading={this.state.loading}
              />
              : null
            }
            {this.state.loading ? null : this.renderButtons()}
        </Col>
      </div>
    );
  }
}
