import React from 'react';
import { connect } from 'react-redux';

import { Loading } from '@nextui-org/react';
import { mstore } from '../../store';
import checkAccess from '../../lib/checkAccess';

import { updateTitle } from "../../lib/utils";
import { Lightning } from '../../icons/Lightning';

/**
 * @param * @param {Object} product
 * @param {number} product.product_id
 * @param {string} product.product
 * @param {boolean} product.is_active
 */
function getHrefForProduct(product) {
  switch (product.product_id) {
    case 1:
      return '/driver/dashboard';
    default:
      return 'Unconfigured Product';
  }
}

const Product = ({
  history,
  href,
  name,
  isActive,
  disable,
  showLogin
}) => {

  if (disable) {
    isActive = false;
  }

  return (
    <div className="card w-full bg-base-100 shadow-xl mb-3">
      <div className="card-body">
        <div className="card-actions justify-between">
          <h2 className="card-title">{name}</h2>
          {showLogin && (
            <button 
              onClick={() => {
                if (isActive) {
                  history.push(href);
                }
              }}
              className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold">
                Enter
            </button>
          )}
        </div>
      </div>
    </div>
  )
};

// TODO declare props, add default values
class Products extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      products: props.products || [],
      canEDProducts: false // Can enable/disable product
    };
  }

  componentDidMount() {
    const isSuperAdmin = mstore.get('user', 'isSuperAdmin');
    const isAdmin = mstore.get('user', 'isAdmin');
    const isPrimaryUser = mstore.get('user', 'isPrimaryUser');
    if (isSuperAdmin || isPrimaryUser || isAdmin) {
      this.setState({ canEDProducts: true });
    }
    if (this.props.initialState) {
      this.setState(this.props.initialState);
    }
    checkAccess(this.props.clientId);
    updateTitle("Product Management");
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.initialState !== nextProps.initialState &&
      nextProps.initialState
    ) {
      this.setState(nextProps.initialState);
    }
    updateTitle("Product Management");
  }

  editProduct = (product, enabled) => {
    const products = this.state.products.map(p => {
      if (p.product_id === product.product_id) {
        p.is_active = enabled;
      }
      return p;
    });
    this.setState(
      {
        products
      },
      () => this.props.onSelectionChange(products)
    );
  };

  render() {
    let { history, showLogin } = this.props;
    let canViewDriver =
      mstore.get('user', 'isAdmin') ||
      mstore.get('user', 'isSuperAdmin') ||
      mstore.get('user', 'isDriverAdmin') ||
      mstore.get('user', 'isDriverPortalUser');
    
    if (showLogin === undefined){
      showLogin = false;
    } 

    return (
      <div className="px-4 py-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-4 lg:py-12">
        <div className="grid gap-5 row-gap-10 lg:grid-cols-2">
          <div className="flex flex-col justify-center">
            <div className="max-w-xl mb-6">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                Welcome to {' '}
                <span className="relative inline-block text-blue-500">
                  My Truckin' Tech™
                </span>
              </h2>
              <p className="text-base text-gray-700 md:text-lg">
                Please head over to <a className="link link-primary" href="https://www.mytruckin.tech/" target='_'>https://www.mytruckin.tech/</a> for up to date information regarding our platform. 
              </p>
            </div>
            <p className="mb-4 text-sm font-bold tracking-widest uppercase">
              Features
            </p>
            <div className="grid space-y-3 sm:gap-2 sm:grid-cols-2 sm:space-y-0">
              <ul className="space-y-3">
                <li className="flex">
                  <Lightning/>
                  Built on AWS for high availability
                </li>
                <li className="flex">
                  <Lightning/>
                  Fully encrypted and secure
                </li>
                <li className="flex">
                  <Lightning/>
                  Optimal performance
                </li>
                <li className="flex">
                  <Lightning/>
                  Driver focused, geared towards retention
                </li>
              </ul>
              <ul className="space-y-3">
                <li className="flex">
                  <Lightning/>
                  Easy to use &amp; deploy
                </li>
                <li className="flex">
                  <Lightning/>
                  World class support
                </li>
                <li className="flex">
                  <Lightning/>
                  Innovation is driven by customers and drivers
                </li>
                <li className="flex">
                  <Lightning/>
                  All features drive operational efficiencies
                </li>
              </ul>
            </div>
          </div>

          <div className='w-full flex'>
            <div className="divider divider-horizontal"></div>
            <div className='w-5/6 p-3'>
              {this.state.products.map(p => {
                return (
                  <Product
                    key={p.product_id}
                    history={history}
                    disable={(p.product_id === 1 && !canViewDriver)}
                    // disable={p.product === 'SDN Control / Comet' ? true : false}
                    href={getHrefForProduct(p)}
                    name={p.product}
                    showLogin={showLogin}
                    isActive={p.is_active}
                  />
                );
              })}
            </div>

            {this.state.products.length === 0 && (
              <div className="w-100 pt4 flex justify-center items-center">
                {' '}
                <Loading size='lg'/>{' '}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    clientId: state.client ? state.client.id : mstore.getPath(["user", "company_id"]),
    Access: state.navigation.Access
  }
}

export default connect(mapStateToProps)(Products);