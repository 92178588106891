// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import { ResponsivePie } from '@nivo/pie'
import React, { Component } from 'react'

import { fetchWithJson } from "../../../lib/api";
import { error } from "../../../lib/userAlerts";
import endpoint from "../../../config/endpoint.json";
import { Radio } from '@nextui-org/react';

const DAYS_30 = 1
const DAYS_365 = 2
const DAYS_ALL = 3

export default class StopRatings extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            data: [],
            chartType: DAYS_30
        }
    }

    componentDidMount(){
        if(this.props.clientId){
            this.fetchData(this.props.clientId)
        }
    }

    componentWillReceiveProps(newProps) {
        if(newProps && newProps.clientId >= 1 && newProps.clientId !== this.props.clientId){
            this.fetchData(newProps.clientId)
        }
    }

    fetchData = async(clientId) =>{
        try {
            this.setState({data: []})
            const res = await fetchWithJson(endpoint.statsStopRatings, {
                company_id: clientId,
                allTime: this.state.chartType === DAYS_ALL ? true : false,
                thisYear: this.state.chartType === DAYS_365 ? true : false
            });
    
            if (res.status !== 200) {
                error('Unable to fetch shipment ratings')
            }
            else{
                const data = await res.json();
                let chartData = [];
    
                /**
                 * Massage the data.
                 */
    
                if(data && data.ratings && data.ratings.totalRecs > 0){
    
                    if(data.ratings.under2 > 0){
                        chartData.push({
                            id: '1 to 2 Stars',
                            label: 'Poor',
                            value: data.ratings.under2,
                            percent: Math.round(100*data.ratings.under2/data.ratings.totalRecs, 2)
                        })
                    }
    
    
                    if(data.ratings.under3 > 0){
                        chartData.push({
                            id: '2 to 3 Stars',
                            label: 'Average',
                            value: data.ratings.under3,
                            percent: Math.round(100*data.ratings.under3/data.ratings.totalRecs, 2)
                        })
                    }
    
                    if(data.ratings.under4 > 0){
                        chartData.push({
                            id: '3 to 4 Stars',
                            label: 'Above Avg',
                            value: data.ratings.under4,
                            percent: Math.round(100*data.ratings.under4/data.ratings.totalRecs, 2)
                        })
                    }
    
                    if(data.ratings.under5 > 0){
                        chartData.push({
                            id: '4 to 5 Stars',
                            label: 'Excellent',
                            value: data.ratings.under5,
                            percent: Math.round(100*data.ratings.under5/data.ratings.totalRecs, 2)
                        })
                    }
    
                    this.setState({data: chartData})
                }
            }
        } 
        catch (err) {
            console.log(err);
            error('Unable to fetch shipment ratings')
        }
    }

    plotGraph = () => {
        return(
            <ResponsivePie
                data={this.state.data}
                margin={{ top: 40, right: 80, bottom: 150, left: 80 }}
                innerRadius={0.8}
                padAngle={1}
                cornerRadius={2}
                activeOuterRadiusOffset={8}
                colors={{ scheme: 'pastel1' }}
                borderWidth={1}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.2
                        ]
                    ]
                }}
                arcLinkLabel={d => `${d.data.label} (${d.data.percent}%)`}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            2
                        ]
                    ]
                }}
                legends={[
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: 56,
                        itemsSpacing: 0,
                        itemWidth: 100,
                        itemHeight: 18,
                        itemTextColor: '#999',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 18,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]
                    }
                ]}
            />
        )
    }

    plotNoGraph = () =>{
        return(
            <p className="h-100 f4 fw2 tc" style={{position: 'relative', top: '50%'}}>
                No driver reviews available
            </p>
        )
    }

    render() {
        return (
          <div className='ba b--dotted bw1 mt2' style={{height: "60vh", width: "100%"}}>
            <Radio.Group row css={{mt: '0.75rem', padding: 10}}
                size='sm'
                value={this.state.chartType}
            >
                <Radio
                    onChange={(e) => {
                        this.setState({ chartType: DAYS_30 }, ()=> {this.fetchData(this.props.clientId)});
                    }} 
                    value={DAYS_30}>
                    30 Days
                    <Radio.Desc>Ratings for last 30 days</Radio.Desc>
                </Radio>
                <Radio
                    onChange={(e) => {
                        this.setState({ chartType: DAYS_365 }, ()=> {this.fetchData(this.props.clientId)});
                    }}
                    value={DAYS_365}>
                    365 Days
                    <Radio.Desc>Ratings for last 365 days</Radio.Desc>                  
                </Radio>
                <Radio
                    onChange={(e) => {
                        this.setState({ chartType: DAYS_ALL }, ()=> {this.fetchData(this.props.clientId)});
                    }}
                    value={DAYS_ALL}>
                    All Time
                    <Radio.Desc>All stop ratings</Radio.Desc>                  
                </Radio>
            </Radio.Group>

            {this.state.data && this.state.data.length > 0 ? this.plotGraph() : this.plotNoGraph()}
          </div>
        );
    }
}


