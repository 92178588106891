import React, { Component } from "react";
import CannedMessageModal from "../PageSelectionComponent/SendCannedMessageComponent";
import PlotLocation from './PlotLocation';
import AutoLinkLogs from './AutoLinkLogs';
import dayjs from '../../lib/day';
import unlink from '../../unlink.svg';
import { Button, Tooltip as NextTooltip, Checkbox, Modal, Text, Card } from '@nextui-org/react';
import { Document, Location, Message, TwoUsers, TimeCircle, Discovery, Swap } from "react-iconly";

class OrderCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOrders: this.props.selectedOrders,
      openCannedMessageModal: false,
      openPlotModal: false,
      isOpen: false,
      openLinkLogModal: false,
    };
  }

  timeConvert = (n) => {
    var num = 0;
    if (n > 0) {
      num = n;
    } else {
      num = -1 * n;
    }
    var days = num / 1440;
    var rdays = Math.floor(days);
    var hours = (days - rdays) * 24;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return (
      rdays + " days and " + rhours + " hours and " + rminutes + " minutes"
    );
  };

  /**
   * 
   * Unlink Driver.
   * 
   * 
   */

  handleClose = () => this.setState({ isOpen: false });

  renderAlert = () => {
    return(
      <Modal
          blur
          open={this.state.isOpen}
          confirmButtonText="Unlink Driver"
          cancelButtonText="Cancel"
          onConfirm={()=>{

          }}
          onCancel={this.handleClose}
      >
          <Modal.Header>
            <Text size={18}>
              Are you sure you want to <b>unlink this driver</b>? You will not be able to undo this action.
            </Text>
          </Modal.Header>
          <Modal.Footer>
            <div className="flex">
              <Button
                size='sm'
                auto
                ghost
                css={{br:0, mr:10}}
                color='secondary'
                onClick={()=>{
                  this.handleClose();
                }}
              >
                Cancel
              </Button>
              <Button
                size='sm'
                auto
                color='error'
                css={{br:0}}
                onClick={()=>{
                  this.handleClose();
                  this.props.unlinkDriver(this.props.item.record_id);
                }}
              >
                Un-Link
              </Button>
            </div>
          </Modal.Footer>
      </Modal>
    );
  }

  openLinkLoModal = () => {
    this.setState({openLinkLogModal: true})
  }

  closeLinkLoModal = () => {
    this.setState({openLinkLogModal: false})
  }

  closeCannedMessageModal = () => {
    this.setState({openCannedMessageModal: false})
  }

  renderLinkLogs = () => {
    return(
      <AutoLinkLogs
        openLinkLogModalState={this.state.openLinkLogModal}
        selectedOrder={this.props.item}
        closeLinkModal={this.closeLinkLoModal}
      >
      </AutoLinkLogs>
    );
  }

  openPlotModal = () => {
    this.setState({openPlotModal: true})
  }

  closePlotModal = () => {
    this.setState({openPlotModal: false})
  }

  renderPlotMap = () => {
    return(
      <PlotLocation
        openPlotModalState={this.state.openPlotModal}
        selectedOrder={this.props.item}
        closeShareModal={this.closePlotModal}
      >
      </PlotLocation>
    );
  }

  renderShipmentStatus = () => {
    let highlightText
    let highlightstyle = {padding: 5, fontSize: '1.25rem', color: '#111111'}

    if(!this.props.item.transporter_client_id && this.props.item.is_transporter_fulfilled === 1){
      highlightText  = "Fulfilled by Carrier"
      highlightstyle = {...highlightstyle, background: '#EAEAEA'}
    }
    else{
      if(this.props.item.is_active !== 1){
        highlightText  = "Shipment Closed"
        highlightstyle = {...highlightstyle, background: '#EAEAEA'}
      }
      else{
        if(this.props.item.detention_text){
          highlightText  = this.props.item.detention_text
          highlightstyle = {...highlightstyle, background: '#F0D3FC'}
        }
        else{
          if(this.props.item.gps_eta_compute_failed !== 0){
            highlightText  = "Not Tracked, ETA Unknown"
            highlightstyle = {...highlightstyle, background: '#CBECFE'}
          }
          else{
            highlightText = this.props.item.delay_minutes > 0 ? "Delayed" : "On Track"
            highlightstyle = this.props.item.delay_minutes > 0 ? {...highlightstyle, background: '#FDA0A5'} : {...highlightstyle, background: '#DDFFED'}
          }
        }
      }
    }

    return(
      <span style={highlightstyle}>{highlightText}</span>
    )
  }

  renderHeaderInfo = () => {
    return(
      <div className="flex items-center w-90 mb1">
        {this.renderShipmentStatus()}
      </div>
    );
  }

  renderAppointmentTime = () => {
    let delay = this.timeConvert(this.props.item.delay_minutes);

    return(
      <div style={{overflowY: "auto", scrollbarWidth: "thin"}}>
        <div className="fw6">
          {`${this.props.item.appointmentTime1 ? 'Appointment Time: ' : 'Appointment Date: '}`} 
          {`${
            dayjs(this.props.item.StopDate).format("MMM Do YYYY") + " "
          }  ${this.props.item.appointmentTime1 ? dayjs('1/1/1 ' + this.props.item.appointmentTime1).format('hh:mm A') : '. Time not available.'}`}
        </div>
        <div className="fw6">
          {this.props.item.gps_eta_compute_failed === 0 && (
            <div
              className={
                this.props.item.delay_minutes > 0 ? "red" : "green"
              }
            >
              {delay}{" "}
              {this.props.item.delay_minutes > 0 ? "behind" : "before"}{" "}
              schedule
            </div>
          )}
        </div>
      </div>
    );
  }

  openLinkLogModal = () => {
    this.setState({ openLinkLogModal: true });
  }

  _renderDriverChangeBadge = () => {
    return (
      <div>
        Driver  
            <NextTooltip
              color="primary"
              content={
                <span>
                  {"Driver has begun tracking, but the phone number was changed. Click to view the activity log."}
                </span>
              }
            >
              {
                <span onClick={this.openLinkLogModal} className="ml2 pointer">
                  <Swap color='red' set='solid'/>
                </span>
              }     
            </NextTooltip>               
      </div>
    )
  }

  renderDriverStatus = () => {

    const showBadgeText = this.props.item.is_active && this.props.item.has_phone_number_changed && this.props.item.is_manually_linked === 0;
    return ( showBadgeText ? this._renderDriverChangeBadge() : <div>Driver</div>)
  }

  _DriverBadgeText = (renderText) =>{
    return (              
      <NextTooltip
        color="primary"
        content={
          <span>
            {"You may click to view the driver link activity log."}
          </span>
        }
        inline={true}
      >
        <span onClick={this.openLinkLogModal} className="badge badge-neutral mr2">{renderText}</span>
      </NextTooltip>  
    )
  }

  renderDriverText = () =>{
    const showBadgeText = !this.props.item.is_rejected && this.props.item.is_active && !this.props.item.last_ping_epoch

    return (
      <div className="flex">
        {showBadgeText && this.props.item.is_manually_linked === 1 ?  this._DriverBadgeText('Manual'): <span></span>}
        {showBadgeText && this.props.item.is_manually_linked === 0 ?  this._DriverBadgeText('Auto'): <span></span> }
        {this.props.item.driver_text}
      </div>
    )
  }

  renderLastPing = () => {
    let gpsText = `Refreshed ${dayjs.unix(this.props.item.last_ping_epoch).local().fromNow()}`

    return(
      this.props.item.last_ping_epoch && this.props.item.is_active ? ( 
        <NextTooltip
          color='primary'
          content={
            <span>
              {"Click to view the driver location on a map"}
            </span>
          }
        >
          <div onClick={() => { this.openPlotModal() }} >
            <Location color='red' set='bold'/>
            <div className="red"> {gpsText} </div>
          </div>
        </NextTooltip>
      ) : 
      <div className="fw6 red">
        GPS Info. Not Available
      </div>
    )
  }

   renderCardBody = () => {
    
    let driverTextStyle = this.props.item.is_rejected ? 'red' : 'black';

    return(
      <table className="mtt-table w-100 h-100 mtt-condensed mtt-interactive mt2 mtt-bordered">
        <tbody>
          <tr>
            <td>BOL</td>
            <td
              style={{
                backgroundColor: this.props.color,
                fontWeight: 600,
              }}
            >
              <span>{this.props.item.bol_number} </span>
              {this.props.item.transporter_client_id && (
                <span className="red ml4">{this.props.item.company_name}</span>
              )}
            </td>
          </tr>
          <tr>
            <td>Order ID</td>
            <td className="fw6">
              <span>{this.props.item.OrderNo} </span>
            </td>
          </tr>
          <tr>
            <td>
              {this.renderDriverStatus()}
            </td>
            <td className="fw6" style={{color: driverTextStyle}}>{this.renderDriverText()}</td>
          </tr>
          <tr>
            <td>Stop Name</td>
            <td>{this.props.item.StopName}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td className="fw6">
              {this.props.item.is_active === 1 ? "Open" : "Closed"}
            </td>
          </tr>
          <tr>
            <td>ETA</td>
            <td className="fw6">
              {this.props.item.gps_eta_compute_failed === 0
                ? this.props.item.expected_arrival_time
                : "Unknown"}
            </td>
          </tr>
          <tr>
            <td>Ship to</td>
            <td>
              <div
                style={{
                  height: 40,
                  overflowY: "auto",
                  scrollbarWidth: "thin",
                }}
              >
                {this.props.item.Address},{this.props.item.StopCity},{" "}
                {this.props.item.StopState}, {this.props.item.StopZip}
              </div>
            </td>
          </tr>
          <tr style={{height: 80}}>
            <td style={{width: 100}}>
              {this.renderLastPing()}
            </td>
            <td>
              {this.renderAppointmentTime()}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  /**
   * 
   * Main Render function.
   * 
   * 
   */
  render() {
    
    let findItem = this.props.selectedOrders.find(
      (element) => element.record_id === this.props.item.record_id
    );

    return (
      <div className="w-25 pr1 mt1" style={{minWidth: 350}}>
        {this.renderPlotMap()}
        {this.renderLinkLogs()}
        {this.renderAlert()}
        <Card
          style={{
            minHeight: 440,
            padding: 5,
            position: "relative"
          }}
          data-value={JSON.stringify({ key: this.props.item.record_id })}
        >
          <div className="flex justify-between items-center">
            {this.renderHeaderInfo()}
            {this.props.item.show_checkbox === 1 && (
              <Checkbox
                checked={findItem ? true : false}
                onChange={() => this.props.select(this.props.item)}
              />
            )}
          </div>

          <div style={{ height: 350 }}>
            {this.renderCardBody()}
          </div>
          
          {this.props.selectedOrders.length < 2 && (
            <div
              className="flex justify-between w-100 bt pt2"
            >
              {this.props.item.allow_send_message === 1 && (
                <NextTooltip color="primary" content={'Send a message to the driver'}>
                  <span
                    className="pointer"
                  >
                    <Message
                      size={18}
                      color='green'
                      onClick={() => {
                        this.setState({ openCannedMessageModal: true });
                      }}
                    />
                  </span>
                </NextTooltip>
              )}
              {this.props.item.show_unlink_button === 1 && (
                <NextTooltip color="error" content={'Click to Un-link driver. Please note, this action cannot be undone.'}>
                  <span
                    onClick={() => {
                      this.setState({isOpen: true});
                    }}
                  >
                    <img style={{ width: '18px' }} src={unlink} />
                  </span>
                </NextTooltip>
              )}
              {this.props.item.show_view_doc_button === 1 && (
                <NextTooltip color="primary" content={'View shipment documents.'}>
                  <span
                    className="pointer"
                  >
                    <Document 
                      size={18}
                      onClick={() => {
                        this.props.history.push(
                          this.props.item.OSD === 1
                            ? "/driver/os&d"
                            : "/driver/shipment-documents",
                          {
                            order: this.props.item,
                          }
                        );
                      }}
                    />
                  </span>
                </NextTooltip>
              )}
              {this.props.item.show_snooze_button === 1 && (
                <NextTooltip color="primary" content={'Snooze this order.'}>
                  <span
                    className="pointer"
                  >
                    <TimeCircle
                      size={18}
                      onClick={() => {
                        this.props.select(this.props.item);
                        this.props.openSnoozeModal();
                      }}
                    />
                  </span>
                </NextTooltip>
              )}
              {this.props.item.show_assign_rep_button === 1 && (
                <NextTooltip color="primary" content={'Assign this to order to a broker rep.'}>
                  <span
                    className="pointer"
                  >
                    <TwoUsers
                      color='blue'
                      set="bold"
                      size={18}
                      onClick={() => {
                        this.props.select(this.props.item);
                        this.props.openRefModal();
                      }}
                    />
                  </span>
                </NextTooltip>
              )}
              {this.props.item.show_share_button === 1 &&
                (this.props.access.allow_broker_carrier_driver_gps === 1 ||
                  this.props.access.allow_broker_pod_submission_free === 1) && (
                  <NextTooltip color="primary" content={'Link a driver to this shipment.'} placement="left">
                    <span
                      className="pointer">
                      <Discovery 
                        size={18}
                        color='green'
                        onClick={() => {
                          this.props.select(this.props.item);
                          this.props.openShareModal();
                        }}
                      />
                    </span>
                  </NextTooltip>
                )}
            </div>
          )}
          {this.props.item.allow_send_message === 1 && (
            <CannedMessageModal
              recordId={this.props.item.record_id}
              driver1Id={this.props.item.Driver1}
              driver2Id={this.props.item.Driver2}
              driver1UserId={this.props.item.driver1UserId}
              driver2UserId={this.props.item.driver2UserId}
              orderId={this.props.item.OrderNo}
              stopId={this.props.item.StopId}
              bolNumber={this.props.item.bol_number}
              clientId={
                this.props.item.transporter_client_id
                  ? this.props.item.transporter_client_id
                  : this.props.item.client_id
              }
              closeCannedMessageModal={this.closeCannedMessageModal}
              openCannedMessageModal={this.state.openCannedMessageModal}
              partnerDocument={
                this.props.item.transporter_client_id ? true : false
              }
            />
          )}
        </Card>
      </div>
    );
  }
}

export default OrderCard;
