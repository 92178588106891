import React, { Component } from "react";
import { Switch, Row, Input, Button, Modal, Text, Loading } from "@nextui-org/react";
import FeatureSelection from "../ProductSelection/FeatureSelection";
import { fetchWithJson } from "../../lib/api";
import { mstore } from "../../store";
import endpoint from "../../config/endpoint.json";
import { error, success } from "../../lib/userAlerts";
import { connect } from "react-redux";
import debounce from 'lodash/debounce';

let companyId;
let userId;

class AutoClose extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: {},
      fetching: false,
      autoCloseInterval: 5,
      numberOfSeats: 1,
      active_users: 1,
      seats: 1,
      isOpen: false,
    };
  }

  componentDidMount() {
    companyId = mstore.get("user", "company_id");
    userId = mstore.get("user", "id");

    this.fetchAutoCloseDetails();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.clientId !== this.props.clientId) {
      this.fetchAutoCloseDetails();
    }
  }

  updateInterval = debounce(() => {
    fetchWithJson(endpoint.dc_update_auto_close, {
      auto_close_interval: this.state.autoCloseInterval,
      company_id: companyId,
      is_auto_close_enabled: this.state.access.is_auto_close_enabled,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        throw new Error("Something went wrong");
      })
      .then((data) => {
        success("You successfully saved auto-close interval");
        this.setState({ access: data });
      })
      .catch((err) => {
        error("Oops! error occured during save auto close interval!");
        console.log(err.message);
      });
  }, 3000);

  handleValueChange = (e) => {

    let valueAsNumber = e.target.value, valueAsString = e.target.value

    if (!isNaN(parseFloat(valueAsString)) && isFinite(valueAsString)) {
      if (
        valueAsNumber < 5 ||
        valueAsNumber > 30 ||
        valueAsString.indexOf("-") >= 0
      ) {
        error(
          "Kindly set an autoclose interval of more than 5 days and less than 31 days"
        );
      } else {
        this.setState({ autoCloseInterval: valueAsNumber }, () => {
          this.updateInterval();
        });
      }
    } else {
      error("Only numbers allowed!");
    }
  };

  updateSeats = debounce(() => {
    success("Updating the number of seats. Hang on .. ");
    this.setState({isOpen: false, fetching: true});

    let valueAsNumber = Number(this.state.seats);
    fetchWithJson(endpoint.dc_update_seats, {
      number_of_seats: valueAsNumber,
      company_id: companyId,
      user_id: userId,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        if (res.status === 422) {
          error("Number of active users greater than number of seats");
          throw new Error("Something went wrong");
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        this.setState({ access: data });
        this.setState({ numberOfSeats: valueAsNumber, seats: valueAsNumber });
      })
      .catch((err) => {
        error("Sorry we are unable to process your request.");
        console.log(err.message);
      })
      .finally(()=>{
        this.setState({fetching: false})
      });
  }, 300);

  handleValueChangeSeats = (e) => {
    let valueAsString = e.target.value
    this.setState({ seats: valueAsString }, () => {
    });

    let debounceDialog = debounce(() => {
      this.setState({isOpen: true})
    }, 1000);
    
    debounceDialog();
  };

  closeDialog = () => {
    this.setState({ isOpen: false }, ()=>{
      let numberOfSeats = this.state.numberOfSeats;
      this.setState({seats: numberOfSeats})
    });
  }

  fetchAutoCloseDetails = () => {
    this.setState({fetching: true})
    fetchWithJson(endpoint.fetchSeatsAndAutoClose, { company_id: companyId })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          access: data,
          autoCloseInterval: data.auto_close_interval,
          numberOfSeats: data.seats,
          active_users: data.active_users,
          seats: data.seats,
        });
      })
      .catch((err) => {
        error("Oops! error occured during fetching auto-close details!");
        console.log(err.message);
      })
      .finally(()=>{
        this.setState({fetching: false})
      });
  };

  handleSwitch = () => {
    fetchWithJson(endpoint.dc_update_auto_close, {
      auto_close_interval: this.state.autoCloseInterval,
      company_id: companyId,
      is_auto_close_enabled:
        this.state.access.is_auto_close_enabled === 1 ? 0 : 1,
      })
      .then((res) => res.json())
      .then((data) => {
        if (this.state.access.is_auto_close_enabled === 1) {
          success("You successfully deactivated the Auto Close feature");
        } else {
          success("You successfully activated the Auto Close feature");
        }
        this.setState({ access: data });
      })
      .catch((err) => {
        error("Oops! error occured");
        console.log(err.message);
      });
  };

  renderSeats = () =>{
    const seatVal = Math.min(100, 100* (this.state.active_users/this.state.numberOfSeats))
    const barColor = seatVal === 100 ? "radial-progress text-error m-4"  : "radial-progress text-primary m-4" 

    return(
      <div className="flex justify-between w-100">
        <div className="lg:w-1/2 sm:w-full">
          <div className="f4">
            Seats
          </div>
          <div className="pt-16 flex">
            <span className="mr-4 pt-2 f5">
              Seats Provisioned
            </span>
            <Input
              min={0}
              type='number'
              width="100px"
              size='lg'
              className="mr3"
              underlined
              css={{ $$inputBorderRadius: "0", h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.state.seats}
              onChange={this.handleValueChangeSeats}
            />
          </div>

        </div>

        <div 
          className={barColor}
          style={{"--value":seatVal, "--size": "8rem", "--thickness": "4px" }} role="progressbar">
            <span className="font-bold leading-none opacity-70 antialiased flex items-center justify-between">
              {this.state.active_users} of {this.state.numberOfSeats} Used
            </span>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="w-100">
        <div className="flex flex-wrap justify-between mt3">
          <div
            className="ml2 p-4 pa border-2 border-dotted"
            style={{ width: '49%'}}
          >
            <div className="f4 mb4">Auto-close</div>
            <Row justify="space-between" className="mb3">
              <div className="f5 fw2">Enable / Disable feature</div>
              <Switch
                className="mr3"
                size='sm'
                checked={
                  this.state.access.is_auto_close_enabled === 1 ? true : false
                }
                onChange={this.handleSwitch}
              />
            </Row>

            <Row justify="space-between">
              <div className="f5 fw2">Auto close interval (days)</div>
              <Input
                type='number'
                width="70px"
                size='lg'
                className="mr3"
                underlined
                min={5}
                max={30}
                disabled={
                  this.state.access.is_auto_close_enabled === 1 ? false : true
                }
                value={this.state.autoCloseInterval}
                onChange={this.handleValueChange}
              />
            </Row>
          </div>

          <div
            className="border-2 border-dotted p-4"
            style={{ width: '49%'}}
          >
            {this.state.fetching ? <Loading css={{m: 30}}>Fetching seat count</Loading>: this.renderSeats()}
            <Modal
              width="480px"
              open={this.state.isOpen}
              onClose={this.closeDialog}
              blur
              closeButton
              css={{br: 0}}
            >
              <Modal.Header>
                <Text size={24}>Number of seats has been changed</Text>
              </Modal.Header>

              <Modal.Body>
                <Row justify='space-between'>
                  <div className="f4"> New value </div>
                  <div className="f4 fw8 mr3">{this.state.seats}</div>
                </Row>

                <Row justify='space-between'>
                  <div className="f4"> Old value </div>
                  <div className="f4 fw8 mr3">{this.state.numberOfSeats}</div>
                </Row>
                
              </Modal.Body>

              <Modal.Footer>
                <Button
                  css={{br: 0}}
                  auto
                  onClick={this.updateSeats}
                >
                  Update
                </Button>
              </Modal.Footer>
            </Modal>

          </div>
          {/* <div
          className="ml2 mt2 w-33 pa2"
          style={{ paddingLeft: "20px", backgroundColor: "#f9f7f7" }}
        >
          <GPSInterval />
        </div> */}
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  clientId: state.client
    ? state.client.id
    : mstore.getPath(["user", "company_id"]),
}))(AutoClose);
