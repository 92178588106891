import PropTypes from 'prop-types'
import createHistory from 'history/createBrowserHistory'

export const propTypes = {
  basename: PropTypes.string,
  forceRefresh: PropTypes.bool,
  getUserConfirmation: PropTypes.func,
  keyLength: PropTypes.number,
  children: PropTypes.node
}

let basename = '/'
let opts = {}
if (process.env.REACT_APP_BASENAME !== undefined) {
  basename = process.env.REACT_APP_BASENAME
  setTimeout(console.log, 200, 'Basename is', basename)
  opts['basename'] = basename
}
const history = createHistory()

if (process.env.REACT_APP_BASENAME !== undefined) {
  let _$savedPush = history.push.bind(history)
  // window.$$savedPush = _$savedPush
  // Patch history.push to prepend the basename
  history.push = (pathName, ...args) => {
    // console.log("Called Patched push", args)
      if (!pathName.startsWith(process.env.REACT_APP_BASENAME)) {
        if (!pathName.startsWith('/')) {
          pathName = '/' + pathName
        }
        pathName = process.env.REACT_APP_BASENAME + pathName
      }
    // console.log("Calling _$savedPush with args:", args)
    _$savedPush(pathName, ...args)
  }
}

function pathname(){
  let pname = history.location.pathname
  if(process.env.REACT_APP_BASENAME !== undefined && pname.startsWith(process.env.REACT_APP_BASENAME)){
    return pname.substr(process.env.REACT_APP_BASENAME.length)
  }
  return pname
}

export { history, basename, pathname }

export default history
