import React, { Component } from "react";
import { Input, Spacer } from '@nextui-org/react';


export default class OrderAndLocation extends Component {
  componentDidMount() {
  }

  renderFirstRow = () => {
    return (
      <div className="grid grid-cols-4 lg:gap-16 md-:gap-8">
        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">Order Number</span>
            </div>
            <Input
              type="text"
              placeholder="Enter Order ID"
              clearable
              onChange={(e) => {
                this.props.setOrderAndLocationToState(
                  "orderAndLocationDetails",
                  "orderId",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.orderId}
            />
          </label>
        </div>
        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">Customer Shipment ID</span>
            </div>
            <Input
              type="text"
              placeholder="Enter Customer Shipment ID"
              clearable
              onChange={(e) => {
                this.props.setOrderAndLocationToState(
                  "orderAndLocationDetails",
                  "customerShipmentId",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.customerShipmentId}
            />
          </label>
        </div>
        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">Enter Stop Sequence</span>
            </div>
            <Input
              type="number"
              placeholder="Enter Stop Sequence"
              onChange={(e) => {
                this.props.setOrderAndLocationToState(
                  "orderAndLocationDetails",
                  "stopSeq",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.stopSeq}
            />
          </label>
        </div>
        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">Stop Date</span>
            </div>
            <Input
              type="date"
              onChange={(e) => {
                this.props.setOrderAndLocationToState(
                  "orderAndLocationDetails",
                  "stopDate",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.stopDate}
            />
          </label>
        </div>
      </div>
    );
  };

  renderSecondRow = () => {
    return (
      <div className="grid grid-cols-4 lg:gap-16 md-:gap-8">
        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">Appointment Time 1</span>
            </div>
            <Input
              type='time'
              onChange={(e) => {
                this.props.setOrderAndLocationToState(
                  "orderAndLocationDetails",
                  "appointmentTime1",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.appointmentTime1}
            />
          </label>
        </div>

        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">Appointment Time 2</span>
            </div>
            <Input
              type='time'
              onChange={(e) => {
                this.props.setOrderAndLocationToState(
                  "orderAndLocationDetails",
                  "appointmentTime2",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.appointmentTime2}
            />
          </label>
        </div>
        
        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">Stop ID</span>
            </div>
            <Input
              type="text"
              placeholder="Enter Stop ID"
              clearable
              onChange={(e) => {
                this.props.setOrderAndLocationToState(
                  "orderAndLocationDetails",
                  "stopId",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.stopId}
            />
          </label>
        </div>

        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">Stop Name</span>
            </div>
            <Input
              type="text"
              placeholder="Enter Stop Name"
              clearable
              onChange={(e) => {
                this.props.setOrderAndLocationToState(
                  "orderAndLocationDetails",
                  "stopName",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.stopName}
            />
          </label>
        </div>
      </div>
    );
  };

  renderFourthRow = () => {
    return (
      <div className="grid grid-cols-4 lg:gap-16 md-:gap-8">
        <div className="col-span-3">
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">Address</span>
            </div>

            <Input
              type="text"
              placeholder="Enter Address"
              clearable
              onChange={(e) => {
                this.props.setOrderAndLocationToState(
                  "orderAndLocationDetails",
                  "address",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.address}
            />
          </label>
        </div> 

        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">City</span>
            </div>
            <Input
              type="text"
              placeholder="Enter City"
              clearable
              onChange={(e) => {
                this.props.setOrderAndLocationToState(
                  "orderAndLocationDetails",
                  "city",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.city}
            />
          </label>
        </div>  

      </div>
    );
  };

  renderFifthRow = () => {
    return (
      <div className="grid grid-cols-4 lg:gap-16 md-:gap-8">
        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">State</span>
            </div>
            <Input
              type="text"
              placeholder="Enter State"
              clearable
              onChange={(e) => {
                this.props.setOrderAndLocationToState(
                  "orderAndLocationDetails",
                  "state",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.state}
            />
          </label>
        </div>
        
        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">ZIP</span>
            </div>
            <Input
              type="text"
              placeholder="Enter Zip Code"
              clearable
              onChange={(e) => {
                this.props.setOrderAndLocationToState(
                  "orderAndLocationDetails",
                  "zip",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.zip}
            />
          </label>
        </div>
      </div>
    );
  };

  renderThirdRow = () => {
    return (
      <div className="grid grid-cols-4 lg:gap-16 md-:gap-8">
        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">Location Contact Name</span>
            </div>
            <Input
              type="text"
              placeholder="Enter Location Contact Name"
              clearable
              onChange={(e) => {
                this.props.setOrderAndLocationToState(
                  "orderAndLocationDetails",
                  "locationContactName",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.locationContactName}
            />
          </label>
        </div>

        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">Location Contact No.</span>
            </div>
            <Input
              type="text"
              placeholder="Enter Location Contact Number"
              clearable
              onChange={(e) => {
                this.props.setOrderAndLocationToState(
                  "orderAndLocationDetails",
                  "locationContactNumber",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.locationContactNumber}
            />
          </label>
        </div>
       
        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">Carrier Name</span>
            </div>
            <Input
              type="text"
              placeholder="Enter Carrier Name"
              clearable
              onChange={(e) => {
                this.props.setOrderAndLocationToState(
                  "orderAndLocationDetails",
                  "carrierName",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.carrierName}
            />
          </label>
        </div>

        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">Bill To</span>
            </div>
            <Input
              type="text"
              placeholder="Enter Bill To"
              clearable
              onChange={(e) => {
                this.props.setOrderAndLocationToState(
                  "orderAndLocationDetails",
                  "billTo",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.billTo}
            />
          </label>
        </div> 
      </div>
    );
  };

  render() {
    return (
      <div className="mt-4">
        {this.renderFirstRow()}
        <Spacer y={0.5}/>
        {this.renderSecondRow()}
        <Spacer y={0.5}/>
        {this.renderThirdRow()}
        <Spacer y={0.5}/>
        {this.renderFourthRow()}
        <Spacer y={0.5}/>
        {this.renderFifthRow()}
      </div>
    );
  }
}
