import React, { Component } from "react";
import { Button, Divider, Input } from '@nextui-org/react';
import dayjs from '../../lib/day'
import Select from "react-select";

export default class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // createColumnDefs: createColumnDefs()
    };
  }

  render() {

    const options = []

    this.props.ratingCategoryList.map(item => {
      options.push({value: item.id, label: item.cat_desc});
    })

    return (
      <div className="pa3" style={{ width: 400 }}>
        <label className="f5 w-100">
          Focus Area
        </label>
        <div className="w-100">
          <Select
            name="focus-area-type"
            style={{ width: "370px" }}
            placeholder="Focus Area"
            value={this.props.focusAreaId}
            onChange={(item) => {this.props.setValueToState("focusAreaId", item ? item.value: null ) }}
            options={options}
            matchPos="start"
            filterProp="value"
          />
        </div>
        <div className="f5 w-100 mt2">
          Date Range
        </div>
        <div className="w-100">
          <select
            className="w-100 db h2 f6 bg-white ba b--sliver black mb2"
            value={this.props.dateRangeId}
            style={{ width: "370px" }}
            onChange={(e) => {
              if (e.target.value === "1") {
                this.props.setValueToState(
                  "startDate",
                  dayjs().format("YYYY-MM-DD")
                );
                this.props.setValueToState(
                  "endDate",
                  dayjs().subtract(1, "days").format("YYYY-MM-DD")
                );
              } else if (e.target.value === "7") {
                this.props.setValueToState(
                  "startDate",
                  dayjs().format("YYYY-MM-DD")
                );
                this.props.setValueToState(
                  "endDate",
                  dayjs().subtract(7, "days").format("YYYY-MM-DD")
                );
              } else if (e.target.value === "30") {
                this.props.setValueToState(
                  "startDate",
                  dayjs().format("YYYY-MM-DD")
                );
                this.props.setValueToState(
                  "endDate",
                  dayjs().subtract(30, "days").format("YYYY-MM-DD")
                );
              } else if (e.target.value === "365") {
                this.props.setValueToState(
                  "startDate",
                  dayjs().format("YYYY-MM-DD")
                );
                this.props.setValueToState(
                  "endDate",
                  dayjs().subtract(365, "days").format("YYYY-MM-DD")
                );
              } else {
                this.props.setValueToState("startDate", "");
                this.props.setValueToState("endDate", "");
              }
              this.props.setValueToState("dateRangeId", e.target.value);
            }}
          >
            {this.props.dateRange.map((item) => {
              return (
                <option key={item.value} value={item.value}>
                  {item.lable}
                </option>
              );
            })}
          </select>
        </div>
        
        <div className="flex justify-between items-center">
          <Input
            type='date'
            placeholder="Enter Start Date"
            onChange={e => {
              this.props.setValueToState("endDate", e.target.value);
            }}
            css={{ $$inputBorderRadius: "0", w: 150, h: 30, mt: 5 }}
            shadow={false}
            bordered={true}
            rounded={false}
            value={this.props.endDate}
          />
          <div className="mt2">To</div>
          <Input
            type='date'
            placeholder="Enter To Date"
            onChange={e => {
              this.props.setValueToState("startDate", e.target.value);
            }}
            css={{ $$inputBorderRadius: "0", w: 150, h: 30, mt: 5 }}
            shadow={false}
            bordered={true}
            rounded={false}
            value={this.props.startDate}
          />
        </div>
        <div className="mt2">
          <label className="f5">
            Stop Name
          </label>
          <Input
            type="text"
            placeholder="Enter stop name"
            clearable
            onChange={e => {
              this.props.setValueToState("stopName", e.target.value);
            }}
            css={{ $$inputBorderRadius: "0", w: '100%', h: 30, mt: 5 }}
            shadow={false}
            bordered={true}
            rounded={false}
            value={this.props.stopName}
          />
        </div>

        <div className="flex justify-between mt2">
          <div>
            <div className="f5 w-100">
              Top Selection
            </div>
            
            <div className="w-100">
              <select
                className="w-100 db h2 f6 bg-white ba b--sliver black mb2"
                value={this.props.topSelectionId}
                onChange={(e) => {
                  this.props.setValueToState("topSelectionId", e.target.value);
                }}
              >
                {this.props.topSelection.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.lable}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div>
            <div className="f5 w-100">
              Top Category
            </div>

            <div className="w-100">
              <select
                value={this.props.topCategoryId}
                className="w-100 db h2 f6 bg-white ba b--sliver black mb2"
                onChange={(e) => {
                  this.props.setValueToState("topCategoryId", e.target.value);
                }}
              >
                {this.props.topCategory.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.lable}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

        </div>
        <Divider css={{mt: 10}}/>
        <div className="flex justify-end mt2">
          <Button
            auto
            size='sm'
            css={{br:0}}
            onClick={this.props.handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    );
  }
}
