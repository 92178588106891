import React, { Component } from 'react'
import { fetchWithJson } from "../../../lib/api";
import { error } from "../../../lib/userAlerts";
import endpoint from "../../../config/endpoint.json";
import { ResponsiveLine } from '@nivo/line'
import cloneDeep from 'lodash/cloneDeep';

export default class TrendArtifax extends Component {

    constructor(props) {
        super(props);
        this.state = { 
          data: []
        }
    }

    componentDidMount(){
      if(this.props.clientId){
          this.fetchData(this.props.clientId)
      }
    }

    componentWillReceiveProps(newProps) {
        if(newProps && newProps.clientId >= 1 && newProps.clientId !== this.props.clientId){
            this.fetchData(newProps.clientId)
        }
    }

    fetchData = async(clientId) =>{
      try {
        this.setState({data: []})
        const res = await fetchWithJson(endpoint.statsStopTrends, {
            company_id: clientId,
        });

        if (res.status !== 200) {
            error('Unable to fetch shipment trends')
        }
        else{
          const data = await res.json();
        
          if(data && data.trends && data.trends.length > 0){
            this.massageData(data.trends)
          }
        }
      } 
      catch (err) {
          console.log(err);
          error('Unable to fetch shipment trends')
      }
    }

    massageData = (trends) => {

      let trendData = {}
      let finalTrends = []

      for(let i =0; i< trends.length; i++){
        const trendRow = cloneDeep(trends[i])
        const trendKey = trendRow.metricType

        if(trendKey){
          let trendKeyData = trendData.hasOwnProperty(trendKey) ? cloneDeep(trendData[trendKey].data) : []
          
          //Push new metric
          const trendMetric = {x: trendRow.metricDate, y: trendRow.value}
          trendKeyData.push(trendMetric)

          //Update master JSON
          trendData[trendKey] = {
            id: trendKey,
            data: trendKeyData
          }
        }
      }

      const trendKeys = Object.keys(trendData)

      trendKeys.forEach((k) =>{
        const trendItem = cloneDeep(trendData[k])
        finalTrends.push(trendItem)
      })

      //console.log(trendData, finalTrends)
      this.setState({
        data: finalTrends
      })
    }

    plotGraph = () => {
      return(
        <ResponsiveLine
          data={this.state.data}
          enableSlices="x"
          margin={{ top: 50, right: 150, bottom: 50, left: 60 }}
          xScale={{ type: 'point' }}
          colors={{ scheme: 'dark2' }}
          yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
              reverse: false
          }}
          yFormat=" >-.2f"
          curve="natural"
          axisTop={null}
          axisRight={null}
          axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Date',
              legendOffset: 36,
              legendPosition: 'middle'
          }}
          axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Count',
              legendOffset: -50,
              legendPosition: 'middle'
          }}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[
              {
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: 'left-to-right',
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 10,
                  symbolShape: 'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  effects: [
                      {
                          on: 'hover',
                          style: {
                              itemBackground: 'rgba(0, 0, 0, .03)',
                              itemOpacity: 1
                          }
                      }
                  ]
              }
          ]}
        />
      )
    }

    plotNoGraph = () =>{
      return(
          <p className="h-100 f4 fw2 tc" style={{position: 'relative', top: '50%'}}>
            Shipment Trends not available
          </p>
      )
    }

    render() {
        return (
          <div className='ba b--dotted bw1 mt2' style={{height: "60vh", width: "calc(100% - 20px)", marginLeft: 20}}>
            {this.state.data && this.state.data.length > 0 ? this.plotGraph() : this.plotNoGraph()}
          </div>
        );
    }
}


