import React, { Component } from "react";
import {
  Button,
  Switch,
  Radio,
  Divider,
} from "@nextui-org/react";
import { Loading, Modal, Text, Input, Button as NextButton} from '@nextui-org/react';

import FeatureSelection from "../ProductSelection/FeatureSelection";
import { secureFetch } from "../../lib/api";
import { success, warning } from "../../lib/userAlerts";

import { mstore } from "../../store";
import endpoint from "../../config/endpoint.json";
import { error } from "../../lib/userAlerts";
import { connect } from "react-redux";

import CrossCompanySettings from './CrossCompanySettings';
import { PaperPlus } from "react-iconly";

class ManageArtifacts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      artifactsList: [],
      artifactsListc: [],
      addArtifacts: false,
      editArtifacts: false,
      deleteArifacts: false,
      loading: false,
      loadinge: false,
      addArtifactsName: "",
      newArtifactsName: "",
      oldArtifactsName: "",
      deleteArifactsName: "",
      fetching: true,
      editButtonClicked: false,
      editIndex: null,
    };
  }

  componentDidMount() {
    this.getArtifactsList();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.clientId !== this.props.clientId) {
      this.getArtifactsList();
    }
  }

  getArtifactsList = () => {
    secureFetch(endpoint.getArtifactsList, {}).then(([ok, status, json]) => {
      if (ok) {
        this.setState({
          artifactsList: json.artifactsList,
          loading: false,
          fetching: false,
          artifactsListc: JSON.parse(JSON.stringify(json.artifactsList)),
        });
      } else {
        error("Oops! error occured during fetching artifacts list!");
      }
    });
  };

  // updateIsGrayScaleEnable

  handleSwitch = (obj) => {
    if (obj.status === 1) {
      this.deleteArifacts(obj);
    } else {
      this.recoverArifacts(obj);
    }
  };

  handleAutoCrop = (obj) => {
    if (obj.is_scanner_enable === 1) {
      this.disableAutoCrop(obj);
    } else {
      this.enableAutoCrop(obj);
    }
  };

  handlePartnerVisibility = (obj) => {
    if (obj.partner_visibility === 1) {
      this.disablePartnerVisibility(obj);
    } else {
      this.enablePartnerVisibility(obj);
    }
  };

  handleGrayScaleImage = (obj) => {
    secureFetch(endpoint.updateIsGrayScaleEnable, {
      type_name: obj.type_name,
      grayScaleEnable: obj.is_grayscale_enable === 1 ? 0 : 1,
    }).then(([ok, status, json]) => {
      if (ok) {
        this.getArtifactsList();
        success(json.message);
        this.setState({
          loading: false,
        });
      } else {
        error("Oops! error occured during update gray scale features!");
      }
    });
  };

  setIsPod = (obj) => {
    if (obj.is_osd === 1) {
      error("This artifacts type is already slected as OS&D");
      return;
    }

    if (obj.is_bol === 1) {
      error("This artifacts type is already slected as BOL");
      return;
    }

    secureFetch(endpoint.setIsPod, { type_name: obj.type_name }).then(
      ([ok, status, json]) => {
        if (ok) {
          this.getArtifactsList();
          success(json.message);
          this.setState({
            loading: false,
          });
        } else {
          error("Oops! error occured during set is pod features!");
        }
      }
    );
  };

  removePod = () => {
    secureFetch(endpoint.removeIsPod, {}).then(([ok, status, json]) => {
      if (ok) {
        this.getArtifactsList();
        success(json.message);
      } else {
        error("Oops! error occured during remove is pod features!");
      }
    });
  };

  setIsBOL = (obj) => {
    if (obj.is_pod === 1) {
      error("This artifacts type is already slected as POD");
      return;
    }

    if (obj.is_osd === 1) {
      error("This artifacts type is already slected as OS&D");
      return;
    }

    secureFetch(endpoint.setIsBol, { type_name: obj.type_name }).then(
      ([ok, status, json]) => {
        if (ok) {
          this.getArtifactsList();
          success(json.message);
          this.setState({
            loading: false,
          });
        } else {
          error("Oops! error occured during set BOL features!");
        }
      }
    );
  };

  removeBOL = () => {
    secureFetch(endpoint.removeIsBol, {}).then(([ok, status, json]) => {
      if (ok) {
        this.getArtifactsList();
        success(json.message);
      } else {
        error("Oops! error occured during remove is BOL features!");
      }
    });
  };

  setIsOsd = (obj) => {
    if (this.props.Access.allow_osd === 0) {
      error("Please enable os&d features");
      return;
    }

    if (obj.is_pod === 1) {
      error("This artifacts type is already slected as POD");
      return;
    }

    if (obj.is_bol === 1) {
      error("This artifacts type is already slected as BOL");
      return;
    }

    secureFetch(endpoint.setIsOsd, { type_name: obj.type_name }).then(
      ([ok, status, json]) => {
        if (ok) {
          this.getArtifactsList();
          success(json.message);
          this.setState({
            loading: false,
          });
        } else {
          error("Oops! error occured during set is OS&D features!");
        }
      }
    );
  };

  removeOsd = () => {
    if (this.props.Access.allow_osd === 0) {
      error("Please enable os&d features");
      return;
    }

    secureFetch(endpoint.removeIsOsd, {}).then(([ok, status, json]) => {
      if (ok) {
        this.getArtifactsList();
        success(json.message);
      } else {
        error("Oops! error occured during remove is OS&D features!");
      }
    });
  };

  disableAutoCrop = (obj) => {
    secureFetch(endpoint.disableAutoCrop, { type_name: obj.type_name }).then(
      ([ok, status, json]) => {
        if (ok) {
          this.getArtifactsList();
          success(json.message);
          this.setState({
            loading: false,
          });
        } else {
          error("Oops! error occured during disable auto crop features!");
        }
      }
    );
  };

  enableAutoCrop = (obj) => {
    secureFetch(endpoint.enableAutoCrop, { type_name: obj.type_name }).then(
      ([ok, status, json]) => {
        if (ok) {
          this.getArtifactsList();
          success(json.message);
          this.setState({
            loading: false,
          });
        } else {
          error("Oops! error occured during enable auto crop features!");
        }
      }
    );
  };

  disablePartnerVisibility = (obj) => {
    secureFetch(endpoint.disablePartnerVisibility, { type_name: obj.type_name }).then(
      ([ok, status, json]) => {
        if (ok) {
          this.getArtifactsList();
          success(json.message);
          this.setState({
            loading: false,
          });
        } else {
          error("Oops! error occured during disable partner visibility!");
        }
      }
    );
  };

  enablePartnerVisibility = (obj) => {
    secureFetch(endpoint.enablePartnerVisibility, { type_name: obj.type_name }).then(
      ([ok, status, json]) => {
        if (ok) {
          this.getArtifactsList();
          success(json.message);
          this.setState({
            loading: false,
          });
        } else {
          error("Oops! error occured during enable partner visibility!");
        }
      }
    );
  };

  addArtifacts = () => {
    this.setState({ loading: true });
    secureFetch(endpoint.addArtifacts, {
      type_name: this.state.addArtifactsName,
    }).then(([ok, status, json]) => {
      if (ok) {
        success(json.message);
        this.getArtifactsList();
        this.setState({ loading: false, addArtifactsName: "" });
      } else {
        error("Oops! error occured during add new artifacts!");
      }
    });
  };

  editArtifacts = (obj) => {
    if (obj.status !== 1) {
      return;
    } else {
      this.setState({ editButtonClicked: true, editIndex: obj.id });
    }
  };

  saveArtifacts = (obj) => {
    if (obj.status !== 1) {
      return;
    } else {
      if (
        this.state.artifactsListc.findIndex((item) => {
          return item.type_name === obj.type_name.trim();
        }) === -1
      ) {
        this.setState({ loadinge: true });
        secureFetch(endpoint.editArtifacts, {
          new_type_name: obj.type_name,
          id: obj.id,
        }).then(([ok, status, json]) => {
          if (ok) {
            this.getArtifactsList();
            success(json.message);
            this.setState({ editButtonClicked: false });
          } else {
            error("Oops! error occured during save artifacts!");
          }
        });
      } else {
        warning("This artifact type already exist");
        this.setState({ editButtonClicked: false });
      }
    }
  };

  deleteArifacts = (obj) => {
    if (this.state.editButtonClicked) {
      warning("Please save first");
    } else {
      this.setState({ loading: true });
      secureFetch(endpoint.deleteArtifacts, { type_name: obj.type_name }).then(
        ([ok, status, json]) => {
          if (ok) {
            this.getArtifactsList();
            success(json.message);
            this.setState({
              loading: false,
              deleteArifactsName: "",
              isActive: false,
            });
          } else {
            error("Oops! error occured during delete artifacts!");
          }
        }
      );
    }
  };

  recoverArifacts = (obj) => {
    this.setState({ loading: true });
    secureFetch(endpoint.recoverArtifacts, { type_name: obj.type_name }).then(
      ([ok, status, json]) => {
        if (ok) {
          this.getArtifactsList();
          success(json.message);
          this.setState({ loading: false, isActive: true });
        } else {
          error("Oops! error occured during recover artifacts!");
        }
      }
    );
  };

  onCancel = () => {
    this.setState({ addArtifacts: false });
  };

  renderUserRow = (obj, idx) => {
    return (
      <tr key={idx} className="flex">
        <td className="f5 fw2 flex flex-start items-center" style={{
          width: '12.5%'
        }}>
          <div>{obj.type_name}</div>
        </td>
        <td className="f5 flex justify-center items-center h3" style={{
          width: '12.5%'
        }}>
          {
            <Switch
              size='sm'
              // label={obj.status === 1 ? "Active" : "Inactive"}
              checked={obj.status === 1 ? true : false}
              onChange={this.handleSwitch.bind(null, obj)}
            />
          }
        </td>
        <td className="flex justify-center items-center h3" style={{
          width: '12.5%'
        }}>
          {
            <Switch
              size='sm'
              disabled={obj.status === 0 ? true : false}
              // label="Auto Crop"
              checked={obj.is_scanner_enable === 1 ? true : false}
              onChange={this.handleAutoCrop.bind(null, obj)}
            />
          }
        </td>
        <td className="flex justify-center items-center h3" style={{
          width: '12.5%'
        }}>
          {
            <Switch
              size='sm'
              disabled={obj.status === 0 ? true : false}
              // label="Auto Crop"
              checked={obj.is_grayscale_enable === 1 ? true : false}
              onChange={this.handleGrayScaleImage.bind(null, obj)}
            />
          }
        </td>
        <td className="flex justify-center items-center h3" style={{
          width: '12.5%'
        }}>
          {
            <Radio
              size='sm'
              disabled={obj.status === 0 ? true : false}
              checked={obj.is_pod === 1 ? true : false}
              onChange={this.setIsPod.bind(null, obj)}
              onClick={() => {
                if (obj.is_pod === 1) {
                  this.removePod();
                }
              }}
            />
          }
        </td>
        <td className="flex justify-center items-center h3" style={{
          width: '12.5%'
        }}>
          {
            <Radio
              size='sm'
              disabled={obj.status === 0 ? true : false}
              checked={obj.is_bol === 1 ? true : false}
              onChange={this.setIsBOL.bind(null, obj)}
              onClick={() => {
                if (obj.is_bol === 1) {
                  this.removeBOL();
                }
              }}
            />
          }
        </td>
        <td className="flex justify-center items-center h3" style={{
          width: '12.5%'
        }}>
          {
            <Radio
              size='sm' 
              disabled={obj.status === 0 ? true : false}
              checked={obj.is_osd === 1 ? true : false}
              onChange={this.setIsOsd.bind(null, obj)}
              onClick={() => {
                if (obj.is_osd === 1) {
                  this.removeOsd();
                }
              }}
            />
          }
        </td>
        <td className="f5 flex justify-center items-center h3" style={{
          width: '12.5%'
        }}>
          {
            <Switch
              size='sm'
              disabled={obj.status === 0 ? true : false}
              checked={obj.partner_visibility === 1 ? true : false}
              onChange={this.handlePartnerVisibility.bind(null, obj)}
            />
          }
        </td> 
      </tr>
    );
  };

  render() {
    if(this.props.Access.allow_artifax !== 1){
      return(
        <div role="alert" className="alert alert-warning w-1/2 mt-16 ">
          <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="white" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
          <span className="">Please subscribe to <b>Shipment Documents</b> feature</span>
        </div>
      )
    }

    return (
      <div className="ml2 mt2 w-100">
        {this.state.fetching && (
          <div className="flex justify-center mt6">
            <Loading size="md">Fetching Shipment Artifax™..</Loading>
          </div>
        )}
        <div className="">
          {this.props.Access.allow_artifax === 1 && !this.state.fetching && this.state.artifactsList.length !== 0 && (
            <div
              className="ml2 my-4 border-2 border-dotted p-4 mr2"
              style={{ paddingLeft: "20px" }}
            >
              <div className="flex justify-between mb4">
                <div className="f4 mb2">
                  Shipping Document Types
                </div>
                <NextButton
                  icon={<PaperPlus/>}
                  auto
                  css={{br: 0}}
                  onClick={() => {
                    this.setState({
                      addArtifacts: !this.state.addArtifacts,
                      editArtifacts: false,
                      deleteArifacts: false,
                    });
                  }}
                >
                  <Text color="white" size="1rem">Create Artifax™ Type</Text>
                </NextButton>
              </div>

              <table
                className={"flex flex-column"}
              >
                <tr className="flex pb3">
                  <th className="flex flex-start items-center text-blue-gray-900 gap-2 font-bold opacity-70" style={{
                    width: '12.5%'
                  }}>
                    Document Types
                  </th>
                  <th className="flex justify-center items-center text-blue-gray-900 font-bold opacity-70" style={{
                    width: '12.5%'
                  }}>
                    Active
                  </th>
                  <th className="flex justify-center items-center text-blue-gray-900 font-bold opacity-70" style={{
                    width: '12.5%'
                  }}>
                    Auto Crop Image
                  </th>
                  <th className="flex justify-center items-center text-blue-gray-900 font-bold opacity-70" style={{
                    width: '12.5%'
                  }}>
                    Grascale Image
                  </th>
                  <th className="flex justify-center items-center text-blue-gray-900 font-bold opacity-70" style={{
                    width: '12.5%'
                  }}>
                    Is POD
                  </th>
                  <th className="flex justify-center items-center text-blue-gray-900 font-bold opacity-70" style={{
                    width: '12.5%'
                  }}>
                    Is BOL
                  </th>
                  <th className="flex justify-center items-center text-blue-gray-900 font-bold opacity-70" style={{
                    width: '12.5%'
                  }}>
                    Is OS&D
                  </th>
                  <th className="flex justify-center items-center text-blue-gray-900 font-bold opacity-70" style={{
                    width: '12.5%'
                  }}>
                    Partner Visibility
                  </th>
                </tr>
                <Divider/>
                <tbody
                >
                  {this.state.artifactsList.map((obj, idx) =>
                    this.renderUserRow(obj, idx)
                  )}
                </tbody>
              </table>
            </div>
          )}

            <Modal
                closeButton
                blur
                preventClose
                aria-labelledby="Add Message"
                open={this.state.addArtifacts}
                onClose={this.onCancel}
            >
              <Modal.Header>
                  <Text id="modal-title" size={18}>
                    Add a new Artifax™ Type
                  </Text>
              </Modal.Header>

              <Modal.Body css={{py: 40}}>
                <Input 
                  underlined
                  clearable 
                  bordered 
                  required 
                  labelPlaceholder="Your Artifax Name"
                  onChange={(e) => {
                    this.setState({ addArtifactsName: e.target.value });
                  }}
                  value={this.state.addArtifactsName}
                  />
              </Modal.Body>

              <Modal.Footer>
                <NextButton
                  auto
                  disabled={this.state.loading}
                  onClick={() => {
                    if (this.state.addArtifactsName) {
                      if (
                        this.state.artifactsList.findIndex((item) => {
                          return (
                            item.type_name.toLowerCase() === this.state.addArtifactsName.trim().toLocaleLowerCase()
                          );
                        }) === -1
                      ) {
                        this.addArtifacts();
                        this.getArtifactsList();
                      } else {
                        error("This Artifax type already exists. Do note, Artifax names are case-insensitive.");
                      }
                    } else {
                      warning("Please enter name of Artifax");
                    }
                  }}
                >
                {this.state.loading ? <Loading type="points" color="white"></Loading> : <Text color="white">Submit</Text>}
                </NextButton>
              </Modal.Footer>
          </Modal>

        </div>
        {!this.state.fetching && this.state.artifactsList.length === 0 && (
          <div>
            <div className=" mt3 f5">No Artifax™ Found.</div>
            <Button
              text="Add Artifax Type"
              onClick={() => {
                this.setState({
                  addArtifacts: !this.state.addArtifacts,
                  editArtifacts: false,
                  deleteArifacts: false,
                });
              }}
            />
          </div>
        )}
        <CrossCompanySettings/>
      </div>
    );
  }
}

export default connect((state) => ({
  Access: state.navigation.Access,
  clientId: state.client
    ? state.client.id
    : mstore.getPath(["user", "company_id"]),
}))(ManageArtifacts);
