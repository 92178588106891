import React from 'react';
import PropTypes from 'prop-types';

import TruckImage from "../../assets/truck-loaded.svg";
import StartImage from "../../assets/start-detention.png";
import ExitImage from "../../assets/exit-detention.svg";
import GateImage from "../../assets/gate.png";

const ExitMarkerComponent = () =>  <img style={{ width: '100%', height: '100%', opacity:0.8 }} src={ExitImage}/>;
const DockMarkerComponent = () =>  <img style={{ width: '100%', height: '100%', opacity:0.8 }} src={TruckImage}/>;
const GateMarkerComponent = () => <img style={{ width: '85%', height: '85%' }} src={GateImage}/>;
const StartMarkerComponent = () => <img style={{ width: '85%', height: '85%' }} src={StartImage}/>;

const Marker = ({ text, onClick }) => {

    let renderMe = (text === 1) ? <StartMarkerComponent/> : ( text === 99 ? <ExitMarkerComponent/> : (text === 2 ? <GateMarkerComponent/>: <DockMarkerComponent/>) )
    let zIndex = (text === 1) ? 2 : ( text === 99 ? 4 : (text === 2 ? 8 : 16) )

    return (
        <div
            className='marker'
            alt={text}
            onClick={onClick}
            style={{zIndex: zIndex, opacity: 0.7}}
        >
            {renderMe}
        </div>
    )
};

Marker.defaultProps = {
  onClick: null,
};

Marker.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default Marker;
