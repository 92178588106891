import React, { PureComponent, Fragment } from 'react'
import { Button, Loading } from '@nextui-org/react';
import { StripeProvider } from 'react-stripe-elements'

import CardInput from '../../components/CardInput'
import { mstore } from '../../store'
import { error as errorNotif, success as successNotif } from '../../lib/userAlerts';
import { secureFetch } from '../../lib/api';
import endpoint from '../../config/endpoint'
import { updateTitle } from "../../lib/utils";

import logo from '../../assets/stripe.png';
import { CreditCard } from '../../icons/CreditCard';

class PaymentInformation extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      selectedProduct: 'current_month',
      selectedMonth: '',
      select: false,
      holderName: mstore.get('user', 'name') || '',
      savedCards: [],
      clicked: false,
      submitClicked: false,
      fetching: true,
      loadingCard: -1,
    }
    this.cardValue = null
  }

  stopLoading = () => {
    this.setState({clicked: false})
  }

  setCardValue = (v) => {
    this.cardValue = v
  }

  setHolderName = (name) => {
    this.setState({ holderName: name })
  }

  handleSave = () => {
    this.setState({
      submitClicked: true,
      fetching: true
    })
    if (this.props.onSave)
      return this.props.onSave(this.state)
  }

  handleCardSubmit = async evt => {
    evt.preventDefault()
    // FIXME: stripe is not giving the card number to us
    if (!this.stripe) {
      console.error("Stripe not loaded")
      return errorNotif('Cannot add card! Please Reload and try')
    } else {
      const { token, error } = await this.stripe.createToken({
        name: mstore.get('user', 'name')
      })
      if (error) {
        console.error(error)
        return errorNotif('Couldn\'t add card!')
      }
      this.setState({ fetching: true })
      secureFetch(endpoint.stripeToken + '?operation=add', {
        token
      }).then(([ok, status, res]) => {
        if (ok) {
          this.fetchCardDetails()
          successNotif(res.message)
        } else {
          res.json().then(r => errorNotif(r.message))
          this.setState({ fetching: false })
        }
      }).catch(err => {
        console.error(err)
        this.setState({ fetching: false })
        errorNotif('Oops! Something is wrong') // NETWORK probably
      })
    }
  }

  handleDefault = async (token, idx) => {
    // FIXME: stripe is not giving the card number to us
    if (!this.stripe) {
      console.error("Stripe not loaded")
      return errorNotif('Cannot add card! Please Reload and try')
    } else {
      this.setState({ fetching: true, loadingCard: idx })
      secureFetch(endpoint.stripeToken + '?operation=set_default', {
        token
      }).then(([ok, status, res]) => {
        if (ok) {
          this.fetchCardDetails()
          successNotif('Successfully updated default card')
        } else {
          this.setState({ fetching: false, loadingCard: -1 })
          res.json().then(r => errorNotif(r.message))
        }
      }).catch(err => {
        this.setState({ fetching: false, loadingCard: -1 })
        console.error(err)
        errorNotif('Opps! Something is wrong') // NETWORK probably
      })
    }
  }

  deleteCard = async (token, idx) => {
    // FIXME: stripe is not giving the card number to us
    if (!this.stripe) {
      console.error("Stripe not loaded")
      return errorNotif('Cannot add card! Please Reload and try')
    } else {
      if (this.state.savedCards.length < 2) {
        return errorNotif('You have only one card left')
      }
      this.setState({ fetching: true, loadingCard: idx })
      secureFetch(endpoint.stripeToken + '?operation=remove', {
        token
      }).then(([ok, status, res]) => {
        if (ok) {
          this.fetchCardDetails()
          successNotif('Successfully deleted card')
        } else {
          this.setState({ fetching: false, loadingCard: -1 })
          res.json().then(r => errorNotif(r.message))
        }
      }).catch(err => {
        this.setState({ fetching: false, loadingCard: -1 })
        console.error(err)
        errorNotif('Opps! Something is wrong') // NETWORK probably
      })
    }
  }

  handleStripeForm = formRef => {
    if (formRef) {
      formRef.addEventListener('submit', this.handleCardSubmit)
    }
  }

  setStripeWalaRef = $ref => {
    if ($ref) {
      setTimeout(() => { // FIXME: <><><>
        if (!$ref.stripe)
          return this.setStripeWalaRef($ref)
        this.stripe = $ref.stripe
      }, 1000)
    }
  }

  fetchCardDetails = () => secureFetch(endpoint.stripeToken)
    .then(([ok, status, res]) => {
      if (ok) {
        this.setState({ savedCards: res, fetching: false, loadingCard: -1 })
      } else {
        this.setState({ fetching: false, loadingCard: -1 })
        res.json().then(j => errorNotif(j.message))
          .catch(() => errorNotif('Opps! An error occured'))
      }
    }).catch(err => {
      console.error(err)
      this.setState({ fetching: false, loadingCard: -1 })
      errorNotif('Opps! Something is wrong') // NETWORK probably
    })

  componentDidMount() {
    this.fetchCardDetails()
    updateTitle("Payment Management");
  }

  componentWillUnmount() {
  }

  componentDidCatch(err) {
    errorNotif('Oops! We have encountered an error')
  }

  render() {
    setTimeout( this.stopLoading , 5000)

    return (
      <div className='lg:px-32'>
        <div className="flex justify-between py-8">
          <div className="w-100-m shadow-lg p-6 bg-white" style={{width: '600px'}}>
            <div className="flex items-center mb2">
                {this.state.fetching && <Loading size='xs'css={{mr: 10}}/>} 
                <label className="f4 fw7 mb3">Enter card details</label>
            </div>
            <CardInput
              inputFormRef={this.setStripeWalaRef}
              formRef={this.handleStripeForm}
              holderName={this.state.holderName}
              onNameChange={this.setHolderName}
              onCardValueChange={this.setCardValue}
            />
          </div>

          <div className="flex flex-column w-2/5 bg-white p-6 shadow-lg">
              <div className="flex items-center mb2">
                <label className="f4 fw7 pb-4 w-full">Saved Cards</label>
                {this.state.fetching && <Loading size='md'css={{ml: 20}}/>} 
              </div>

              <div className="flex items-center-m pt-4">
                <div className="w-100">
                  <table class="f6 w-100 mw8 center" cellspacing="0">
                    <thead>
                      <tr>
                        <th class="fw6 bb b--black-20 tl pb3 pr3">Card Details</th>
                        <th class="fw6 bb b--black-20 tl pb3 pr3">Card Status</th>
                      </tr>
                    </thead>
                    <tbody class="lh-copy">
                    {this.state.savedCards.map((cd, idx) => {
                      return(
                        <Fragment>
                          <tr>
                            <td class="pv3 pr3 bb b--black-20">
                              <div className='flex'>
                                <span className="f4 mr3 fw2">{cd.card_brand}</span>
                                <CreditCard height={24} width={24}/>
                                <span className='f5 fw2 dib mh2'>XXXX-XXXX-XXXX-{cd.card_last4}</span>
                                <span className='f5 fw2 bg-washed-yellow dib i'>({cd.exp_month}/{cd.exp_year})</span>
                              </div>
                            </td>

                            <td class="pv3 pr3 bb b--black-20">
                              {cd.is_default ?
                                <Button color='success' disabled css={{br: 0}} size='sm'>Default Card</Button>  :
                                <Button 
                                  flat
                                  size='sm'
                                  css={{br: 0}}
                                  onClick={this.handleDefault.bind(null, cd, idx)}>
                                  {idx === this.state.loadingCard ? <Loading size='sm'/> : 'Make Default'}
                                </Button>
                              }
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div> 

        <div className="py-8">
          <img src={logo} style={{height: 30}}/>
        </div>

      </div>
    )

  }
}


export default props => (
  <StripeProvider apiKey={process.env.REACT_APP_STRIPE_APIKEY}>
    <PaymentInformation {...props} />
  </StripeProvider>
)
