import React, { Component } from "react";
import { Modal, Text, Button as NextButton } from '@nextui-org/react';
import Select from "react-select";

class SnoozeModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    // const clientId = mstore.get("user", "company_id");
    return (
      <Modal
          width="350px"
          closeButton
          blur
          aria-labelledby="Snooze Order"
          open={this.props.openSnoozeModalState}
          onClose={this.props.closeSnoozeModal}
      >
        <Modal.Header>
          <Text size="1.25rem">
            Snooze Order(s)
          </Text>
        </Modal.Header>
        <Modal.Body>
          <div style={{paddingBottom: 200}}>
            <Select
              name="form-field-name"
              style={{ width: "300px" }}
              placeholder="Select snooze bucket"
              value={this.props.selectedSnoozeBucket}
              onChange={(data) => {
                this.props.handleSelectSnoozeBucket(data)
              }}
              options={this.props.selectSnoozeBucketList}
              matchPos="start"
              filterProp="value"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <NextButton
            auto
            css={{br: 0}}
            onClick={() => {
              this.props.snoozeMultiple();
            }}
          >
            Snooze Me!
          </NextButton>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SnoozeModal;
