import React, { Component } from "react";
import "react-phone-input-2/lib/style.css";
import GoogleMapReact from 'google-map-react';
import TruckImage from "../../assets/truck_gps_icon.png";
import { Modal, Text } from "@nextui-org/react";

const MapMarkerComponent = () =>  <img style={{ width: 60, height: 60 }} src={TruckImage}/>;

class PlotLocation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      item: {},
      center: { lat: 72.41, lng: -12.34 },
      zoom: 12
    };
  }

  _closeModal = () => {
    this.props.closeShareModal();
  }

  async componentDidUpdate() {
    
    if (this.props.openPlotModalState && this.props.selectedOrder && this.state.item.record_id !== this.props.selectedOrder.record_id) {
        this.setState({
            item: this.props.selectedOrder, 
            center: {lat: this.props.selectedOrder.gps_lat, lng: this.props.selectedOrder.gps_long}
        })
    }
  }

  render() {
    // const clientId = mstore.get("user", "company_id");
    return (
      <Modal
        blur
        open={this.props.openPlotModalState}
        width='960'
        css={{
          height: 720,
          br: 0,
        }}
        onClose={() => {
          this._closeModal();
        }}
        canEscapeKeyClose={true}
      >
        <Modal.Header>
          <Text size={18}>
            Showing the last known driver location
          </Text>
        </Modal.Header>
        <Modal.Body>
          {this.state.item.record_id && 
              <div className="mt3 h-100 w-95 mh4 flex">
                  <GoogleMapReact
                      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_APIKEY }}
                      center={this.state.center}
                      zoom={this.state.zoom}
                  >
                      <MapMarkerComponent
                          lat={this.state.center.lat}
                          lng={this.state.center.lng}
                      />
                  </GoogleMapReact>
              </div>
          }
        </Modal.Body>

      </Modal>
    );
  }
}

export default PlotLocation;
