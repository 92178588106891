import { WebAuth, Management } from 'auth0-js'
import { mstore } from './store'
import { secureFetch } from './lib/api';
import endpoint from './config/endpoint'


export const webAuth = new WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
  audience: process.env.REACT_APP_AUTH0_AUD_ACNT,
  responseType: 'token id_token',
  scope: 'openid profile'
});


let auth0Manage = localStorage.idToken && new Management({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  token: localStorage.idToken
})


export const loginAndSaveCreds = (username, password, remember) => {
  return new Promise((resolve, reject) => {
    webAuth.client.login({
      realm: process.env.REACT_APP_AUTH0_REALM,
      username,
      password,
      audience: process.env.REACT_APP_AUTH0_AUD_API,
      responseType: 'token id_token',
      scope: "openid profile email"
    }, (err, authResult) => {
      // Auth tokens in the result or an error
      if (err) {
        console.error(err)
        reject(err)
      } else {
        const {
          accessToken,
          idToken,
          tokenType,
          scope,
          expiresIn
          } = authResult;
        auth0Manage = new Management({
          domain: process.env.REACT_APP_AUTH0_DOMAIN,
          token: idToken
        })

        localStorage.setItem('remember', remember)

        if (remember) {
          localStorage.setItem('accessToken', accessToken)
          localStorage.setItem('idToken', idToken)
          localStorage.setItem('tokenType', tokenType)
          localStorage.setItem('scope', scope)
          localStorage.setItem('expiresIn', expiresIn)
          localStorage.setItem('createdAt', Date.now())
        } else {
          sessionStorage.setItem('accessToken', accessToken)
          sessionStorage.setItem('idToken', idToken)
          sessionStorage.setItem('tokenType', tokenType)
          localStorage.setItem('scope', scope)
          sessionStorage.setItem('expiresIn', expiresIn)
          sessionStorage.setItem('createdAt', Date.now())
        }
      }
      resolve(authResult)
    })
  })
}

// @return boolean
export const shouldaccessTokenExpire = () => {
  return Date.now() - parseInt(localStorage.createdAt, 10) >= parseInt(localStorage.expiresIn, 10) * 1000
}

// TODO: lift out of auth0
export const updateBasicUserInfo = () => {
  if (!localStorage.accessToken && !sessionStorage.accessToken) {
    return Promise.reject("No access token")
  }

  return secureFetch(endpoint.userInfo)
    .then(async ([ok, status, userObj]) => {
      if (!ok) {
        throw new Error('Status: ' + status + '\n' + JSON.stringify(await userObj.text()))
      }
      mstore.set('user', userObj)
      return userObj
    })
}

export const userPasswordReset = (email) => {
  return new Promise((resolve, reject) => {
    webAuth.changePassword({
      connection: process.env.REACT_APP_AUTH0_REALM,
      email
    }, (err, resp) => {
      if (err) reject(err)
      else resolve(resp)
    })
  })
}

export const userLogout = () => {
  localStorage.clear()
  sessionStorage.clear()
  webAuth.logout({ returnTo: window.location.origin + '/login' })
}

export const updatePassword = newPassowrd => {
  return new Promise((resolve, reject) => {
    webAuth.changePassword({
      password: newPassowrd,
      connection: process.env.REACT_APP_AUTH0_REALM,
      email: mstore.get('user', 'email')
    }, (err, data) => {
      if (err) return reject(err)
      resolve(data)
    })

  })
}

export const userHasScopes = (scopes) => {
  const grantedScopes = JSON.parse(localStorage.getItem('scopes')).split(' ');
  return scopes.every(scope => grantedScopes.includes(scope));
}

export const logoutAndClearStore = (dispatch) => {
  userLogout()
}

// FIXME: Remove before production
window.$auth0Manage = auth0Manage
window.$webAuth = webAuth