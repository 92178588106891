import React, { Component } from "react";
import Select from "react-select";

import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";

import { mstore } from "../../store";
import { updateTitle } from "../../lib/utils";
import { secureFetch } from "../../lib/api";
import endpoint from "../../config/endpoint.json";

import { error } from "../../lib/userAlerts";
import LocationDetails from "../../components/LocationInformation/LocationDetails";
import checkAccess from '../../lib/checkAccess';
import { Loading, Button } from "@nextui-org/react";

const createColumnDefs = () => {
  return [
    {
      headerName: "Stop Id",
      field: "stopId",
    },
    {
      headerName: "Stop Name",
      field: "stopName",
    },
    {
      headerName: "Address",
      field: "address",
    },
    {
      headerName: "City",
      field: "city",
    },
    {
      headerName: "State",
      field: "state",
    },
    {
      headerName: "ZIP",
      field: "zip",
    },
    {
      headerName: "Country",
      field: "country",
    },
  ];
};

class LocationInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heading: "All Locations",
      columnDefs: createColumnDefs(),
      stopIdList: [],
      stopNameList: [],
      stopNameListBK: [],
      stopId: "",
      stopName: "",
      selectedLocation: {},
      locationList: [
        {
          stopId: "12/23/23",
          stopName: "wrwr",
          address: "hghj",
          city: "ldfldsfjlfj",
          state: "jkhkhk",
          zip: "wwwwww",
          country: "sfsdf",
          comments: '[{ id: 1, driverNote: "sdsd" }]',
        },
      ],
      selectedStopId: "",
      selectedStopName: "",
      loading: false,
      showDetails: false,
    };
  }

  componentDidMount() {
    updateTitle("Location Information");
    checkAccess(this.props.clientId);

    this.fetchStopId();
    this.fetchStopName();
    this.getLocationForLocationInformationPage(2);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.clientId !== this.props.clientId) {
      checkAccess(newProps.clientId);
    }

    this.fetchStopId();
    this.fetchStopName();
    this.getLocationForLocationInformationPage(2);
  }

  fetchStopId = () => {
    secureFetch(endpoint.getStopIdListFromLocationTable, {}).then(
      ([ok, status, json]) => {
        if (ok) {
          this.setState({ stopIdList: json.stopIdList });
        } else {
          error("Oops! error occured during stop id list!");
        }
      }
    );
  };

  fetchStopName = () => {
    secureFetch(endpoint.getStopNameListFromLocationTable, {}).then(
      ([ok, status, json]) => {
        if (ok) {
          this.setState({
            stopNameList: json.stopNameList,
            stopNameListBK: json.stopNameList,
          });
        } else {
          error("Oops! error occured during stop name list!");
        }
      }
    );
  };

  getLocationForLocationInformationPage = (unverifiedFilter) => {
    let headerName = "";

    if (unverifiedFilter === 2) {
      headerName = "All Locations";
    } else if (unverifiedFilter === 1) {
      headerName = "Unverified Location";
    } else if (unverifiedFilter === 3) {
      if (this.state.selectedStopId || this.state.selectedStopName) {
        headerName = "Filtered Location";
      } else {
        headerName = "All Locations";
      }
    } else {
      headerName = "Location With Unresolved Comments";
    }

    this.setState({
      loading: true,
      showDetails: false,
      heading: headerName,
    });
    secureFetch(endpoint.getLocationForLocationInformationPage, {
      stopId: this.state.selectedStopId,
      stopName: this.state.selectedStopName,
      unverifiedFilter: unverifiedFilter === 1 ? 1 : 0,
    }).then(([ok, status, json]) => {
      if (ok) {
        this.setState({
          locationList: json.location,
          loading: false,
        });
      } else {
        this.setState({ loading: false });
        error("Oops! error occured during search order list!");
      }
    });
  };

  getUnresolvedCommentForLocationInformationPage = () => {
    this.setState({
      loading: true,
      showDetails: false,
      heading: "Location With Unresolved Comments",
    });
    secureFetch(endpoint.getUnresolvedCommentForLocationInformationPage, {
      stopId: this.state.selectedStopId,
      stopName: this.state.selectedStopName,
    }).then(([ok, status, json]) => {
      if (ok) {
        this.setState({
          locationList: json.locationWithUnsolvedComment,
          loading: false,
        });
      } else {
        this.setState({ loading: false });
        error("Oops! error occured during search order list!");
      }
    });
  };

  toggleShowDetails = (row) => {
    if (row) {
      this.setState({
        showDetails: !this.state.showDetails,
        selectedLocation: row.data,
      });
    } else {
      this.getLocationForLocationInformationPage(2)
      this.setState({
        showDetails: !this.state.showDetails,
      });
    }
  };

  setStopId = (selectedOption) => {
    if (selectedOption) {
      this.setState({ selectedStopId: selectedOption.value });
    } else {
      this.setState({ selectedStopId: "" });
    }
  };

  setStopName = (selectedOption) => {
    if (selectedOption) {
      this.setState({
        selectedStopName: selectedOption.value,
        stopNameList: this.state.stopNameListBK,
      });
    } else {
      this.setState({ selectedStopName: "" });
    }
  };

  filterList = (input) => {
    let list = [];
    if (input) {
      this.state.stopNameList.forEach((name) => {
        let index = name.value.indexOf(input);
        if (index === -1) {
          return;
        }
        list.push(name);
      });
      this.setState({ stopNameList: list });
    } else {
      this.setState({ stopNameList: this.state.stopNameListBK });
    }
  };

  deleteCommentFromState = (value) => {
    const selectLocation = this.state.selectedLocation;
    let filterCommentList = JSON.parse(selectLocation.comments).filter(item => item.id !== value.id);
    selectLocation.comments = JSON.stringify(filterCommentList);
    this.setState({ selectedLocation: selectLocation });
  }

  renderLocationList() {

    return (
      <div id="scrollTop" style={{height: 'calc(100vh - 180px)'}}>
        <div className="f3 ml3 mt2">{this.state.heading}</div>
        <div
          style={{height: 'calc(100vh - 200px)'}}
          className={"ag-theme-quartz p-3"}
        >
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={this.state.locationList}
            pagination={true}
            paginationAutoPageSize={true}
            // // events
            onRowDoubleClicked={this.toggleShowDetails}
            onGridReady={this.onGridReady}
            enableColResize
            enableSorting
            enableFilter
          >

          </AgGridReact>
        </div>
      </div>
    );
  }

  renderFilterSection() {
    return (
      <div className="flex items-center mt2">
        <div className="ml3 mr4 flex pt2 pb2 items-center">
          <Select
            name="form-field-name"
            style={{ width: "200px" }}
            placeholder="(Enter Stop Id)"
            value={this.state.selectedStopId}
            onChange={this.setStopId}
            options={this.state.stopIdList}
            matchPos="start"
            filterProp="value"
          />
          <div className="f5 ml3">OR</div>
          <Select
              name="form-field-name"
              style={{ width: "200px", marginLeft: 10 }}
              placeholder="(Enter Stop Name)"
              value={this.state.selectedStopName}
              onChange={this.setStopName}
              onInputChange={this.filterList}
              options={this.state.stopNameList}
            />
          <Button
            ghost
            auto
            css={{br:0, ml: 10}}
            onClick={this.getLocationForLocationInformationPage.bind(null, 3)}
          >
            Search
          </Button>
          <Button
            ghost
            auto
            css={{br:0, ml: 10}}
            onClick={this.getLocationForLocationInformationPage.bind(null, 3)}
          >
            All Locations
          </Button>
        </div>
        <div className="bg-gray" style={{ width: "1px", height: "30px" }}></div>
        <div className="ml4 mr4 flex pt2 pb2 items-center">
          <Button
            ghost
            auto
            css={{br:0, ml: 10}}
            color='secondary'
            onClick={this.getUnresolvedCommentForLocationInformationPage.bind(
              null
            )}
          >
            Show Location With Unsolved Comments
          </Button>
          <Button
            ghost
            auto
            css={{br:0, ml: 10}}
            color='secondary'
            onClick={this.getLocationForLocationInformationPage.bind(null, 1)}
          >
            Show Unverified Locations
          </Button>
        </div>
      </div>
    );
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  };

  render() {
    return (
      <div>
        {this.state.loading ? (
          <div className="flex justify-center mt6">
            <div>
              <Loading size="xl" />
              <div style={{ textAlign: "center" }}>Loading.....</div>
            </div>
          </div>
        ) : (
          <div>
            {this.state.showDetails ? (
              <LocationDetails
                getLocationWithUnsolvedComments={
                  this.getLocationForLocationInformationPage
                }
                getUnresolvedCommentForLocationInformationPage={
                  this.getUnresolvedCommentForLocationInformationPage
                }
                selectedLocation={this.state.selectedLocation}
                toggleShowDetails={this.toggleShowDetails}
                deleteCommentFromState={this.deleteCommentFromState}
              />
            ) : (
              <div className="ml3">
                {this.renderFilterSection()}
                {this.renderLocationList()}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    clientId: state.client ? state.client.id : mstore.getPath(["user", "company_id"]),
    Access: state.navigation.Access
  }
}

export default connect(mapStateToProps)(LocationInformation);