import 'react-toastify/dist/ReactToastify.css';
import "react-select/dist/react-select.css";
import "tachyons/css/tachyons.css";
import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';

import Root from "./Root";
import { updateBasicUserInfo } from "./auth0";
import { history, pathname } from "./history";
import { loadClientListConditionally } from "./store";

/**
 * smoothscroll polyfill needed because some browser doesn't render the
 * "behavior" parameter out of the box
 */
import smoothscroll from "smoothscroll-polyfill";
// kicks off the polyfill
smoothscroll.polyfill();

let $loader = document.getElementById("loader");
let $root = document.getElementById("root");

let rendered = false;
const renderApp = () => {
  if (rendered) {
    return;
  }
  rendered = true;

  $loader.classList.add("fadeOutLeft");
  const root = createRoot( $root); 
  root.render(<Root />);

  // Callback
  setTimeout(() => {
    if (window.location.pathname.startsWith("/login")) {
      $root.classList.add("zoomIn");
    }
    
    setTimeout(() => {
      $loader.remove();
      document.getElementById("loader-style").remove();
      $loader = null;
    }, 100);

    setTimeout(() => {
      $root.classList.remove("zoomIn");
    }, 800);

  }, 0);
};

// fetch current logged in user details or redirect to /login
updateBasicUserInfo()
  .then(userObj => {
    if (userObj.companyIsActive === 1) {
      loadClientListConditionally(userObj);
      const path = pathname();
      console.log("initial path", path);
      if (path === "/login" || path === "/") {
        history.push("/products");
      }
      renderApp();
    } else {
      history.push("/login");
      renderApp();
    }
  })
  .catch(err => {
    console.error(err);
    history.push("/login");
    renderApp();
  });


