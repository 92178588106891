import { Card, Divider, Link, Row, Text } from '@nextui-org/react';
import React, { Component } from 'react'

import { fetchWithJson } from "../../../lib/api";
import { error } from "../../../lib/userAlerts";
import endpoint from "../../../config/endpoint.json";

export default class ArtifaxStats extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            receipts: 0,
            safetyDocs: 0
        }
    }

    componentDidMount(){
        if(this.props.clientId){
            this.fetchReceiptData(this.props.clientId)
            this.fetchSafetyData(this.props.clientId)
        }
    }

    componentWillReceiveProps(newProps) {
        if(newProps && newProps.clientId >= 1 && newProps.clientId !== this.props.clientId){
            this.fetchReceiptData(newProps.clientId)
            this.fetchSafetyData(newProps.clientId)
        }
    }

    fetchReceiptData = async(clientId) =>{
        try {
            const res = await fetchWithJson(endpoint.statsReceipts, {
                company_id: clientId,
            });
    
            if (res.status !== 200) {
                error('Unable to fetch receipts stats')
            }
            else{
                const data = await res.json();
                this.setState({
                    receipts : data.receipts.unprocessedReceipts ? data.receipts.unprocessedReceipts : 0
                })
            }
        } 
        catch (err) {
            console.log(err);
            error('Unable to fetch receipts stats')
        }
    }

    fetchSafetyData = async(clientId) =>{
        try {
            const res = await fetchWithJson(endpoint.statsSafety, {
                company_id: clientId,
            });
    
            if (res.status !== 200) {
                error('Unable to fetch satey doc stats')
            }
    
            const data = await res.json();
            this.setState({
                safetyDocs : data.safety.numSafetyDocs ? data.safety.numSafetyDocs : 0
            })
        } 
        catch (err) {
            console.log(err);
            error('Unable to fetch satey doc stats')
        }
    }

    renderRow = (desc, value, link) => {
        return(
            <Row justify='space-between'>
                <Text size={18}>{desc}</Text>
                <Text size={18} b><Link href={link}>{value}</Link></Text>
            </Row>
        )
    }

    render() {
        return (
            <Card css={{br: 0}} 
                bordered 
                shadow={false}
            >
                <Card.Header>
                    <Text size={20}>Documents</Text>
                </Card.Header>
                <Divider/>
                <Card.Body css={{br:0, px: '$6'}} >
                    {this.renderRow('Unprocessed Receipts', this.state.receipts, '/driver/reimbursable-receipts-management')}
                    {this.renderRow('Unprocessed Safety Documents', this.state.safetyDocs, '/driver/safety-documents')}
                </Card.Body>
            </Card>
        );
    }
}