import { toast } from 'react-toastify';

export function success(message, iconName = 'tick') {
  toast.success(message)
}

export function error(message, iconName = 'cross') {
  toast.error(message)
}

export function warning(message, iconName = 'warning-sign') {
  toast.warning(message)
}

export function notify(message, iconName = 'notifications') {
  toast.info(message)
}
