// @flow
import { NAV_OPEN, NAV_CLOSE, SET_ACCESS } from '../action/constants'
type State = {
	+isNavOpen: boolean
}

const defaultState: State = { isNavOpen: true, Access: {} }
function navigation(state = defaultState, action): State {
  switch (action.type) {
    case NAV_OPEN:
      return { ...state, isNavOpen: true }
    case NAV_CLOSE:
      return { ...state, isNavOpen: false }
    case SET_ACCESS:
      return { ...state, Access: action.payload }
    default:
      return state
  }
}

export default navigation