import React, { Component } from "react";
import { Button, Loading, Checkbox, Input } from '@nextui-org/react';
import Select from "react-select";

export default class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  dateMatch = e => {
    this.setState({ DateMatch: this.state.DateMatch === 0 ? 1 : 0 });
  };

  totalMatch = e => {
    this.setState({ TotalMatch: this.state.TotalMatch === 0 ? 1 : 0 });
  };

  render() {
    
    const options = []

    this.props.documentTypeList.map(item => {
      options.push({value: item.type_name, label: item.type_name});
    })

    return (
      <div className="pa2" style={{width: 200}}>
        <div>       
          <label htmlFor="" className="f5">
            Document Number
          </label>
          <Input
            css={{ $$inputBorderRadius: "0", w: '100%', h: 30, mt: 5 }}
            shadow={false}
            bordered={true}
            rounded={false}
            type='number'
            min={0}
            onChange={e => {
              this.props.setValueToState("documentNumber", e.target.value);
            }}
            value={this.props.documentNumber}
          />
        </div>
        <div className="mt3">
          <label htmlFor="" className="f5">
            Employee Id
          </label>
          <Input
            type="text"
            placeholder="Enter employee id"
            onChange={e => {
              this.props.setValueToState("employeeId", e.target.value);
            }}
            css={{ $$inputBorderRadius: "0", w: '100%', h: 30, mt: 5 }}
            shadow={false}
            bordered={true}
            rounded={false}
            value={this.props.employeeId}
          />
        </div>
        <div className="mt3">
          <label className="f5">
            Document Type
          </label>
          <div className="w-100">
            <Select
              name="document-type"
              style={{ width: "185px" }}
              placeholder="Document Type"
              value={this.props.documentType}
              onChange={(item) => {this.props.setValueToState("documentType", item.value)}}
              options={options}
              matchPos="start"
              filterProp="value"
            />
          </div>
        </div>
        <div>
          <div className="mt3">
            <label htmlFor="" className="f5">
              Date Entered
            </label>
            <Input
              css={{ $$inputBorderRadius: "0", w: '100%', h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              type="date"
              onChange={(e) => {
                this.props.setValueToState("dateEntered", e.target.value);
              }}
              value={this.props.dateEntered}
            />
          </div>
        </div>

        <div className="flex flex-row mt3">
          <div className="f5 w4 w-80">
            Open
          </div>
          <div className="w-20">
            <Checkbox
              checked={this.props.openCheck === 1 ? true : false}
              onChange={() => {
                this.props.setValueToState(
                  "openCheck",
                  this.props.openCheck === 1 ? 0 : 1
                );
                this.props.setValueToState(
                  "closeCheck",
                  0
                );
              }}
            />
          </div>
        </div>

        <div className="flex flex-row mt2">
          <div className="f5 w4 w-80">
            Close
          </div>
          <div className="w-20">
            <Checkbox
              checked={this.props.closeCheck === 1 ? true : false}
              onChange={() => {
                this.props.setValueToState(
                  "closeCheck",
                  this.props.closeCheck === 1 ? 0 : 1
                );
                this.props.setValueToState(
                  "openCheck",
                  0
                );
              }}
            />
          </div>
        </div>

        <div className="flex flex-row justify-center items-center justify-between mt3">
          <Button
            ghost
            auto
            css={{br:0, color: '$gray600', borderColor: '$gray600' }}
            onClick={() => {
              this.props.resetFilter()
            }}
          >
            Reset
          </Button>

          {this.props.fetching ? (
            <Button
              auto
              css={{br:0 }}
              loading
            >
              <Loading type='points' size="md" color='white'/>
            </Button>
          ) : (
            <Button
              auto
              css={{br:0 }}
              onClick={() => {
                this.props.fetchSafetyDocumentList()
              }}
            >
              Search
            </Button>
          )}
        </div>
      </div>
    );
  }
}
