import React, { Component } from "react";
import { Switch, Row } from "@nextui-org/react";
import FeatureSelection from "../ProductSelection/FeatureSelection";
import { fetchWithJson } from "../../lib/api";
import { mstore } from "../../store";
import endpoint from "../../config/endpoint.json";
import { error, success } from "../../lib/userAlerts";
import { connect } from "react-redux";

let companyId;
let userId;

class NewsFeed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newsFeedSettings: {},
    };
  }

  componentDidMount() {
    companyId = mstore.get("user", "company_id");
    userId = mstore.get("user", "id");

    this.props.setHandleIndustryNewsSwitch(this.handleIndustryNewsSwitch);
    this.props.setHandleAppInfoSwitch(this.handleAppInfoSwitch);

    this.fetchNewsFeedSettingsDetails();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.clientId !== this.props.clientId) {
      this.fetchNewsFeedSettingsDetails();
    }
  }

  fetchNewsFeedSettingsDetails = () => {
    fetchWithJson(endpoint.fetchNewsFeesSettings, {})
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          this.setState({ newsFeedSettings: data.newsFeedSettings });
        } else {
          error(
            "Oops! error occured during fetching news feed settings details!"
          );
        }
      })
      .catch((err) => {
        error(
          "Oops! error occured during fetching news feed settings details!"
        );
        console.log(err.message);
      });
  };

  handleIndustryNewsSwitch = (disable) => {
    fetchWithJson(endpoint.updateHasSubscribedIndustryNews, {
      industyNewsAccess: disable
        ? 0
        : this.state.newsFeedSettings.has_subscribed_industry_news === 1
        ? 0
        : 1,
    })
      .then((res) => res.json())
      .then((data) => {
        if (this.state.newsFeedSettings.has_subscribed_industry_news === 1) {
          success(
            "You have successfully deactivated the Industry Content in Feed feature"
          );
        } else {
          if (!disable) {
            success(
              "You have successfully activated the Industry Content in Feed feature"
            );
          }
        }
        this.fetchNewsFeedSettingsDetails();
      })
      .catch((err) => {
        error("Oops! error occured");
        console.log(err.message);
      });
  };

  handleAppInfoSwitch = (disable) => {
    fetchWithJson(endpoint.updateHasSubscribedAppInfo, {
      appInfoAccess: disable
        ? 0
        : this.state.newsFeedSettings.has_subscribed_app_info === 1
        ? 0
        : 1,
    })
      .then((res) => res.json())
      .then((data) => {
        if (this.state.newsFeedSettings.has_subscribed_app_info === 1) {
          success(
            "You have successfully deactivated the App Info and News in Feed feature"
          );
        } else {
          if (!disable) {
            success(
              "You have successfully activated the App Info and News in Feed feature"
            );
          }
        }
        this.fetchNewsFeedSettingsDetails();
      })
      .catch((err) => {
        error("Oops! error occured");
        console.log(err.message);
      });
  };

  render() {
    if(this.props.Access.allow_blog_posts !== 1){
      return(
        <div role="alert" className="alert alert-warning w-1/2 mt-16 ">
          <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="white" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
          <span className="">Please subscribe to the <b>News Feed</b> feature</span>
        </div>
      )
    }

    return (
      <div className="grid grid-cols-2 gap-4 py-4">
        <div className="col-span-1 border-2 border-dotted ml2 p-4">
          <div className="f4 mb4">
            Show MTT Industry Content in Feed
          </div>

          <Row justify="space-between">
            <div className="f5 fw2">Enable / Disable feature</div>
            <Switch
              size='sm'
              checked={
                this.state.newsFeedSettings
                  .has_subscribed_industry_news === 1
                  ? true
                  : false
              }
              onChange={() => {
                this.handleIndustryNewsSwitch(false)
              }}
            />
          </Row>
        </div>

        <div className="col-span-1 border-2 border-dotted p-4">
          <div className="f4 mb4">
            Show MTT App Info and News in Feed
          </div>

          <Row justify="space-between">
            <div className="f5 fw2">Enable / Disable feature</div>
            <Switch
              size='sm'
              checked={
                this.state.newsFeedSettings.has_subscribed_app_info === 1
                  ? true
                  : false
              }
              onChange={() => {
                this.handleAppInfoSwitch(false)
              }}
            />
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    clientId: state.client ? state.client.id : mstore.getPath(["user", "company_id"]),
    Access: state.navigation.Access
  }
}

export default connect(mapStateToProps)(NewsFeed);
