import React, { Component } from "react";
import { Button, Loading, Checkbox, Input } from "@nextui-org/react";

import Select from "react-select";

export default class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exp1: this.props.exp1 ? this.props.exp1 : "",
      exp2: this.props.exp2 ? this.props.exp2 : "",
      OS: this.props.OS ? this.props.OS : 0,
      withReview: this.props.withReview ? this.props.withReview : 0,
      stopId: this.props.stopId ? this.props.stopId : "",
      stopName: this.props.stopName ? this.props.stopName : "",
      driverId: this.props.driverId ? this.props.driverId : "",
      noOfFilter: this.props.noOfFilter ? this.props.noOfFilter : 0,
      coordinatorList: this.props.coordinatorList
        ? this.props.coordinatorList
        : [],
      coordinatorId: this.props.coordinatorId ? this.props.coordinatorId : "",
      bolNumber: this.props.bolNumber ? this.props.bolNumber : ""
    };
    this.handleChange = this.handleChange.bind(this);
  }

  setExp1 = e => {
    this.setState({ exp1: e.target.value });
  };

  setExp2 = e => {
    this.setState({ exp2: e.target.value });
  };

  setOs = () => {
    this.setState({ OS: this.state.OS === 0 ? 1 : 0 });
  };

  setWithReview = e => {
    this.setState({ withReview: this.state.withReview === 0 ? 1 : 0 });
  };

  setStopId = e => {
    this.setState({ stopId: e.target.value });
  };

  setStopName = e => {
    this.setState({ stopName: e.target.value });
  };

  setDriverId = e => {
    this.setState({ driverId: e.target.value });
  };
  setBolNumber = e => {
    this.setState({ bolNumber: e.target.value });
  };
  handleChange = e => {
    this.setState({ coordinatorId: e ? e.value : "" });
  };

  checkFilter = cb => {
    var count = 0;
    if (this.state.exp1 !== "") {
      count++;
    }
    if (this.state.exp2 !== "") {
      count++;
    }
    if (this.state.driverId !== "") {
      count++;
    }
    if (this.state.stopId !== "") {
      count++;
    }
    if (this.state.stopName !== "") {
      count++;
    }
    if (this.state.OS !== 0) {
      count++;
    }
    if (this.state.withReview !== 0) {
      count++;
    }
    if (this.state.coordinatorId !== "") {
      count++;
    }
    if (this.state.bolNumber !== "") {
      count++;
    }
    this.setState({ noOfFilter: count }, cb);
  };

  render() {
    return (
      <div className="pa2">
        <div className="flex">
          <div className="">
            <label htmlFor="" className="f5">
              Order Date (From)
            </label>
            <Input
              type="date"
              onChange={this.setExp1}
              css={{ $$inputBorderRadius: "0", w: '100%', h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.state.exp1}
            />
            <label htmlFor="" className="f5">
              To
            </label>
            <Input
              type="date"
              onChange={this.setExp2}
              css={{ $$inputBorderRadius: "0", w: '100%', h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.state.exp2}
            />
          </div>
        </div>
        <div className="mt2">
          <label htmlFor="" className="f5">
            Stop Id
          </label>
          <Input
            type="text"
            placeholder="Enter Stop ID"
            clearable
            onChange={this.setStopId}
            css={{ $$inputBorderRadius: "0", w: '100%', h: 30, mt: 5 }}
            shadow={false}
            bordered={true}
            rounded={false}
            value={this.state.stopId}
          />
        </div>
        <div className="mt2">
          <label htmlFor="" className="f5">
            Stop Name
          </label>
          <Input
            type="text"
            placeholder="Enter Stop Name"
            clearable
            onChange={this.setStopName}
            css={{ $$inputBorderRadius: "0", w: '100%', h: 30, mt: 5 }}
            shadow={false}
            bordered={true}
            rounded={false}
            value={this.state.stopName}
          />
        </div>
        <div className="mt2">
          <label htmlFor="" className="f5">
            Driver Id
          </label>
          <Input
            type="text"
            placeholder="Enter Driver ID"
            clearable
            onChange={this.setDriverId}
            css={{ $$inputBorderRadius: "0", w: '100%', h: 30, mt: 5 }}
            shadow={false}
            bordered={true}
            rounded={false}
            value={this.state.driverId}
          />
        </div>
        <div className="mt2">
          <label htmlFor="" className="f5">
            Coordinator List
          </label>
          <Select
            name="form-field-name"
            style={{ width: "190px" }}
            placeholder="Search coordinator"
            value={this.state.coordinatorId}
            onChange={this.handleChange.bind()}
            options={this.state.coordinatorList}
          />
        </div>
        <div className="mt2">
          <label htmlFor="" className="f5">
            BOL Number
          </label>
          <Input
            type="text"
            placeholder="Enter BOL Number"
            clearable
            onChange={this.setBolNumber}
            css={{ $$inputBorderRadius: "0", w: '100%', h: 30, mt: 5 }}
            shadow={false}
            bordered={true}
            rounded={false}
            value={this.state.bolNumber}
          />
        </div>
        <div className="flex flex-row mt2">
          <span className="f5 w4" style={{width: 170}}>
            OS&D only
          </span>
          <Checkbox
            checked={this.state.OS === 1 ? true : false}
            value={this.state.OS === 1 ? true : false}
            onChange={this.setOs}
          />
        </div>

        <div className="flex flex-row space-between mt2 mb3">
          <span className="f5 w4" style={{width: 170}}>
            Orders With Review
          </span>
          <Checkbox
            value={this.state.withReview === 1 ? true : false}
            checked={this.state.withReview === 1 ? true : false}
            onChange={this.setWithReview}
          />
        </div>

        {this.props.clicked ? (
          <Button
            auto
            css={{br:0}}
          >
            <Loading type='points' color='white' size="md"/>
          </Button>
        ) : (
          <Button
            auto
            css={{br:0}}
            onClick={() => {
              this.checkFilter(() => {
                this.props.handleSearchButton(
                  this.state.exp1,
                  this.state.exp2,
                  this.state.OS,
                  this.state.stopId,
                  this.state.stopName,
                  this.state.driverId,
                  this.state.withReview,
                  this.state.noOfFilter,
                  this.state.coordinatorId,
                  this.state.bolNumber
                );
              });
            }}
          >
            Search
          </Button>
        )}
      </div>
    );
  }
}
