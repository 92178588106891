import React, { Component } from "react";
import { secureFetch } from "../../lib/api";
import endpoint from "../../config/endpoint.json";

import Filter from "../../components/SafetyDocumentComponent/Filter";
import { mstore } from "../../store";
import { error, success  } from "../../lib/userAlerts";
import { connect } from "react-redux";
import checkAccess from '../../lib/checkAccess';
import { AgGridReact } from "ag-grid-react";
import { Button, Divider, Loading, Modal, Text, Tooltip } from "@nextui-org/react";
import { Filter2, PaperDownload } from "react-iconly";
import SubPageHeader from "../../components/SubPageHeader";

const createColumnDefs = () => {
  return [
    {
      headerName: "Document Number",
      field: "id",
      width: 200,
    },
    {
      headerName: "Date Entered",
      field: "uploaded_time",
      width: 200,
    },
    {
      headerName: "Employee Id",
      field: "employee_id",
      width: 200,
    },
    {
      headerName: "Document Type",
      field: "document_type",
      width: 200,
    },
    {
      headerName: "Status",
      field: "status",
      width: 200,
    },
  ];
};

class safetyDocuments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      DocumentListBackUp: [],
      DocumentList: [
        {
          documentNumber: "1211214214",
          dateEntered: "10/10/3434",
          employeeId: 12123,
          documentType: "Ticket",
          status: "closed",
        },
      ],
      currentDocuments: {},
      previewDoc: "",
      documentTypeList: [],
      isOpen: false,
      docStatusUpdating: false,
      fetching: false,
      pdfLoading: false,
      columnDefs: createColumnDefs(),
      documentNumber: 0,
      employeeId: "",
      documentType: "",
      dateEntered: "",
      openCheck: 0,
      closeCheck: 0,
    };

    this.zoomRef = null;
  }

  setValueToState = (field, value) => {
    let state = this.state;
    state[field] = value;
    this.setState(state);
  };

  getZoomProps = () => {
    const zoomProps = {
      width: parseInt(window.innerWidth * 0.45),
      height: parseInt(window.innerHeight * 0.65),
      offset: { vertical: 0, horizontal: -1000 },
      scale: 0.4,
      img: this.state.currentDocuments.docUrl,
    };
    return zoomProps;
  };
  getZoomRef = (ref) => {
    this.zoomRef = ref;
  };

  downloadImage = () => {
    let filename =
      "safety_document_" + Math.random().toString(36).substr(2, 5) + ".jpg";

    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = this.state.currentDocuments.docUrl;
    tempLink.setAttribute("download", filename);
    tempLink.click();
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  };

  setPreview = (url) => {
    this.setState({ pdfLoading: true });

    fetch(url, {
      method: "GET",
    })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        var file = new Blob([blob], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        this.setState({ previewDoc: fileURL });
      })
      .catch((err) => console.error(err))
      .finally(()=>{
        this.setState({ pdfLoading: false });
      });
  };

  rowDoubleClicked = (row) => {
    this.setState({ isOpen: true, currentDocuments: row.data }, () => {
      this.setPreview(row.data.docUrl);
    });
  };

  onCancel = () => {
    this.setState({ isOpen: false, previewDoc: "" });
  };

  componentDidMount() {
    checkAccess(this.props.clientId);

    this.fetchSafetyDocumentList();
    this.getDocumentTypeList();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.clientId !== this.props.clientId) {
      checkAccess(newProps.clientId);

      this.fetchSafetyDocumentList();
      this.getDocumentTypeList();
    }
  }

  resetFilter = () => {
    this.setState({
      documentNumber: 0,
      employeeId: "",
      documentType: "",
      dateEntered: "",
      openCheck: 0,
      closeCheck: 0,
    });
  };

  getDocumentTypeList = () => {
    secureFetch(endpoint.getDocumentTypeList, {}).then(([ok, status, json]) => {
      if (ok) {
        this.setState({
          documentTypeList: json.documentTypeList
        });
      } else {
        error("An error occured while fetching the artifacts list!");
      }
    });
  };

  fetchSafetyDocumentList = (clientId) => {

    // Trigger spinner
    this.setState({fetching: true});

    secureFetch(endpoint.fetchSafetyDocumentList, {
      clientId: clientId,
      documentNumber: this.state.documentNumber ? this.state.documentNumber : 0,
      employeeId: this.state.employeeId,
      documentType: this.state.documentType,
      dateEntered: this.state.dateEntered,
      status:
        this.state.openCheck === 0 && this.state.closeCheck === 0
          ? -1
          : this.state.openCheck === 1
          ? 1
          : 0,
    }).then(([ok, status, json]) => {
      if (ok) {
        this.setState({
          DocumentListBackUp: json.safetyDocumentList,
          DocumentList: json.safetyDocumentList,
          fetching: false
        });
      } else {
        error("Oops! An error occured while fetching the document list");
      }
    });
  };

  updateDocumentStatus = (from) => {
    // Trigger spinner
    this.setState({docStatusUpdating: true});
    
    secureFetch(endpoint.updateSafetyDocumentStatus, {
      documentNumber: this.state.currentDocuments.id,
      status: from === "CLOSE" ? 0 : 1,
    })
    .then(([ok, status, json]) => {
      if (ok) {
        success("Successfully updated document status");
        this.setState({ isOpen: false });
        this.fetchSafetyDocumentList();
      } else {
        error("Oops! An error occured when updating the document status");
      }
    })
    .finally(()=>{
      this.setState({docStatusUpdating: false});
    });
  };

  renderStatusUpdateButton = () =>{
    return (
      <button 
        onClick={() => {
          if (this.state.currentDocuments.status === "OPEN" ) {
            this.updateDocumentStatus("CLOSE");
          }
          else{
            this.updateDocumentStatus("RESET");
          }
        }}
        className="flex ml-auto text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded">
        {this.state.currentDocuments.status === "OPEN" ? 'Close' : 'Reset Status'}
      </button>
    )
  }

  safetyDocumentDetails = () => {
    return (
      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="lg:w-5/6 mx-auto flex flex-wrap">
            <div className="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
              <h2 className="text-sm title-font text-gray-500 tracking-widest">{this.state.currentDocuments.uploaded_time}</h2>
              <h1 className="text-gray-900 text-3xl title-font font-medium mb-4">Document Number: {this.state.currentDocuments.id}</h1>
              <p className="leading-relaxed mb-4">
                <span className="mr2 fw6"> Driver Comments: </span> {` ${this.state.currentDocuments.comment}`}
              </p>
              <div className="flex border-y border-gray-200 py-2">
                <span className="text-gray-500">Document Type</span>
                <span className="ml-auto text-gray-900">{this.state.currentDocuments.document_type}</span>
              </div>
              <div className="flex border-b border-gray-200 py-2">
                <span className="text-gray-500">Status</span>
                <span className="ml-auto text-gray-900">{this.state.currentDocuments.status}</span>
              </div>
              <div className="flex py-8">
                {this.state.docStatusUpdating ? <Loading>Updating status</Loading> : this.renderStatusUpdateButton()}
                <span className="text-secondary">
                  <PaperDownload size={38} style={{marginLeft: 10}} onClick={this.downloadImage}/>
                </span>
              </div>
            </div>
            <div className="lg:w-1/2 w-full h-[70vh]">
              {this.state.pdfLoading  
                ? <Loading type='points' />
                : <iframe
                  className="object-cover object-center rounded w-full h-full"
                  src={this.state.previewDoc}/>
              }
            </div>
          </div>
        </div>
      </section>
    );
  };

  render() {
    var height =(window.innerHeight - 160);

    const gridContainerStyle = {
      height: height + "px",
    };

    if (this.props.Access.allow_safety_documents === 1) {
      return (
        <div className="">
          <div className="flex justify-between p-4 border-y-2 border-dotted">
            <SubPageHeader>Safety Management</SubPageHeader>

            <div className="flex">
              <Tooltip 
                css={{br: 0}}
                placement="bottom" 
                content={
                  <Filter
                    documentNumber={this.state.documentNumber}
                    employeeId={this.state.employeeId}
                    documentType={this.state.documentType}
                    dateEntered={this.state.dateEntered}
                    openCheck={this.state.openCheck}
                    closeCheck={this.state.closeCheck}
                    documentTypeList={this.state.documentTypeList}
                    setValueToState={this.setValueToState}
                    fetchSafetyDocumentList={this.fetchSafetyDocumentList}
                    fetching={this.state.fetching}
                    resetFilter={this.resetFilter}
                  />
                }
                trigger='click'>
                <Button
                  css={{br: 0, ml: 10}}
                  icon={<Filter2 set='light' />}
                >
                  <Text size='1rem' color='currentColor' css={{pl: 30}}>Filter Safety Documents</Text>
                </Button>
              </Tooltip>
            </div>
          </div>
          {this.state.fetching ? (
            <div className="flex justify-center mt6">
              <Loading size="lg">Fetching safety documents. Hold on! </Loading>
            </div>
          ) : (
            <div
              style={{ height: height + 10 }}
              className="mr2 mt2"
            >
              {this.state.DocumentList.length === 0 ? (
                <div>
                  <div className=" mt3 f5">No document found.</div>
                </div>
              ) : (
                <div style={gridContainerStyle} className="ag-theme-quartz p-4">
                  <AgGridReact
                    // properties
                    columnDefs={this.state.columnDefs}
                    rowData={this.state.DocumentList}
                    pagination={true}
                    paginationAutoPageSize={true}
                    // events
                    onGridReady={this.onGridReady}
                    rowSelection={this.state.rowSelection}
                    onRowDoubleClicked={this.rowDoubleClicked}
                    //ID
                    getRowId={(params) => params.data.id}
                    enableColResize
                    enableSorting
                    enableFilter
                  ></AgGridReact>
                </div>
              )}
            </div>
          )}

          <Modal
            css={{br: 0}}
            height='95vh'
            width="90vw"
            open={this.state.isOpen}
            blur
            closeButton
            onClose={this.onCancel}
          >
            <Modal.Body>
              {this.safetyDocumentDetails()}
            </Modal.Body>
          </Modal>
        </div>
      );
    } else {
      return (
        <div>
          <div className="flex justify-between">
            <h4 className={"f3 fw4 mb1 pt1 pb3 ph1 mt2 "}>
              Safety Management
            </h4>
          </div>
          <div className=" ml2 mt3 f5">This feature is unavailable</div>
        </div>       
      );
    }
  }
}

const mapStateToProps = function(state) {
  return {
    clientId: state.client ? state.client.id : mstore.getPath(["user", "company_id"]),
    Access: state.navigation.Access
  }
}

export default connect(mapStateToProps)(safetyDocuments);
