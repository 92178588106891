// @flow
import { Text } from '@nextui-org/react';
import React from 'react';
import { updateTitle } from "../lib/utils";

export default function SubPageHeader({ children, className }) {
  updateTitle(children);
  return (
    <Text size={24}>
      {children}
    </Text>
  )
}

SubPageHeader.defaultProps = {
  className: ''
}
