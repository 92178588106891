/* @flow */

import endpoint from '../../config/endpoint.json'
import { fetchUrl } from './fetcher'

/**
 * Validators and autocomplete api for forms
 */

export const VALID_EMAIL_REGEX = /^\S+@\S+\.\S{2,}$/
export const VALID_PHONE_REGEX = /^[0-9+ ]+$/

 /**
  * TODO: return 4 states error, not unique, unique & invalid email
  * @param {String} email
  * @returns {Promise<boolean>} return true for in complete email
  */
export function isUniqueEmail(email) {
  return fetchUrl(endpoint.isValidValue, {
    field: 'email',
    value: email
  }).then(([ok, status, res]) => {
    if(ok) {
      return res.is_valid
    } else {
      res.text().then(console.error)
    }
  })
}
