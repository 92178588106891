import { Col, Divider, Row, Text, Link } from "@nextui-org/react";
import React, { Component } from "react";
import DocumentStats from "./DocumentStats";
import IntegrationStats from "./IntegrationStats";
import OtherStats from "./OtherStats";
import StopRatings from "./StopRatings";
import TrendArtifax from "./TrendArtifax";

class CurrentData extends Component {
    constructor(props) {
        super(props);
    }

    render() { 
        return (
            <div style={{height: "100%", width: "100%"}}>
                <Row justify='space-between' gap={1}>
                    <Col>
                        <IntegrationStats clientId={this.props.clientId}/>
                    </Col>
                    <Col>
                        <DocumentStats clientId={this.props.clientId}/>
                    </Col>
                    <Col>
                        <OtherStats clientId={this.props.clientId}/>
                    </Col>
                </Row>
                <Divider css={{my: '$6'}}/>
                <Row justify='space-between' css={{py: '$6'}}>
                    <Col span={5}>
                        <Text size={20}>Stop Ratings<Link css={{ml: 10} }href='/driver/location-analytics'>(Detailed report)</Link></Text>
                        <StopRatings clientId={this.props.clientId}/>
                    </Col>
                    <Col span={7}>
                        <Text css={{pl: 20}} size={20}>Shipment Trends (Last 7 days)</Text>
                        <TrendArtifax clientId={this.props.clientId}/>
                    </Col>
                </Row>
            </div>

        );
    }
}
 
export default CurrentData;