import React, { Component } from "react";
import "react-phone-input-2/lib/style.css";
import GoogleMapReact from 'google-map-react';

import Marker from './Marker';

const MAXZOOM = 20
const mapOptions = {
  panControl: false,
  mayTypeControl: false,
  scrollwheel: false,
  fullscreenControl: false,
  disableDefaultUI: true,
  maxZoom: MAXZOOM
};

// Return map bounds based on list of places
const getMapBounds = (map, maps, places) => {
  const bounds = new maps.LatLngBounds();

  places.forEach((place) => {
    bounds.extend(new maps.LatLng(
      place.lat,
      place.lng,
    ));
  });
  return bounds;
};

// Re-center map when resizing the window
const bindResizeListener = (map, maps, bounds) => {
  maps.event.addDomListenerOnce(map, 'idle', () => {
    maps.event.addDomListener(window, 'resize', () => {
      map.setOptions({ maxZoom: undefined })
      map.fitBounds(bounds);
    });
  });
};

// Fit map to its bounds after the api is loaded
const apiIsLoaded = (map, maps, places) => {
  // Get bounds by our places
  const bounds = getMapBounds(map, maps, places);
  map.setOptions({ maxZoom: MAXZOOM })
  // Fit map to bounds
  map.fitBounds(bounds);
  // Bind the resize listener
  bindResizeListener(map, maps, bounds);
};

class PlotDetention extends Component {

  constructor(props) {
    super(props);
    this.state = {
      width: 758,
      height: 430
    };
  }

  _closeModal = () => {
    this.props.closeShareModal();
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        height: this.props.container.height, width: this.props.container.width
      })
    }, 1000)     
  }

  componentWillReceiveProps(newProps){
    setTimeout(() => {
      this.setState({
        height: newProps.container.height, width: newProps.container.width
      })
    }, 1000)
  }

  render() {

    // const clientId = mstore.get("user", "company_id");
    return (
        <div style={{height: this.state.height, width: this.state.width}}>
            <GoogleMapReact
                options={mapOptions}
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_APIKEY }}
                defaultCenter={this.props.bounds.center}
                defaultZoom={this.props.bounds.zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, this.props.places)}
            >
                {this.props.places.map(marker => (
                    <Marker
                        key={marker.id}
                        text={marker.id}
                        lat={marker.lat}
                        lng={marker.lng}
                    />
                ))}
            </GoogleMapReact>
        </div>
    );
  }
}

export default PlotDetention;
