import { Card, Divider, Row, Text } from '@nextui-org/react';
import React, { Component } from 'react';
import dayjs from '../../../lib/day';

import { fetchWithJson } from "../../../lib/api";
import { error } from "../../../lib/userAlerts";
import endpoint from "../../../config/endpoint.json";

export default class IntegrationStats extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            integrationTime: 'N/A',
            integrationRecs: 0
        }
    }

    componentDidMount(){
        if(this.props.clientId){
            this.fetchData(this.props.clientId)
        }
    }

    componentWillReceiveProps(newProps) {
        if(newProps && newProps.clientId >= 1 && newProps.clientId !== this.props.clientId){
            this.fetchData(newProps.clientId)
        }
    }

    fetchData = async(clientId) =>{
        try {
            const res = await fetchWithJson(endpoint.statsIntegration, {
                company_id: clientId,
            });
    
            if (res.status !== 200) {
                error('Unable to fetch integration stats')
            }
            else{
                const data = await res.json();
                this.setState({
                    integrationTime : data && data.integration && data.integration.runTime ? dayjs.unix(data.integration.runTime).fromNow(): 'N/A', 
                    integrationRecs: data && data.integration && data.integration.numRecords ? data.integration.numRecords : 0
                })
            }
        } 
        catch (err) {
            console.log(err);
            error('Unable to fetch integration stats')
        }
    }

    renderRow = (desc, value) => {
        return(
            <Row justify='space-between'>
                <Text size={18}>{desc}</Text>
                <Text size={18} b>{value}</Text>
            </Row>
        )
    }

    render() {
        return (
            <Card css={{br: 0}} 
                bordered 
                shadow={false}
            >
                <Card.Header>
                    <Text size={20}>Shipment API</Text>
                </Card.Header>
                <Divider/>
                <Card.Body css={{br:0, px: '$6'}} >
                    {this.renderRow('Shipment data last updated', this.state.integrationTime)}
                    {this.renderRow('Records Processed', this.state.integrationRecs)}
                </Card.Body>
            </Card>
        );
    }
}