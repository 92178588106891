import React, { Component } from "react";
import { Loading, Modal, Text, Textarea, Button as NextButton} from '@nextui-org/react';
import { Delete} from 'react-iconly'

import { secureFetch } from "../../lib/api";
import { success, warning } from "../../lib/userAlerts";
import { mstore } from "../../store";
import endpoint from "../../config/endpoint.json";
import { error } from "../../lib/userAlerts";
import { connect } from "react-redux";

class CannedDriverMessages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cannedMessageList: [],
      cannedMessageListc: [],
      addCannedMessage: false,
      loading: false,
      loadinge: false,
      addNewCannedMessage: "",
      fetching: true,
    };
  }

  componentDidMount() {
    this.getCannedMessageList();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.clientId !== this.props.clientId) {
      this.getCannedMessageList();
    }
  }

  getCannedMessageList = () => {
    secureFetch(endpoint.fetchCannedMessage, {
      isBrokerShipmentManagement: this.props.isBrokerShipmentManagement,
    }).then(([ok, status, json]) => {
      if (ok) {
        this.setState({
          cannedMessageList: json.cannedMessage,
          loading: false,
          fetching: false,
          cannedMessageListc: JSON.parse(JSON.stringify(json.cannedMessage)),
        });
      } else {
        error("Oops! error occured during fetching message list!");
      }
    });
  };

  addCannedMessage = () => {
    this.setState({ loading: true });
    secureFetch(endpoint.addNewCannedMessage, {
      message: this.state.addMessageName,
      isBrokerShipmentManagement: this.props.isBrokerShipmentManagement,
    }).then(([ok, status, json]) => {
      if (ok) {
        success(json.message);
        this.getCannedMessageList();
        this.setState({ loading: false, addMessageName: "" });
      } else {
        error("Oops! error occured during add new message!");
      }
    });
  };

  deleteMessage = (obj) => {
    this.setState({ loading: true });
    secureFetch(endpoint.deleteCannedMessage, { messageId: obj.id }).then(
      ([ok, status, json]) => {
        if (ok) {
          this.getCannedMessageList();
          success(json.message);
          this.setState({
            loading: false,
            isActive: false,
          });
        } else {
          error("Oops! error occured during delete message!");
        }
      }
    );
  };

  onCancel = () => {
    this.setState({ addCannedMessage: false });
  };

  renderUserRow = (obj, idx) => {
    return (
      <tr key={idx} className="flex justify-between">
        <td
          className="f5 fw2 flex flex-start h3"
          style={{
            flex: 1,
          }}
        >
          <div>{obj.message}</div>
        </td>
        <td
          className="flex justify-right h3"
        >
          <NextButton
            ghost
            auto
            color="error"
            onClick={this.deleteMessage.bind(null, obj)}
            icon={<Delete fill="currentColor" />}
          >
            <Text color="currentColor">Delete</Text>
          </NextButton>
        </td>
      </tr>
    );
  };

  render() {
    if(this.props.Access.allow_broker_push_notification !== 1 && this.props.isBrokerShipmentManagement){
      return(
        <div role="alert" className="alert alert-warning w-1/2 mt-16 ">
          <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="white" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
          <span className="">Please subscribe to <b>Push Notification</b> feature</span>
        </div>
      )
    }

    if(this.props.Access.allow_broker_push_notification !== 1 ){
      return null
    }

    return (
      <div className="mt2 w-100">
        {this.state.fetching && (
          <div className="flex justify-center mt6">
            <Loading size="md">Fetching your canned messages..</Loading>
          </div>
        )}
        <div className="">
          {!this.state.fetching &&
            this.state.cannedMessageList.length !== 0 && (
              <div
                className="ml2 mt3 mr2 border-2 border-dotted p-4"
              >
                <div className="flex justify-between mb4 border-b">
                  <div className="f4 mb2" style={{ paddingTop: "10px" }}>
                    {this.props.isBrokerShipmentManagement ? `Canned Driver Messages (For Carrier Drivers, you may work with as a Broker)` : `Canned Driver Messages (Applicable for your Own Drivers, Free Users)`}
                  </div>
                  <NextButton
                    ghost
                    auto
                    color="primary"
                    css={{br:0 }}
                    onClick={() => {
                      this.setState({
                        addCannedMessage: !this.state.addCannedMessage,
                      });
                    }}
                  >
                    <Text color='$$currentColor' size="1rem">Create Message</Text>
                  </NextButton>
                </div>

                <table
                  className={"flex flex-column w-100 pr2"}
                >
                  <tr className="flex justify-around pb3">
                    <th
                      className="f5 flex flex-start items-center h2"
                      style={{
                        width: "100%",
                      }}
                    >
                      Message
                    </th>
                  </tr>
                  <tbody
                    transitionName="table-row"
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={500}
                    component={"tbody"}
                  >
                    {this.state.cannedMessageList.map((obj, idx) =>
                      this.renderUserRow(obj, idx)
                    )}
                  </tbody>
                </table>
              </div>
            )}

            <Modal
                closeButton
                blur
                preventClose
                aria-labelledby="Add Message"
                open={this.state.addCannedMessage}
                onClose={this.onCancel}
            >
              <Modal.Header>
                  <Text id="modal-title" size={18}>
                    Add your canned message
                  </Text>
              </Modal.Header>
              <Modal.Body>
                  <Textarea
                    onChange={(e) => {
                      this.setState({ addMessageName: e.target.value });
                    }}
                    value={this.state.addMessageName}
                    rows={5}
                    label="Please enter the message meant for the driver below."
                  />
              </Modal.Body>
              <Modal.Footer>
                <NextButton
                  auto
                  disabled={this.state.loading}
                  onClick={() => {
                    if (this.state.addMessageName) {
                      if (
                        this.state.cannedMessageList.findIndex((item) => {
                          return (
                            item.message === this.state.addMessageName.trim()
                          );
                        }) === -1
                      ) {
                        this.addCannedMessage();
                        this.getCannedMessageList();
                      } else {
                        warning("This message already exists.");
                      }
                    } else {
                      warning("Please enter a message and then click submit.");
                    }
                  }}
                >
                {this.state.loading ? <Loading type="points" color="white"></Loading> : <Text color="white">Submit</Text>}
                </NextButton>
              </Modal.Footer>
          </Modal>
        </div>
        {!this.state.fetching && this.state.cannedMessageList.length === 0 && (
          <div
            className="ml2 mt3 mr2 pa2 pb3"
            style={{ paddingLeft: "20px", backgroundColor: "#f9f7f7" }}
          >
            <div className="flex justify-between mb4">
              <div className="f4 mb2 fw2" style={{ paddingTop: "10px" }}>
              {this.props.isBrokerShipmentManagement ? `Canned Driver Messages (For Carrier Drivers, you may work with as a Broker)` : `Canned Driver Messages (Applicable for your Own Drivers, Free Users)`}
              </div>
            </div>

            <NextButton
              auto
              color="primary"
              css={{br:0 }}
              onClick={() => {
                this.setState({
                  addCannedMessage: !this.state.addCannedMessage,
                });
              }}
            >
              <Text size="1rem" color="currentColor">Create your first canned message!</Text>
            </NextButton>
          </div>
        )}
      </div>
    );
  }
}

export default connect((state) => ({
  Access: state.navigation.Access,
  clientId: state.client
    ? state.client.id
    : mstore.getPath(["user", "company_id"]),
}))(CannedDriverMessages);
