import React, { Component } from "react";
import { Divider, Radio } from '@nextui-org/react'

import checkAccess from '../../../lib/checkAccess';
import { secureFetch } from "../../../lib/api";
import { error } from "../../../lib/userAlerts";
import endpoint from "../../../config/endpoint.json";

import { mstore } from "../../../store";
import { connect } from "react-redux";
import { updateTitle } from "../../../lib/utils";

import CurrentData from "./CurrentData";
import DriverStats from "./DriverStats";
import BrokerStats from "./BrokerStats";


const OVERALL_DATA = 1
const DRIVER_STATS = 2
const BROKER_TRENDS = 3

class DashBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartType: OVERALL_DATA,
      isBroker: 0
    };
  }

  componentDidMount() {
    checkAccess(this.props.clientId);
    this.fetchCompanyDetails();
    updateTitle("Dashboard");
  }

  componentWillReceiveProps(newProps) {
    if (newProps.clientId && newProps.clientId !== this.props.clientId) {
      checkAccess(newProps.clientId);
      this.fetchCompanyDetails();
    }
  }

  fetchCompanyDetails = () => {
    return secureFetch(endpoint.getCompanyDetails)
      .then(([ok, status, company]) => {
        if (ok) {
          this.setState({
            isBroker: company.is_broker,
          });
        } else {
          error("Unable to fetch company details");
        }
      })
      .catch((err) => {
        error("Unable to fetch company details");
      });
  };

  render() {
    return (
      <div style={{height: "100%", width: "100%"}}>
        
        <Radio.Group row css={{mt: '0.75rem', padding: 10}}
          value={this.state.chartType}
        >
          <Radio
            onChange={(e) => {
              this.setState({ chartType: OVERALL_DATA });
            }} 
            value={OVERALL_DATA}>
              System Snapshot
              <Radio.Desc>The default dashboard view</Radio.Desc>
          </Radio>
          <Radio
            onChange={(e) => {
              this.setState({ chartType: DRIVER_STATS });
            }}
            value={DRIVER_STATS}>
              Driver Stats
              <Radio.Desc>See how your company drivers are performing</Radio.Desc>                  
          </Radio>
          <Radio
            disabled={this.state.isBroker ? false: true}
            onChange={(e) => {
              this.setState({ chartType: BROKER_TRENDS });
            }}
            value={BROKER_TRENDS}>
              {this.state.isBroker ? `Broker Trends` : `Broker Trends (Disabled)`}
              <Radio.Desc>
                {this.state.isBroker ? `See how your shipments are Fulfilled` : `Please enable the broker options in the accounts section`}
              </Radio.Desc>                  
          </Radio>
        </Radio.Group>
        
        <Divider />
        
        <div className="w-100 pa2" style={{height: 'calc(100vh - 220px)'}}>
          {this.state.chartType === OVERALL_DATA ? <CurrentData clientId={this.props.clientId}/>: null}
          {this.state.chartType === DRIVER_STATS ? <DriverStats/>: null}
          {this.state.chartType === BROKER_TRENDS ? <BrokerStats clientId={this.props.clientId}/>: null}
        </div>

      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    clientId: state.client ? state.client.id : mstore.getPath(["user", "company_id"]),
    Access: state.navigation.Access
  }
}

export default connect(mapStateToProps)(DashBoard);