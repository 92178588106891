import React, { PureComponent } from 'react'
import { Button, Modal, Text } from '@nextui-org/react'

const Success = () => (
  <div>
    <span>Success</span>
  </div>
)

const Error = () => (
  <div>
    <span>Error</span>
  </div>
)

export default class SuccessErrorDialog extends PureComponent {

  render() {
    return (
      <Modal
          css={{br: 0}}
          width={600}
          blur
          closeButton
          open={this.props.showDialog}
          onClose={this.props.onDone}
      >
          <Modal.Header>
            <Text b id="modal-title" size={18}>
              {this.props.ind ? <Success /> : <Error />}
            </Text>
          </Modal.Header>

          <Modal.Body>
            <div className="pa3 bg-near-white">
              <Text>
                {this.props.children}
              </Text>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button auto css={{br:0}} onClick={this.props.onDone}>Close</Button>
          </Modal.Footer>
      </Modal>
    )
  }
}