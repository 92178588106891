import React, { Component } from "react";
import { Async } from 'react-select';
import { debounce } from "lodash";

import { Modal, Loading, Button as NextButton, Text, Tooltip } from '@nextui-org/react';
import { Delete, Download, Filter2, Plus, Upload } from 'react-iconly'

import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";

import Papa from "papaparse";
import cloneDeep from 'lodash/cloneDeep';
import size from 'lodash/size';

import { mstore } from "../../store";
import endpoint from "../../config/endpoint.json";

import checkAccess from '../../lib/checkAccess';
import { secureFetch, fetchWithJson } from "../../lib/api";
import { error, success, warning } from "../../lib/userAlerts";

import Filter from "../../components/PageSelectionComponent/Filter";
import FileUpload from "../../components/FileUpload";
import ShipmentDispatchDetails from "./ShipmentDispatchDetails";
import SubPageHeader from "../../components/SubPageHeader";
import { searchOrderList } from "../../lib/utils";

var height = window.innerHeight;

const createColumnDefs = () => {
  return [
    {
      headerName: "Order Id",
      field: "OrderNo",
      filter: "agTextColumnFilter",
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
      resizable: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      headerName: "Order Stop Seq",
      field: "Stop",
      filter: "agTextColumnFilter",
      width: 120,
      resizable: true,
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
    },
    {
      headerName: "Stop Id",
      field: "StopId",
      filter: "agTextColumnFilter",
      resizable: true,
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
    },
    {
      headerName: "Stop Name",
      field: "StopName",
      filter: "agTextColumnFilter",
      resizable: true,
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
    },
    {
      headerName: "Stop Date",
      field: "StopArriveTime",
      filter: "agTextColumnFilter",
      resizable: true,
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
    },
    {
      headerName: "Stop Type",
      field: "StopType",
      filter: "agTextColumnFilter",
      resizable: true,
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
    },
    {
      headerName: "Driver1 Id",
      field: "Driver1",
      filter: "agTextColumnFilter",
      resizable: true,
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
    },
    {
      headerName: "Driver2 Id",
      field: "Driver2",
      filter: "agTextColumnFilter",
      resizable: true,
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
    },
    {
      headerName: "Stop Address",
      field: "Address",
      filter: "agTextColumnFilter",
      resizable: true,
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
    },
    {
      headerName: "Close Button Clicked",
      field: "closeButtonClicked",
      filter: "agTextColumnFilter",
      resizable: true,
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
      valueGetter: params => params.data.closeButtonClicked ? 'Y' : 'N',
    },
    {
      headerName: "Shipment Closed",
      field: "auto_close_ind",
      filter: "agTextColumnFilter",
      resizable: true,
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
      valueGetter: params => params.data.auto_close_ind ? 'Y' : 'N',
    },
    {
      headerName: "Closed by Driver",
      field: "closed_by_driver",
      filter: "agTextColumnFilter",
      resizable: true,
      filterParams: {
        applyButton: true,
        clearButton: true,
      }
    },
    {
      headerName: "Closed by driver At",
      field: "driver_close_ts",
      filter: "agTextColumnFilter",
      resizable: true,
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
    },
    {
      headerName: "Auto Close Date",
      field: "auto_close_date",
      filter: "agTextColumnFilter",
      resizable: true,
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
    },
    {
      headerName: "BOL Number",
      field: "bol_number",
      filter: "agTextColumnFilter",
      resizable: true,
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
    },
    {
      headerName: "Created On",
      field: "CreatedOn",
      filter: "agTextColumnFilter",
      resizable: true,
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
    },
  ];
};

const exportArtifax = (csv) => {
  if (csv == null) return;
  const blob = new Blob([csv], {type: 'text/csv'});
  const filename = "ShipmentList.csv";

  if(window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  }
  else{
      const elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;        
      document.body.appendChild(elem);
      elem.click();        
      document.body.removeChild(elem);
  }
};

class ShipmentDispatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowIndex: -1,
      orderNo: "",
      exp1: "",
      exp2: "",
      OS: 0,
      stopId: "",
      stopName: "",
      driverId: "",
      bolNumber: "",
      withReview: 0,
      noOfFilter: 0,
      clicked: false,
      orderList: [],
      artifactsList: [],
      activeArtifactsList: [],
      orderNoList: [],
      loading: true,
      processingAPICall: '',
      processingText: {error: false, text: ''},
      count: 7,
      coordinatorId: "",
      coordinatorList: [{ value: "loading.......", label: "Loading...." }],
      openPopover: false,
      openPopoverUpload: false,
      openPopoverDelete: false,
      shipmentFile: null,
      uploadedCount: 0,
      failedCount: 0,
      selectedOrders: {},
      columnDefs: createColumnDefs(),
      customFields: {},
      doubleClickOrder: {},
      isOpen: false,
      newOrder: false,
      isBroker: 0
    };
  }

  componentDidMount() {
    checkAccess(this.props.clientId);

    this.getShipmentList();
    this.getArtifactsList();
    this.getCoordinatorList();
    this.getCustomFields();
    this.fetchCompanyDetails();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.clientId !== this.props.clientId) {
      checkAccess(newProps.clientId);
      
      this.getShipmentList();
      this.getArtifactsList();
      this.getCoordinatorList();
      this.getCustomFields();
      this.fetchCompanyDetails();

      this.setState({
        orderNoList: []
      })
    }
  }

  fetchCompanyDetails = () => {
    return secureFetch(endpoint.getCompanyDetails)
      .then(([ok, status, company]) => {
        if (ok) {
          this.setState({
            isBroker: company.is_broker
          });
        } else {
          error("Oops! error occured during fetch company details")
        }
      })
      .catch((err) => {
        error("Oops! error occured during fetch company details")
      });
  };

  getCustomFields = () => {
    secureFetch(endpoint.getCustomFields, {}).then(([ok, status, json]) => {
      if (ok) {
        this.setState({ customFields: json.customFields[0] });
      } else {
        error("Oops! error occured during fetching custom fields!");
      }
    });
  };

  onCancel = () => {
    this.setState({ isOpen: false, processingAPICall: '', processingText: {error: false, text: ''} });
  };

  finishedProcessing = (pt, pc) => {
    this.setState({ processingAPICall: pc, processingText: pt });
  }

  getCoordinatorList = () => {
    secureFetch(endpoint.getCoordinatorList, {}).then(([ok, status, json]) => {
      if (ok) {
        this.setState({
          coordinatorList: json.coordinatorList.map((k) => ({
            label: k.name,
            value: k.id,
          })),
        });
      } else {
        error("Oops! error occured during fetching order number list!");
      }
    });
  };

  downloadOrderDataCSV = () => {
    let config = {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
    };

    const emptyString = '';
    const orderList = this.state.orderList && this.state.orderList.length > 0 ? this.state.orderList : [];
    let csvData = [];
   
    for(let i = 0; i< orderList.length; i++){
      const item = orderList[i];
      const csvLine = {
        order_id: item.OrderNo ? item.OrderNo : emptyString,
        stop_id: item.StopId ? item.StopId : emptyString,
        stop_name: item.StopName ? item.StopName : emptyString,
        stop_address: item.Address ? item.Address : emptyString,
        order_stop_seq: item.Stop ? item.Stop : emptyString,
        stop_type: item.StopType ? item.StopType : emptyString,
        stop_date: item.StopDate ? item.StopDate : emptyString,
        driver1_id: item.Driver1 ? item.Driver1 : emptyString,
        driver2_id: item.Driver2 ? item.Driver2 : emptyString,
        stop_city: item.StopCity ? item.StopCity : emptyString,
        stop_state: item.StopState ? item.StopState : emptyString,
        stop_country: item.country ? item.country : emptyString,
        stop_zip: item.StopZip ? item.StopZip : emptyString,
        bol_number: item.bol_number ? item.bol_number : emptyString,
        po_num: item.PO ? item.PO : emptyString,
        truck_no: item.TruckNo ? item.TruckNo : emptyString,
        location_contact_name: item.locationContactName ? item.locationContactName : emptyString,
        location_contact_number: item.locationContactNumber ? item.locationContactNumber : emptyString,
        appointment_time_1: item.appointmentTime1 ? item.appointmentTime1 : emptyString,
        appointment_time_2: item.appointmentTime2 ? item.appointmentTime2 : emptyString,
        usr_text1: item.UserText1 ? item.UserText1 : emptyString,
        usr_text2: item.UserText2 ? item.UserText2 : emptyString,
        usr_text3: item.UserText3 ? item.UserText3 : emptyString,
        usr_num1: item.UserNum1 ? item.UserNum1 : emptyString,
        usr_num2: item.UserNum2 ? item.UserNum2 : emptyString,
        usr_num3: item.UserNum3 ? item.UserNum3 : emptyString,
        usr_date1: item.UserDate1 ? item.UserDate1 : emptyString,
        usr_date2: item.UserDate2 ? item.UserDate2 : emptyString,
        usr_date3: item.UserDate3 ? item.UserDate3 : emptyString,
        scac_code: item.ScacCode ? item.ScacCode : emptyString,
        dot_number: item.DotNumber ? item.DotNumber : emptyString,
        mc_number: item.McNumber ? item.McNumber : emptyString,
        broker_rep: item.BrokerRep ? item.BrokerRep : emptyString,
        driver_phone: item.driver_phone ? item.driver_phone : emptyString,
        customer_shipment_id: item.customer_shipment_id ? item.customer_shipment_id : emptyString,
        carrier_name: item.carrier_name ? item.carrier_name : emptyString,
        bill_to: item.bill_to ? item.bill_to : emptyString
      }

      csvData.push(csvLine)
    }

    let csv = Papa.unparse(csvData, config);
    exportArtifax(csv);
  };

  getShipmentList = () => {
    secureFetch(endpoint.getShipmentList, {}).then(([ok, status, json]) => {
      if (ok) {
        this.setState(
          {
            orderList: json,
            orderNo: "",
            loading: false
          }
        );
      } else {
        error("Oops! Could't get order list :(");
      }
    });
  };

  getOrderNO = (input, callback) => {
    let orderNumbers = [];

    // If our state is not empty
    if(this.state.orderNoList?.length > 0){
      orderNumbers = searchOrderList(this.state.orderNoList, input)
    }

    // If order exists in our cache
    if(orderNumbers?.length > 0){
      callback(null, {
        options: orderNumbers,
        // CAREFUL! Only set this to true when there are no more options,
        // or more specific queries will not be sent to the server.
        complete: true
      });
    }
    else{
      // Now fetch orders from the API.
      secureFetch(endpoint.getOrderNO, {
        filter: input,
        os: this.state.OS,
        osFilterRequired: false,
      })
      .then(([ok, status, json]) => {
        if (ok) {
          // Update state for future search.
          this.setState({
            orderNoList: json.orderNo.map((k) => ({
              label: k.order_id,
              value: k.order_id,
            }))
          });

          orderNumbers = json.orderNo.map((k) => ({
            label: k.order_id,
            value: k.order_id,
          }))
        } else {
          error("Oops! Unable to fetch order number list.");
        }
      })
      .finally(()=>{
        callback(null, {
          options: orderNumbers,
          // CAREFUL! Only set this to true when there are no more options,
          // or more specific queries will not be sent to the server.
          complete: true
        });
      });
    }
  };

  getArtifactsList = () => {
    secureFetch(endpoint.getArtifactsList, {}).then(([ok, status, json]) => {
      if (ok) {
        let activeArtifactsList = [];
        json.artifactsList.forEach((item) => {
          if (item.status !== 0) {
            activeArtifactsList.push(item);
          } else {
            return;
          }
        });
        this.setState({
          artifactsList: json.artifactsList,
          activeArtifactsList: activeArtifactsList,
        });
      } else {
        error("Oops! error occured during fetching artifacts list!");
      }
    });
  };

  handleShipmentsUpload = (uploadChunk) => {
    const self = this;
    let val = uploadChunk.length;

    fetchWithJson(endpoint.dc_process_shipment_data, {
      shipment_data: uploadChunk,
      company_id: this.props.clientId,
    }).then((res) => {
      if (res.status === 422) {
        error("Unable to process the data. Required fields missing");
        self.setState((state) => ({
          failedCount: state.failedCount + val,
        }));
      } else if (res.status === 200) {
        success("Succesfully processed the shipment data");
        self.setState((state) => ({
          uploadedCount: state.uploadedCount + val,
        }));
      } else {
        error("Unable to process the data");
        self.setState((state) => ({
          failedCount: state.failedCount + val,
        }));
      }
    });
  };

  handleShipmentDeletes = () => {
    const self = this;

    let clickedKeys = cloneDeep(this.state.selectedOrders);
    let filteredOrderList = [];

    clickedKeys.forEach((item) => {
      let params = {
        order_id: item.OrderNo,
        bill_to: item.bill_to === null ? "" : item.bill_to,
        customer_shipment_id: item.customer_shipment_id === null ? "" : item.customer_shipment_id,
        carrier_name: item.carrier_name === null ? "" : item.carrier_name,
        stop_id: item.StopId,
        stop_name: item.StopName === null ? "" : item.StopName,
        stop_address: item.Address === null ? "" : item.Address,
        order_stop_seq: item.Stop === null ? "" : item.Stop,
        stop_type: item.StopType === null ? "" : item.StopType,
        stop_date: item.StopDate === null ? "" : item.StopDate,
        driver1_id: item.Driver1 === null ? "" : item.Driver1,
        driver2_id: item.Driver2 === null ? "" : item.Driver2,
        stop_city: item.StopCity === null ? "" : item.StopCity,
        stop_state: item.StopState === null ? "" : item.StopState,
        stop_country: item.country === null ? "" : item.country,
        stop_zip: item.StopZip === null ? "" : item.StopZip,
        bol_number: item.bol_number === null ? "" : item.bol_number,
        po_num: item.PO === null ? "" : item.PO,
        truck_no: item.TruckNo === null ? "" : item.TruckNo,
        driver_phone: item.driver_phone === null ? "" : item.driver_phone,
        location_contact_name: item.locationContactName === null ? "" : item.locationContactName ,
        location_contact_number: item.locationContactNumber === null ? "" : item.locationContactNumber,
        appointment_time_1: item.appointmentTime1 === null ? "00:00": item.appointmentTime1,
        appointment_time_2: item.appointmentTime2 === null ? "00:00": item.appointmentTime2,
        usr_text1: item.UserText1 === null ? "" : item.UserText1,
        usr_text2: item.UserText2 === null ? "" : item.UserText2,
        usr_text3: item.UserText3 === null ? "" : item.UserText3,
        usr_num1: item.UserNum1 === null ? 0 : item.UserNum1,
        usr_num2: item.UserNum2 === null ? 0 : item.UserNum1,
        usr_num3: item.UserNum3 === null ? 0 : item.UserNum1,
        usr_date1: item.UserDate1 === null ? "" : item.UserDate1,
        usr_date2: item.UserDate2 === null ? "" : item.UserDate2,
        usr_date3: item.UserDate3 === null ? "" : item.UserDate3,
        scac_code: item.ScacCode === null ? "" : item.ScacCode,
        dot_number: item.DotNumber === null ? "" : item.DotNumber,
        mc_number: item.McNumber === null ? "" : item.McNumber,
        broker_rep: item.BrokerRep === null ? "" : item.BrokerRep
      };

      filteredOrderList.push(params);
    });

    fetchWithJson(endpoint.dc_delete_shipment_data, {
      delete_data: filteredOrderList,
      company_id: this.props.clientId,
    }).then((res) => {
      if (res.status === 422) {
        error("Unable to process the delete request. Required fields missing.");
      } else if (res.status === 200) {
        success("Succesfully processed the deletes");
        self.deleteFromOrderList();
      } else {
        error("Unable to process the delete request. ");
      }
    }).finally(()=>{
      this.closePopupDelete();
    });
  };

  handleUpload = () => {
    let uploadChunk = [];
    let itemsChunk = 0,
      itemCount = 0;
    let file = this.state.shipmentFile;
    const self = this;

    if (!file || !file.name) {
      error("Please select a file first");
      return;
    }

    Papa.parse(file, {
      header: true,
      //worker: true,
      step: function (row) {
        if (
          row.data[0].hasOwnProperty("order_id") &&
          row.data[0].hasOwnProperty("stop_id") &&
          row.data[0].hasOwnProperty("order_stop_seq")
        ) {
          uploadChunk.push(row.data[0]);
          itemsChunk++;
          itemCount++;
        }

        if (itemsChunk === 400) {
          self.handleShipmentsUpload(uploadChunk);
          itemsChunk = 0;
          uploadChunk = [];
        }
      },
      complete: function (results) {
        if (itemCount === 0) {
          error("We could not detect any shipment data in the input file.");
        }
        if (itemsChunk > 0) {
          self.handleShipmentsUpload(uploadChunk);
        }
      },
    });
  };

  setuploadFile = (file, email) => {
    this.setState({ shipmentFile: file });
    this.setState({ uploadedCount: 0, failedCount: 0 });
    return {
      sub(handlers) {
        // handlers.start();
        // uploadDocument(self, key, file, handlers);
      },
    };
  };

  /**
   *
   * Search according to order number or filters set
   *
   */

  handleChange = (selectedOption) => {
    this.setState({ orderNo: selectedOption ? selectedOption.value : "" });
    this.setState({ clicked: true, loading: true });

    secureFetch(endpoint.searchOrderList, {
      order_no: selectedOption ? selectedOption.value : "",
      exp1: this.state.exp1,
      exp2: this.state.exp2,
      os: this.state.OS,
      stopId: this.state.stopId,
      stopName: this.state.stopName,
      driverId: this.state.driverId,
      withReview: this.state.withReview,
      coordinator_Id: this.state.coordinatorId,
      bol_Number: this.state.bolNumber,
    }).then(([ok, status, json]) => {
      if (ok) {
        this.setState(
          {
            orderList: json.orderList,
            clicked: false,
            loading: false
          }
        );
      } else {
        error("Oops! error occured during search order list!");
      }
    });
  };

  setExp1 = (e) => {
    this.setState({ exp1: e.target.value });
  };

  setExp2 = (e) => {
    this.setState({ exp2: e.target.value });
  };

  setOs = () => {
    this.setState({ OS: this.state.OS === 0 ? 1 : 0 });
  };

  togglePopup = () => {
    this.setState({ openPopover: !this.state.openPopover });
  };

  togglePopupUpload = () => {
    this.setState({ openPopoverUpload: !this.state.openPopoverUpload });
  };

  togglePopupDelete = () => {
    this.setState({ openPopoverDelete: !this.state.openPopoverDelete });
  };

  closePopupDelete = () => {
    this.setState({ openPopoverDelete: false });
  }

  //filter orderlist
  handleSearchButton = (
    exp1,
    exp2,
    OS,
    stopId,
    stopName,
    driverId,
    withReview,
    noOfFilter,
    coordinatorId,
    bolNumber
  ) => {
    this.setState({
      clicked: true,
      noOfFilter: noOfFilter,
      exp1: exp1,
      exp2: exp2,
      OS: OS,
      stopId: stopId,
      stopName: stopName,
      driverId: driverId,
      withReview: withReview,
      coordinatorId: coordinatorId,
      bolNumber: bolNumber,
    });
    secureFetch(endpoint.searchOrderList, {
      order_no: this.state.orderNo,
      exp1: exp1,
      exp2: exp2,
      os: OS,
      stopId: stopId,
      stopName: stopName,
      driverId: driverId,
      withReview: withReview,
      coordinator_Id: coordinatorId,
      bol_Number: bolNumber,
    }).then(([ok, status, json]) => {
      if (ok) {
        this.setState(
          {
            orderList: json.orderList,
            clicked: false
          }
        );
      } else {
        error("Oops! error occured during search order list!");
        this.setState(
          {
            clicked: false
          }
        );
      }
    });
  };

  setOrderListAfterUpdate = (recentOrderObject) => {
    
    let updateIndex = this.state.rowIndex;
    let orders = cloneDeep(this.state.orderList);

    if(this.state.newOrder){
      orders.push(recentOrderObject)
    }
    else{
      orders[updateIndex] = recentOrderObject
    }

    this.setState({orderList: orders});
  };

  /**
   *
   * Delete Shipments functions
   * --------------------------------
   *
   */

  // Delete the items from the internal state and cleanup.
  deleteFromOrderList = () => {
    let cloneOrderList = cloneDeep(this.state.orderList);
    let clickedKeys = cloneDeep(this.state.selectedOrders);

    let filteredOrderList = [];
    cloneOrderList.forEach((item) => {
      let index = clickedKeys.findIndex(
        (value) => value.OrderNo === item.OrderNo && value.Stop === item.Stop
      );
      if (index === -1) {
        filteredOrderList.push(item);
      }
    });

    this.setState(
      {
        orderList: filteredOrderList,
        clicked: false,
        selectedOrders: []
      }
    );
  };

  renderShipmentUpload() {
    return (
      <div className="pa2 bg-light-gray">
        <div style={{height: "40vh", overflowY: "auto"}}>
          <h5> Please ensure the CSV contains the following columns. </h5>
          
          <div className="f5 mt2 flex justify-between"> 
            <p className="w-50 bt bb"> Mandatory Fields. </p>
            <p className="w-50 bt bb"> Optional Fields. </p>
          </div>

          <table style={{ borderCollapse: "collapse" }}>
            <tr>
              <td>order_id</td>
              <td className="br pr2">:- Order Identifier (cannot be NULL)</td>
              <td className="pl2">driver2_id</td>
              <td>:- Ther employee ID of the driver.</td>
            </tr>
            <tr>
              <td>stop_id</td>
              <td className="br pr2">:- Stop identifier </td>
              <td className="pl2">stop_city</td>
            </tr>
            <tr>
              <td>stop_name</td>
              <td className="br pr2"></td>
              <td className="pl2">stop_state</td>
            </tr>
            <tr>
              <td>order_stop_seq</td>
              <td className="br pr2"></td>
              <td className="pl2">stop_country</td>
            </tr>
            <tr>
              <td>stop_date</td>
              <td className="br pr2">:- Date should be in YYYY-MM-DD format</td>
              <td className="pl2">stop_zip</td>
            </tr>
            <tr>
              <td>stop_type</td>
              <td className="br pr2"></td>
              <td className="pl2">bol_number</td>
              <td>:- Bill of lading number</td>
            </tr>
            <tr>
              <td>driver1_id</td>
              <td className="br pr2">:- Ther employee ID of the driver.</td>
              <td className="pl2">po_num</td>
            </tr>
            <tr>
              <td>stop_address</td>
              <td className="br pr2">
                :- The address of the pickup / drop location
              </td>
              <td className="pl2">truck_no</td>
            </tr>
            <tr>
              <td></td>
              <td className="br pr2"></td>
              <td className="pl2">location_contact_name</td>
            </tr>
            <tr>
              <td></td>
              <td className="br pr2"></td>
              <td className="pl2">location_contact_number</td>
            </tr>
            <tr>
              <td></td>
              <td className="br pr2"></td>
              <td className="pl2">appointment_time_1</td>
            </tr>
            <tr>
              <td></td>
              <td className="br pr2"></td>
              <td className="pl2">appointment_time_2</td>
            </tr>
            <tr>
              <td></td>
              <td className="br pr2"></td>
              <td className="pl2">usr_text1</td>
            </tr>
            <tr>
              <td></td>
              <td className="br pr2"></td>
              <td className="pl2">usr_text2</td>
            </tr>
            <tr>
              <td></td>
              <td className="br pr2"></td>
              <td className="pl2">usr_text3</td>
            </tr>
            <tr>
              <td></td>
              <td className="br pr2"></td>
              <td className="pl2">usr_num1</td>
            </tr>
            <tr>
              <td></td>
              <td className="br pr2"></td>
              <td className="pl2">usr_num2</td>
            </tr>
            <tr>
              <td></td>
              <td className="br pr2"></td>
              <td className="pl2">usr_num3</td>
            </tr>
            <tr>
              <td></td>
              <td className="br pr2"></td>
              <td className="pl2">usr_date1</td>
            </tr>
            <tr>
              <td></td>
              <td className="br pr2"></td>
              <td className="pl2">usr_date2</td>
            </tr>
            <tr>
              <td></td>
              <td className="br pr2"></td>
              <td className="pl2">usr_date3</td>
            </tr>
            <tr>
              <td></td>
              <td className="br pr2"></td>
              <td className="pl2">scac_code</td>
            </tr>
            <tr>
              <td></td>
              <td className="br pr2"></td>
              <td className="pl2">dot_number</td>
            </tr>
            <tr>
              <td></td>
              <td className="br pr2"></td>
              <td className="pl2">mc_number</td>
            </tr>
            <tr>
              <td></td>
              <td className="br pr2"></td>
              <td className="pl2">broker_rep</td>
            </tr>
            <tr>
              <td></td>
              <td className="br pr2"></td>
              <td className="pl2">carrier_name</td>
            </tr>
            <tr>
              <td></td>
              <td className="br pr2"></td>
              <td className="pl2">customer_shipment_id</td>
            </tr>
            <tr>
              <td></td>
              <td className="br pr2"></td>
              <td className="pl2">driver_phone</td>
            </tr>
            <tr>
              <td></td>
              <td className="br pr2"></td>
              <td className="pl2">bill_to</td>
            </tr>
          </table>
        </div>

        <div className="flex justify-between pa2 mt2 bg-white ">
          <label className="f5 fw1 mr2">
            <span className="f4 fw8">{this.state.uploadedCount} </span>{" "}
            processed. /{" "}
            <span className="f4 fw8 red">{this.state.failedCount} </span>{" "}
            failed.
          </label>

          <FileUpload
            defaultName={null}
            uploadCb={this.setuploadFile}
            fileUploadClassName={"w-50"}
          />
        </div>

        <div className="flex justify-end mt3">
          <NextButton
            auto
            css={{br: 0}}
            onClick={() => {
              this.handleUpload();
            }}
          >
            Upload
          </NextButton>
        </div>
      </div>
    );
  }

  renderShipmentDeleteButton() {
    return (
      <NextButton
        color='error'
        css={{br: 0, mr: 10}}
        auto
        icon={<Delete set="light" fill="currentColor" />}
        onClick={this.togglePopupDelete}
      >
        <Text size="1rem" color='currentColor'>Delete Shipments ({size(this.state.selectedOrders)})</Text>
      </NextButton>
    );
  }

  renderShipmentDelete() {
    return (
      <Modal           
        width="500px"
        closeButton
        open={this.state.openPopoverDelete}
        onClose={this.closePopupDelete}
        aria-labelledby="Delete Selected Shipments">
        <Modal.Header>
          <Text b size="1.25rem">
            Caution!!
          </Text>
        </Modal.Header>
        <Modal.Body>
          <p className="mt3">
            This will delete the following information from the portal.
          </p>

          <ul className="mt1">
            <li>Shipment information</li>
            <li>Driver's notes</li>
            <li>Ratings</li>
            <li>All documents (Artifax) related to the shipment</li>
          </ul>
        </Modal.Body>

        <Modal.Footer>
          <div className="flex justify-between">
            <NextButton
              size='sm'
              css={{br: 0, mr: 10}}
              ghost
              auto
              color='secondary'
              onClick={this.closePopupDelete}
            >
              Close
            </NextButton>

            <NextButton
              css={{br: 0, mr: 10}}
              ghost
              auto
              size='sm'
              onClick={() => {
                this.setState({
                  selectedOrders: {},
                });
                this.togglePopupDelete();
                this.gridApi.deselectAll();
              }}
            >
              Reset
            </NextButton>

            <NextButton
              css={{br: 0}}
              size='sm'
              auto
              onClick={() => {
                let x = size(this.state.selectedOrders);

                if (x > 0) {
                  this.handleShipmentDeletes();
                } else {
                  warning(
                    "Kindly mark one or more shipment lines for deletion from the data grid."
                  );
                }
              }}
              color='error'
            >
              Delete Shipments
            </NextButton>
          </div>
        </Modal.Footer>

      </Modal>
    );
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  };

  rowDoubleClicked = (row) => {
    this.gridApi.deselectAll();
    this.setState({
      isOpen: true,
      rowIndex: row.rowIndex,
      doubleClickOrder: row.data,
      newOrder: false,
    });
  };

  handleModalMethods = (handleSingleShipmentUpload, handleSingleShipmentReopen) =>{
    this.handleSingleShipmentUpload = handleSingleShipmentUpload
    this.handleSingleShipmentReopen = handleSingleShipmentReopen
  }

  onSelectionChanged() {
    let selectedRows = this.gridApi.getSelectedRows();
    this.setState({ selectedOrders: selectedRows });
  }

  renderOrderList() {
    const gridContainerStyle = {
      height: (height - 160) + "px",
      zIndex: .99
    };

    if (this.state.orderList.length === 0 && !this.state.loading) {
      return <div className=" ml2 mt3 f5">No Shipment Records found</div>;
    }

    return (
      <div
        id="scrollTop"
        style={{ zIndex: .99 }}
      >
        {this.state.loading ? (
          <div className="flex justify-center mt6">
            <Loading size='lg'>Fetching Shipment Data...</Loading>
          </div>
        ) : (
          <div style={gridContainerStyle} className={"ag-theme-quartz p-4"}>
            <AgGridReact
              // properties
              columnDefs={this.state.columnDefs}
              rowData={this.state.orderList}
              // // events
              onGridReady={this.onGridReady}
              rowSelection="multiple"
              onRowDoubleClicked={this.rowDoubleClicked}
              onSelectionChanged={this.onSelectionChanged.bind(this)}
              // //ID
              // getRowNodeId={this.state.getRowNodeId}
              // Pagination
              pagination={true}
              paginationAutoPageSize={true}
              enableSorting
              enableFilter
            ></AgGridReact>
          </div>
        )}
      </div>
    );
  }

  renderFilterSection() {

    let orderList = this.state.orderNoList;

    return (
      <div style={{ display: "flex" }}>
        {!this.state.loading && orderList !== 0 && (
          <div className="flex">
            <NextButton
              icon={<Plus set="light" fill="currentColor" />}
              css={{br: 0, mr: 10}}
              auto
              onClick={() => {
                this.setState({ isOpen: true, newOrder: true });
              }}
            >
              <Text size="1rem" color='currentColor'>New Record</Text> 
            </NextButton>

            {this.renderShipmentDeleteButton()}
            {this.renderShipmentDelete()}

            <Tooltip 
              css={{br: 0}}
              placement="bottom" 
              content={this.renderShipmentUpload()} 
              trigger='click'>
              <NextButton
                icon={<Upload set="light" fill="currentColor" />}
                ghost
                auto
                css={{br: 0, mr: 10}}
                color='secondary'
                onClick={() => {
                  this.togglePopupUpload();
                }}
              >
                <Text size="1rem" color='currentColor'>Upload Shipments</Text>
              </NextButton>
            </Tooltip>

            <NextButton
              icon={<Download set="light" fill="currentColor" />}
              ghost
              auto
              css={{br: 0}}
              onClick={() => {
                this.downloadOrderDataCSV();
              }}
            >
              <Text size="1rem" color='currentColor'>Export to CSV</Text>
            </NextButton>
            <div id="ShipmentArtifactsContainer" style={{ display: "none" }} />
          </div>
        )}
        {!this.state.loading && (
          <div className="ml2 flex" key={this.props.clientId}>
            <Async
              name="form-field-name"
              style={{ width: "200px", zIndex: 99, height: 40}}
              placeholder="Search order"
              value={this.state.orderNo}
              onChange={this.handleChange}
              loadOptions={debounce(this.getOrderNO, 500)}
            />
            <Tooltip 
              placement="bottom" 
              css={{br: 0}}
              content={
                <Filter
                  handleSearchButton={this.handleSearchButton}
                  exp1={this.state.exp1}
                  exp2={this.state.exp2}
                  OS={this.state.OS}
                  stopId={this.state.stopId}
                  stopName={this.state.stopName}
                  driverId={this.state.driverId}
                  withReview={this.state.withReview}
                  noOfFilter={this.state.noOfFilter}
                  coordinatorList={this.state.coordinatorList}
                  coordinatorId={this.state.coordinatorId}
                  togglePopup={this.togglePopup}
                  bolNumber={this.state.bolNumber}
                  clicked={this.state.clicked}
                />
              }
              trigger='click'
            >
              <NextButton
                auto
                css={{br: 0, ml: 10}}
                onClick={this.togglePopup}
                icon={<Filter2 set='light' color='currentColor'/>}
              >
                <Text size='1rem' color='currentColor'>
                  Choose Filter ({this.state.noOfFilter})
                </Text>
              </NextButton>
            </Tooltip>
          </div>
        )}
      </div>
    );
  }

  render() {
    const processing = (this.state.processingAPICall && this.state.processingAPICall.length > 0);

    if (this.props.Access.allow_ratings_artifax === 0 && !this.state.loading) {
      return (
        <div>
          <div className="ml3 w-100">
            <h4 className={"f3 fw4 mb1 pt1 pb3 ph1 mt2 "}>Shipment Dispatch</h4>
          </div>
          <div className=" ml2 mt3 f5">This feature is unavailable</div>
        </div>
      );
    }
    return (
      <div>
        <div className="p-4 flex justify-between border-y-2 border-dotted">
          <SubPageHeader>Shipment Dispatch</SubPageHeader>
          {this.renderFilterSection()}
        </div>
        
        {this.renderOrderList()}
        
        <Modal 
            scroll 
            fullScreen
            preventClose={true}
            aria-labelledby="shipment-details"   
            aria-describedby="Shipment Details" 
            open={this.state.isOpen}
        >
            <Modal.Body>
                <ShipmentDispatchDetails
                  handleModalMethods={this.handleModalMethods}
                  isBroker={this.state.isBroker}
                  shipmentList={this.shipmentList}
                  onCancel={this.onCancel}
                  setOrderListAfterUpdate={this.setOrderListAfterUpdate}
                  doneProcessing={this.finishedProcessing}
                  // handleSearchButton={this.handleSearchButton}
                  newOrder={this.state.newOrder}
                  orderDetails={this.state.doubleClickOrder}
                  customFields={this.state.customFields}
                />
            </Modal.Body>
            <Modal.Footer>
              <Text blockquote size="1.25rem" color={this.state.processingText.error ? 'error': 'primary'} className="mr2">
                {this.state.processingText.text}
              </Text>
              {processing && <Loading color="primary">{this.state.processingAPICall}</Loading>}
              {(this.state.doubleClickOrder && this.state.doubleClickOrder.auto_close_ind === 1) ? (
                <NextButton
                  disabled={processing}
                  ghost
                  auto
                  color="warning"
                  onClick={() => {
                      this.setState({ processingAPICall: 'Re-opening shipment.' });
                      this.handleSingleShipmentReopen(); 
                    }
                  }>
                    Re-open order
                </NextButton>
              ) : null}
              <NextButton 
                disabled={processing}
                ghost 
                auto 
                onClick={() => {
                  this.handleSingleShipmentUpload();
                }
              }>
                {this.state.newOrder ? "Save" : "Update"}
              </NextButton>
              <NextButton 
                disabled={processing}
                ghost 
                auto 
                onClick={() => this.onCancel()}>
                Cancel 
              </NextButton>
            </Modal.Footer>
        </Modal>


      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    clientId: state.client ? state.client.id : mstore.getPath(["user", "company_id"]),
    Access: state.navigation.Access
  }
}

export default connect(mapStateToProps)(ShipmentDispatch);