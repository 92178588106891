/* eslint-disable no-useless-concat */
import React, { Component } from "react";
import { Card, Spacer, Row, Tooltip, Loading, Modal, Text, Button as NextButton, Textarea } from '@nextui-org/react';
import { Upload, TwoUsers, Send, Chat, Unlock, EditSquare, Delete, Download, Show, ArrowRightSquare, Location } from 'react-iconly'
import { CopyToClipboard } from "react-copy-to-clipboard";
import Select, { Async } from "react-select";
import { debounce } from "lodash";

import { secureFetch, fetchAuthed } from "../../lib/api";
import endpoint from "../../config/endpoint.json";
import { error, success, warning } from "../../lib/userAlerts";
import { searchOrderList } from "../../lib/utils";

import FileUpload from "../FileUpload";
import CannedMessageModal from "./SendCannedMessageComponent";

import dayjs from '../../lib/day'
import { CopyIcon } from "../../icons/copy";

/**
 *
 * List Card Elements   
 *
 *
 */

const LocationDetails = (props) => (
  <Text>
    <Location set="bulk" size={20} primaryColor="red"/>
    {props.dispText}
  </Text>
);

/****
 *
 * Download file
 *
 */

const downloadURI = (blob, name, documentType) => {
  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob);
    return;
  }

  let fileObj;

  if (documentType === "pdf") {
    fileObj = new Blob([blob], { type: "application/pdf" });
  } else {
    fileObj = new Blob([blob], { type: "image/jpeg" });
  }

  const uri = window.URL.createObjectURL(fileObj);
  const link = document.createElement("a");

  link.download = name;
  link.href = uri;
  link.style.display = "none";

  document.body.appendChild(link);
  link.click();

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(uri);
  }, 100);
};

function getFileKey(name) {
  return Math.random().toString(32).substring(2).toUpperCase() + "_" + name;
}

/**
 *
 * Upload attachments
 *
 */

async function uploadDocument(self, key, file) {
  try{
    const preSignedUrlResponse = await fetchAuthed(`${endpoint.signedDocumentUploadUrl}?key=${key}`, {method: "POST"})
    const preSignedUrl = await preSignedUrlResponse.json()

    /** Upload the file into S3 */
    const uploadResponse = await fetch(preSignedUrl.s3_logo_url, {
      method: "PUT",
      headers: {
        "content-type": "application/octet-stream",
      },
      body: file, // param #3
    })

    if(!uploadResponse.ok){
      throw new Error('Upload failed')
    }

    /** Save results into the DB */
    const saveToDatabaseResponse = await secureFetch(endpoint.save_documents, {
      stopId: self.state.stop,
      orderId: self.state.orderNo,
      stopSequence: self.props.stop,
      documentName: key,
      documentType: self.state.selectedDocType,
      realDocumentType: file.type === "application/pdf" ? "pdf" : file.type,
    })

    if(!saveToDatabaseResponse[0]){
      throw new Error('Unable to save to database')
    }

    success("Successfully attached documents to order id :- " + self.state.orderNo);
  }
  catch(e){
    console.log(e)
    error("Oops! Could't upload the document. Please try later :(");
  }
  finally{
    self.setState({
      uploadModal: false,
      uploadProgress: false,
      selectedDocType: "Select Document Type",
    });
    self.props.getShipmentList(
      self.state.searchOrderNo,
      self.state.searchBolNumber
    );
  }
}

const CancelOrReAssignClick = (props) => {
  let lbl = props.dispLable ? props.dispLable : "Reassign Documents";

  return (
    <NextButton
      auto
      css={{br: 0}}
      title={lbl}
      onClick={props.onClick}
    >
      {props.dispText}
    </NextButton>
  );
};

let logoPrompt = null;
export default class ListItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openCannedMessageModal: false,
      stop: this.props.shipmentId,
      orderNo: this.props.ordderNo,
      openFirstModal: false,
      openSecondModal: false,
      openThirdModal: false,
      document: this.props.documents,
      array: [],
      imageUrl: "",
      selectedDocument: [],
      stopIdList: [],
      stopId: "",
      orderNoList: this.props.orderNoList,
      orderId: "",
      selectedStopSequence: "",
      stopSequenceList: [],
      allDocuments: [],
      artifactsList: this.props.artifactsList,
      activeArtifactsList: this.props.activeArtifactsList,
      uploadModal: false,
      downloadModal: false,
      selectedDocType: this.props.activeArtifactsList[0]
        ? this.props.activeArtifactsList[0].type_name
        : "",
      attachmentUrl: "",
      dialogTitle: "Move Documents to another Order",
      reassingLinkClick: false,
      reassignLink: "Reassign",
      searchOrderNo: this.props.searchOrderNo,
      searchBolNumber: this.props.searchBolNumber,
      toolTipText: null,
      uploadAttachment: null,
      uploadProgress: false,
      downloadWarningDialog: false,
      openDriverDetailsModal: false,
      reopenLoading: false,
      isFlipped: false,
      driver1Details: {},
      driver2Details: {},
      loadingDriverDetails: false,
    };

    this.reassingOnClick = this.reassingOnClick.bind(this);
    this.imageDownload = this.imageDownload.bind(this);
    this.closeUploadModal = this.closeUploadModal.bind(this);
    this.openUploadModal = this.openUploadModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    const isFlipped = this.state.isFlipped
    this.setState({ isFlipped: !isFlipped})
  }

  closeUploadModal() {
    this.setState({
      uploadModal: false,
      documentTypeNotSelected: false,
      selectedDocType: "Select Document Type",
    });
  }

  closeCannedMessageModal = () => {
    this.setState({ openCannedMessageModal: false });
    this.props.getShipmentList(
      this.state.searchOrderNo,
      this.state.searchBolNumber
    );
  };

  openUploadModal() {
    this.setState({
      attachmentUrl: "",
      uploadModal: true,
      selectedDocType: this.state.activeArtifactsList[0].type_name,
    });
  }

  openFirstModal = (doc) => {
    this.setState({ openFirstModal: true, array: doc });
  };

  openFirstModal2 = () => {
    this.setState({
      openFirstModal: true,
      openSecondModal: false,
      orderId: "",
      stopId: "",
    });
  };

  openSecondModal = () => {
    if (this.state.selectedDocument.length !== 0) {
      this.setState({ openSecondModal: true, dialogTitle: "Select details" });
    } else {
      warning("Please select the documents first");
    }
  };

  openThirdModal = () => {
    if (this.state.orderId && this.state.stopId) {
      this.setState({
        openThirdModal: true,
        dialogTitle: "Confirmation",
      });
    } else {
      warning("Please select order id and stop id");
    }
  };

  componentWillReceiveProps(newProps) {
    this.setState({
      stop: newProps.shipmentId,
      orderNo: newProps.ordderNo,
      orderNoList: newProps.orderNoList,
      artifactsList: newProps.artifactsList,
      searchOrderNo: newProps.searchOrderNo,
      searchBolNumber: newProps.searchBolNumber,
      selectedDocType: newProps.activeArtifactsList[0]?.type_name,
      activeArtifactsList: newProps.activeArtifactsList,
    });
  }

  toggleDialogf = () => {
    this.setState({
      openFirstModal: false,
      openSecondModal: false,
      openThirdModal: false,
      reassingLinkClick: false,
      dialogTitle: "Move Documents to another Order / Stop",
      reassignLink: "Reassign",
      selectedDocument: [],
      stopId: "",
      orderId: "",
      selectedStopSequence: "",
      AssignAllDocumentButtonClicked: false,
    });
  };

  toggleDownloadWarningDialog = () => {
    this.setState({ downloadWarningDialog: !this.state.downloadWarningDialog });
  };

  toggleDriverDetailsModal = async () => {
    if (!this.state.openDriverDetailsModal) {
      this.setState({
        openDriverDetailsModal: !this.state.openDriverDetailsModal,
        loadingDriverDetails: true,
      });

      let driver1Details = {};
      let driver2Details = {};

      let [driver1Ok, driver1Status, driver1Json] = await secureFetch(
        endpoint.getDriverDetails,
        {
          clientId: this.props.clientId,
          driverId: this.props.driver1EmpId,
          driverUserId: this.props.driver1UserId,
        }
      );

      if (driver1Ok) {
        driver1Details = driver1Json.driverDetails[0]
          ? driver1Json.driverDetails[0]
          : {};
      }

      let [driver2Ok, driver2Status, driver2Json] = await secureFetch(
        endpoint.getDriverDetails,
        {
          clientId: this.props.clientId,
          driverId: this.props.driver2EmpId,
          driverUserId: this.props.driver2UserId,
        }
      );

      if (driver2Ok) {
        driver2Details = driver2Json.driverDetails[0]
          ? driver2Json.driverDetails[0]
          : {};
      }

      this.setState({
        driver1Details,
        driver2Details,
        loadingDriverDetails: false,
      });
    } else {
      this.setState({
        openDriverDetailsModal: !this.state.openDriverDetailsModal,
        loadingDriverDetails: false,
      });
    }
  };

  getOrderNO = (input, callback) => {
    let orderNumbers = [];

    // If our state is not empty
    if(this.state.orderNoList?.length > 0){
      orderNumbers = searchOrderList(this.state.orderNoList, input)
    }

    // If order exists in our cache
    if(orderNumbers?.length > 0){
      callback(null, {
        options: orderNumbers,
        // CAREFUL! Only set this to true when there are no more options,
        // or more specific queries will not be sent to the server.
        complete: true
      });
    }
    else{
      // Now fetch orders from the API.
      secureFetch(endpoint.getOrderNO, {
        filter: input,
        os: this.state.OS,
        osFilterRequired: false,
      })
      .then(([ok, status, json]) => {
        if (ok) {
          // Update state for future search.
          this.setState({
            orderNoList: json.orderNo.map((k) => ({
              label: k.order_id,
              value: k.order_id,
            }))
          });

          orderNumbers = json.orderNo.map((k) => ({
            label: k.order_id,
            value: k.order_id,
          }))
        } else {
          error("Oops! Unable to fetch order number list.");
        }
      })
      .finally(()=>{
        callback(null, {
          options: orderNumbers,
          // CAREFUL! Only set this to true when there are no more options,
          // or more specific queries will not be sent to the server.
          complete: true
        });
      });
    }
  };

  handleSelect = (obj) => {
    var itemIndex = this.state.selectedDocument.findIndex(
      (item) => item.id === obj.id
    );
    if (itemIndex === -1) {
      this.setState({
        selectedDocument: [...this.state.selectedDocument, obj],
      });
    } else {
      this.setState({
        selectedDocument: [
          ...this.state.selectedDocument.slice(0, itemIndex),
          ...this.state.selectedDocument.slice(itemIndex + 1),
        ],
      });
    }
  };

  // on change of the stop sequence
  handleChangeStopSeq = (selectedOption) => {
    const selectedStopSequence = selectedOption ? selectedOption.value : "";
    this.setState({ selectedStopSequence });
  };

  // on change of stop id auto select stop sequence
  handleChange = (selectedOption) => {
    const stopId = selectedOption ? selectedOption.value : "";
    const stopSequenceList = [];

    selectedOption.stopSequences.forEach((o) => {
      stopSequenceList.push({ label: o.sequence, value: o.sequence });
    });

    const selectedStopSequence =
      stopSequenceList.length > 0
        ? stopSequenceList[0].value
        : this.state.selectedStopSequence;
        
    this.setState({ stopId, stopSequenceList, selectedStopSequence });
  };

  // on change of the order id ...
  handleChangeR = async (selectedOption) => {
    const orderId = selectedOption ? selectedOption.value : "";
    this.setState({ orderId });

    const stopIdList = [];
    const stopSequenceList = [];
    let stopId = this.state.stopId;
    let selectedStopSequence = this.state.selectedStopSequence;

    // filter the whole list based on order id
    // and generate the stopIdList and stopSequenceList accordingly
    try{
      let [ok, status, stopList] = await  secureFetch(endpoint.getStopIdList, {orderId: orderId })

      if(stopList.success){
        
        // List of stops!
        stopList.stopId.forEach(o => {
          stopIdList.push({
            label: o.stop_id,
            value: o.stop_id,
            stopSequences: JSON.parse(o.stop_sequence)
          });
        });

        // assign the first value in the list for stop id
        if (stopIdList.length > 0) {
          stopId = stopIdList[0].value;
          stopIdList[0].stopSequences.forEach((o) => {
            stopSequenceList.push({ label: o.sequence, value: o.sequence });
          });
        }

        // assign the first value in the list for stop sequence
        if (stopSequenceList.length > 0) {
          selectedStopSequence = stopSequenceList[0].value;
        }

        console.log(stopSequenceList)
      }
      else{
        error("Unable to retrieve the stop list for this order.")
      }
    }
    catch(e) {
      console.log(e)
      error("Unable to retrieve the stop list for this order.")
    }

    this.setState({
      stopIdList,
      stopId,
      stopSequenceList,
      selectedStopSequence,
    });

  };

  reassignAllDocuments = () => {
    let realList = this.props.documents;
    let documentTypeList = [];
    let allDocumentList = [];
    Object.keys(realList).forEach((key) => {
      const doc = realList[key];
      documentTypeList.push(doc);
    });

    documentTypeList.forEach((item) => {
      item.forEach((items) => {
        allDocumentList.push(items);
      });
    });
    this.setState({
      selectedDocument: allDocumentList,
      openFirstModal: true,
      AssignAllDocumentButtonClicked: true,
      openSecondModal: true,
    });
  };

  reassignDocuments = () => {
    let stopId = this.state.stopId;
    let orderId = this.state.orderId;
    let stopSequence = this.state.selectedStopSequence;
    this.state.selectedDocument.forEach((items) => {
      let id = items.id;
      secureFetch(endpoint.reassignDocuments, {
        id,
        stopId,
        orderId,
        stopSequence,
      }).then(([ok, status, json]) => {
        if (ok) {
          success("Successfully reassigned documents to order id : " + orderId);
          this.setState({ selectedDocument: [] });
          this.props.getShipmentList(
            this.state.searchOrderNo,
            this.state.searchBolNumber
          );
        } else {
          error("Oops! Could't reassign documents :(");
        }
      });
    });

    this.setState({
      openFirstModal: false,
      openSecondModal: false,
      openThirdModal: false,
      reassingLinkClick: false,
      dialogTitle: "Move Documents to another Order / Stop",
      reassignLink: "Reassign",
      selectedDocument: [],
      stopId: "",
      orderId: "",
    });
  };

  handleUpload = (file, email) => {
    this.setState({ documentTypeNotSelected: false });
    if (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf"
    ) {
      this.setState({ uploadAttachment: file });
      return {
        sub(handlers) {
          // handlers.start();
          // uploadDocument(self, key, file, handlers);
        },
      };
    } else {
      this.setState({ documentTypeNotSelected: true });
      return {
        sub(handlers) {
          handlers.end();
        },
      };
    }
  };

  uploadAttachment = () => {
    this.setState({ uploadProgress: true });
    const file = this.state.uploadAttachment;
    const self = this;
    let key = getFileKey(file.name);
    uploadDocument(self, key, file);
  };

  reassingOnClick(e) {
    if (e) {
      this.setState({
        reassingLinkClick: false,
        dialogTitle: "Move Documents to another Order / Stop",
        reassignLink: "Reassign",
      });
    } else {
      this.setState({
        reassingLinkClick: true,
        dialogTitle: "Select documents",
        reassignLink: "Cancel",
      });
    }
  }

  imageDownload(e, documentType, documentName, origDocName) {
    if (!this.state.reassingLinkClick) {
      let fileName = "";
      let r = origDocName;

      //remove the file extension.
      r = r.substring(0, r.lastIndexOf("."));

      this.setState({ downloadModal: true });

      if (documentType === "pdf") {
        fileName = "SDN_Driver_Connect_Artifax_Download" + " - " + r + ".pdf";
      } else {
        let x = documentType.split("/");
        fileName =
          "SDN_Driver_Connect_Artifax_Download" + " - " + r + "." + x[1];
      }

      fetch(documentName)
        .then((res) => res.blob()) // Gets the response and returns it as a blob
        .then((blob) => {
          this.setState({ downloadModal: false });
          downloadURI(blob, fileName, documentType);
        });
    }
  }

  deleteAttachment = (attachmentId) => {
    secureFetch(endpoint.deleteAttachment, {
      attachmentId,
    }).then(([ok, status, json]) => {
      if (ok) {
        success("You have successfully deleted attachment");
        this.props.getShipmentList(
          this.state.searchOrderNo,
          this.state.searchBolNumber
        );
      } else {
        error("Oops! error occour during delete attachment :(");
      }
    });
  };

  reopenShipment = () => {
    this.setState({ reopenLoading: true });

    secureFetch(endpoint.reopenShipment, {
      sdnRecordId: this.props.sdnRecordId
    }).then(([ok, status, json]) => {
      if (ok) {
        success("You have successfully re-opened this shipment");
        this.props.getShipmentList(
          this.props.ordderNo,
          this.state.searchBolNumber
        );
        this.setState({ reopenLoading: false });
      } else {
        error("Oops! error occured during reopen this shipment");
        this.setState({ reopenLoading: false });
      }
    });
  };

  onCopy = () =>{
    success("Copied to clipboard")
  }

  driverDetailsModal() {
    return (
      <Modal
          width="500px"
          closeButton
          blur
          aria-labelledby="Driver Details"
          open={this.state.openDriverDetailsModal}
          onClose={this.toggleDriverDetailsModal}
      >
          <Modal.Header>
            <Text size="1.25rem">
              Driver details for Stop {this.props.shipmentId}, Sequence {this.props.stop}
            </Text>
          </Modal.Header>
          <Modal.Body>
              <div className="pa3">
              {this.state.loadingDriverDetails ? (
                <div className="flex justify-center items-center">
                  <Loading size="md">Fetching driver details..</Loading>
                </div>
              ) : (
              <div>
                <div>
                  <div className="f4">Driver 1 Details</div>
                  <div className="flex flex-row mt2 items-center">
                    <div className="f5 w-30">Name:</div>
                    <div className="f5">
                      {this.state.driver1Details.name
                        ? this.state.driver1Details.name
                        : "Not available"}
                    </div>
                    <CopyToClipboard
                      text={
                        this.state.driver1Details.name
                          ? this.state.driver1Details.name
                          : " "
                      }
                      onCopy={this.onCopy}
                    >
                      <span className="ml2">
                        <CopyIcon height={16} width={16}/>
                      </span>
                    </CopyToClipboard>
                  </div>
                  <div className="flex flex-row mt2 items-center">
                    <div className="f5 w-30">Employee Id:</div>
                    <div className="f5">
                      {this.state.driver1Details.employee_id
                        ? this.state.driver1Details.employee_id
                        : "Not available"}
                    </div>
                    <CopyToClipboard
                      text={
                        this.state.driver1Details.employee_id
                          ? this.state.driver1Details.employee_id
                          : " "
                      }
                      onCopy={this.onCopy}
                    >
                      <span className="ml2">
                        <CopyIcon height={16} width={16}/>
                      </span>
                    </CopyToClipboard>
                  </div>
                  <div className="flex flex-row mt2 items-center">
                    <div className="f5 w-30">Email:</div>
                    <div className="f5">
                      {this.state.driver1Details.user_email
                        ? this.state.driver1Details.user_email
                        : "Not available"}
                    </div>
                    <CopyToClipboard
                      text={
                        this.state.driver1Details.user_email
                          ? this.state.driver1Details.user_email
                          : " "
                      }
                      onCopy={this.onCopy}
                    >
                      <span className="ml2">
                        <CopyIcon height={16} width={16}/>
                      </span>
                    </CopyToClipboard>
                  </div>
                  <div className="flex flex-row mt2 items-center">
                    <div className="f5 w-30">Phone:</div>
                    <div className="f5">
                      {this.state.driver1Details.phone
                        ? this.state.driver1Details.phone
                        : "Not available"}
                    </div>
                    <CopyToClipboard
                      text={
                        this.state.driver1Details.phone
                          ? this.state.driver1Details.phone
                          : " "
                      }
                      onCopy={this.onCopy}
                    >
                      <span className="ml2">
                        <CopyIcon height={16} width={16}/>
                      </span>
                    </CopyToClipboard>
                  </div>
                </div>
                <div className="mt3">
                  <div className="f4">Driver 2 Details</div>
                  <div className="flex flex-row mt2 items-center">
                    <div className="f5 w-30">Name:</div>
                    <div className="f5">
                      {this.state.driver2Details.name
                        ? this.state.driver2Details.name
                        : "Not available"}
                    </div>
                    <CopyToClipboard
                      text={
                        this.state.driver2Details.name
                          ? this.state.driver2Details.name
                          : " "
                      }
                      onCopy={this.onCopy}
                    >
                      <span className="ml2">
                        <CopyIcon height={16} width={16}/>
                      </span>
                    </CopyToClipboard>
                  </div>
                  <div className="flex flex-row mt2 items-center">
                    <div className="f5 w-30">Employee Id:</div>
                    <div className="f5">
                      {this.state.driver2Details.employee_id
                        ? this.state.driver2Details.employee_id
                        : "Not available"}
                    </div>
                    <CopyToClipboard
                      text={
                        this.state.driver2Details.employee_id
                          ? this.state.driver2Details.employee_id
                          : " "
                      }
                      onCopy={this.onCopy}
                    >
                      <span className="ml2">
                        <CopyIcon height={16} width={16}/>
                      </span>
                    </CopyToClipboard>
                  </div>
                  <div className="flex flex-row mt2 items-center">
                    <div className="f5 w-30">Email:</div>
                    <div className="f5">
                      {this.state.driver2Details.user_email
                        ? this.state.driver2Details.user_email
                        : "Not available"}
                    </div>
                    <CopyToClipboard
                      text={
                        this.state.driver2Details.user_email
                          ? this.state.driver2Details.user_email
                          : " "
                      }
                      onCopy={this.onCopy}
                    >
                      <span className="ml2">
                        <CopyIcon height={16} width={16}/>
                      </span>
                    </CopyToClipboard>
                  </div>
                  <div className="flex flex-row mt2 items-center">
                    <div className="f5 w-30">Phone:</div>
                    <div className="f5">
                      {this.state.driver2Details.phone
                        ? this.state.driver2Details.phone
                        : "Not available"}
                    </div>
                    <CopyToClipboard
                      text={
                        this.state.driver2Details.phone
                          ? this.state.driver2Details.phone
                          : " "
                      }
                      onCopy={this.onCopy}
                    >
                      <span className="ml2">
                        <CopyIcon height={16} width={16}/>
                      </span>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
              )}
            </div>
          </Modal.Body>
      </Modal>
    );
  }

  renderDownloadInfoModal() {
    if (!this.state.downloadModal) {
      return false;
    }

    return (
      <Modal
        blur
        closeButton
        open={true}
        onClose={() => {
          this.setState({ downloadModal: false });
        }}
      >
        <Modal.Header>
          <Text size={20}>Downloading ... </Text>
        </Modal.Header>
        <Modal.Body>
          <Text size={18}>
            In most cases, the file will saved in the{" "} <strong>'Downloads'</strong> folder. Please check there.
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <NextButton 
            size='sm'
            auto
            css={{br:0}}
            ghost
            onClose={() => {
              this.setState({ downloadModal: false });
            }} 
          >Close</NextButton>
        </Modal.Footer>
      </Modal>
    );
  }

  renderUploadModal() {
    if (!this.state.uploadModal) {
      return false;
    }

    return (
      <Modal
        width="480px"
        open={this.state.uploadModal}
        onClose={()=>{
          this.setState({uploadModal: false})
        }}
        blur
        css={{br: 0}}
        closeButton
      >
        <Modal.Header>
          <Text size="1.25rem">Upload a document</Text>
        </Modal.Header>

        <Modal.Body>
          <Text size="1rem">Select a document type</Text>
          <Row justify="space-between">
            <div className="mt2 mb3 w-full">
              <select
                className="select select-sm select-primary w-full"
                value={this.state.selectedDocType}
                onChange={(e) =>
                  this.setState({ selectedDocType: e.target.value })
                }
              >
                {this.state.artifactsList.map((item, id) => {
                  if (item.status !== 0) {
                    return (
                      <option key={id} value={item.type_name}>
                        {item.type_name}
                      </option>
                    );
                  } else {
                    return false;
                  }
                })}
              </select>
            </div>
          </Row>

          <Row justify="space-between">
            <div style={{width: 240}}>
              <FileUpload defaultName={logoPrompt} uploadCb={this.handleUpload} />
            </div>

            {this.state.uploadProgress 
              ? 
                <Loading type="points" color="primary" size="md" css={{mt: 10}}/> 
              : 
                <NextButton
                  auto
                  css={{br: 0, ml: 10}}
                  size="sm"
                  disabled={this.state.uploadProgress || !this.state.uploadAttachment}
                  onClick={this.uploadAttachment}
                >
                    <span>Upload</span>
                </NextButton>
          }
          </Row>
          {this.state.documentTypeNotSelected ? (
            <div className="flex flex-row pa3 bg-near-white">
              <Text
                b
                size={16}
                color="red"
              >
                Please select image or pdf file
              </Text>
            </div>
          ) : (
            <div className="h1 mt2 ml4" />
          )}

        </Modal.Body>
      </Modal>
    );
  }

  renderCloseDate = () => {
    //Handle bad DB data just in case 
    let closeDate = this.props.autoCloseDate ? this.props.autoCloseDate : new Date().getTime() / 1000
    let driverClosed = false

    if(this.props.driverCloseTs && this.props.driverCloseTs >= closeDate){
      driverClosed = true
      closeDate = this.props.driverCloseTs
    }

    return (
      <div className="pa2">
        <Text size="1.25rem" b color={driverClosed ? 'error': 'primary'}>
          {driverClosed? 'Closed by Driver': 'Auto Closed by System'}
        </Text>
        <Spacer y={0.5}/>
        <Text size="1rem">
          {`Order closed ${dayjs.unix(closeDate).fromNow()}.`}
        </Text>
        <Text size="1rem">
          {`Closed time stamp is ${dayjs.unix(closeDate).utc().format('MM/DD/YYYY h:mm A')} (UTC).`}
        </Text>
        <Spacer y={0.5}/>
        <Text size="1rem" span b>Please click on lock icon (button) to re-open the shipment.</Text>
      </div>
    )
  }

  renderDriverComments = () => {
    //Handle bad data just in DB
    let closeDate = this.props.autoCloseDate ? this.props.autoCloseDate : new Date().getTime() / 1000

    return (
      <div className="pa2" style={{width: '40vw'}}>
        <Text size="1.25rem">
          Driver Comments
        </Text>
        <Spacer y={1}/>
        <Textarea
          bordered
          css={{ br: 0, w: '100%' }}
          className="w-100"
          disabled={true}
          minRows={4}
          rows={4}
          value={this.props.comments ? this.props.comments : "None"}
        />
        <div className="w-100 flex justify-end">
          <NextButton
            css={{br: 0, mt: 20, bc: '$blue500'}}
            size="sm"
            onClick={this.handleClick}
          >
            <Text color="white">Close</Text>
          </NextButton>
        </div>
      </div>
    )
  }

  renderActionButtons() {

    let reOpenButtonColor = this.props.driverCloseTs >= this.props.autoCloseDate ? '$red500': 'primary'

    return (
      <div className="flex flex-column items-end" style={{zIndex: 0}}>
        <div
          className={
            this.props.allowSendMessage === 1
              ? "bg-red flex justify-center items-center white"
              : "flex justify-center items-center white"
          }
          style={{
            width: 30,
            height: 20,
            borderRadius: 5,
            position: "relative",
            top: 10,
            right: 0,
            zIndex: 100,
          }}
        >
          {this.props.allowSendMessage === 1
            ? this.props.pushNotificationCount || 0
            : null}
        </div>
        
        <NextButton.Group ghost rounded={false} style={{ zIndex: 99 }}>
          {((this.props.checkOs === 0 &&
            Object.keys(this.props.documents).length !== 0) ||
            (this.props.checkOs === 1 &&
              this.props.documents[this.props.osdDocType])) && ( 
                <NextButton
                  onClick={this.reassignAllDocuments}
                  icon={<EditSquare set="light" fill="currentColor" />}
                />
          )}

          <NextButton
            onClick={this.openUploadModal}
            icon={<Upload set="light" fill="currentColor" />}
          />
          
          {(this.props.allowBrokersToReopenClosedStop === 1 && this.props.autoCloseInd === 1) && (
            <Tooltip placement="right" content={this.renderCloseDate()}>
              <NextButton 
                css={{br: 0, borderLeft: 'none', color: reOpenButtonColor }}
                onClick={this.reopenShipment}
                icon={<Unlock set="light" />} 
              >
              {this.state.reopenLoading ? ( <Loading type="points" color="primary" size="md" /> ) : null}
              </NextButton>
            </Tooltip>
          )}

          <Tooltip trigger="click" 
            placement="right"
            hideArrow={false} 
            content={this.renderDriverComments()} 
            visible={this.state.isFlipped}>
            <NextButton
              css={{br: 0, borderLeft: 'none'}}
              icon={<Chat set="light" fill="currentColor" />}
              onClick={this.handleClick}
            />
          </Tooltip>

          <NextButton
            onClick={this.toggleDriverDetailsModal}
            icon={<TwoUsers set="light" fill="currentColor" />}
          />

          {this.props.allowSendMessage === 1 && (
            <NextButton
              icon={<Send set="light" fill="currentColor" />}
              onClick={() => {
                this.setState({ openCannedMessageModal: true });
              }}
            />
          )}
        </NextButton.Group>

      </div>
    );
  }

  renderArtifaxSection() {
    return (
      <div className="w-70">
        <Card shadow={false} animated={false} css={{br: 0}}>
          <Row justify="space-between">
            <Text h5>{this.props.ordderNo}</Text>
            <Text h5>{this.props.shipmentId}</Text>
            <Text h5>{this.props.shipmentTime}</Text>
          </Row>
          <Spacer y={0.25}/>
          <Row justify="space-between">
            <LocationDetails dispText={this.props.address} />
            <Text>{`Stop Seq:  ${this.props.stop}`}</Text>
          </Row>
          {this.props.partnerDocument && (
            <div>
              <Spacer y={0.25}/>
              <div className="flex flex-row mt1 pt1 pb1 f5 red">
                Record Source - {this.props.recordSource}
              </div>
            </div>
          )}

          <Card.Footer css={{ pt: 0, br: 0}}>
            <div
              className="w-100 flex justify-between items-center"
              key="front"
              style={{ height: 80 }}
            >
              {this.renderActionButtons()}
            </div>
          </Card.Footer>
        </Card>

      </div>
    );
  }

  showDocuments = () => {
    return (this.props.checkOs === 0 &&
      Object.keys(this.props.documents).length !== 0) ||
      (this.props.checkOs === 1 &&
        this.props.documents[this.props.osdDocType]) ? (
      <div className="w-100 mt1">
        {Object.keys(this.props.documents).map((key) => {
          if (
            (this.props.checkOs === 0 && key !== this.props.osdDocType) ||
            (this.props.checkOs === 1 && key === this.props.osdDocType)
          ) {
            const doc = this.props.documents[key];
            return doc.map((attachment) => {
              return (
                <div className="w-100" key={attachment.id}>
                  <div className="mt1 flex flex-column justify-between w-100 flex-wrap bb pb2">
                    <div className="w-100 f5">{key}</div>
                    <div className="w-100 flex justify-between">
                      <Show
                        color='blue'
                        set='light'
                        size={24}
                        onClick={() => {
                          if (attachment.origDocName) {
                            this.props.setPreview(attachment.documentName);
                          } else {
                            this.toggleDownloadWarningDialog();
                          }
                        }}
                      />
                      <Download
                        color='green'
                        set='light'
                        size={24}
                        onClick={
                          attachment.origDocName
                            ? this.imageDownload.bind(
                                null,
                                `${attachment.documentName}`,
                                attachment.documentType,
                                attachment.documentName,
                                attachment.origDocName
                              )
                            : this.toggleDownloadWarningDialog
                        }
                      />
                      <ArrowRightSquare
                        size={24}
                        onClick={() => {
                          let document = [];
                          document.push(attachment);
                          this.setState({
                            selectedDocument: document,
                            openFirstModal: true,
                            openSecondModal: true,
                          });
                        }}
                      />
                      <Tooltip
                        css={{br: 0}}
                        trigger='click'
                        placement="top"
                        content={       
                          <div className="pa3">
                            <h5 className="mb3">Do you want to delete this attachment?</h5>
                            <NextButton
                              auto
                              ghost
                              size='sm'
                              css={{br: 0}}
                              color='error'
                              onClick={() => {
                                this.deleteAttachment(attachment.id);
                              }}
                            >
                              Yes
                            </NextButton>
                          </div>
                        }
                      >
                        <Delete set='light' size={24} color='red'/>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              );
            });
          }
        })}
      </div>
    ) : (
      <div className="mt2 mr4">None</div>
    );
  };

  renderRatingSection() {
    return (
      <div className="w-30 bg-near-white">
        <div className="flex flex-column pl3 pr3 pb2 pt2 w-100">
          <Text h5>Documents</Text>
          {this.props.activeArtifactsList.length !== 0 ? (
            this.showDocuments()
          ) : (
            <div className="mt2 mr4">None</div>
          )}
        </div>
      </div>
    );
  }

  renderReassignModal() {
    if (!this.state.openFirstModal) {
      return false;
    }

    const filterOptions = (orderList, filter, currentValues) => {
      if (!filter) return orderList.slice(0, 10);
      else {
        // let bSearchOrderList = _.debounce(searchOrderList, 300);
        return searchOrderList(orderList, filter);
      }
    };

    return (
      <Modal
        width='400px'
        closeButton
        blur
        aria-labelledby="Re-Assign docs"
        open={this.state.openFirstModal}
        onClose={this.toggleDialogf}
      >
      <Modal.Header>
          <Text id="modal-title" size={20}>
            {this.state.dialogTitle}
          </Text>
      </Modal.Header>

      <Modal.Body>
        <div className="flex">
          {this.state.openThirdModal ? 
          (
            <div>
              <Text size="1rem"> Reassign Documents to</Text>
              <Spacer y={0.5}/>
              
              <table className="table">
                <tbody>
                  <tr className="f5">
                    <td>Order Id</td>
                    <td className="fw6">{this.state.orderId}</td>
                  </tr>
                  <tr className="f5">
                    <td>Stop Id</td>
                    <td className="fw6">{this.state.stopId}</td>
                  </tr>
                  <tr className="f5">
                    <td>Stop Sequence</td>
                    <td className="fw6">{this.state.selectedStopSequence}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : 
          (
            <div className="">
              <label>Order ID</label>
              <Async
                name="form-field-name"
                clearable={false}
                style={{ width: "350px", marginTop: "10px" }}
                placeholder="Select Order Id"
                value={this.state.orderId}
                onChange={this.handleChangeR}
                options={this.state.orderNoList}
                loadOptions={debounce(this.getOrderNO, 500)}

              />
              <div className="py-3">
                <label>Stop ID</label>
              </div>
              <Select
                name="form-field-name"
                clearable={false}
                style={{ width: "350px" }}
                placeholder="Select Stop Id"
                value={this.state.stopId}
                onChange={this.handleChange}
                options={this.state.stopIdList}
              />
              <div className="py-3">
                <label>Stop Sequence</label>
              </div>
              <Select
                name="form-field-name"
                clearable={false}
                style={{ width: "350px" }}
                placeholder="Select Stop Sequence"
                value={this.state.selectedStopSequence}
                onChange={this.handleChangeStopSeq}
                options={this.state.stopSequenceList}
              />
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {this.state.openThirdModal ? (
          <div className="w-100 flex justify-end">
            <CancelOrReAssignClick
              onClick={this.toggleDialogf}
              dispText="Cancel"
              dispLable="Cancel"
            />
            <Spacer x={1}/>
            <CancelOrReAssignClick
              onClick={this.reassignDocuments}
              dispText="Confirm"
            />
          </div>
        ) : (
          <div className="flex w-100 justify-end">
            <CancelOrReAssignClick
              onClick={this.openThirdModal}
              dispText="Reassign"
            />
          </div>
        )}
      </Modal.Footer>
    </Modal>
    );
  }

  render() {
    return (
      <div className="flex flex-row ba b--black-10 ma2 br2 mb2">
        {this.renderArtifaxSection()}
        {this.renderRatingSection()}
        {this.renderReassignModal()}

        {this.renderUploadModal()}
        {this.renderDownloadInfoModal()}
        {this.driverDetailsModal()}
        {this.props.allowSendMessage === 1 && (
            <CannedMessageModal
              recordId={this.props.sdnRecordId}
              driver1Id={this.props.driver1EmpId}
              driver2Id={this.props.driver2EmpId}
              driver1UserId={this.props.driver1UserId}
              driver2UserId={this.props.driver2UserId}
              orderId={this.props.ordderNo}
              bolNumber={this.props.bolNumber}
              stopId={this.props.stop}
              clientId={this.props.clientId}
              closeCannedMessageModal={this.closeCannedMessageModal}
              openCannedMessageModal={this.state.openCannedMessageModal}
              partnerDocument={this.props.partnerDocument}
            />
          )}
        <Modal
          blur
          width="400px"
          open={this.state.downloadWarningDialog}
          onClose={this.toggleDownloadWarningDialog}
          css={{ height: "150px", br: 0 }}
        >
          <Modal.Header>
            <Text b size={20}>Document getting processed</Text>
          </Modal.Header>
          <Modal.Body>
            We are processing your document. Please try after about 5 minutes.
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
