import React, { Component } from "react";
import dayjs from '../../lib/day';

import { mstore } from "../../store";
import { connect } from "react-redux";

import OrderAndLocation from "../../components/ShipmentDispatchComponents/OrderAndLocation";
import DriversAndDocuments from "../../components/ShipmentDispatchComponents/DriversAndDocuments";
import MoreInformation from "../../components/ShipmentDispatchComponents/MoreInformation";
import CarrierInformation from "../../components/ShipmentDispatchComponents/CarrierInformation";
import endpoint from "../../config/endpoint.json";
import { secureFetch, fetchWithJson } from "../../lib/api";
import { error, success } from "../../lib/userAlerts";

class ShipmentDispatchDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderAndLocationDetails: {
        orderId: this.props.orderDetails.OrderNo
          ? this.props.orderDetails.OrderNo
          : "Not Available",
        customerShipmentId: this.props.orderDetails.customer_shipment_id
          ? this.props.orderDetails.customer_shipment_id
          : "Not Available",
        carrierName: this.props.orderDetails.carrier_name
          ? this.props.orderDetails.carrier_name
          : "Not Available",
        billTo: this.props.orderDetails.bill_to
          ? this.props.orderDetails.bill_to
          : "Not Available",
        stopSeq: this.props.orderDetails.Stop
          ? this.props.orderDetails.Stop
          : "Not Available",
        stopDate: this.props.orderDetails.StopDate
          ? this.props.orderDetails.StopDate
          : "Not Available",
        appointmentTime1:
          this.props.orderDetails.appointmentTime1 &&
          this.props.orderDetails.appointmentTime1 !== "00:00:00"
            ? this.props.orderDetails.appointmentTime1
            : "00:00:00",
        appointmentTime2:
          this.props.orderDetails.appointmentTime2 &&
          this.props.orderDetails.appointmentTime2 !== "00:00:00"
            ? this.props.orderDetails.appointmentTime2
            : "00:00:00",
        stopId: this.props.orderDetails.StopId
          ? this.props.orderDetails.StopId
          : "Not Available",
        stopName: this.props.orderDetails.StopName
          ? this.props.orderDetails.StopName
          : "Not Available",
        address: this.props.orderDetails.Address
          ? this.props.orderDetails.Address
          : "Not Available",
        city: this.props.orderDetails.StopCity
          ? this.props.orderDetails.StopCity
          : "Not Available",
        state: this.props.orderDetails.StopState
          ? this.props.orderDetails.StopState
          : "Not Available",
        zip: this.props.orderDetails.StopZip
          ? this.props.orderDetails.StopZip
          : "Not Available",
        country: this.props.orderDetails.country
          ? this.props.orderDetails.country
          : "Not Available",
        locationContactName: this.props.orderDetails.locationContactName
          ? this.props.orderDetails.locationContactName
          : "",
        locationContactNumber: this.props.orderDetails.locationContactNumber
          ? this.props.orderDetails.locationContactNumber
          : "",
      },
      carrierDetails: {
        scacCode: this.props.orderDetails.ScacCode
          ? this.props.orderDetails.ScacCode
          : "Not Available",
        dotNumber: this.props.orderDetails.DotNumber
          ? this.props.orderDetails.DotNumber
          : "Not Available",
        mcNumber: this.props.orderDetails.McNumber
          ? this.props.orderDetails.McNumber
          : "Not Available",
        brokerRep: this.props.orderDetails.BrokerRep
          ? this.props.orderDetails.BrokerRep
          : "Not Available",
      },
      driverAndDocuments: {
        driver1: this.props.orderDetails.Driver1
          ? this.props.orderDetails.Driver1
          : "Not Available",
        driver2: this.props.orderDetails.Driver2
          ? this.props.orderDetails.Driver2
          : "Not Available",
        bol: this.props.orderDetails.bol_number
          ? this.props.orderDetails.bol_number
          : "Not Available",
        truck: this.props.orderDetails.TruckNo
          ? this.props.orderDetails.TruckNo
          : "Not Available",
        po: this.props.orderDetails.PO
          ? this.props.orderDetails.PO
          : "Not Available",
        stopType: this.props.orderDetails.StopType
          ? this.props.orderDetails.StopType
          : "Not Available",
        driverPhone: this.props.orderDetails.driver_phone
          ? this.props.orderDetails.driver_phone
          : "Not Available",
      },
      moreInformation: {
        userText1: this.props.orderDetails.UserText1
          ? this.props.orderDetails.UserText1
          : "Not Available",
        userText2: this.props.orderDetails.UserText2
          ? this.props.orderDetails.UserText2
          : "Not Available",
        userText3: this.props.orderDetails.UserText3
          ? this.props.orderDetails.UserText3
          : "Not Available",
        UserNum1: this.props.orderDetails.UserNum1
          ? this.props.orderDetails.UserNum1
          : "Not Available",
        UserNum2: this.props.orderDetails.UserNum2
          ? this.props.orderDetails.UserNum2
          : "Not Available",
        UserNum3: this.props.orderDetails.UserNum3
          ? this.props.orderDetails.UserNum3
          : "Not Available",
        UserDate1: this.props.orderDetails.UserDate1
          ? this.props.orderDetails.UserDate1
          : "Not Available",
        UserDate2: this.props.orderDetails.UserDate2
          ? this.props.orderDetails.UserDate2
          : "Not Available",
        UserDate3: this.props.orderDetails.UserDate3
          ? this.props.orderDetails.UserDate3
          : "Not Available",
      },
    };
  }

  componentDidMount() {
    if (this.props.newOrder) {
      this.setState({
        orderAndLocationDetails: {
          orderId: "",
          stopSeq: "",
          stopDate: "",
          appointmentTime1: "00:00:00",
          appointmentTime2: "00:00:00",
          stopId: "",
          stopName: "",
          address: "",
          city: "",
          state: "",
          zip: "",
          country: "",
          locationContactName: "",
          locationContactNumber: "",
          customerShipmentId: "",
          carrierName: "",
          billTo: ""
        },
        carrierDetails: {
          scacCode: "",
          dotNumber: "",
          mcNumber: "",
          brokerRep: "",
        },
        driverAndDocuments: {
          driver1: "",
          driver2: "",
          bol: "",
          truck: "",
          po: "",
          stopType: "",
          driverPhone: ""
        },
        moreInformation: {
          userText1: "",
          userText2: "",
          userText3: "",
          UserNum1: "",
          UserNum2: "",
          UserNum3: "",
          UserDate1: "",
          UserDate2: "",
          UserDate3: "",
        },
      });
    }

    //pass methods back to parent.
    this.props.handleModalMethods(this.handleShipmentsUpload, this.reopenShipment);
  }

  setValueToState = (parentField, childField, value) => {
    const { state } = this;
    state[parentField] = { ...state[parentField], [childField]: value };
    this.setState(state);
  };

  manuallyChangeObject = (appointmentTime1, appointmentTime2, autoCloseInd) => {
    let recentOrderObject = {
      OrderNo:
        this.state.orderAndLocationDetails.orderId === "Not Available"
          ? ""
          : this.state.orderAndLocationDetails.orderId,
      bill_to:
          this.state.orderAndLocationDetails.billTo === "Not Available"
            ? ""
            : this.state.orderAndLocationDetails.billTo,
      carrier_name:
          this.state.orderAndLocationDetails.carrierName === "Not Available"
            ? ""
            : this.state.orderAndLocationDetails.carrierName,
      customer_shipment_id:
          this.state.orderAndLocationDetails.customerShipmentId === "Not Available"
            ? ""
            : this.state.orderAndLocationDetails.customerShipmentId,
      StopId:
        this.state.orderAndLocationDetails.stopId === "Not Available"
          ? ""
          : this.state.orderAndLocationDetails.stopId,
      StopName:
        this.state.orderAndLocationDetails.stopName === "Not Available"
          ? ""
          : this.state.orderAndLocationDetails.stopName,
      Address:
        this.state.orderAndLocationDetails.address === "Not Available"
          ? ""
          : this.state.orderAndLocationDetails.address,
      Stop:
        this.state.orderAndLocationDetails.stopSeq === "Not Available"
          ? ""
          : this.state.orderAndLocationDetails.stopSeq,
      StopType:
        this.state.driverAndDocuments.stopType === "Not Available"
          ? ""
          : this.state.driverAndDocuments.stopType,
      StopDate:
        this.state.orderAndLocationDetails.stopDate === "Not Available"
          ? ""
          : this.state.orderAndLocationDetails.stopDate,
      StopArriveTime:
        this.state.orderAndLocationDetails.stopDate === "Not Available"
          ? ""
          : dayjs(this.state.orderAndLocationDetails.stopDate).format(
              "MMM Do, YY"
            ),
      Driver1:
        this.state.driverAndDocuments.driver1 === "Not Available"
          ? ""
          : this.state.driverAndDocuments.driver1,
      Driver2:
        this.state.driverAndDocuments.driver2 === "Not Available"
          ? ""
          : this.state.driverAndDocuments.driver2,
      StopCity:
        this.state.orderAndLocationDetails.city === "Not Available"
          ? ""
          : this.state.orderAndLocationDetails.city,
      StopState:
        this.state.orderAndLocationDetails.state === "Not Available"
          ? ""
          : this.state.orderAndLocationDetails.state,
      country:
        this.state.orderAndLocationDetails.country === "Not Available"
          ? ""
          : this.state.orderAndLocationDetails.country,
      StopZip:
        this.state.orderAndLocationDetails.zip === "Not Available"
          ? ""
          : this.state.orderAndLocationDetails.zip,
      bol_number:
        this.state.driverAndDocuments.bol === "Not Available"
          ? ""
          : this.state.driverAndDocuments.bol,
      PO:
        this.state.driverAndDocuments.po === "Not Available"
          ? ""
          : this.state.driverAndDocuments.po,
      TruckNo:
        this.state.driverAndDocuments.truck === "Not Available"
          ? ""
          : this.state.driverAndDocuments.truck,
      driver_phone:
          this.state.driverAndDocuments.driverPhone === "Not Available"
            ? ""
            : this.state.driverAndDocuments.driverPhone,
      locationContactName:
        this.state.orderAndLocationDetails.locationContactName ===
        "Not Available"
          ? ""
          : this.state.orderAndLocationDetails.locationContactName,
      locationContactNumber:
        this.state.orderAndLocationDetails.locationContactNumber ===
        "Not Available"
          ? ""
          : this.state.orderAndLocationDetails.locationContactNumber,
      appointmentTime1: appointmentTime1,
      appointmentTime2: appointmentTime2,
      auto_close_ind: autoCloseInd,
      UserText1:
        this.state.moreInformation.userText1 === "Not Available"
          ? ""
          : this.state.moreInformation.userText1,
      UserText2:
        this.state.moreInformation.userText2 === "Not Available"
          ? ""
          : this.state.moreInformation.userText2,
      UserText3:
        this.state.moreInformation.userText3 === "Not Available"
          ? ""
          : this.state.moreInformation.userText3,
      UserNum1:
        this.state.moreInformation.UserNum1 === "Not Available"
          ? ""
          : this.state.moreInformation.UserNum1,
      UserNum2:
        this.state.moreInformation.UserNum2 === "Not Available"
          ? ""
          : this.state.moreInformation.UserNum2,
      UserNum3:
        this.state.moreInformation.UserNum3 === "Not Available"
          ? ""
          : this.state.moreInformation.UserNum3,
      UserDate1:
        this.state.moreInformation.UserDate1 === "Not Available"
          ? ""
          : this.state.moreInformation.UserDate1,
      UserDate2:
        this.state.moreInformation.UserDate2 === "Not Available"
          ? ""
          : this.state.moreInformation.UserDate2,
      UserDate3:
        this.state.moreInformation.UserDate3 === "Not Available"
          ? ""
          : this.state.moreInformation.UserDate3,
      ScacCode:
        this.state.carrierDetails.scacCode === "Not Available"
            ? ""
            : this.state.carrierDetails.scacCode,
      DotNumber:
        this.state.carrierDetails.dotNumber === "Not Available"
            ? ""
            : this.state.carrierDetails.dotNumber,
      McNumber:
        this.state.carrierDetails.mcNumber === "Not Available"
            ? ""
            : this.state.carrierDetails.mcNumber,
      BrokerRep:
        this.state.carrierDetails.brokerRep === "Not Available"
            ? ""
            : this.state.carrierDetails.brokerRep,
    };
    
    this.props.setOrderListAfterUpdate(recentOrderObject);
  };

  handleShipmentsUpload = () => {

    let companyId = mstore.get("user", "company_id");

    let appointmentTime1 = "";
    let appointmentTime2 = "";

    if (this.state.orderAndLocationDetails.appointmentTime1) {
      appointmentTime1 = `${this.state.orderAndLocationDetails.appointmentTime1}`;
    } else {
      appointmentTime1 = "";
    }

    if (this.state.orderAndLocationDetails.appointmentTime2) {
      appointmentTime2 = `${this.state.orderAndLocationDetails.appointmentTime2}`;
    } else {
      appointmentTime2 = "";
    }

    let uploadData = [
      {
        order_id:
          this.state.orderAndLocationDetails.orderId === "Not Available"
            ? ""
            : this.state.orderAndLocationDetails.orderId,
        customer_shipment_id:
            this.state.orderAndLocationDetails.customerShipmentId === "Not Available"
              ? ""
              : this.state.orderAndLocationDetails.customerShipmentId,
        bill_to:
            this.state.orderAndLocationDetails.billTo === "Not Available"
              ? ""
              : this.state.orderAndLocationDetails.billTo,
        carrier_name:
            this.state.orderAndLocationDetails.carrierName === "Not Available"
              ? ""
              : this.state.orderAndLocationDetails.carrierName,
        stop_id:
          this.state.orderAndLocationDetails.stopId === "Not Available"
            ? ""
            : this.state.orderAndLocationDetails.stopId,
        stop_name:
          this.state.orderAndLocationDetails.stopName === "Not Available"
            ? ""
            : this.state.orderAndLocationDetails.stopName,
        stop_address:
          this.state.orderAndLocationDetails.address === "Not Available"
            ? ""
            : this.state.orderAndLocationDetails.address,
        order_stop_seq:
          this.state.orderAndLocationDetails.stopSeq === "Not Available"
            ? ""
            : this.state.orderAndLocationDetails.stopSeq,
        stop_type:
          this.state.driverAndDocuments.stopType === "Not Available"
            ? ""
            : this.state.driverAndDocuments.stopType,
        stop_date:
          this.state.orderAndLocationDetails.stopDate === "Not Available"
            ? ""
            : this.state.orderAndLocationDetails.stopDate,
        driver1_id:
          this.state.driverAndDocuments.driver1 === "Not Available"
            ? ""
            : this.state.driverAndDocuments.driver1,
        driver2_id:
          this.state.driverAndDocuments.driver2 === "Not Available"
            ? ""
            : this.state.driverAndDocuments.driver2,
        stop_city:
          this.state.orderAndLocationDetails.city === "Not Available"
            ? ""
            : this.state.orderAndLocationDetails.city,
        stop_state:
          this.state.orderAndLocationDetails.state === "Not Available"
            ? ""
            : this.state.orderAndLocationDetails.state,
        stop_country:
          this.state.orderAndLocationDetails.country === "Not Available"
            ? ""
            : this.state.orderAndLocationDetails.country,
        stop_zip:
          this.state.orderAndLocationDetails.zip === "Not Available"
            ? ""
            : this.state.orderAndLocationDetails.zip,
        bol_number:
          this.state.driverAndDocuments.bol === "Not Available"
            ? ""
            : this.state.driverAndDocuments.bol,
        po_num:
          this.state.driverAndDocuments.po === "Not Available"
            ? ""
            : this.state.driverAndDocuments.po,
        truck_no:
          this.state.driverAndDocuments.truck === "Not Available"
            ? ""
            : this.state.driverAndDocuments.truck,
        driver_phone:
            this.state.driverAndDocuments.driverPhone === "Not Available"
              ? ""
              : this.state.driverAndDocuments.driverPhone,
        location_contact_name:
          this.state.orderAndLocationDetails.locationContactName ===
          "Not Available"
            ? ""
            : this.state.orderAndLocationDetails.locationContactName,
        location_contact_number:
          this.state.orderAndLocationDetails.locationContactNumber ===
          "Not Available"
            ? ""
            : this.state.orderAndLocationDetails.locationContactNumber,
        appointment_time_1: appointmentTime1,
        appointment_time_2: appointmentTime2,
        usr_text1:
          this.state.moreInformation.userText1 === "Not Available"
            ? ""
            : this.state.moreInformation.userText1,
        usr_text2:
          this.state.moreInformation.userText2 === "Not Available"
            ? ""
            : this.state.moreInformation.userText2,
        usr_text3:
          this.state.moreInformation.userText3 === "Not Available"
            ? ""
            : this.state.moreInformation.userText3,
        usr_num1:
          this.state.moreInformation.UserNum1 === "Not Available"
            ? ""
            : this.state.moreInformation.UserNum1,
        usr_num2:
          this.state.moreInformation.UserNum2 === "Not Available"
            ? ""
            : this.state.moreInformation.UserNum2,
        usr_num3:
          this.state.moreInformation.UserNum3 === "Not Available"
            ? ""
            : this.state.moreInformation.UserNum3,
        usr_date1:
          this.state.moreInformation.UserDate1 === "Not Available"
            ? ""
            : this.state.moreInformation.UserDate1,
        usr_date2:
          this.state.moreInformation.UserDate2 === "Not Available"
            ? ""
            : this.state.moreInformation.UserDate2,
        usr_date3:
          this.state.moreInformation.UserDate3 === "Not Available"
            ? ""
            : this.state.moreInformation.UserDate3,
        scac_code:
          this.state.carrierDetails.scacCode === "Not Available"
          ? ""
          : this.state.carrierDetails.scacCode,
        dot_number:
          this.state.carrierDetails.dotNumber === "Not Available"
          ? ""
          : this.state.carrierDetails.dotNumber,
        mc_number:
          this.state.carrierDetails.mcNumber === "Not Available"
          ? ""
          : this.state.carrierDetails.mcNumber,
        broker_rep:
          this.state.carrierDetails.brokerRep === "Not Available"
          ? ""
          : this.state.carrierDetails.brokerRep,
      },
    ];

    let text = {text: '', error: true};
    this.props.doneProcessing('', 'Processing shipment data');
    //console.log(uploadData)

    fetchWithJson(endpoint.dc_process_shipment_data, {
      shipment_data: uploadData,
      company_id: companyId,
    }).then((res) => {
      if (res.status === 422) {
        text.text = 'Unable to process the data. Required fields missing';
      } else if (res.status === 200) {
        this.manuallyChangeObject(appointmentTime1, appointmentTime2, this.props.orderDetails.auto_close_ind);
        text.text = 'Succesfully processed the shipment data';
        text.error = false;
      } else {
        text.text = 'Unable to process the data';
      }
      this.props.doneProcessing(text, '');
    });
  };

  reopenShipment = () => {

    let appointmentTime1 = "";
    let appointmentTime2 = "";

    if (this.state.orderAndLocationDetails.appointmentTime1) {
      appointmentTime1 = `${this.state.orderAndLocationDetails.appointmentTime1}`;
    } else {
      appointmentTime1 = "";
    }

    if (this.state.orderAndLocationDetails.appointmentTime2) {
      appointmentTime2 = `${this.state.orderAndLocationDetails.appointmentTime2}`;
    } else {
      appointmentTime2 = "";
    }

    /* --- DEBUG ---
    console.log(this.props.orderDetails, this.props.orderDetails.key, {
      sdnRecordId: this.props.orderDetails.key,
    });
    --- DEBUG --- */
    
    secureFetch(endpoint.reopenShipment, {
      sdnRecordId: this.props.orderDetails.key,
    }).then(([ok, status, json]) => {
      if (ok) {
        success("You have successfully re-opened this shipment");
        this.manuallyChangeObject(appointmentTime1, appointmentTime2, 0);
        this.props.onCancel();
      } else {
        error("Oops! error occured when reopening this shipment");
      }
    });
  };

  render() {
    return (
      <div className="px-4">
        <div className="collapse collapse-arrow">
          <input type="radio" name="shipment-dispatch-fields" defaultChecked /> 
          <div className="collapse-title text-xl font-medium border-b">
            Order and Location
          </div>
          <div className="collapse-content"> 
            <OrderAndLocation
              setOrderAndLocationToState={this.setValueToState}
              {...this.state.orderAndLocationDetails}
          />
          </div>
        </div>
        <div className="collapse collapse-arrow">
          <input type="radio" name="shipment-dispatch-fields" /> 
          <div className="collapse-title text-xl font-medium border-y">
            {`${this.props.isBroker === 1 ? 'Carrier': 'Broker'} Information`}
          </div>
          <div className="collapse-content"> 
            <CarrierInformation
              setCarrierDetailsToState={this.setValueToState}
              {...this.state.carrierDetails}
              isBroker={this.props.isBroker}
          />
          </div>
        </div>
        <div className="collapse collapse-arrow">
          <input type="radio" name="shipment-dispatch-fields" /> 
          <div className="collapse-title text-xl font-medium border-y">
            Drivers And Documents
          </div>
          <div className="collapse-content"> 
            <DriversAndDocuments
              setDriversAndDocumentsToState={this.setValueToState}
              {...this.state.driverAndDocuments}
            />
          </div>
        </div>
        <div className="collapse collapse-arrow">
          <input type="radio" name="shipment-dispatch-fields" /> 
          <div className="collapse-title text-xl font-medium border-y">
            More Information
          </div>
          <div className="collapse-content"> 
            <MoreInformation
              setMoreInformationToState={this.setValueToState}
              {...this.state.moreInformation}
              customFields={this.props.customFields}
            />
          </div>
        </div>
        {this.props.orderDetails.auto_close_ind === 1 ? (
          <div className="p-4 w-1/2">
            <div role="alert" className="alert">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
              <span className="font-semibold">
                Order closed by 
                {this.props.orderDetails.closed_by_driver === 'Y' ? ' the Driver '  : ' the system (auto-closed) '} 
                at {this.props.orderDetails.closed_by_driver === 'Y' ? this.props.orderDetails.driver_close_ts : this.props.orderDetails.auto_close_date} 
                {' (UTC)'}
              </span>
            </div>
          </div>
        ) : null}
    </div>
    );
  }
}

export default connect((state) => ({
  clientId: state.client
    ? state.client.id
    : mstore.getPath(["user", "company_id"]),
}))(ShipmentDispatchDetails);
