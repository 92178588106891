import React from 'react'
import { Elements, CardElement, injectStripe } from 'react-stripe-elements'
import { wrapInputHandler, noop } from '../lib/utils'
import { Button as NextButton} from '@nextui-org/react';

const styles = {
  cardStyle: {
    base: {
      fontSize: '16px',
      color: '#424770',
      letterSpacing: '0.025em',
      fontFamily: "Lato', sans-serif",
      '::placeholder': {
        color: '#aab7c4',
        fontFamily: "Lato', sans-serif",
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const InputForm = (props) => {
  let { className, holderName, onNameChange, onCardValueChange, onSubmit, formRef } = props
  className = (className ? className : "") + " flex flex-column"
  props.inputFormRef(props)
  return (
    <form ref={formRef} className={className} onSubmit={onSubmit} action=''>
      <label className="stripe-card">
        Cardholder Name
          <div className="StripeElement">
            <input className="bw0 outline-0 f5" type="text" placeholder="John Doe" value={holderName} onChange={wrapInputHandler(onNameChange)} />
          </div>
      </label>
      <label className="stripe-card">
        Card Details
          <CardElement style={styles.cardStyle} onChange={onCardValueChange} />
      </label>
      <div className='flex justify-end'>
        <NextButton
            auto
            color="primary"
            css={{br:0 }}
            onClick={onSubmit}
            className="ba bw1 self-start"
        >
          Save Card
        </NextButton>
      </div>
    </form>
  )
}

const InjectedInputForm = injectStripe(InputForm)

const CardInput = (props) => {
  const inputFormRef = props.inputFormRef || noop
  return (
    <Elements>
      <InjectedInputForm inputFormRef={inputFormRef} {...props} />
    </Elements>

  )
}

export default CardInput