import React, { Component } from 'react'
import { Button, Loading, Checkbox, Radio, Input} from '@nextui-org/react';

export default class FilterReceipt extends Component {

    constructor(props) {
        super(props)
        this.state = {
            receiptReference: this.props.receiptReference ? this.props.receiptReference : null,
            driverId: this.props.driverId ? this.props.driverId : '',
            exp1: this.props.exp1 ? this.props.exp1 : '',
            exp2: this.props.exp2 ? this.props.exp2 : '',
            Approved: this.props.Approved ? this.props.Approved : 0,
            Denyed: this.props.Denyed ? this.props.Denyed : 0,
            New: this.props.New ? this.props.New : 0,
            DateMatch: this.props.DateMatch ? this.props.DateMatch : 0,
            TotalMatch: this.props.TotalMatch ? this.props.TotalMatch : 0,
            orderNo: this.props.orderNo ? this.props.orderNo : ''
        }
    }

    setExp1 = (e) => {
        this.setState({ exp1: e.target.value })
    }

    setExp2 = (e) => {
        this.setState({ exp2: e.target.value })
    }

    setApproved = () => {
        this.setState({ Approved: this.state.Approved === 0 ? 1 : 0, Denyed: 0, New: 0 })
    }

    setDenyed = () => {
        this.setState({ Denyed: this.state.Denyed === 0 ? 1 : 0, Approved: 0, New: 0 })
    }

    setNew = () => {
        this.setState({ New: this.state.New === 0 ? 1 : 0, Denyed: 0, Approved: 0 })
    }

    setDriverId = (e) => {
        this.setState({ driverId: e.target.value })
    }

    setOrderNo = (e) => {
        this.setState({ orderNo: e.target.value })
    }

    setReceiptReference = (e) => {
        this.setState({ receiptReference: e.target.value })
    }

    dateMatch = (e) => {
        this.setState({ DateMatch: this.state.DateMatch === 0 ? 1 : 0 })
    }

    totalMatch = (e) => {
        this.setState({ TotalMatch: this.state.TotalMatch === 0 ? 1 : 0 })
    }


    checkFilter = (cb) => {
        var count = 0
        if (this.state.exp1 !== '') {
            count++
        }
        if (this.state.driverId !== '') {
            count++
        }
        if (this.state.orderNo !== '') {
            count++
        }
        if (this.state.receiptReference) {
            count++
        }
        if (this.state.New !== 0) {
            count++
        }
        if (this.state.Denyed !== 0) {
            count++
        }
        if (this.state.Approved !== 0) {
            count++
        }
        if (this.state.DateMatch !== 0) {
            count++
        }
        if (this.state.TotalMatch !== 0) {
            count++
        }
        this.setState({ noOfFilter: count }, cb)
    }


    render() {
        return (
            <div className='pa2 mr4'>
                <div>
                    <label htmlFor="" className="f5">Receipt Reference</label>
                    <Input
                        type="number"
                        placeholder="Receipt Reference"
                        onChange={this.setReceiptReference}
                        css={{ $$inputBorderRadius: "0", w: '100%', h: 38, mt: 5 }}
                        shadow={false}
                        bordered={true}
                        rounded={false}
                        value={this.state.receiptReference}
                    />
                </div>
                <div className='mt2'>
                    <label htmlFor="" className="f5">Driver Id</label>
                    <Input
                        type="text"
                        placeholder="Enter Driver ID"
                        onChange={this.setDriverId}
                        clearable
                        css={{ $$inputBorderRadius: "0", w: '100%', h: 38, mt: 5 }}
                        shadow={false}
                        bordered={true}
                        rounded={false}
                        value={this.state.driverId}
                    />
                </div>
                <div className='mt2'>
                    <label htmlFor="" className="f5">Order Number</label>
                    <Input
                        type="text"
                        placeholder="Enter Order Number"
                        onChange={this.setOrderNo}
                        clearable
                        css={{ $$inputBorderRadius: "0", w: '100%', h: 38, mt: 5 }}
                        shadow={false}
                        bordered={true}
                        rounded={false}
                        value={this.state.orderNo}
                    />
                </div>
                <div className='mt2'>
                    <label htmlFor="" className="f5">From</label>
                    <Input
                        type="date"
                        onChange={this.setExp1}
                        css={{ $$inputBorderRadius: "0", w: '100%', h: 38, mt: 5 }}
                        shadow={false}
                        bordered={true}
                        rounded={false}
                        value={this.state.exp1}
                    />
                    <div className="mh2"><label htmlFor="" className="f5">To</label></div>
                    <Input
                        type="date"
                        onChange={this.setExp2}
                        css={{ $$inputBorderRadius: "0", w: 192, h: 38, mt: 5 }}
                        shadow={false}
                        bordered={true}
                        rounded={false}
                        value={this.state.exp2}
                    />
                </div>

                <div className="flex justify-space-between mt3">
                    <div className="f5 w-100">New</div>
                    <Radio checked={this.state.New === 1 ? true : false} onChange={this.setNew} />
                </div>

                <div className="flex justify-space-between mt3">
                    <div className="f5 w-100">Approved</div>
                    <Radio checked={this.state.Approved === 1 ? true : false} onChange={this.setApproved} />
                </div>

                <div className="flex justify-space-between mt3">
                    <div className="f5 w-100">Rejected</div>
                    <Radio checked={this.state.Denyed === 1 ? true : false} onChange={this.setDenyed} />
                </div>

                <div className="flex justify-space-between mt3 mr2">
                    <div className="f5 w-100">Dates Match</div>
                    <Checkbox type="checkbox" checked={this.state.DateMatch === 1 ? true : false} onChange={this.dateMatch} />
                </div>

                <div className="flex justify-space-between mt3 mr2">
                    <div className="f5 w-100">Totals Match</div>
                    <Checkbox type="checkbox" checked={this.state.TotalMatch === 1 ? true : false} onChange={this.totalMatch} />
                </div>

                <div className="flex flex-row justify-center items-center justify-between mt3">
                    <Button 
                        size='sm'
                        ghost
                        auto
                        css={{br:0, color: '$gray600', borderColor: '$gray600' }}
                        onClick={
                            () => {
                                this.setState({
                                    receiptReference: null,
                                    driverId: '',
                                    exp1: '',
                                    exp2: '',
                                    Approved: 0,
                                    Denyed: 0,
                                    New: 0,
                                    DateMatch: 0,
                                    TotalMatch: 0,
                                    orderNo: ''
                                },
                                () =>{
                                    this.props.handleSearchButton(this.state.exp1, this.state.exp2, this.state.Approved, this.state.Denyed, this.state.New, this.state.driverId, this.state.orderNo, this.state.receiptReference, this.state.DateMatch, this.state.TotalMatch, this.state.noOfFilter)
                                })
                            }
                        }
                    >
                        Reset
                    </Button>


                    {this.props.clicked ? 
                        <Button
                            auto
                            css={{br:0 }}
                            loading
                        >
                            <Loading type='points' size="md" color='white'/>
                        </Button> : 
                        <Button 
                            size='sm'
                            auto
                            css={{br:0 }}
                            onClick={() => {
                                this.checkFilter(() => {
                                    this.props.handleSearchButton(this.state.exp1, this.state.exp2, this.state.Approved, this.state.Denyed, this.state.New, this.state.driverId, this.state.orderNo, this.state.receiptReference, this.state.DateMatch, this.state.TotalMatch, this.state.noOfFilter)
                                })
                            }} 
                        >
                            Search
                        </Button>
                    }
                </div>


            </div>
        )
    }
}
