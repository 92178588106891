import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { Loading, Radio, Text, Button as NextButton, Modal, Row, Switch, Card, Grid, Divider } from '@nextui-org/react';
import { Chat, Download, Lock, Unlock } from 'react-iconly';

import jsPDF from "jspdf";
import html2canvas from 'html2canvas';

import { updateTitle } from "../../lib/utils";
import { fetchWithJson } from "../../lib/api";
import { mstore } from "../../store";
import { error, success, warning } from "../../lib/userAlerts";
import { connect } from "react-redux";
import { fitBounds } from "google-map-react/utils";

import PlotDetention from "../../components/detentionManagement/PlotDetention";
import dayjs from '../../lib/day';
import checkAccess from '../../lib/checkAccess';

import LatLngBounds from "google-map-react/lib/utils/lib_geo/lat_lng_bounds.js";
import LatLng from "google-map-react/lib/utils/lib_geo/lat_lng.js";
import endpoint from "../../config/endpoint.json";

import TruckImage from "../../assets/truck-loaded.svg";
import StartImage from "../../assets/start-detention.png";
import ExitImage from "../../assets/exit-detention.svg";
import GateImage from "../../assets/gate.png";
import MTTLogo from "../../assets/mtt.png";
import SubPageHeader from "../../components/SubPageHeader";

const CLOSED = "Closed";
const OPEN = "Open";
const ALL = "All";
const cellStyle = {paddingRight: '5px', paddingLeft: '10px'};

const mapSize = {
  width: 780,
  height: 440
};

const createColumnDefs = () => {
  return [
    {
      headerName: "Order No.",
      field: "order_id",
      filter: "agNumberColumnFilter",
      checkboxSelection: true,
      cellStyle: cellStyle,
      width: 100,
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
    },
    {
      headerName: "Seq. (Order Seq)",
      field: "order_stop_seq",
      filter: "agTextColumnFilter",
      width: 90,
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
      cellStyle: cellStyle,
    },
    {
      headerName: "BOL",
      field: "bol_number",
      filter: "agTextColumnFilter",
      width: 100,
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
      cellStyle: cellStyle,
    },
    {
      headerName: "Bill To",
      field: "bill_to",
      filter: "agTextColumnFilter",
      width: 100,
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
      cellStyle: cellStyle,
    },
    {
      headerName: "Detention Status",
      field: "status",
      filter: "agTextColumnFilter",
      width: 80,
      cellStyle: cellStyle,
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
    },
    {
      headerName: "Stop Name",
      field: "stop_name",
      filter: "agTextColumnFilter",
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
      cellStyle: cellStyle,
    },
    {
      headerName: "Driver",
      field: "driver_name",
      filter: "agTextColumnFilter",
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
      width: 150,
      cellStyle: cellStyle,
    },
    { 
      headerName: "Driver Phone", 
      field: "driver_phone" , 
      width: 140,
      suppressMenu: true,
      cellStyle: cellStyle,
    },
    {
      headerName: "Driver Email",
      field: "driver_email",
      filter: "agTextColumnFilter",
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
      cellStyle: cellStyle,
    },
    { 
      headerName: "Late Arrival", 
      field: "late_arrival", 
      width: 70,
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
      cellStyle: cellStyle,
    },
    { 
      headerName: "Appointment Time", 
      field: "appointment_time", 
      width: 150,
      suppressMenu: true, 
      cellStyle: cellStyle,
    },
    { 
      headerName: "Arrival", 
      field: "arrival_ts" , 
      suppressMenu: true,
      width: 150,
      cellStyle: cellStyle,
    },
    { 
      headerName: "Check-In", 
      field: "checkin_ts" , 
      suppressMenu: true,
      width: 150,
      cellStyle: cellStyle,
    },
    { 
      headerName: "At Dock", 
      field: "dock_ts", 
      suppressMenu: true, 
      width: 150,
      cellStyle: cellStyle,
    },
    { 
      headerName: "Stop Completion", 
      field: "exit_ts", 
      suppressMenu: true,
      width: 150,
      cellStyle: cellStyle,
    },
    { 
      headerName: "Estimated Detention", 
      field: "detention_text", 
      suppressMenu: true,
      cellStyle: cellStyle,
    },
    { 
      headerName: "Driver Notes", 
      field: "comment", 
      suppressMenu: true ,
      cellClass: "cell-wrap-text",
      autoHeight: true,
      cellStyle: cellStyle,
      width: 450,
    },
  ];
};

class DetentionManagementDetails extends Component {
  constructor(props) {
    super(props);
    let gridApi;

    this.state = {
      detentionList: [],
      allDetentions: [],
      currentDetentions: {},
      selectedDetentions: [],
      isOpen: false,
      logoURL: '',
      logoBlob: null,
      fetching: false,
      showDriverNotes: true,
      showMinimal: false,
      check: false,
      downloadingPDF: false,
      columnDefs: createColumnDefs(),
      rowSelection: "multiple",
      showState: OPEN,
    };
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
  };

  onSelectionChanged(evt) {
    let selectedRows = this.gridApi.getSelectedRows();
    this.setState({ selectedDetentions: selectedRows });
  }

  rowDoubleClicked = (row) => {
    updateTitle("Detention Report");
    this.setState({ isOpen: true, currentDetentions: row.data });
  };

  onCancel = () => {
    this.setState({ isOpen: false });
  };

  componentDidMount() {
    checkAccess(this.props.clientId);

    this.fetchDetentionList(this.props.clientId);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.clientId !== this.props.clientId) {
      checkAccess(newProps.clientId);
      this.fetchDetentionList(newProps.clientId);
    }
  }

  fetchDetentionList = (companyId) => {
    this.setState({
      fetching: true,
      detentionList: [],
      allDetentions: []
    });

    fetchWithJson(endpoint.getDetentionDetails, {company_id: companyId})
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          logoURL: data.logoUrl,
          allDetentions: data.detentionData,
          fetching: false,
        }, ()=>{
          this.populateGrid(this.state.showState);
        });

        fetch(data.logoUrl)
        .then(response => response.blob())
        .then(imageBlob => {
          // Then create a local URL for that image and print it 
          const imageObjectURL = URL.createObjectURL(imageBlob);
          this.setState({logoBlob: imageObjectURL});
        });
      })
      .catch((err) => {
        error("Oops! Unable to fetch detention records");
        console.log(err);
      });
  };

  populateGrid = (val) => {
    let detentionList = []; 

    if(!this.state.allDetentions){
      return;
    }

    this.state.allDetentions.forEach((item) => {
      item['detention_text'] = this._calcTimeDiffGrid(item.appointment_time, item.exit_ts, item.detention_window_hrs, item.start_ts);
      
      if (item.status === CLOSED && val === CLOSED) {
        detentionList.push(item);
      } 
      if (item.status === OPEN && val === OPEN) {
        detentionList.push(item);
      }   
      if (val === ALL) {
        detentionList.push(item);
      }     
    });

    this.setState({
      detentionList: detentionList,
      showState: val
    });
  }

  setStatusOnGrid = (
    record_id,
    status
  ) => {
    let newRecs = [];
    let rec = {};

    for (let i = 0; i < this.state.allDetentions.length; i++ ){  
      rec = this.state.allDetentions[i];
      if( rec.record_id === record_id){
        rec.status = status;
      }
      newRecs.push(rec);
    }

    this.setState({
      allDetentions: newRecs
    });

    this.populateGrid(this.state.showState);
  };

  handleReopen = () => {
    if (this.state.selectedDetentions.length === 0) {
      warning("Please select a detention item");
    } else {
      this.state.selectedDetentions.forEach((items) => {
        fetchWithJson(endpoint.setDetentionStatus, {
          record_id: items.record_id,
          status: OPEN
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.success) {
              success(`Detention record for Order ${items.order_id}, Seq. ${items.order_stop_seq} was successfully opened`);
              this.setStatusOnGrid(items.record_id, OPEN);
            }
            else{
              error("Error occurred when opening the detention entry");
            }
            // this.fetchDetentionList();
          })
          .catch((err) => {
            error("Error occurred when opening the detention entry");
            console.log(err);
          });
      });
    }
  };

  handleClose = () => {
    if (this.state.selectedDetentions.length === 0) {
      warning("Please select a detention item");
    } else {
      this.state.selectedDetentions.forEach((items) => {
        fetchWithJson(endpoint.setDetentionStatus, {
          record_id: items.record_id,
          status: CLOSED
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.success) {
              success(`Detention record for Order ${items.order_id}, Seq. ${items.order_stop_seq} was successfully closed`);
              this.setStatusOnGrid(items.record_id, CLOSED);
            }
            else{
              error("Error occurred while closing the detention entry");
            }
            // this.fetchDetentionList();
          })
          .catch((err) => {
            error("Error occurred while closing the detention entry");
            console.log(err);
          });
      });
    }
  };

  _calcTimeDiff = (startTS, endTS, longText=false) => {
    
    let detentionWindow = this.state.currentDetentions.detention_window_hrs
    let now = dayjs(endTS, "YYYY-MM-DD HH:mm")
    let then = longText ? dayjs(startTS, "YYYY-MM-DD HH:mm").add(detentionWindow, 'hours') :  dayjs(startTS, "YYYY-MM-DD HH:mm")
    
    if(startTS && endTS){
      let diffHours = now.diff(then, 'hours')
      let diffMins = now.diff(then, 'minutes')

      let hh = Math.abs(diffHours) 
      let mm = Math.abs(diffMins) % 60
      
      let narration
      
      if(!longText){
        narration = hh + ' hr ' + mm + ' min' +  ((startTS > endTS) ?  ' early' : ' late')
        return narration
      }
      else{
        let entry = dayjs(this.state.currentDetentions.start_ts, "YYYY-MM-DD HH:mm")
        let appointment = dayjs(this.state.currentDetentions.appointment_time, "YYYY-MM-DD HH:mm")

        if(entry > appointment){
          narration = 'No estimated detention due to late arrival.'
        }
        else{
          if (then >= now){
            narration = 'Shipment delivered on time.'
          }
          else {
            narration = 'Estimated: ' + hh + ' hour(s) ' + mm + ' minute(s) detention.'
          }
        }
        return narration
      }
    }
    else
    {
      return 'Unknown'
    }
  }

  _calcTimeDiffGrid = (appointmentTS, endTS, detentionWindow, entryTS) => {
    
    let now = dayjs(endTS, "YYYY-MM-DD HH:mm")
    let then = dayjs(appointmentTS, "YYYY-MM-DD HH:mm").add(detentionWindow, 'hours') 
    let entry = dayjs(entryTS, "YYYY-MM-DD HH:mm")
    let appointment = dayjs(appointmentTS, "YYYY-MM-DD HH:mm")

    if(appointmentTS && endTS){
      let diffHours = now.diff(then, 'hours')
      let diffMins = now.diff(then, 'minutes')

      let hh = Math.abs(diffHours) 
      let mm = Math.abs(diffMins) % 60
      
      if (entry > appointment) 
        return 'Late arrival. No detention.'

      if (then >= now)
        return 'On time.'

      return hh + ' hr ' + mm + ' min' +  ' detention'
    }
    else
    {
      return 'Unknown'
    }
  }

  // Fit map to its bounds after the api is loaded
  calcuateCenterAndZoom = (places) => {

    // Get bounds by our places
    const bounds = new LatLngBounds();

    places.forEach((place) => {
      bounds.extend(new LatLng(place.lat,place.lng));
    });

    const newBounds = {
      ne: {
        lat: bounds.getNorthEast().lat,
        lng: bounds.getNorthEast().lng
      },
      sw: {
        lat: bounds.getSouthWest().lat,
        lng: bounds.getSouthWest().lng
      }
    };

    return fitBounds(newBounds, mapSize);
  };

  _renderDetentionLegend = (img, headerTxt, timeTxt, detentionTxt) => {
    return (
      <Card 
        clickable={false}
        shadow={false} 
        animated={false} 
        css={{ br: 0, ma: 10 }}
      >
        <Card.Header>
          <img
            alt="logo"
            src={img}
            width="34px"
            height="34px"
          />
          <Grid.Container css={{ pl: "$6" }}>
            <Grid xs={12}>
              <Text h5 css={{ lineHeight: "$xs" }}>
                {headerTxt}
              </Text>
            </Grid>
          </Grid.Container>
        </Card.Header>
        <Divider/>
        <Card.Body css={{ py: "$2" }}>
          <Text>
            {timeTxt}
          </Text>
          <Text css={{py: "$1"}}>
            {detentionTxt}
          </Text>
        </Card.Body>
      </Card>
    )
  }

  _renderClassic = () => {
    return(
      <div className="flex justify-between w-100 pt2 bg-white b--dashed b--blue bw1">
        <div className="pa2">
          <table style={{width: '420px'}}>
            <tbody className="lh-copy">
              <tr>
                <td>
                  Order ID: 
                  <span className="fw6"> {this.state.currentDetentions.order_id} </span> 
                  | BOL: 
                  <span className="fw6"> {this.state.currentDetentions.bol_number} </span> 
                </td>
              </tr>
              <tr>
                <td>
                  Stop Sequence: {this.state.currentDetentions.order_stop_seq}
                </td>
              </tr>
              <tr>
                <td>
                  Stop Name: {this.state.currentDetentions.stop_name}
                </td>
              </tr>
              <tr>
                <td>
                  Appointment Time: {this.state.currentDetentions.appointment_time} (Local)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="pa2">
          <table>
            <tbody className="lh-copy">
              <tr>
                <td>
                  Driver: {this.state.currentDetentions.driver_name} 
                </td>
              </tr>
              <tr>
                <td>
                  Phone: {this.state.currentDetentions.driver_phone}
                </td>
              </tr>
              <tr>
                <td>
                  Email: <a href={this.state.currentDetentions.driver_email}>{this.state.currentDetentions.driver_email}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  _renderMinimal = () => {
    return(
      <div className="flex justify-between w-100 pt2 bg-white b--dashed b--blue bw1">
        <div className="pa2">
          <table style={{width: '420px'}}>
            <tbody className="lh-copy">
              <tr>
                <td>
                  Order ID: 
                  <span className="fw6"> {this.state.currentDetentions.order_id} </span> 
                  | BOL: 
                  <span className="fw6"> {this.state.currentDetentions.bol_number} </span> 
                </td>
              </tr>
              <tr>
                <td>
                  Stop Sequence: {this.state.currentDetentions.order_stop_seq}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="pa2">
          <table>
            <tbody className="lh-copy">
              <tr>
                <td>
                  Stop Name: {this.state.currentDetentions.stop_name}
                </td>
              </tr>
              <tr>
                <td>
                  Appointment Time: {this.state.currentDetentions.appointment_time} (Local)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  renderDetentionDetails = () => {

    const arrivalDelay = this._calcTimeDiff(this.state.currentDetentions.appointment_time, this.state.currentDetentions.arrival_ts)
    const startDelay = this._calcTimeDiff(this.state.currentDetentions.appointment_time, this.state.currentDetentions.checkin_ts)
    const dockDelay = this._calcTimeDiff(this.state.currentDetentions.appointment_time, this.state.currentDetentions.dock_ts)
    const exitDelay = this._calcTimeDiff(this.state.currentDetentions.appointment_time, this.state.currentDetentions.exit_ts)
    const exitDelayLong = this._calcTimeDiff(this.state.currentDetentions.appointment_time, this.state.currentDetentions.exit_ts, true)
    
    let places = [], bounds = {}, id = this.state.currentDetentions.record_id;
    let container = {width: 745, height: 430}

    if( this.state.currentDetentions.dock_latitude && this.state.currentDetentions.start_latitude && this.state.currentDetentions.exit_latitude){
      places= [
        {lat: this.state.currentDetentions.dock_latitude, lng: this.state.currentDetentions.dock_longitude, id: 3},
        {lat: this.state.currentDetentions.start_latitude, lng: this.state.currentDetentions.start_longitude, id: 2},
        {lat: this.state.currentDetentions.arrival_latitude, lng: this.state.currentDetentions.arrival_longitude, id: 1},
        {lat: this.state.currentDetentions.exit_latitude, lng: this.state.currentDetentions.exit_longitude, id: 99}
      ];
      
      bounds = this.calcuateCenterAndZoom(places);
    } 

    if(id){
      container.width = 746
    }

    return (
      <div className="pa3 h-100" id="divToPrint">

        <div className="flex w-100 bg-white pb2">
          <div className="w-50">
            {this.state.logoBlob ? (
              <img
                style={{
                  height: "70px",
                  objectFit: "cover"
                }}
                className=""
                src={this.state.logoBlob}
              />) : null}
          </div>
          <div className="pa2 w-100 mt3 flex items-start">
            <span className="fw6 f4"> Check in and Detention Report</span>
          </div>
        </div>

        {this.state.showMinimal ? this._renderMinimal() : this._renderClassic()}
        
        <div className="flex justify-between pt2 pb2 bg-white" style={{height: 450, width: 748}}>
          {id ? (
            <PlotDetention
              item={this.state.currentDetentions}
              bounds={bounds}
              places={places}
              key={id} 
              container={container}
            />
          )
          : <p style={{height: 450, width: 748}}> </p>}
        </div>

        <div className="flex justify-around w-100 pt2 pb2 b--dashed b--blue bw1 bg-white">
            {this._renderDetentionLegend(StartImage, this.state.currentDetentions.arrival_desc, this.state.currentDetentions.arrival_ts, arrivalDelay)}
            {this._renderDetentionLegend(GateImage, this.state.currentDetentions.checkin_desc, this.state.currentDetentions.checkin_ts, startDelay)}
            {this._renderDetentionLegend(TruckImage, this.state.currentDetentions.dock_desc, this.state.currentDetentions.dock_ts, dockDelay)}
            {this._renderDetentionLegend(ExitImage, this.state.currentDetentions.exit_desc, this.state.currentDetentions.exit_ts, exitDelay)}
        </div>
        
        <div className="flex justify-around w-100 pt3 pb3 bg-white red fw6 f5">
          {exitDelayLong}
        </div>
        
        {this.state.showDriverNotes ?
          <div className="w-100 bg-white pb3" style={{height: '200px'}}>
              <p className="f5 fw6 pb2">Driver Notes</p>
              <p className="f6 fw3 pa2 bg-near-white" style={{height: '160px'}}>
                {this.state.currentDetentions.comment}
              </p>
          </div>
        : 
          null
        }

        <div className="mt2 flex w-100 bg-white pb3">
          <img
            style={{
              height: "25px",
              objectFit: "cover"
            }}
            className=""
            src={MTTLogo}
          />
          <span className="ml2 fw2 f6"> Powered by My Truckin' Tech. Report generated on {dayjs().format("dddd, MMMM Do YYYY, h:mm:ss a")}</span>
        </div>

      </div>
    )
  }

  buildHTMLToPDFPayload = async () => {
    
    this.setState({downloadingPDF: true});
    const input = document.getElementById('divToPrint');
    html2canvas(input, {
        width: 780,
        height: 1160,
        useCORS: true,
        scale: 1.5,
        allowTaint: false,
        ignoreElements: function (node) {
          return node.nodeName === 'IFRAME';
        }       
     })
      .then((canvas) => {
        const img = canvas.toDataURL('img/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgProps= pdf.getImageProperties(img);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(img, 'PNG', 0, 0, pdfWidth, pdfHeight);
        // pdf.output('dataurlnewwindow');
        pdf.save("MTT_detention_and_checkin_report.pdf");
        this.setState({downloadingPDF: false});
      })
    ;

  }

  toggleDriverComments = () =>{
    let showDriverNotes = this.state.showDriverNotes
    this.setState({showDriverNotes: !showDriverNotes})
  }

  toggleMinimalView = () =>{
    let showMinimal = this.state.showMinimal
    this.setState({showMinimal: !showMinimal})
  }

  renderDetentionReportTitle = () => {

    if(this.state.downloadingPDF){
      return(<div><Loading size="xs">Downloading detention report</Loading></div>)
    }

    return (
      <Row justify='space-between' className="pt2">
        <p className="text-lg pt2">Detention Report</p>
        <div className="flex">
          <div className="f5 fw2 pt2">Minimal</div>
          <Switch
            css={{mt: 2, mr: 10, ml: 5}}
            size='lg'
            checked={this.state.showMinimal}
            onChange={this.toggleMinimalView}
          />
          <div className="f5 fw2 pt2">Show Notes</div>
          <Switch
            css={{mt: 2, mr: 10, ml: 5}}
            size='lg'
            checked={this.state.showDriverNotes}
            onChange={this.toggleDriverComments}
          />
          <NextButton
            auto
            ghost
            css={{br:0}}
            icon={<Download/>}
            onClick={() => {
              this.buildHTMLToPDFPayload();
            }}
          >
            <Text color='currentColor'>Download</Text>
          </NextButton>        
        </div>
      </Row>
    )
  }

  render() {
    var height = window.innerHeight;

    const gridContainerStyle = {
      height: (height - 160) + "px",
    };

    if (this.props.Access.allow_detention_management === 1) {
      return (
        <div className="">
          <div className="flex justify-between border-y-2 border-dotted p-4">
            <SubPageHeader>Detention Management</SubPageHeader>

            <div className="flex flex-wrap">
              <Radio.Group size="sm" row css={{mt: '0.75rem'}} value={this.state.showState}>
                <Radio 
                  value="Closed" 
                  color="primary"  
                  checked={this.state.showState === CLOSED} 
                  onChange={() => {
                      this.populateGrid(CLOSED)
                  }
                }>
                  {CLOSED} <Radio.Desc>Display Closed detentions</Radio.Desc>
                </Radio>

                <Radio 
                  value="Open" 
                  checked={this.state.showState === OPEN} 
                  onChange={() => {
                    this.populateGrid(OPEN)
                  }
                }>
                  {OPEN} <Radio.Desc>Display Open detentions</Radio.Desc>
                </Radio>

                <Radio 
                  value="All" 
                  checked={this.state.showState === ALL} 
                  onChange={() => {
                    this.populateGrid(ALL)
                  }
                }>
                  {ALL} <Radio.Desc>All records</Radio.Desc>
                </Radio>
              </Radio.Group>

              <div className="flex pt2 mr1 render-below">
                <NextButton
                  auto
                  css={{br:0 }}
                  onClick={this.handleClose}
                  icon={<Lock set="light" fill="currentColor" />}
                >
                  <Text color="currentColor">Close Selected</Text>
                </NextButton>

                <NextButton
                  auto
                  color="error"
                  css={{br:0, ml: 10 }}
                  onClick={this.handleReopen}
                  icon={<Unlock set="light" fill="currentColor" />}
                >
                  <Text color="currentColor">Open Selected</Text>
                </NextButton>
              </div>
            </div>
          </div>
          {this.state.fetching ? (
            <div className="flex justify-center mt6">
              <Loading size="lg">Fetching the detention data..</Loading>
            </div>
          ) : (
            <div
              className="mr2 mt2"
            >
              {this.state.detentionList.length === 0 ? (
                <div>
                  <div className="p-4 text-md">No detention records found.</div>
                </div>
              ) : (
                <div style={gridContainerStyle} className="ag-theme-quartz p-4">
                  <AgGridReact
                    // properties
                    columnDefs={this.state.columnDefs}
                    rowData={this.state.detentionList}
                    rowMultiSelectWithClick={true}
                    pagination={true}
                    paginationAutoPageSize={true}
                    // events
                    onGridReady={this.onGridReady}
                    rowSelection={this.state.rowSelection}
                    onRowDoubleClicked={this.rowDoubleClicked}
                    onSelectionChanged={this.onSelectionChanged.bind(this)}
                    //ID
                    getRowId={(params) => params.data.record_id}
                    enableColResize
                    enableSorting
                    enableFilter
                  ></AgGridReact>
                </div>
              )}
            </div>
          )}

          <Modal
            height='1140px'
            width="780px"
            css={{
              overflow: 'hidden',
              br: 0
            }}
            open={this.state.isOpen}
            onClose={this.onCancel}
            closeButton
            blur
          >
            <Modal.Header>
              {this.renderDetentionReportTitle()}
            </Modal.Header>
            <Divider/>
            {this.renderDetentionDetails()}
          </Modal>
        </div>
      );
    } else {
      return (
        <div>
          {this.state.check ? (
            <div className="flex justify-center mt6">
              <Loading size="lg">Fetching the detention data..</Loading>
            </div>
          ) : (
            <div>
              <div className="flex justify-between">
                <h4 className={"f3 fw4 mb1 pt1 pb3 ph1 mt2 "}>
                    Detention Management
                </h4>
              </div>
              <div className=" ml2 mt3 f5">This feature is unavailable</div>
            </div>
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = function(state) {
  return {
    clientId: state.client ? state.client.id : mstore.getPath(["user", "company_id"]),
    Access: state.navigation.Access
  }
}

export default connect(mapStateToProps)(DetentionManagementDetails);

