import React, { Component } from "react";
import { Spacer, Input } from '@nextui-org/react';

export default class MoreInformation extends Component {
  componentDidMount() {}

  renderFirstRow = () => {
    return (
      <div className="grid grid-cols-4 lg:gap-x-16 md-:gap-x-8 gap-y-2">
        {this.props.customFields.usr_text1_lbl && (
          <div>
            <label className="form-control w-full ">
              <div className="label">
                <span className="label-text">{this.props.customFields.usr_text1_lbl}</span>
              </div>
              <Input
                type="text"
                placeholder={`Enter ${this.props.customFields.usr_text1_lbl}`}
                clearable
                onChange={(e) => {
                  this.props.setMoreInformationToState(
                    "moreInformation",
                    "userText1",
                    e.target.value
                  );
                }}
                css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
                shadow={false}
                bordered={true}
                rounded={false}
                value={this.props.userText1}
              />
            </label>
          </div>
        )}

        {this.props.customFields.usr_text2_lbl && (
          <div>
            <label className="form-control w-full ">
              <div className="label">
                <span className="label-text">{this.props.customFields.usr_text2_lbl}</span>
              </div>
              <Input
                type="text"
                placeholder={`Enter ${this.props.customFields.usr_text2_lbl}`}
                clearable
                onChange={(e) => {
                  this.props.setMoreInformationToState(
                    "moreInformation",
                    "userText2",
                    e.target.value
                  );
                }}
                css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
                shadow={false}
                bordered={true}
                rounded={false}
                value={this.props.userText2}
              />
            </label>
          </div>
        )}

        {this.props.customFields.usr_text3_lbl && (
          <div>
            <label className="form-control w-full ">
              <div className="label">
                <span className="label-text">{this.props.customFields.usr_text3_lbl}</span>
              </div>
              <Input
                type="text"
                placeholder={`Enter ${this.props.customFields.usr_text3_lbl}`}
                clearable
                onChange={(e) => {
                  this.props.setMoreInformationToState(
                    "moreInformation",
                    "userText3",
                    e.target.value
                  );
                }}
                css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
                shadow={false}
                bordered={true}
                rounded={false}
                value={this.props.userText3}
              />
            </label>
          </div>
        )}

        {this.props.customFields.usr_date1_lbl && (
          <div>
            <label className="form-control w-full ">
              <div className="label">
                <span className="label-text">{this.props.customFields.usr_date1_lbl}</span>
              </div>
              <Input
                type='date'
                placeholder={`Enter ${this.props.customFields.usr_date1_lbl}`}
                onChange={(e) => {
                  this.props.setMoreInformationToState(
                    "moreInformation",
                    "UserDate1",
                    e.target.value
                  );
                }}
                css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
                shadow={false}
                bordered={true}
                rounded={false}
                value={this.props.UserDate1}
              />
            </label>
          </div>
        )}

        {this.props.customFields.usr_date2_lbl && (
          <div>
            <label className="form-control w-full ">
              <div className="label">
                <span className="label-text">{this.props.customFields.usr_date2_lbl}</span>
              </div>
              <Input
                type='date'
                placeholder={`Enter ${this.props.customFields.usr_date2_lbl}`}
                onChange={(e) => {
                  this.props.setMoreInformationToState(
                    "moreInformation",
                    "UserDate2",
                    e.target.value
                  );
                }}
                css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
                shadow={false}
                bordered={true}
                rounded={false}
                value={this.props.UserDate2}
              />
            </label>
          </div>
        )}

        {this.props.customFields.usr_date3_lbl && (
          <div>
            <label className="form-control w-full ">
              <div className="label">
                <span className="label-text">{this.props.customFields.usr_date3_lbl}</span>
              </div>
              <Input
                type='date'
                placeholder={`Enter ${this.props.customFields.usr_date3_lbl}`}
                onChange={(e) => {
                  this.props.setMoreInformationToState(
                    "moreInformation",
                    "UserDate3",
                    e.target.value
                  );
                }}
                css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
                shadow={false}
                bordered={true}
                rounded={false}
                value={this.props.UserDate3}
              />
            </label>
          </div>
        )}

        {this.props.customFields.usr_num1_lbl && (
          <div>
            <label className="form-control w-full ">
              <div className="label">
                <span className="label-text">{this.props.customFields.usr_num1_lbl}</span>
              </div>
              <Input
                type='number'
                placeholder={`Enter ${this.props.customFields.usr_num1_lbl}`}
                onChange={(e) => {
                  this.props.setMoreInformationToState(
                    "moreInformation",
                    "UserNum1",
                    e.target.value
                  );
                }}
                css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
                shadow={false}
                bordered={true}
                rounded={false}
                value={this.props.UserNum1}
              />
            </label>
          </div>
        )}

        {this.props.customFields.usr_num2_lbl && (
          <div>
            <label className="form-control w-full ">
              <div className="label">
                <span className="label-text">{this.props.customFields.usr_num2_lbl}</span>
              </div>
              <Input
                type='number'
                placeholder={`Enter ${this.props.customFields.usr_num2_lbl}`}
                onChange={(e) => {
                  this.props.setMoreInformationToState(
                    "moreInformation",
                    "UserNum2",
                    e.target.value
                  );
                }}
                css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
                shadow={false}
                bordered={true}
                rounded={false}
                value={this.props.UserNum2}
              />
            </label>
          </div>
        )}

        {this.props.customFields.usr_num3_lbl && (
          <div>
            <label className="form-control w-full ">
              <div className="label">
                <span className="label-text">{this.props.customFields.usr_num3_lbl}</span>
              </div>
              <Input
                type='number'
                placeholder={`Enter ${this.props.customFields.usr_num3_lbl}`}
                onChange={(e) => {
                  this.props.setMoreInformationToState(
                    "moreInformation",
                    "UserNum3",
                    e.target.value
                  );
                }}
                css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
                shadow={false}
                bordered={true}
                rounded={false}
                value={this.props.UserNum3}
              />
            </label>
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      <div>
        {(this.props.customFields.usr_text1_lbl ||
          this.props.customFields.usr_text2_lbl ||
          this.props.customFields.usr_text3_lbl ||
          this.props.customFields.usr_date1_lbl ||
          this.props.customFields.usr_date2_lbl ||
          this.props.customFields.usr_date3_lbl ||
          this.props.customFields.usr_num1_lbl ||
          this.props.customFields.usr_num2_lbl ||
          this.props.customFields.usr_num3_lbl) && (
          <div className="mt-4">
            {this.renderFirstRow()}
          </div>
        )}
      </div>
    );
  }
}
