import React, { Component } from "react";
import { Input, Spacer } from '@nextui-org/react';

export default class CarrierInformation extends Component {
  componentDidMount() {}

  renderFirstRow = () => {
    return (
      <div className="grid grid-cols-4 lg:gap-16 md-:gap-8">
        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">SCAC Code</span>
            </div>
            <Input
              type="text"
              placeholder="Enter SCAC Code"
              clearable
              onChange={(e) => {
                this.props.setCarrierDetailsToState(
                  "carrierDetails",
                  "scacCode",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.scacCode}
            />
          </label>
        </div>

        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">DOT No.</span>
            </div>
            <Input
              type="text"
              placeholder="Enter DOT Number"
              clearable
              onChange={(e) => {
                this.props.setCarrierDetailsToState(
                  "carrierDetails",
                  "dotNumber",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.dotNumber}
            /> 
          </label>
        </div>

        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">MC No.</span>
            </div>
            <Input
              type="text"
              placeholder="Enter MC Number"
              clearable
              onChange={(e) => {
                this.props.setCarrierDetailsToState(
                  "carrierDetails",
                  "mcNumber",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.mcNumber}
            /> 
          </label>
        </div>
        
        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">Broker Rep</span>
            </div>
            <Input
              type="text"
              placeholder="Enter Broker Rep"
              clearable
              onChange={(e) => {
                this.props.setCarrierDetailsToState(
                  "carrierDetails",
                  "brokerRep",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.brokerRep}
            />
          </label>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="mt-4">
        {this.renderFirstRow()}
      </div>
    );
  }
}
