import React, { Component } from "react";
import FeatureSelection from "../ProductSelection/FeatureSelection";
import CannedDriverMessages from './CannedDriverMessages';
import { mstore } from "../../store";
import { connect } from "react-redux";

let companyId;
let userId;

class CannedMessageSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    companyId = mstore.get("user", "company_id");
    userId = mstore.get("user", "id");

   }

  render() {
    return (
      <div className="w-100">
        <CannedDriverMessages isBrokerShipmentManagement={0}/>
        <CannedDriverMessages isBrokerShipmentManagement={1}/>
      </div>
    );
  }
}

export default connect((state) => ({
  Access: state.navigation.Access,
  clientId: state.client
    ? state.client.id
    : mstore.getPath(["user", "company_id"]),
}))(CannedMessageSettings);
