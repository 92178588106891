import React, { Component } from "react";
import PhoneInput from "react-phone-input-2";
import { Input, Spacer } from '@nextui-org/react';

export default class DriversAndDocuments extends Component {
  componentDidMount() {}

  renderFirstRow = () => {
    return (
      <div className="grid grid-cols-4 lg:gap-16 md-:gap-8">
        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">Driver 1</span>
            </div>
            <Input
              type="text"
              placeholder="Enter Driver 1 Employee Code"
              clearable
              onChange={(e) => {
                this.props.setDriversAndDocumentsToState(
                  "driverAndDocuments",
                  "driver1",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.driver1}
            />
          </label>
        </div>

        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">Truck No.</span>
            </div>
            <Input
              type="text"
              placeholder="Enter Truck Number"
              clearable
              onChange={(e) => {
                this.props.setDriversAndDocumentsToState(
                  "driverAndDocuments",
                  "truck",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.truck}
            />
          </label>
        </div>    

        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">BOL</span>
            </div>
            <Input
              type="text"
              placeholder="Enter BOL Number"
              clearable
              onChange={(e) => {
                this.props.setDriversAndDocumentsToState(
                  "driverAndDocuments",
                  "bol",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.bol}
            />
          </label>
        </div>

        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">PO</span>
            </div>
            <Input
              type="text"
              placeholder="Enter Purchase Order Number"
              clearable
              onChange={(e) => {
                this.props.setDriversAndDocumentsToState(
                  "driverAndDocuments",
                  "po",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.po}
            />
          </label>
        </div>
      </div>
    );
  };

  renderSecondRow = () => {
    return (
      <div className="grid grid-cols-4 lg:gap-16 md-:gap-8">

        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">Driver 2</span>
            </div>
            <Input
              type="text"
              placeholder="Enter Driver 2 Employee Code"
              clearable
              onChange={(e) => {
                this.props.setDriversAndDocumentsToState(
                  "driverAndDocuments",
                  "driver2",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.driver2}
            />
          </label>
        </div>

        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">Stop Type</span>
            </div>
            <Input
              type="text"
              placeholder="Enter Stop Type (Pickup/Drop)"
              clearable
              onChange={(e) => {
                this.props.setDriversAndDocumentsToState(
                  "driverAndDocuments",
                  "stopType",
                  e.target.value
                );
              }}
              css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
              shadow={false}
              bordered={true}
              rounded={false}
              value={this.props.stopType}
            />
          </label>
        </div>
        
        <div>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">Driver Phone (for Auto-Linking)</span>
            </div>
            <PhoneInput
                country={"us"}
                prefix={'+'}
                containerStyle={{ width: '100%' }}
                inputStyle={{ height: "32px", width: "100%" }}
                preferredCountries={["us", "in"]}
                value={this.props.driverPhone}
                onChange={ phone => {
                  
                  this.props.setDriversAndDocumentsToState(
                    'driverAndDocuments',
                    'driverPhone',
                    phone
                  );
                }}
              />
          </label>
        </div>  
      </div>
    );
  };

  render() {
    return (
      <div className="mt-4">
        {this.renderFirstRow()}
        <Spacer y={0.5}/>
        {this.renderSecondRow()}
      </div>
    );
  }
}
