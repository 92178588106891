export function noop() {
  // if(__DEV__){
  //     console.log('noop called from -> {todo: get stack}')
  // }
}

export const emptyString = ''

export const defaultObserver = {
  next: noop,
  error: noop,
  complete: noop
}

// TODO: just double negate over if, aka !!val
export const toBool = val => val ? true : false;

const IS_NUMBER_REGEX = /^[0-9.]*$/;

/**
 * Test if it is a empty string or decimal
 * @param {string} val 
 */
export const isNumber = val => IS_NUMBER_REGEX.test(val);

export const keyPath = (obj, pathArray) => {
  let thing = obj;
  let i = 0, n = pathArray.length;
  while (thing && i < n) {
    thing = thing[pathArray[i++]];
  }
  return thing;
}

export const pickKeys = (obj, keyArray) => {
  let nObj = {}, key = null
  let i = 0, n = keyArray.length
  for (; i < n; i++) {
    key = keyArray[i]
    nObj[key] = obj[key]
  }
  return nObj
}

export const cutArray = (array, start, end) => {
  const i = end ? end : start + 1
  if (start === 0)
    return end ? array.slice(1, end) : array.slice(1)
  else
    return [...array.slice(0, start), ...array.slice(i)]
}

export const insArray = (array, idx, elm) => {
  if (idx === 0)
    return [elm, ...array]
  return [...array.slice(0, idx), elm, ...array.slice(idx + 1)]
}

export const withoutKey = (obj, key) => {
  let ret = {}
  Object.keys(obj)
    .forEach(k => {
      if (k !== key) {
        ret[k] = obj[k]
      }
    })
  return ret
}

export const classSet = classes => {
  return Object.keys(classes).filter(k => classes[k]).join(' ')
}

export const makeCancelable = (promise) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(val =>
      hasCanceled_ ? noop : resolve(val)
      , error =>
        hasCanceled_ ? noop : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

export function handleCancelablePromiseErr(err) {
  if (err.isCanceled) { return }
  console.error(err)
}

const DEFAULT_TITLE = "MTT Control"
export const updateTitle = title => {
  let ctitle = title ? `${title} | ${DEFAULT_TITLE}` : DEFAULT_TITLE
  if(document.title !== ctitle){
    document.title = ctitle
  }
}

export const camel2snake = str => str.replace(/[A-Z]/g, (m, i, _str) => '_' + m.toLowerCase())

export const snakeCaseObj = obj => {
  return Object.keys(obj)
          .reduce((o, k) => {
            o[camel2snake(k)] = obj[k]
            return o
          }, {})
}

export function wrapInputHandler(onTextChangeHandler) {
  return function innerEventHandler(event) {
    return onTextChangeHandler(event.target.value)
  }
}

export const searchOrderList = (haystack, needle) => {
  let results = [];
  let index,
    sCount = 0;
  let entry;

  for (index = 0; index < haystack.length; ++index) {
    entry = haystack[index];
    if (entry && entry.value && entry.value.toUpperCase().startsWith(needle?.toUpperCase())) {
      sCount++;
      results.push(entry);
    }

    if (sCount > 3) break;
  }

  return results;
};