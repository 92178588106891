import React, { Component } from "react";
import { Loading, Switch, Text } from '@nextui-org/react';

class FeatureSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      featureList: [],
      wantToDesableFeature: false,
      switchKey: new Date().getTime()
    };
  }

  render() {

    if(this.props.featureList?.length === 0){
      return null
    }

    return (
      <>
        {this.props.featureList.map((obj, idx) =>
          <div className="card bg-base-100 shadow-md" key={idx}>
            <div className="card-body">
              <h2 className="card-title flex justify-between">
                {obj.feature_name}
                {obj.is_feature_active === 0 || obj.is_feature_active === 1 ? 
                  <Switch
                    size='sm'
                    key={this.state.switchKey}
                    checked={obj.is_feature_active === 1 ? true : false}
                    disabled={obj.is_mandatory === 1 ? true : false}
                    onChange={(e) => {
                      this.setState({switchKey: new Date().getTime()})  // HACK!!
                      this.props.handleSwitch(obj);
                    }}
                  /> 
                  : null
                }
                {obj.coming_soon_ind ?
                  <div className="badge badge-primary">Upcoming</div>
                  : null
                }
              </h2>
              <p className="text-wrap text-sm text-gray-500 py-4 my-2 border-y ">{obj.item_desc_ext}</p>
              <div className="flex justify-end">
                <span 
                  className="text-lg text-wrap font-medium text-primary">{obj.display_price} 
                  <span className="text-wrap text-sm text-gray-500 font-normal">
                  ,&nbsp; {obj.uom_desc}
                  </span>
                </span>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default FeatureSelection;
