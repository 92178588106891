import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import * as dayjs from 'dayjs';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { error, success } from "../../lib/userAlerts";
import { secureFetch } from "../../lib/api";
import endpoint from "../../config/endpoint.json";
import { Modal, Text, Button, Card } from "@nextui-org/react";
import { Search } from "react-iconly";

const relativeTime = require('dayjs/plugin/relativeTime');

//Config Relative time plugin
dayjs.extend(relativeTime);

const createColumnDefs = () => {
  return [
    {
      headerName: "Phone Number",
      field: "phone_number",
      cellClass: "cell-wrap-text",
      autoHeight: true
    },
    {
      headerName: "Invited",
      field: "send_time",
      cellClass: "cell-wrap-text",
      autoHeight: true
    },
    {
      headerName: "Invite status",
      field: "error_text",
      cellClass: "cell-wrap-text",
      autoHeight: true
    },
  ];
};

class ShareModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: createColumnDefs(),
      selectedDriver: null,
      driverPhone: "",
      phone: "",
      firstTimeUser: false,
      secondTimeUser: false,
      registeredUser: false,
      smsLog: [],
      searchNumber: false,
      recordId: 0,
      reRenderComponent: false
    };
  }

  _fetchPreviousLinkRequests (recordId){
    return secureFetch(endpoint.fetchLinkRequests, {
      recordId: recordId
    })
  }

  _fetchPreviousLinkRequestsAndUpdateState = async () => {
    try {
      let [ok, status, json] = await this._fetchPreviousLinkRequests(this.props.selectedOrder.record_id);
      let smsLog = [];
      
      if(ok){
        json.userData.forEach((element) => {
          element.send_time = dayjs
                              .unix(element.send_time)
                              .fromNow()
                              .toString();
          element.error_text = element.has_errored === 1 ? element.error_text : "Invitation successfully sent";
          smsLog.push(element);
        });
        this.setState({smsLog});
      }
      else{
        this.setState({ smsLog: [] });
        error("Oops! Unable to fetch previous link requests");
      }
    }
    catch(e){
      this.setState({ smsLog: [] });
      error("Oops! Unable to fetch previous link requests");
    }
  }

  _closeModal = async () => {
    await this.props.closeShareModal();
    this.setState({
      selectedDriver: null,
      driverPhone: "",
      phone: "",
      firstTimeUser: false,
      secondTimeUser: false,
      registeredUser: false,
      smsLog: [],
      searchNumber: false,
      recordId: 0,
      reRenderComponent: false
    });
  }
  

  async componentDidUpdate() {

    if (this.props.openShareModalState && this.state.recordId === 0) {
      this.setState({recordId: this.props.selectedOrder.record_id})
      this._fetchPreviousLinkRequestsAndUpdateState()
    }

    if(this.state.reRenderComponent){
      this.setState({recordId: this.props.selectedOrder.record_id, reRenderComponent:false})
      this._fetchPreviousLinkRequestsAndUpdateState()
      this.forceUpdate()
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  };

  searchForNumber = () => {
    this.setState({ searchNumber: true });
    secureFetch(endpoint.searchForNumber, {
      phone: `+${this.state.phone}`,
      recordId: this.state.recordId
    }).then(([ok, status, json]) => {
      if (ok) {
        if (json.userData.length > 0) {
          let registeredUser = json.userData.find(
            (item) => item.registered_user === 1
          );

          if (registeredUser) {
            this.setState({
              registeredUser: true,
              firstTimeUser: false,
              secondTimeUser: false,
              selectedDriver: registeredUser,
            });
            this.setState({ searchNumber: false });
          } else {
            let smsLog = [];
            json.userData.forEach((element) => {
              if (element.registered_user === 0) {
                element.send_time = dayjs
                  .unix(element.send_time)
                  .fromNow()
                  .toString();
                element.error_text =
                  element.has_errored === 1
                    ? element.error_text
                    : "Invitation successfully sent";
                smsLog.push(element);
              }
            });
            this.setState({
              secondTimeUser: true,
              registeredUser: false,
              firstTimeUser: false,
              smsLog,
            });
            this.setState({ searchNumber: false });
          }
        } else {
          this.setState({ searchNumber: false });
          this.setState({
            firstTimeUser: true,
            secondTimeUser: false,
            registeredUser: false,
          });
        }
      } else {
        this.setState({ searchNumber: false });
        error("Oops! error occured during search for phone number");
      }
    });
  };

  sendInvitation = () => {
    // Reset Error text
    this.setState({errLinkAndInvite: ''})

    secureFetch(endpoint.sendTextMessage, {
      phone: `+${this.state.phone}`,
      recordId: this.state.recordId,
      orderId: this.props.selectedOrder.OrderNo
    })
      .then(([ok, status, json, errJson]) => {
        if (ok) {
          success("You have successfully sent invitation");
          this._closeModal();
        } else {
          error(errJson.message);
          this.setState({reRenderComponent: true})
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  renderRegisterMessage = () => {
    return(
      <Card css={{br: 0, ml: 10}}>
        <Card.Header >
          <Text size={20} b>Do want to link with this driver?</Text>
        </Card.Header>

        <Card.Body>
          <div>
            <div className="f5">
              <Text size={16}>Driver Name: {this.state.selectedDriver.driver_name}</Text>
            </div>
            <div className="f5">
              <Text size={16}>Phone: +{this.state.phone}</Text>
            </div>
          </div>
        </Card.Body>

        <Card.Footer >
          <div
            className="w-100 flex justify-end"
            key="front"
            style={{ height: 80 }}
          >
            <Button
              auto
              css={{br: 0 , mt: 10 , ml: 10}}
              onClick={() => {
                this.props.shareShipment(
                  this.state.selectedDriver.driver_user_id
                );
                this._closeModal();
              }}
              loading={this.state.loadShare}
            >
              Link
            </Button>
          </div>
        </Card.Footer>
      </Card>
    )
  }

  registeredUserUI = () => {
    return (
      <div>
          {this.renderRegisterMessage()}
      </div>
    );
  };

  renderLinkMessage = () => {
    return(
      <div>
        <p className="mt2">
          User has not registered yet. Do you want to link this shipment to this phone number ? 
          A text message will be sent to the user and the order will be linked to this phone number.
        </p>
        <p className="mt2">
          <strong>Please verify the phone number and ensure that it is accurate before linking the shipment </strong>
        </p>
      </div>
    )
  }

  firstTimeUserUI = () => {
    return (
      <div>
        <div className="ml3 pa3 bg-near-white">
          <h5 className="mb3">
            Invite and Link this phone number to this shipment - 
            <strong className="ml1"> (Order) {this.props.selectedOrder.OrderNo} / (Stop) {this.props.selectedOrder.StopId} </strong> ?
          </h5>
          {this.renderLinkMessage()}
        </div>
        <p className="ml3 mt2 f5 fw6">Any Error messages during linkage will be displayed in the grid below.</p>
        <div style={{ height: "125px" }} className={"ag-theme-quartz ml3 mt2"}>
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={this.state.smsLog}
            // // events
            onGridReady={this.onGridReady}
            enableColResize
            enableSorting
            enableFilter
          />
        </div>
        <div>
          <Button
            auto
            ghost
            css={{br:0, mt: 10, ml: 10}}
            onClick={() => {
              this.sendInvitation();
            }}
            loading={this.state.loadShare}
          >
            Invite user & Link Order
          </Button>
        </div>
      </div>
    );
  };

  secondTimeUserUI = () => {
    return (
      <div>
        <div className="f5 mb3 ml3">
          All previous invites made by you (if any) for <strong className="ml1"> (Phone number) {this.state.phone} </strong> are shown below.
        </div>
        <div style={{ height: "200px" }} className={"ag-theme-quartz ml3"}>
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={this.state.smsLog}
            // // events
            onGridReady={this.onGridReady}
            enableColResize
            enableSorting
            enableFilter/>
        </div>
        <div className="f5 mt3 pa1 bg-washed-green ml3">
          {this.renderLinkMessage()}
        </div>
        <div>
          <Button
            auto
            ghost
            css={{br:0, mt: 10, ml: 10}}
            onClick={() => {
              this.sendInvitation();
            }}
            loading={this.state.loadShare}
          >
            Re-send user invite text & Link Order
          </Button>
        </div>
      </div>
    );
  };

  initialUI = () => {
    return (
      <div>
        <div className="f5 mb3 ml3">
          Previous driver invites (if any) for 
          <strong className="ml1"> (Order) {this.props.selectedOrder.OrderNo} / (Stop) {this.props.selectedOrder.StopId} </strong>
          are shown below.
        </div>
        <div style={{ height: "200px" }} className={"ag-theme-quartz ml3"}>
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={this.state.smsLog}
            // // events
            onGridReady={this.onGridReady}
            enableColResize
            enableSorting
            enableFilter
          ></AgGridReact>
        </div>
      </div>
    );
  };

  render() {
    var height = window.innerHeight + 350;
    var width = window.innerWidth;
    // const clientId = mstore.get("user", "company_id");
    return (
      <Modal
        open={this.props.openShareModalState}
        closeButton
        fullScreen
        css={{br:0}}
        onClose={() => {
          this._closeModal();
        }}
        canEscapeKeyClose={true}
      >
        <Modal.Header>
          <Text size={18} b>Link Driver</Text>
        </Modal.Header>
        <Modal.Body>
          <div className="mt3 h-25 mh4 flex">
            <div
              className="w-2/5 br flex"
              style={{ height: parseInt(height * 0.4, 10) }}
            >
              <div className="">
                <PhoneInput
                  country={"us"}
                  preferredCountries={["us", "in"]}
                  value={this.state.phone}
                  onChange={(phone) => {
                    this.setState({ phone });
                  }}
                />
              </div>
              <Button
                css={{br:0, ml: 5, mt: 2}}
                auto
                ghost
                size='sm'
                icon={<Search/>}
                onClick={this.searchForNumber}
                loading={this.state.searchNumber}
              />
            </div>
            <div className="w-3/5 h-100 br-red">
              {!this.state.registeredUser && !this.state.firstTimeUser && !this.state.secondTimeUser && this.initialUI()}
              {this.state.registeredUser && this.registeredUserUI()}
              {this.state.firstTimeUser && this.firstTimeUserUI()}
              {this.state.secondTimeUser && this.secondTimeUserUI()}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ShareModal;
