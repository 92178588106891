import React, { Component } from "react";
import { Modal } from "@nextui-org/react";
import { secureFetch } from "../../lib/api";
import endpoint from "../../config/endpoint.json";
import { error, success } from "../../lib/userAlerts";
import { AgGridReact } from "ag-grid-react";
import dayjs from '../../lib/day';

const createColumnDefs = () => {
  return [
    {
      headerName: "Log Time",
      field: "log_entry_created_at",
      width: 150
    },
    {
      headerName: "Log Entry",
      field: "log_narration",
      width: 784, 
      cellStyle: {'white-space': 'normal'},
      autoHeight: true
    },
  ];
};

export default class AutoLinkLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: createColumnDefs(),
      componentLoaded: false,
      autoLinkLogs: []
    };
  }

  _closeModal = () => {
    this.props.closeLinkModal();
    this.setState({
      componentLoaded: false
     })
  }

  getRowHeight = (params) => {
    let rowHeight = 18 * (Math.ceil(params.data.log_narration.length / 78) + 1)
    return rowHeight;
  }

  async componentDidUpdate() {
    
    if (this.props.openLinkLogModalState && this.props.selectedOrder && this.props.selectedOrder.record_id && !this.state.componentLoaded) {
        await this.getAutoLinkLogs()
        this.setState({
          componentLoaded: true
        })
    }
  }

  getAutoLinkLogs = async () => {
    try{
      let [ok, status, json] = await secureFetch(endpoint.fetchAutoLinkLogs, {recordId: this.props.selectedOrder.record_id})
      if (ok) {

        let autoLinkLogs = [];

        json.autoLinkLogs.forEach((element) => {
          element.log_entry_created_at = dayjs
                              .unix(element.log_entry_created_at)
                              .fromNow()
                              .toString();
          autoLinkLogs.push(element);
        });

        this.setState({autoLinkLogs: autoLinkLogs });

      } else {
        error('Unable to fetch the auto link log data at this time.');
      }
    }
    catch(err){
      error('Unable to fetch the auto link log data at this time.')
    }
  };

  render() {
    return (
      <div>
        <Modal
          width="980px"
          open={this.props.openLinkLogModalState}
          closeButton
          blur
          onClose={() => {
            this._closeModal();
          }}
          canEscapeKeyClose={true}
          css={{br:0, height: 640}}
          title="Showing the auto link process logs"
        >
          <Modal.Header>
            The entries shown in the table below are sorted in the chronological order (ascending order in which the events occured).
          </Modal.Header>

          <Modal.Body>
            <div style={{ height: "500px", width: 900 }} className={"ag-theme-quartz ml3 mt2 mr2"}>
              <AgGridReact
                // properties
                columnDefs={this.state.columnDefs}
                rowData={this.state.autoLinkLogs}
                // // events
                getRowHeight={this.getRowHeight}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
