// @flow
import React from 'react';
import { withRouter } from 'react-router';

function SideBar({ style, className, location, children, bottomWidget }) {
  const { pathname } = location;
  return (
    <nav style={{
      ...style,
      position: 'fixed',
      top: 64, 
      left: 0,
      zIndex: 99
    }} 
    className={className}>
      <ul style={{height: '100%', listStyleType: 'none', padding: 0}}>
        {React.Children.map(children, (elm, i) => {
          if (elm && elm.props.to){
            const isActive = elm.props.to !== '/' && pathname.startsWith(elm.props.to)
            
            return <li 
                      style={{
                        padding: 8,
                        background: isActive ? '#fff' : 'inherit',
                        borderRight: isActive ? '4px solid #3794d0' : 'inherit',
                        paddingLeft: isActive ? 8 : 12,
                      }}
                      isActive={isActive} 
                      key={'navi-' + i}>{elm}
                    </li>
          }
          else
            return elm && React.cloneElement(elm, { key: 'decor-' + i })
        })}
      </ul>
      {bottomWidget}
    </nav>
  )
}

// const RSideBar = withRouter(SideBar)
// export default RSideBar

export default withRouter(SideBar)
