import React, { Component } from "react";
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable';
import chroma from "chroma-js";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";

import { mstore } from "../../../store";
import { secureFetch } from "../../../lib/api";
import endpoint from "../../../config/endpoint.json";
import { error } from "../../../lib/userAlerts";
import PrintDiv from "../../../components/LocationAnalyticsComponent/printDiv";
import Filter from "../../../components/LocationAnalyticsComponent/filter";

import dayjs from '../../../lib/day'
import checkAccess from '../../../lib/checkAccess';
import { Loading, Button, Text, Divider, Row, Tooltip } from "@nextui-org/react";
import { Download, Filter2 } from "react-iconly";
import LocationReport from "./LocationReport";
import SubPageHeader from "../../../components/SubPageHeader";

var scale = chroma.scale(["red", "orange", "lightgreen"]).domain([0, 2.5, 5]);

const createColumnDefs = () => {
  return [
    {
      headerName: "Stop ID",
      field: "stopId",
      width: 80,
      resizable: true
    },
    {
      headerName: "Rating",
      field: "averageRating",
      filter: "agNumberColumnFilter",
      width: 80,
      resizable: true,
      cellStyle: params => {
        const color = scale(params.value).hex()
        return {color: '#222', backgroundColor: color, textAlign: 'center'};
      },
    },
    {
      headerName: "Reviews",
      field: "number_of_reviews",
      filter: "agNumberColumnFilter",
      width: 80,
      cellStyle: params => {
        return {textAlign: 'center'};
      },
      resizable: true
    },
    {
      headerName: "Stop Name",
      field: "stop_name",
      width: 120,
      resizable: true
    },
    {
      headerName: "Address",
      field: "location",
      width: 200,
      resizable: true
    },
    {
      headerName: "City",
      field: "stopCity",
      width: 100,
      resizable: true
    },
    {
      headerName: "State",
      field: "stopState",
      width: 80,
      resizable: true
    },
    {
      headerName: "Zip",
      field: "zip",
      width: 80,
      resizable: true
    },
  ];
};

const addFooters = doc => {

  const pageCount = doc.internal.getNumberOfPages();
  const dt = new Date().toLocaleTimeString();

  for (var i = 1; i <= pageCount; i++) {

    /**
     * 
     * Page border
     * 
     */

    doc.rect(5, 5, doc.internal.pageSize.width - 10, doc.internal.pageSize.height - 10, 'S');

    /**
     * 
     * Font style.
     * 
     */
    doc.setTextColor(169,169,169);
    doc.setFont('helvetica', 'italic');
    doc.setPage(i);

    /**
     * 
     * Report generated at..
     * 
     */
    
    doc.setFontSize(8);
    doc.text('Report generated at ' +  dt + '. Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width / 2, 287, {
      align: 'center'
    });

    /**
     * 
     * Powered by..
     * 
     */
    doc.setFontSize(16)
    doc.text('Powered by MTT.', 10, 287);
  }
}

const pdfColumnDefs = [{
                          title: "Date",
                          dataKey: "createdAt"
                        },
                        {
                          title: "Driver-1",
                          dataKey: "driver1_name"
                        },
                        {
                          title: "Driver-2",
                          dataKey: "driver2_name"
                        },
                        {
                          title: "Comments",
                          dataKey: "comment"
                        }
                      ];

const pdfTitle ='Location Analytics Report';
const pdfDetailTitle ='Driver comments';
const pdfSummaryTitle ='Rating summary';

const thStyle = {
  fontSize: "1.2rem",
  fontWeight: "600",
  textAlign: "center"
};

const tdStyle = {
  fontSize: "1.0rem",
  fontWeight: "800",
  verticalAlign: "middle",
};

class LocationAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationList: [],
      driverCommentList: [],
      columnDefs: createColumnDefs(),
      dateRange: [
        {
          lable: "(All Dates)",
          value: 0,
        },
        {
          lable: "Today",
          value: 1,
        },
        {
          lable: "This Week",
          value: 7,
        },
        {
          lable: "This Month",
          value: 30,
        },
        {
          lable: "This Year",
          value: 365,
        },
      ],
      topSelection: [
        {
          lable: "Top 10",
          value: 10,
        },
        {
          lable: "Top 25",
          value: 25,
        },
        {
          lable: "Top 50",
          value: 50,
        },
        {
          lable: "Top 100",
          value: 100,
        },
        {
          lable: "Top 500",
          value: 500,
        },
        {
          lable: "Top 1000",
          value: 1000,
        },
        {
          lable: "All Data",
          value: 1000000,
        },
      ],
      topCategory: [
        {
          lable: "Best",
          value: "DESC",
        },
        {
          lable: "Worst",
          value: "ASC",
        },
      ],
      ratingCategoryList: [],
      focusAreaId: 0,
      dateRangeId: 0,
      startDate: "",
      endDate: "",
      topSelectionId: 10,
      topCategoryId: "DESC",
      selectedItem: false,
      headerArrayElement: [],
      ratings: [],
      locationName: "",
      locationAddress: "",
      stopCity: "",
      stopState: "",
      stopCountry: "",
      stopZip: "",
      stopName: "",
      loading: false,
      focusAreaIdDisplay: 0,
      dateRangeIdDisplay: 0,
      startDateDisplay: "",
      endDateDisplay: "",
      disableDownload: true,
      topSelectionIdDisplay: 10,
      topCategoryIdDisplay: "DESC",
      rowSelection: 'single'
    };
  }

  componentDidMount() {
    checkAccess(this.props.clientId);

    this.getRatingCategories();
    this.getDriverComments();
    this.getPointsPerChoice();
    this.getLocationList();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.clientId !== this.props.clientId) {
      checkAccess(newProps.clientId);
    }

    this.getRatingCategories();
    this.getDriverComments();
    this.getPointsPerChoice();
    this.getLocationList();
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    //this.gridApi.sizeColumnsToFit();
  };

  onRowClicked = (row) => {
    this.setState(
      {
        selectedItem: true,
        locationName: row.data.stop_name,
        locationAddress: row.data.location,
        stopCity: row.data.stopCity,
        stopState: row.data.stopState,
        stopCountry: row.data.stopCountry,
        stopZip: row.data.zip,
        disableDownload: true
      },
      () => {
        this.getDriverComments();
        this.getPointsPerChoice();
      }
    );
  };

  getRatingCategories = () => {
    secureFetch(endpoint.getRatingCategoryList, {}).then(
      ([ok, status, json]) => {
        if (ok) {
          this.setState({ ratingCategoryList: json.ratingCategoryList });
        } else {
          error("Oops! error occured during fetching rating category list!");
        }
      }
    );
  };

  handleSubmit = () => {
    this.setState({
      selectedItem: false,
      focusAreaIdDisplay: this.state.focusAreaId,
      dateRangeIdDisplay: this.state.dateRangeId,
      startDateDisplay: this.state.startDate ? dayjs(this.state.startDate).format('DD-MMM-YYYY'): this.state.startDate,
      endDateDisplay: this.state.endDate ? dayjs(this.state.endDate).format('DD-MMM-YYYY'): this.state.endDate, 
      topSelectionIdDisplay: this.state.topSelectionId,
      topCategoryIdDisplay: this.state.topCategoryId,
    });
    this.getDriverComments();
    this.getPointsPerChoice();
    this.getLocationList();
  };

  getDriverComments = () => {
    secureFetch(endpoint.getDriverCommentList, {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      location: this.state.locationAddress,
    }).then(([ok, status, json]) => {
      if (ok) {
        this.setState({ driverCommentList: json.driverCommentList });
      } else {
        error("Oops! error occured during fetching rating category list!");
      }
    });
  };

  getPointsPerChoice = () => {
    secureFetch(endpoint.getPointsPerChoice, {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      location: this.state.locationAddress,
    }).then(([ok, status, json]) => {
      if (ok) {
        this.setState({
          headerArrayElement: json.headerArrayElement,
          ratings: json.ratings,
          disableDownload: false,
        });
      } else {
        error("Oops! error occured during fetching rating category list!");
      }
    });
  };

  getLocationList = () => {
    this.setState({ loading: true });
    secureFetch(endpoint.getLocationList, {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      focusArea: this.state.focusAreaId,
      orderSequence: this.state.topCategoryId,
      limit: this.state.topSelectionId,
      stopName: this.state.stopName,
    }).then(([ok, status, json]) => {
      if (ok) {
        this.setState({
          locationList: json.locationList,
          loading: false,
        });
      } else {
        error("Oops! error occured during fetching rating category list!");
      }
    });
  };

  setValueToState = (field, value) => {
    let state = this.state;
    state[field] = value;
    this.setState(state);
  };

  renderLocationList() {
    let focusArea = this.state.ratingCategoryList.find((item) => {
      return item.id == this.state.focusAreaIdDisplay;
    });
    let catDesc = focusArea ? focusArea.cat_desc : "Overall rating";
    let topSelection = this.state.topSelection.find((item) => {
      return item.value == this.state.topSelectionIdDisplay;
    });
    let topCategory = this.state.topCategory.find((item) => {
      return item.value == this.state.topCategoryIdDisplay;
    });
    let filterHeader = `${catDesc}, ${topSelection.lable || ""} - ${topCategory.lable || ""}`;

    let dateRange = [ 
      this.state.endDateDisplay, 
      this.state.startDateDisplay
    ]
    .filter(Boolean)
    .join(" - ");

    return (
      <div style={{height: 'calc(100vh - 150px)'}}>
        <div className="mt2 f4 ml3">
          {dateRange ? `${filterHeader}, ${dateRange}`: filterHeader}
        </div>
        <div className="p-3 ag-theme-quartz w-100" style={{height: 'calc(100vh - 180px)'}}>
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={this.state.locationList}
            // // events
            onGridReady={this.onGridReady}
            onRowClicked={this.onRowClicked}

            rowSelection={this.state.rowSelection}
            enableColResize
            enableSorting
            enableFilter
            pagination={true}
            paginationAutoPageSize={true}
            >
          </AgGridReact>
        </div>
      </div>
    );
  }

  renderFilterSection() {
    return (
      <div style={{ display: "flex" }}>
        <div className="flex items-center">
          <Tooltip
            css={{br: 0}}
            className="pointer"
            placement= 'leftStart'
            trigger='click'
            content={
              <Filter
                ratingCategoryList={this.state.ratingCategoryList}
                dateRange={this.state.dateRange}
                topSelection={this.state.topSelection}
                topCategory={this.state.topCategory}
                focusAreaId={this.state.focusAreaId}
                dateRangeId={this.state.dateRangeId}
                topSelectionId={this.state.topSelectionId}
                topCategoryId={this.state.topCategoryId}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                stopName={this.state.stopName}
                setValueToState={this.setValueToState}
                handleSubmit={this.handleSubmit}
              />
            }
          >
            <Button
              auto
              ghost
              css={{br: 0}}
              icon={<Filter2 set='light' />}
            >
              <Text color="currentColor" size='1rem'>Filter Results</Text>
            </Button>
          </Tooltip>
        </div>
      </div>
    );
  }

  downloadPDF = () => {

    /**
     * 
     * Generate the location address
     * 
     */

    let locationText = [
      this.state.locationAddress,
      this.state.stopCity,
      this.state.stopState,
      this.state.stopCountry,
      this.state.stopZip,
    ]
      .filter(Boolean)
      .join(", ");

    let dateRange = [ 
      this.state.endDateDisplay, 
      this.state.startDateDisplay
    ]
    .filter(Boolean)
    .join(" - ");

    dateRange = dateRange ? 'Between (' + dateRange + ')' : '';
    var doc = new jsPDF();
    let generatedText = 'Report generated for ' + mstore.get("user", "companyName");

    /**
     * 
     * Generated text
     * 
     */

    doc.setFontSize(12);
    doc.setTextColor(169,169,169);
    doc.setFont("Helvetica","italic");
    doc.text(generatedText, 200, 10, 'right');

    doc.setFont("Helvetica", "bold");

    /**
     * 
     * PDF header.
     * 
     */

    doc.setTextColor(0,0,0);
    doc.setFontSize(16);
    doc.text(10, 20, pdfTitle + '  ' + dateRange);

     /**
     * 
     * Location address
     * 
     */

    doc.setFontSize(12);
    doc.setFont("Helvetica","bold");
    doc.text(10, 30, this.state.locationName);

    doc.setFontSize(9);
    doc.setFont("Helvetica","italic");
    doc.text(10, 35, locationText);

    doc.setFontSize(14);
    doc.setFont("Helvetica","bold");
    doc.text(10, 45, pdfSummaryTitle);

    /**
     * 
     * Location summary table.
     * 
     */
    var res = doc.autoTableHtmlToJson(document.getElementById("loc-analytics-summary"));
    doc.autoTable(res.columns, res.data, {
      startY: 50,
      theme: 'striped',
      headStyles: { fontSize: 10, halign: 'center' },
      bodyStyles: { fontSize: 11, fontStyle: 'italic', overflow: 'linebreak', halign: 'center' },    
      margin: {horizontal: 10},
      pageBreak: 'auto',
      rowPageBreak: 'avoid',
      didParseCell: function (data) {
        if(data.section === 'body'){
          data.cell.styles.fillColor = scale(data.cell.raw.textContent).hex();
        }       
      },
    });

    /**
     * 
     * Driver comments header.
     * 
     */

    let finalY = doc.lastAutoTable.finalY; // The y position on the page
    doc.setFontSize(14);
    doc.text(10, finalY + 10, pdfDetailTitle);
      
    doc.autoTable(pdfColumnDefs, this.state.driverCommentList, {
      startY: finalY + 15,
      theme: 'striped',
      showHead: 'everyPage',
      headStyles: { fontSize: 10, halign: 'center' },
      bodyStyles: { fontSize: 8, fontStyle: 'italic', overflow: 'linebreak', halign: 'center' },
      columnStyles:{
        "comment": {halign:'left'}
      },    
      margin: {horizontal: 10},
      pageBreak: 'auto',
      rowPageBreak: 'avoid',
      didDrawPage: function (data) {
        // Re-set top margin. The change will be reflected only after print the first page.
        data.settings.margin.top = 10; 
      }
    });
    
    addFooters(doc);
    doc.save('mtt_location_analtyics_report.pdf')

  };

  renderDetailsSection() {
    if (this.state.selectedItem) {
      let text = [
        this.state.locationAddress,
        this.state.stopCity,
        this.state.stopState,
        this.state.stopCountry,
        this.state.stopZip,
      ]
        .filter(Boolean)
        .join(", ");
      return (
        <div className="w-100">
          <div className="flex justify-between">
            <div>
              <div className="f4">{this.state.locationName}</div>
              <div className="f5">{text}</div>
            </div>
            <Button
              size='sm'
              auto
              css={{br: 0}}
              icon={<Download set='light' size={20}/>}
              disabled={this.state.disableDownload}
              onClick={this.downloadPDF}
            >
              <Text color='currentColor'>Download PDF</Text>
            </Button>
          </div>

          <table id="loc-analytics-summary"
            className={
              "table ba br2 b--black-10 w-100 mt5"
            }
            style={{ border: "1px solid #c3c3c3" }}
          >
            <thead>
              <tr>
                {this.state.headerArrayElement.map((item, index) => {
                  return (
                    <th key={index} style={{ ...thStyle }}>
                      {item}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                {this.state.ratings.map((item, index) => {
                  return (
                    <td
                      key={index}
                      style={{
                        ...tdStyle,
                        backgroundColor: scale(item).hex(),
                        textAlign: "center",
                      }}
                    >
                      {item}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
          <LocationReport driverCommentList={this.state.driverCommentList} selectedItem={this.state.selectedItem}/>
        </div>
      );
    } else {
      return (
        <div className="f4 mt5">Please select a location from the list</div>
      );
    }
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <div className="flex justify-center mt6">
            <div>
              <Loading size='lg'>Fetching location data</Loading>
            </div>
          </div>
        ) : (
          <div>
            <Row justify='space-between' className="border-y-2 border-dotted p-4">
              <SubPageHeader>Ratings & Location Analytics</SubPageHeader>
              {this.renderFilterSection()}
            </Row>
            <Divider />
            <div className="flex">
              <div className="w-50">{this.renderLocationList()}</div>
              <div className={"w-50 flex flex-column items-center mt2 mr2"}>
                {this.renderDetailsSection()}
              </div>
            </div>
            <div style={{ display: "none" }}>
              <PrintDiv
                headerArrayElement={this.state.headerArrayElement}
                ratings={this.state.ratings}
                driverCommentList={this.state.driverCommentList}
                locationName={this.state.locationName}
                locationAddress={this.state.locationAddress}
                stopCity={this.state.stopCity}
                stopState={this.state.stopState}
                stopCountry={this.state.stopCountry}
                stopZip={this.state.stopZip}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    clientId: state.client ? state.client.id : mstore.getPath(["user", "company_id"]),
    Access: state.navigation.Access
  }
}

export default connect(mapStateToProps)(LocationAnalytics);
