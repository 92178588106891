import { combineReducers } from 'redux'

import navigation from './navigation'
import client from './client'

const reducerCollection = {
  navigation
}

export default combineReducers(reducerCollection)

export const adminReducer = () => {
  return combineReducers({
    client,
    ...reducerCollection
  })
}
