import React, { Component } from "react";
import { Switch } from "@nextui-org/react";
import { secureFetch } from "../../lib/api";
import { success } from "../../lib/userAlerts";

import { mstore } from "../../store";
import endpoint from "../../config/endpoint.json";
import { error } from "../../lib/userAlerts";
import { connect } from "react-redux";
import { Loading } from '@nextui-org/react';

class CrossCompanySettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allowSendMessage: false,
      allowReopenCloseOrder: false,
      allowShareShipmentData: false,
      loading: false,
      loadinge: false,
      addNewCannedMessage: "",
      fetching: true,
    };
  }

  componentDidMount() {
    this.getCrossCompantSettings();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.clientId !== this.props.clientId) {
      this.getCrossCompantSettings();
    }
  }

  getCrossCompantSettings = () => {
    secureFetch(endpoint.fetchCrossCompanySettings, {}).then(
      ([ok, status, json]) => {
        if (ok) {
          if (json.crossCompantSettings.length > 0) {
            this.setState({
              allowSendMessage:
                json.crossCompantSettings[0].allow_brokers_to_send_message === 1
                  ? true
                  : false,
              allowReopenCloseOrder:
                json.crossCompantSettings[0]
                  .allow_brokers_to_reopen_closed_stop === 1
                  ? true
                  : false,
              allowShareShipmentData:
                json.crossCompantSettings[0]
                  .allow_brokers_to_share_shipment_data === 1
                  ? true
                  : false,
              loading: false,
              fetching: false,
            });
          } else {
            this.setState({
              allowSendMessage: false,
              allowReopenCloseOrder: false,
              allowShareShipmentData: false,
              loading: false,
              fetching: false,
            });
          }
        } else {
          this.setState({ fetching: false, loading: false });
          error("Oops! error occured during fetching cross company settings!");
        }
      }
    );
  };

  saveAndUpdateCrossCompanySettings = (
    allowSendMessage,
    allowReopenCloseOrder,
    allowShareShipmentData
  ) => {
    secureFetch(endpoint.saveAndUpdateCrossCompanySettings, {
      allowSendMessage: allowSendMessage ? 1 : 0,
      allowReopen: allowReopenCloseOrder ? 1 : 0,
      allowShareShipmentData: allowShareShipmentData ? 1 : 0,
    }).then(([ok, status, json]) => {
      if (ok) {
        this.getCrossCompantSettings();
        success(json.message);
      } else {
        error(
          "Oops! error occured during save and update cross company settings!"
        );
      }
    });
  };

  render() {
    return (
      <div className="mt2 w-100">
        {this.state.fetching && (
          <div className="flex justify-center mt6">
            <Loading size="md">Fetching cross company settings..</Loading>
          </div>
        )}
        <div className="">
          {this.props.Access.allow_artifax === 1 && !this.state.fetching && (
            <div
              className="ml2 border-2 border-dotted my-4 mr2 p-4"
            >
              <div className="flex justify-between border-b">
                <div className="f4 pb-4">
                  Cross company settings for carriers who ship for brokers
                </div>
              </div>

              <table
                className={"flex flex-column w-100 py-4"}
              >
                <tbody
                >
                  <tr className="flex">
                    <td
                      className="f5 fw2 flex flex-start items-center h3"
                      style={{
                        width: "90%",
                      }}
                    >
                      <div>
                        Allow brokers I do business with to send message to my
                        drivers directly
                      </div>
                    </td>
                    <td
                      className="flex justify-center items-center h3"
                      style={{
                        width: "10%",
                      }}
                    >
                      <Switch
                        checked={this.state.allowSendMessage}
                        size='sm'
                        onChange={() => {
                          this.saveAndUpdateCrossCompanySettings(
                            !this.state.allowSendMessage,
                            this.state.allowReopenCloseOrder,
                            this.state.allowShareShipmentData
                          );
                        }}
                      />
                    </td>
                  </tr>
                  <tr className="flex">
                    <td
                      className="f5 fw2 flex flex-start items-center h3"
                      style={{
                        width: "90%",
                      }}
                    >
                      <div>
                        Allow brokers I do business with to reopen closed stop
                      </div>
                    </td>
                    <td
                      className="flex justify-center items-center h3"
                      style={{
                        width: "10%",
                      }}
                    >
                      <Switch
                        checked={this.state.allowReopenCloseOrder}
                        size='sm'
                        onChange={() => {
                          this.saveAndUpdateCrossCompanySettings(
                            this.state.allowSendMessage,
                            !this.state.allowReopenCloseOrder,
                            this.state.allowShareShipmentData
                          );
                        }}
                      />
                    </td>
                  </tr>
                  <tr className="flex">
                    <td
                      className="f5 fw2 flex flex-start items-center h3"
                      style={{
                        width: "90%",
                      }}
                    >
                      <div>
                        Allow brokers I do business with to share shipment data
                      </div>
                    </td>
                    <td
                      className="flex justify-center items-center h3"
                      style={{
                        width: "10%",
                      }}
                    >
                      <Switch
                        checked={this.state.allowShareShipmentData}
                        size='sm'
                        onChange={() => {
                          this.saveAndUpdateCrossCompanySettings(
                            this.state.allowSendMessage,
                            this.state.allowReopenCloseOrder,
                            !this.state.allowShareShipmentData
                          );
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  Access: state.navigation.Access,
  clientId: state.client
    ? state.client.id
    : mstore.getPath(["user", "company_id"]),
}))(CrossCompanySettings);
