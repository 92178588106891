import React, { Component } from "react";
import { secureFetch, fetchWithJson } from "../../lib/api";
import { isNumber } from "../../lib/utils";
import { success } from "../../lib/userAlerts";
import { mstore } from "../../store";
import endpoint from "../../config/endpoint.json";
import { error, warning } from "../../lib/userAlerts";
import { connect } from "react-redux";
import ReactImageZoom from "react-image-zoom";
import dayjs from '../../lib/day';
import { Loading, Button as NextButton, Text, Row, Card, Divider, Textarea, Input } from '@nextui-org/react';
import { TickSquare, CloseSquare, PaperDownload, Danger, Chat } from 'react-iconly'

const APPROVED = "Approved";
const REJECTED = "Rejected";
const RESET = "New";
const SAVED = "Saved";

class ReimbursableRecipetsDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      receiptsDetails: {},
      Amount: 0,
      Date: "",
      AdoptOCR: false,
      AdoptDriver: false,
      receiptUrl: "",
      localUrl: "",
      fetching: false,
      imageBlob: null,
      comment: ""
    };
    this.zoomRef = null;
  }
  getZoomProps = () => {
    const zoomProps = {
      width: 400,
      height: 500,
      zIndex: 999,
      offset: { vertical: 0, horizontal: -1000 },
      zoomWidth: 500,
      img: this.state.localUrl
    };
    return zoomProps;
  };
  getZoomRef = ref => {
    this.zoomRef = ref;
  };

  componentDidMount() {
    this.fetchDriverReceiptDetails();
  }

  handleInputChangetotal = e => {
    const value = e.target.value;
    if (isNumber(value)) {
      this.setState({ Amount: e.target.value });
    }
  };

  handleInputChangedate = e => {
    this.setState({ Date: e.target.value });
  };

  fetchDriverReceiptDetails = () => {
    this.setState({ fetching: true });
    fetchWithJson(endpoint.dc_fetch_driver_receipt_detail, {
      receipt_id: this.props.currentReceipt.receipt_number
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ receiptsDetails: data, comment: data.comments });
        secureFetch(endpoint.getSignedUrl, { file_key: data.file_key }).then(
          ([ok, status, json]) => {
            if (ok) {
              this.setState({ receiptUrl: json.preSignedUrl, fetching: false });
              /**
               *
               * Get the image from S3
               *
               */
              fetch(json.preSignedUrl, {
                mode: "cors",
                header: {
                  "Access-Control-Allow-Origin": "*"
                }
              })
                .then(response => response.blob())
                .then(images => {
                  let urlCreator = window.URL || window.webkitURL;
                  let imageUrl = urlCreator.createObjectURL(images);

                  this.setState({ localUrl: imageUrl, imageBlob: images });
                })
                .catch(function () {
                  error(" Download Error ");
                });
            } else {
              error("Oops! error occured while fetching the receipt!");
            }
          }
        );
      })
      .catch(err => {
        error("Unknown error occurred");
        console.log(err);
      });
  };

  handleApproved = () => {
    // FIXME: can Amount be both string & number? if not make this strict inequality
    if (Number(this.state.Amount) !== 0 && this.state.Date) {

      let dt_format = dayjs(this.state.Date, "YYYY-MM-DD").format("DD-MMM-YYYY");

      fetchWithJson(endpoint.dc_approve_receipt, {
        receipt_id: this.state.receiptsDetails.receipt_reference,
        amount: this.state.Amount,
        date: this.state.Date,
        comments: this.state.comment
      })
        .then(res => res.json())
        .then(data => {

          success(APPROVED);
          this.props.setStatusOnGrid(this.state.receiptsDetails.receipt_reference, APPROVED, dt_format, this.state.Amount);
          this.props.resetFilter();
          this.setState({receiptsDetails: {...this.state.receiptsDetails, status: APPROVED}});
        })
        .catch(err => {
          error("Error occurred while approving the receipt");
          console.log(err);
        });
    } else {
      alert(
        "Kindly click on Adopt driver or Adopt OCR button first. Or you may fill in the approval amounts and date manually. Then click on the approved button."
      );
    }
  };

  handleReject = () => {
    fetchWithJson(endpoint.dc_reject_receipt, {
      receipt_id: this.state.receiptsDetails.receipt_reference,
      comments: this.state.comment
    })
      .then(res => res.json())
      .then(data => {

        error(REJECTED);
        this.props.setStatusOnGrid(this.state.receiptsDetails.receipt_reference, REJECTED);
        this.props.resetFilter();
        this.setState({receiptsDetails: {...this.state.receiptsDetails, status: REJECTED}});
      })
      .catch(err => {
        error("Error occurred while rejecting the receipt");
        console.error(err);
      });
  };

  handleReset = () => {
    this.setState({ Amount: 0, Date: "" });
    fetchWithJson(endpoint.dc_reset_receipt, {
      receipt_id: this.state.receiptsDetails.receipt_reference
    })
      .then(res => res.json())
      .then(data => {

        warning("Reset");
        this.props.setStatusOnGrid(this.state.receiptsDetails.receipt_reference, RESET);
        this.props.resetFilter();
        this.setState({receiptsDetails: {...this.state.receiptsDetails, status: RESET}});
      })
      .catch(err => {
        error("Error occurred while re-setting the receipt status");
        console.error(err);
      });
  };

  handleSaveReceipt = () => {
    let dt_format = dayjs(this.state.Date, "YYYY-MM-DD").format("DD-MMM-YYYY");

    fetchWithJson(endpoint.dc_save_receipt, {
      receipt_id: this.state.receiptsDetails.receipt_reference,
      amount: this.state.Amount,
      date: this.state.Date,
      comments: this.state.comment
    })
      .then(res => res.json())
      .then(data => {

        success(SAVED);
        this.fetchDriverReceiptDetails();
        this.props.setStatusOnGrid(this.state.receiptsDetails.receipt_reference, this.state.receiptsDetails.status, dt_format, this.state.Amount);
        this.props.resetFilter();
      })
      .catch(err => {
        error("Error occurred while saving the receipt updates");
        console.log(err);
      });
  };


  downloadImage = () => {
    let filename =
      "receipt_file_" +
      Math.random()
        .toString(36)
        .substr(2, 5) +
      ".jpg";
    let newBlob = new Blob([this.state.imageBlob], {
      type: "application/octet-stream"
    });

    if (typeof window.navigator.msSaveBlob !== "undefined") {
      window.navigator.msSaveBlob(newBlob, filename);
      return;
    }

    // Other browsers
    // Create a link pointing to the ObjectURL containing the blob

    const blobURL = window.URL.createObjectURL(newBlob);
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.

    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(blobURL);
    }, 100);
  };

  render() {
    return (
      <div>
        {this.state.fetching ? (
          <div className="flex justify-center mt6">
            <div>
              <Loading size="lg">Fetching the receipt details..</Loading>
            </div>
          </div>
        ) : (
            <div className="ml4 mt2 mr4 flex flex-row">
              <div className="w-3/5">
                <Card 
                  bordered
                  shadow={false}
                  css={{ w: "100%", br: 0 }}>
                    <Card.Header>
                      <Row justify="space-between">
                        <Text 
                          css={{
                            bc: `${this.state.receiptsDetails.status === REJECTED ? '$red100' : '$gray100'}`, p: 10
                          }}
                          size="1.2rem" 
                          b>
                          {this.state.receiptsDetails.status}
                        </Text>

                        <Text 
                          css={{
                            p: 10,
                            color: '$gray600'
                          }}
                          size="1.2rem" 
                          weight="semibold"
                          >
                          Order: {this.state.receiptsDetails.order_id}, Stop Sequence: {this.state.receiptsDetails.order_stop_seq}
                        </Text>
                      </Row>
                    </Card.Header>
                    <Divider/>
                    <Card.Body css={{ py: '$10' }}>
                      <table
                        className={"striped mt4 w-100"}
                        style={{ border: "1px solid #c3c3c3" }}
                      >
                        <thead className="f5">
                          <tr style={{ backgroundColor: "#d8ecf9" }}>
                            <th style={{ padding: "20px" }} />
                            <th style={{ padding: "20px" }}>OCR Data</th>
                            <th style={{ padding: "20px" }}>Driver Data</th>
                            <th style={{ padding: "20px" }}>Match Status</th>
                          </tr>
                        </thead>
                        <tbody style={{ border: "1px solid #c3c3c3" }} className="f5">
                          <tr style={{ border: "1px solid #c3c3c3" }}>
                            <td style={{ padding: "20px" }}>
                              <div>Date</div>
                            </td>
                            <td style={{ padding: "20px" }}>
                              <div>{this.state.receiptsDetails.ocr_date}</div>
                            </td>
                            <td style={{ padding: "20px" }}>
                              <div>{this.state.receiptsDetails.employee_date}</div>
                            </td>
                            <td style={{ padding: "20px" }}>
                              {this.state.receiptsDetails.date_match === "Y" ? (
                                <TickSquare set='light' color='green' />
                              ) : (
                                <Danger set='light' color='red' /> 
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ padding: "20px" }}>
                              <div>Total Amount</div>
                            </td>
                            <td style={{ padding: "20px" }}>
                              <div>$ {this.state.receiptsDetails.ocr_total}</div>
                            </td>
                            <td style={{ padding: "20px" }}>
                              <div>$ {this.state.receiptsDetails.employee_total}</div>
                            </td>
                            <td style={{ padding: "20px" }}>
                              {this.state.receiptsDetails.total_match === "Y" ? (
                                <TickSquare set='light' color='green' />
                              ) : (
                                <Danger set='light' color='red' /> 
                                )}
                            </td>
                          </tr>

                          <tr>
                            <td style={{ padding: "20px" }}>
                              <div>Confidence Level</div>
                            </td>
                            <td style={{ padding: "20px" }}>
                              <div>{this.state.receiptsDetails.ocr_confidence}</div>
                            </td>
                            <td style={{ padding: "20px" }}>
                              <div />
                            </td>
                            <td style={{ padding: "20px" }}>
                              <div />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Card.Body>
                    <Divider/>
                    <Card.Footer>
                      <Row justify="space-between">
                        <Text
                          size="1.2rem"
                          css={{
                            p: 10,
                            color: '$gray700'
                          }}
                          weight="medium"
                        >
                          Driver: {this.state.receiptsDetails.driver}
                        </Text>
                        <div className="flex">
                          <NextButton
                            auto
                            css={{br:0, mr: 20 }}
                            onClick={() => {
                              this.setState({
                                AdoptOCR: true,
                                Amount: this.state.receiptsDetails.ocr_total,
                                Date: this.state.receiptsDetails.ocr_date
                                  ? dayjs(
                                    this.state.receiptsDetails.ocr_date,
                                    "DD-MMM-YYYY"
                                  ).format("YYYY-MM-DD")
                                  : ""
                              });
                            }}
                          >
                            <Text color="currentColor">Adopt OCR Data</Text>
                          </NextButton>
                          <NextButton
                            auto
                            css={{br:0 }}
                            onClick={() => {
                              this.setState({
                                AdoptDriver: true,
                                Amount: this.state.receiptsDetails.employee_total,
                                Date: dayjs(
                                  this.state.receiptsDetails.employee_date,
                                  "DD-MMM-YYYY"
                                ).format("YYYY-MM-DD")
                              });
                            }}
                          >
                            <Text color="currentColor">Adopt Driver Data</Text>
                          </NextButton>
                        </div>
                      </Row>
                    </Card.Footer>
                </Card>

                <div className="mt3 flex flex-wrap justify-end">
                  <span className="text-xl py-6 font-semibold">Your Input</span>
                  <div className="px-4">
                    <label htmlFor="" className="f5 fw6" style={{width: '75px'}}>
                      Amount
                    </label>
                    <div className="">
                      <Input
                        type='number'
                        placeholder={`Amount`}
                        onChange={this.handleInputChangetotal}
                        css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
                        shadow={false}
                        bordered={true}
                        rounded={false}
                        value={this.state.Amount}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="" className="f5 fw6">
                      Date
                    </label>
                    <div className="">
                      <Input
                        type='date'
                        placeholder={`Date`}
                        onChange={this.handleInputChangedate}
                        css={{ $$inputBorderRadius: "0", flexGrow: 1, h: 30, mt: 5 }}
                        shadow={false}
                        bordered={true}
                        rounded={false}
                        value={this.state.Date}
                      />
                    </div>
                  </div>

                  <div className="w-100 mt3" style={{alignItems: 'center'}}>
                    <label htmlFor="" className="f5 fw6" style={{width: '75px'}}>
                      Comments
                    </label>
                    <div className="w-100 mt2 mb3">
                      <Textarea 
                        fullWidth
                        bordered
                        rows={4}
                        css={{w: "100%", $$inputBorderRadius: "0" }} 
                        value={this.state.comment} 
                        onChange={(e) => {
                          if (e.target.value.length < 2000) {
                            this.setState({ comment: e.target.value })
                          }
                        }}
                      />
                    </div>
                  </div>

                </div>

                <div className="flex justify-between w-100">
                  <NextButton
                    size='sm'
                    auto
                    color='success'
                    css={{br:0}}
                    onClick={this.handleApproved}
                    icon={<TickSquare fill="currentColor" />}
                  >
                      <Text color="currentColor">Save & Approve</Text> 
                  </NextButton>
                  <NextButton
                    size='sm'
                    auto
                    color='error'
                    css={{br:0, ml: 10}}
                    onClick={this.handleReject}
                    icon={<Danger fill="currentColor" />}
                  >
                    <Text color="currentColor">Save & Reject</Text> 
                  </NextButton>
                  <NextButton
                    size='sm'
                    auto
                    ghost
                    css={{br:0, color: '$gray600', ml: 10, borderColor: '$gray600' }}
                    onClick={this.handleReset}
                    icon={<CloseSquare fill="currentColor" />}
                  >
                    <Text color="currentColor">Reset status</Text> 
                  </NextButton>   
                  <NextButton
                    size='sm'
                    auto
                    ghost
                    css={{br:0, ml: 10}}
                    onClick={this.handleSaveReceipt}
                    icon={<Chat fill="currentColor"/>}
                  >  
                    <Text color="currentColor">Save Comments</Text> 
                  </NextButton>
                  <NextButton
                    size='sm'
                    auto
                    ghost
                    css={{br:0, ml: 10, mr: 10}}
                    onClick={this.downloadImage}
                    icon={<PaperDownload fill="currentColor" />}
                  >
                    <Text color="currentColor">Download Receipt</Text> 
                  </NextButton>
                  <NextButton
                    size='sm'
                    auto
                    ghost
                    css={{br:0, color: '$gray600', borderColor: '$gray600' }}
                    onClick={this.props.closeAction}
                  >
                    <Text color="currentColor">Close</Text> 
                  </NextButton>
                </div>
              </div>
              <div
                className="ml4 bg-near-white w-2/5 flex justify-center items-center render-top"
              >
                {this.state.localUrl ? (
                  <ReactImageZoom {...this.getZoomProps() } />
                ) : null}
              </div>
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    clientId: state.client ? state.client.id : mstore.getPath(["user", "company_id"]),
    Access: state.navigation.Access
  }
}

export default connect(mapStateToProps)(ReimbursableRecipetsDetails);
