// @flow
import { SET_CLIENT, SET_CLIENT_LIST, CLEAR_SESSION } from '../action/constants'

export type Client = {
  id: string,
  name: string,
  email: string,
  active: boolean,
  address: string,
  city: string,
  state: string,
  zip: string,
  country: string,
  primary_contact: string
}

type State = {
  id: string,
  name: string,
  loadedClients: Array<Client>
}

const emptyString = ''

const defaultState: State = {
  id: emptyString,
  name: emptyString,
  loadedClients: []
}

type ActionType = SET_CLIENT | SET_CLIENT_LIST | CLEAR_SESSION

export default function client(state: State = defaultState, action: { type: ActionType, payload: any }) {
  switch (action.type) {
    case CLEAR_SESSION:
      return defaultState
    case SET_CLIENT:
      // we trust action.payload
      return { loadedClients: state.loadedClients, ...action.payload }
    case SET_CLIENT_LIST:
      return { ...state, loadedClients: action.payload }
    default:
      return state
  }
}
