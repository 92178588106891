// @flow
import React from 'react';
import { Row, Text, Tooltip, Button } from '@nextui-org/react';
import { User, Logout, Home } from 'react-iconly';
import { OpenMenu } from '../icons/OpenMenu';
import { CloseMenu } from '../icons/CloseMenu';

const TopMenu = (params) => {
  return(
    <div style={{width: 200, padding: 0}}>
      <Button auto light onClick={(e)=>{e.preventDefault(); window.location.href='/products/select';}}>
          <Home fill="currentColor" set="light" />
          <Text size={16} css={{pl: 10}}>Home</Text>
      </Button>
      <Button light auto onClick={params.onLogOut}>
          <Logout fill="currentColor" set="light" />
          <Text size={16} css={{pl: 10}}>Logout</Text>
      </Button>
    </div>
  )
}

export default function Header({ leftTitle, centerTitle, rightItem, name, isNavOpen, onNavShow, onNavHide, onLogOut }) {

  return (
    <Row css={{p: '$4'}} id="NavBar" className="is-sticky bg-gray-100 border-b">
      <div className="mr3 mt2">
        {isNavOpen === true ? (
          <span style={{cursor: 'pointer'}} onClick={onNavHide}><CloseMenu width={32} height={32} fill='currentColor'/></span>
        ) : <span style={{cursor: 'pointer'}} onClick={onNavShow}><OpenMenu width={32} height={32} fill='currentColor'/></span>}
      </div>
      {leftTitle}
      <div className="w-3/5 flex-1 tc ttu f3 text-gray-700 mt2">
        <div className="">
          {centerTitle}
        </div>
      </div>
      {rightItem}
      <Tooltip 
        css={{br: 0}}
        placement="bottom" 
        trigger='click'
        content={<TopMenu onLogOut={onLogOut}/>}>
        <div className="flex mt2 mr2">        
          <span className='px-2 py-1 text-gray-700 fw6 text-lg'>{name}</span>          
          <span className='gray-700 text-primary'> <User size='large' set="light" filled /> </span>
        </div>
      </Tooltip>
    </Row>
  );
}
