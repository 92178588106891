import { NAV_OPEN, NAV_CLOSE, SET_ACCESS } from './constants';

export function navShow() {
  return { type: NAV_OPEN }
}

export function navHide() {
  return { type: NAV_CLOSE }
}

export function setAccess(access) {
  return { type: SET_ACCESS, payload: access }
}
