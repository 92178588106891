import React from 'react'
import { connect } from 'react-redux'
import { setCurrentClient } from '../action/client'

const ClientSelector = ({ clientList, client_id, name, onClientChange }) => {
  if(!Array.isArray(clientList) || clientList.length === 0 ){
    return (
    <div className="client-selector">
      Loading client list...
    </div>
    )
  }
  return (
    <div className="client-selector p-2 border-t">
      <select
        title={name}
        className="select-sm black my-2"
        value={client_id}
        onChange={onClientChange}
      >
        {clientList.map(client => (
          <option key={client.id} value={client.id}>{client.name}</option>
        ))}
      </select>
    </div>
  )
}

export default connect(state => ({
  clientList: state.client && state.client.loadedClients,
  client_id: state.client && state.client.id,
  name: state.client.name
}), dispatch => {
  return {
    onClientChange(evt) {
      dispatch(setCurrentClient({
        name: evt.target.selectedOptions[0].text,
        id: evt.target.value
      }))
    }
  }
})(ClientSelector)
