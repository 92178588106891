import React, { Component } from "react";

import LogoImage from "../../assets/no_image_placeholder.jpg";
import { Textarea, Text, Button } from "@nextui-org/react";
import { CloseSquare } from "react-iconly";

export default class LinkPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div
        className="flex w-100 bg-white mt3 ba b--black-20"
        style={{ height: 200, borderRadius: 10 }}
      >
        <img
          style={{
            height: "100%",
            width: 200,
            objectFit: "cover",
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10
          }}
          className="br b--black-20"
          src={this.props.ogImageUrl || LogoImage}
        />
        <div className="ml3 pv2 flex flex-column justify-between" style={{ width: 'calc(100% - 240px)' }}>
          <div className="f5">{this.props.ogTitle || "No title available"}</div>
            <Textarea 
              readOnly 
              minRows={5}
              css={{ br: 0 }} 
              initialValue={this.props.ogDescription || 'No description available'}/>

          <div className="f6">     
              { this.props.link && 
                (<a role="button" className="f5" target="_blank" rel="noopener noreferrer" href={this.props.link} 
                    style={{display: "block", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", textAlign: "left"}}>
                     <Text ellipsize={true}>
                     {this.props.link}
                    </Text>
                </a>)
              }
              { !this.props.link && 
                  <Text ellipsize={true}>
                      {'No link available'}
                  </Text>
              }
          </div>
        </div>
        {this.props.showClose && (
          <CloseSquare
            set='bold'
            onClick={this.props.closePreviewLink}
          />
        )}
      </div>
    );
  }
}
