import React, { Component } from "react";
import { Loading, Text } from '@nextui-org/react';
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { warning as warnNotif } from "../../lib/userAlerts";

import {
  noop,
  snakeCaseObj,
  makeCancelable,
  handleCancelablePromiseErr,
} from "../../lib/utils";

import {
  isUniqueEmail,
  VALID_EMAIL_REGEX
} from "../../lib/api";
import LogoImage from '../../assets/mtt.png';

const RequireLabel = props => (
    <span className="label-text">{props.lblName} <span className="fw8" style={{ color: 'red' }}>*</span></span>
);

export default class ContactDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isActive: true,
      address: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      primaryContact: "",
      email: "",
      email_free: true,
      phoneNo: "",
      logoUrl: false,
      companyUniqueId: "",
      useEmployeesDriver: 1,
      isBroker: 1
    };
    this.selectedFile = null;
    this.lastIsValidCanceller = noop;
    this.savedEmail = "";
    this.access = null;
   }

  setGAddress = (address) => {
    this.setState({ address });

    geocodeByAddress(address)
    .then((results) => {
      const address_components = results[0].address_components;
      const cityObj = address_components.find(
        (a) => a.types.indexOf("locality") !== -1
      );
      const countryObj = address_components.find(
        (a) => a.types.indexOf("country") !== -1
      );
      const zipObj = address_components.find(
        (a) => a.types.indexOf("postal_code") !== -1
      );
      const stateObj = address_components.find(
        (a) => a.types.indexOf("administrative_area_level_1") !== -1
      );
      this.setState({
        country: countryObj ? countryObj.long_name : this.state.country,
        city: cityObj ? cityObj.long_name : "Not Available",
        zipCode: zipObj ? zipObj.long_name : "Not Available",
        state: stateObj ? stateObj.long_name : "Not Available",
      });
    })
    .catch(()=>{
        ;;
    });
  };

  setCity = (e) => {
    this.setState({ city: e.target.value });
  };

  setUserState = (e) => {
    this.setState({ state: e.target.value });
  };

  setZIP = (e) => {
    const zipCode = e.target.value;
    if (/^\d*$/.test(zipCode)) this.setState({ zipCode });
  };

  setCountry = (e) => {
    this.setState({ country: e.target.value });
  };

  setPrimaryContact = (e) => {
    this.setState({ primaryContact: e.target.value });
  };

  setEmailAddress = (e) => {
    const email = e.target.value;

    this.setState({ email });
    this.props.updateEmail(email);

    if (VALID_EMAIL_REGEX.test(email) && email !== this.savedEmail) {
      this.lastIsValidCanceller();
      // TODO: use abortSignal of latest FetchAPI
      const { promise, cancel } = makeCancelable(isUniqueEmail(email));
      this.lastIsValidCanceller = cancel;

      promise
        .then((isUnique) =>
          this.setState({
            email_free: isUnique,
          })
        )
        .catch(handleCancelablePromiseErr);
    }
  };

  handleSave = async () => {
    if (this.$form) {
      this.$form.reportValidity();
      if (this.$form.checkValidity() === false) return;
    }
    if (!VALID_EMAIL_REGEX.test(this.state.email)) {
      return warnNotif("Please enter a valid email");
    }
    if (this.state.zipCode.length !== 5) {
      return warnNotif("Please enter a valid Zipcode");
    }
    this.setState({ loading: true });
    let info = snakeCaseObj(this.state);
    delete info.loading;
    try {
      if (this.props.onSave) {
        if ((await this.props.onSave(info)) === false) {
          this.setState({ loading: false });
        }
      }
      this.savedEmail = this.state.email;
    } catch (ex) {
      this.setState({ loading: false });
    }
  };

  componentWillUnmount() {
    this.selectedFile = null;
    this.lastIsValidCanceller();
  }

  render() {
    const placeOption = {
        types: ["address"],
        componentRestrictions: { country: "us" },
    };

    const placeInputProps = {
        value: this.state.address,
        onChange: this.setGAddress,
        required: true,
    };

    const placeCssClasses = {
        root: "w-100",
        input: "h-10 border mt-1 rounded px-4 w-full",
        autocompleteContainer: "ba bw1 rounded",
    };

    if(this.state.loading){
      return <Loading css={{mt: 200, ml: 200}} size='lg'/>
    }

    return (
      <div className="p-6 bg-gray-100 flex items-center justify-center">
        <div className="container max-w-screen-lg mx-auto">      
            <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
              <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                <div className="text-gray-600">
                  <p className="font-medium text-lg">Contact Details</p>
                  <p>
                    We do not share your information with anyone else. 
                    For more details, please see our <a className="link link-info" rel="noreferrer" href="https://www.mytruckin.tech/mtt-site-privacy" target="_blank">privacy policy</a> .
                  </p>
                  <img
                    alt="login pic"
                    className="login-content-image lg:ml-6 lg:my-16"
                    style={{width: 120, objectFit: 'contain'}}
                    src={LogoImage}
                    />
                </div>
      
                <div className="lg:col-span-2">
                  <div className="grid gap-4 gap-y-2 sm:grid-cols-1 md:grid-cols-5">
                    <div className="md:col-span-5">
                      <RequireLabel lblName="Full Name" /> 
                      <input 
                        type="text" 
                        name="full_name" 
                        id="full_name" 
                        className="h-10 border mt-1 rounded px-4 w-full" 
                        onChange={this.setPrimaryContact}
                        value={this.state.primaryContact}
                        required
                        />
                    </div>
      
                    <div className="md:col-span-5">
                      <RequireLabel lblName="Email Address" /> 
                      <input 
                        id="email" 
                        onChange={this.setEmailAddress}
                        value={this.state.email}
                        type="email"
                        name="email"
                        className={
                          'h-10 border mt-1 rounded px-4 w-full' +
                          (this.state.email_free ? '' : ' input-error')
                        }
                        required
                        placeholder="email@domain.com" />
                    </div>

                    {!this.state.email_free &&
                      <div className="md:col-span-5">
                        <Text css={{mt:5}} color='red' size={12}>Email address is already used. Please use another email.</Text>
                      </div>
                    }
      
                    <div className="md:col-span-5">
                        <RequireLabel lblName="Address" />
                        <PlacesAutocomplete
                            classNames={placeCssClasses}
                            inputProps={placeInputProps}
                            debounce={500}
                            options={placeOption}
                        />
                    </div>

                    <div className="md:col-span-3">
                      <label for="city">City</label>
                      <input 
                        required
                        type="text" 
                        name="city" 
                        id="city" 
                        className="h-10 border mt-1 rounded px-4 w-full" 
                        onChange={this.setCity}
                        value={this.state.city}
                        placeholder="" />
                    </div>
            
                    <div className="md:col-span-1">
                      <RequireLabel lblName="Zip Code" />
                      <input 
                        onChange={this.setZIP}
                        value={this.state.zipCode}
                        type="text" 
                        name="zipcode" 
                        id="zipcode" 
                        className="transition-all flex items-center h-10 border mt-1 rounded px-4 w-full" 
                        placeholder="" 
                        required
                      />
                    </div>

                    <div className="md:col-span-2">
                      <RequireLabel lblName="Country" />
                      <select 
                        onChange={this.setCountry}
                        value={this.state.country}
                        className="select select-bordered select-sm h-10 mt-1 rounded  w-full">
                        <option disabled selected>Select Country</option>
                        <option>USA</option>
                        <option>India</option>
                      </select>
                    </div>
                    
                    <div className="md:col-span-2">
                      <label for="city">Phone Number</label>
                      <PhoneInput
                        containerClass="transition-all flex items-center h-10 border mt-1 rounded w-full"
                        country={"us"}
                        prefix={'+'}
                        inputStyle={{ height: '100%' }}
                        preferredCountries={["us", "in"]}
                        value={this.state.phoneNo}
                        onChange={(phone) => {
                            this.setState({ phoneNo: phone });
                        }}
                      />
                    </div>

                    <div className="md:col-span-5 text-right mt-4">
                      <div className="inline-flex items-end">
                        {
                          this.state.loading 
                          ? <span className="loading loading-spinner text-primary"></span>
                          : 
                            <div>
                              <button onClick={this.props.onCancel} className="btn bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2"> Cancel </button>
                              <button onClick={this.handleSave} className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"> Next </button>
                            </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}
