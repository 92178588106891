import { history } from "../history";
import { setAccess } from "../action/navigation";
import { fetchWithJson } from "./api";
import { store } from "../store";
import endpoint from "../config/endpoint.json";

const ERRORSTRING = 'Oops! error encountered when we tried to fetch your product access settings.'

/**
 *  
 *  Reset access 
 * 
 */

const noCompany = companyId => {
    store.dispatch(
        setAccess({
            allow_artifax: 0,
            allow_dc_access: 0,
            allow_location_analytics: 0,
            allow_location_info: 0,
            allow_osd: 0,
            allow_ratings: 0,
            allow_ratings_artifax: 0,
            allow_receipt_management: 0,
            allow_safety_documents: 0,
            allow_broker_carrier_driver_gps: 0,
            allow_broker_carrier_shipment_document: 0,
            allow_broker_push_notification: 0,
            company_id: companyId,
        })
    );
}

/**
 *  
 *  Reset access and navigate to the dispatch page?
 * 
 */

const noAccess = companyId => {
    history.push("/driver/shipment-dispatch");
    noCompany(companyId);
}

const checkAccess =  async (companyId) => {

    if(!companyId){
        noCompany();
        return
    }

    try {
        const accessRes = await fetchWithJson(endpoint.dc_app_access, {
            company_id: companyId,
        });

        if (accessRes.status !== 200) {
            noAccess();
            throw new Error(ERRORSTRING)
        }

        const data = await accessRes.json();
        store.dispatch(setAccess(data));
    } 
    catch (err) {
        console.log(err);
        noAccess();
        throw new Error(ERRORSTRING);
    }
  }

  export default checkAccess;