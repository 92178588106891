// Constants

const actionName = process.env.NODE_ENV === 'production' ? name => Symbol(name) : name => name

export const NAV_OPEN = actionName('NAV_OPEN')
export const NAV_CLOSE = actionName('NAV_CLOSE')

export const CLEAR_SESSION = actionName('CLEAR_SESSION')
export const SET_CLIENT = actionName('SET_CLIENT')
export const SET_CLIENT_LIST = actionName('SET_CLIENT_LIST')
export const SET_ACCESS = actionName('SET_ACCESS')

// END CONSTANTS
