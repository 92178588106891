// @flow

import { history, basename } from '../../history'

// TODO: Make a bit safe API
export function makeUrl(url, isSuperAdmin = false, client_id = '1') {
  if(!client_id)
    client_id = 1
  if (isSuperAdmin) {
    if (url.indexOf('?') === -1) {
      url += '?client_id=' + client_id
    } else {
      url += '&client_id=' + client_id
    }
    if (!(url.startsWith('/api/admin/')|| url.startsWith('http'))) {
      url = '/api/admin' + url.substr(4)
    }
  }
  // FIXME:
  if (basename !== '/'  && !url.startsWith('http')) {
    url = basename + url
  }
  return url
}

export function processResponse(res) {
  if (res.status === 401 && history.location.pathname !== '/login') {
    setTimeout(() => {
      window.location.href = '/login' 
    }, 100)
    throw Error("Not logged in")
  } else {
    if (res.ok && (res.type.startsWith('application/json') || res.type === "basic")) {
      return res.json().then(json => [res.ok, res.status, json])
    } else {
      return res.json().then(json => [res.ok, res.status, res, json])
    }
  }
}
