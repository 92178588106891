import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";

const createColumnDefs = () => {
  return [
    {
      headerName: "Date",
      field: "createdAt",
      width: 15,
    },
    {
      headerName: "Driver 1",
      field: "driver1_name",
      width: 15,
    },
    {
      headerName: "Driver 2",
      field: "driver2_name",
      width: 15,
    },
    {
      headerName: "Comments",
      field: "comment",
      width: 55,
      cellClass: "cell-wrap-text",
      autoHeight: true
    },
  ];
};

class LocationReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: createColumnDefs(),
            selectedItem: false,
            locationList: [],
            driverCommentList: [],
        };
    }

    componentDidMount(){
        if (this.props.driverCommentList && this.props.driverCommentList.length > 0) {
            this.setState({
                driverCommentList: this.props.driverCommentList,
                selectedItem: this.props.selectedItem
            })
        }
    }

    componentWillReceiveProps(newProps) {        
        this.setState({
            driverCommentList: newProps.driverCommentList,
            selectedItem: newProps.selectedItem
        })
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
    };

    render() {
        if (this.state.selectedItem)
            return (
                <div style={{ height: "500px" }} className={"ag-theme-quartz mt5"}>
                      <AgGridReact
                      // properties
                      columnDefs={this.state.columnDefs}
                      rowData={this.state.driverCommentList}
                      // // events
                      onGridReady={this.onGridReady}
                      enableColResize
                      enableSorting
                      enableFilter
                      ></AgGridReact>
                </div>
            );
            else {
                return (
                    <div className="f4 mt5">Please select a location from the list</div>
                );
            }
    }
}

export default LocationReport;
