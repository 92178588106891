import React, { Component } from "react";
import { Button, Input, Loading, Text } from "@nextui-org/react";

import FeatureSelection from "../ProductSelection/FeatureSelection";
import { mstore } from "../../store";
import { connect } from "react-redux";
import endpoint from "../../config/endpoint.json";
import { success, error } from "../../lib/userAlerts";
import { secureFetch } from "../../lib/api";


class GPSInterval extends Component {
  constructor(prorps) {
    super(prorps);
    this.state = {
      range: 0,
      pingInterval: 0,
      updating: false
    };
  }

  componentDidMount() {
    this.fetchGeofenceRange();
    this.fetchPingInterval();
  }

  saveGeofenceRange = () => {
    this.setState({updating: true})
    secureFetch(endpoint.saveGeofenceRange, {
      range: this.state.range,
    }).then(([ok, status, json]) => {
      if (ok) {
        success(json.message);
        this.fetchGeofenceRange();
        this.setState({updating: false})
      } 
      else {
        this.setState({updating: false})
        error(
          "Oops! error occured during save geofence range for this company"
        );
      }
    });
  };

  savePingInterval = () => {
    if (10 > this.state.pingInterval || this.state.pingInterval > 300) {
      error(
        "GPS ping interval should be greater than 10 seconds and less than 300 seconds"
      );
      return;
    }
    secureFetch(endpoint.savePingInterval, {
      pingInterval: this.state.pingInterval,
    }).then(([ok, status, json]) => {
      if (ok) {
        success(json.message);
        this.fetchPingInterval();
      } else {
        error(
          "Oops! error occured during save geofence range for this company"
        );
      }
    });
  };

  fetchGeofenceRange = () => {
    secureFetch(endpoint.fetchGeofenceRange, {}).then(([ok, status, json]) => {
      if (ok) {
        this.setState({ range: json.geofanceRange[0].geofence_range });
      } else {
        error(
          "Oops! error occured during fetch geofence range for this company"
        );
      }
    });
  };

  fetchPingInterval = () => {
    secureFetch(endpoint.fetchPingInterval, {}).then(([ok, status, json]) => {
      if (ok) {
        this.setState({
          pingInterval: json.pingInterval[0].gps_refresh_interval,
        });
      } else {
        error(
          "Oops! error occured during fetch geofence range for this company"
        );
      }
    });
  };

  setRange = (e) => {
    let value = e.target.value
    this.setState({ range: value });
  };

  setPingInterval = (value) => {
    this.setState({ pingInterval: value });
  };

  render() {

    if(this.props.Access.allow_location_info !== 1){
      return(
        <div role="alert" className="alert alert-warning w-1/2 mt-16 ">
          <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="white" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
          <span className="">Please subscribe to the <b>Location Information</b> feature</span>
        </div>
      )
    }

    return (
      <div className="w-100 flex flex-row">
        <div className="ml2 mt3 w-1/2 p-4 border-2 border-dotted">
          <div className="f4 mb3">GPS Range</div>
          <div className="flex flex-row justify-between items-center">
            <div className="f5">Geofence range in miles</div>
            <Input
              type='number'
              underlined
              css={{w: '100px'}}
              min={0}
              max={20}
              defaultValue={0}
              value={this.state.range}
              onChange={this.setRange}
            />
          </div>
          <div className="flex justify-end mt-4">
            <Button
              size='sm'
              auto
              css={{br:0}}
              onClick={this.saveGeofenceRange}>
              {this.state.updating ? <Loading color='primary' type='points' /> : <Text color='currentColor'>Update GPS Range</Text>}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  Access: state.navigation.Access,
  clientId: state.client
    ? state.client.id
    : mstore.getPath(["user", "company_id"]),
}))(GPSInterval);
