import React, { Component } from 'react'
import { connect } from "react-redux";
import { Loading } from '@nextui-org/react';

import { mstore } from "../../store";
import checkAccess from '../../lib/checkAccess';
import endpoint from "../../config/endpoint.json";
import { error } from "../../lib/userAlerts";
import { fetchWithJson } from "../../lib/api";

import FeatureSelection from "./FeatureSelection";

class FeatureManagement extends Component {
    state = {  
        fetching: true,
        rawfeatureList: [],
        featureList: [],
        pageText: {}
    } 

    componentDidMount() {
        this.fetchFeatureList();
        this.fetchPageText();
    }

    sortFeaturesList = () => {
        let filteredList = {};
        this.state.rawfeatureList.forEach((item) => {
            let k = item['cat_desc']

            if(filteredList.hasOwnProperty(k)){
                filteredList[k].push(item)
            }
            else{
                filteredList[k] = [item];
            }
        });

        this.setState({ featureList: filteredList });
    }

    fetchFeatureList = () => {
        this.setState({fetching: true})
        fetchWithJson(endpoint.settingsProductFeatures, {
          company_id: this.props.clientId,
        })
        .then((res) => res.json())
        .then((data) => {
            this.setState({ rawfeatureList: data }, () => {
                // Process feature list
                this.sortFeaturesList()
            });
        })
        .catch((err) => {
            error("Unable to fetch feature list");
            console.log(err);
        })
        .finally(()=>{
            this.setState({fetching: false})
            checkAccess(this.props.clientId);
        });
    };

    fetchPageText = () => {
        fetchWithJson(endpoint.subscriptionPageText)
        .then((res) => res.json())
        .then((data) => {
            this.setState({pageText: data})
        })
        .catch((err) => {
            error("Unable to page header text");
            console.log(err);
        })
    };

    settingsFeatureUpdate = (obj) => {
        this.setState({fetching: true})

        fetchWithJson(endpoint.settingsFeatureUpdate, {
            company_id: this.props.clientId,
            feature_id: obj.feature_id,
            is_enabled: obj.is_feature_active === 1 ? 0 : 1,
        })
        .then((res) => res.json())
        .then((data) => {
             this.fetchFeatureList();
        })
        .catch((err) => {
            this.setState({fetching: false})
            console.log(err)
        })
    };

    handleSwitch = (obj) => {
        if ( obj.feature_id === 1004 && obj.is_feature_active === 1 && this.props.Access.allow_osd === 1) {
            error(
              "Please turn off the OS&D feature before disabling the Shipment documents feature."
            );
            this.setState({ fetching: false});
            return;
        }
    
        if ( obj.feature_id === 1007 && obj.is_feature_active === 0 && this.props.Access.allow_artifax === 0 ) {
            error(
              "Please turn on the documents setting feature before enabling the OS&D feature."
            );
            this.setState({ fetching: false});
            return;
        }
    
        if ( obj.feature_id === 1013 && obj.is_feature_active === 0 && this.props.Access.allow_broker_shipment_management === 0 ) {
            error(
              "Please enable shipment management features first. GPS tracking cannot be enabled when shipment management is disabled."
            );
            this.setState({ fetching: false});
            return;
        }
    
        if ( obj.feature_id === 1016 && obj.is_feature_active === 1 && this.props.Access.allow_broker_carrier_driver_gps === 1 ) {
            error(
              "Please disable GPS tracking features first. GPS tracking cannot remain enabled when shipment management is disabled."
            );
            this.setState({ fetching: false});
            return;
        }
          
        this.settingsFeatureUpdate(obj)
    }

    render() { 

        if(this.state.fetching){
            return (
                <Loading className='p-32' size='lg'>Fetching fetaure list</Loading>
            );
        }

        return (
            <div className="p-16 bg-gray-100">
                <div className='card w-full bg-base-100 shadow-lg'>
                    <div className="card-body">
                        <div className='pb-4 text-2xl font-semibold'>{this.state.pageText?.sub_page_title}</div>
                        <p className='text-slate-800'>{this.state.pageText?.sub_page_details}</p>
                    </div>
                </div>
                {
                    Object.keys(this.state.featureList).map(key => {
                        const features = this.state.featureList[key];
                        return(
                            <div key={JSON.stringify(features)} className='pt-8'>
                                <div className='pb-4 text-2xl font-semibold'>{key}</div>
                                <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4 pb-4">
                                    <FeatureSelection
                                        featureList={features}
                                        handleSwitch={this.handleSwitch}
                                    />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

const mapStateToProps = function(state) {
    return {
      clientId: state.client ? state.client.id : mstore.getPath(["user", "company_id"]),
      Access: state.navigation.Access
    }
}
  
export default connect(mapStateToProps)(FeatureManagement);
 