import React, { Component } from "react";
import { Button, Loading, Text, Textarea, Modal } from '@nextui-org/react';
import { Delete } from 'react-iconly'

import endpoint from "../../config/endpoint.json";
import { success, error } from "../../lib/userAlerts";
import { secureFetch } from "../../lib/api";

export default class LocationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // driverComment: '',
      note: this.props.selectedLocation.locationNotes
        ? this.props.selectedLocation.locationNotes
        : "",
      addressBackgroundColor: "yellow",
      googleMatchReview: "",
      action: "",
      latitide: 0,
      longitude: 0,
      googleAddress: "",
      override: false,
      submitting: false,
      deleting: false,
      visible: false
    };
  }

  componentDidMount() {
    if (this.props.selectedLocation.locationStatusId === 5) {
      this.setState({ addressBackgroundColor: "green", action: "verified" });
    } else if (this.props.selectedLocation.locationStatusId === 6) {
      this.setState({ addressBackgroundColor: "red", action: "incorrect" });
    } else if (this.props.selectedLocation.locationStatusId === 7) {
      this.setState({ addressBackgroundColor: "blue", action: "override" });
    } else {
      this.setState({ addressBackgroundColor: "yellow", action: "unverified" });
    }

    this.setAddress();
  }

  setAddress = () => {
    if (
      !this.props.selectedLocation.locationStatusId ||
      this.props.selectedLocation.locationStatusId === 1 ||
      this.props.selectedLocation.locationStatusId === 2
    ) {
      this.checkAddress();
    } else {
      this.setState({
        googleAddress: this.props.selectedLocation.gAddress,
        latitide: this.props.selectedLocation.oLat
          ? this.props.selectedLocation.oLat
          : this.props.selectedLocation.gLat,
        longitude: this.props.selectedLocation.oLong
          ? this.props.selectedLocation.oLong
          : this.props.selectedLocation.gLong,
      });
    }
  };

  checkAddress = () => {
    let statusId = this.props.selectedLocation.locationStatusId;

    let address = `${this.props.selectedLocation.address},${this.props.selectedLocation.city},${this.props.selectedLocation.state},${this.props.selectedLocation.country},${this.props.selectedLocation.zip}`;

    secureFetch(endpoint.checkAddress, { address }).then(
      ([ok, status, json]) => {
        if (ok) {
          let body = JSON.parse(json.gAddressDetails);
          if (body.results.length === 0) {
            this.setState({
              googleMatchReview: "| Consider updating your source system",
              googleAddress: "Not available",
              latitide: "Not available",
              longitude: "Not available",
            });
            this.updateGoogleAddress(null);
          } else if (body.results[0].partial_match) {
            this.setState({
              googleMatchReview: "| Partial match in google",
              googleAddress: body.results[0].formatted_address,
              latitide: body.results[0].geometry.location.lat,
              longitude: body.results[0].geometry.location.lng,
            });
            this.updateGoogleAddress(body.results[0]);
          } else {
            this.setState({
              googleMatchReview: "| Complete match in google",
              googleAddress: body.results[0].formatted_address,
              latitide: body.results[0].geometry.location.lat,
              longitude: body.results[0].geometry.location.lng,
            });
            this.updateGoogleAddress(body.results[0]);
          }
        } else {
          error("Oops! error occured during fetch address from google");
        }
      }
    );
  };

  updateGoogleAddress = (googleResponse, isReset) => {
    let gbody = {
      gAddress: "",
      gCity: "",
      gState: "",
      gZip: "",
      gCountry: "",
      gLat: 0,
      gLong: 0,
      locationMatchStatusId: isReset ? 1 : 2,
      stopId: this.props.selectedLocation.stopId,
    };

    if (googleResponse) {
      gbody.gAddress = googleResponse.formatted_address;
      gbody.gLat = googleResponse.geometry.location.lat;
      gbody.gLong = googleResponse.geometry.location.lng;
      gbody.locationMatchStatusId = googleResponse.partial_match ? 4 : 3;

      googleResponse.address_components.forEach((element) => {
        if (element.types[0] === "administrative_area_level_2") {
          gbody.gCity = element.short_name;
          return;
        }
        if (element.types[0] === "administrative_area_level_1") {
          gbody.gState = element.short_name;
          return;
        }
        if (element.types[0] === "country") {
          gbody.gCountry = element.short_name;
          return;
        }
        if (element.types[0] === "postal_code") {
          gbody.gZip = element.short_name;
          return;
        }
      });
    }

    secureFetch(endpoint.updateGoogleAddress, gbody).then(
      ([ok, status, json]) => {
        if (ok) {
          success(json.message);
          if (isReset) {
            this.checkAddress();
          }
        } else {
          error("Oops! error occured during update google address");
        }
      }
    );
  };

  updateGoogleAddressStatus = (oLat, oLong, statusId) => {
    let statusBody = {
      oLat: oLat,
      oLong: oLong,
      locationMatchStatusId: statusId,
      stopId: this.props.selectedLocation.stopId,
    };

    secureFetch(endpoint.updateGoogleAddressStatus, statusBody).then(
      ([ok, status, json]) => {
        if (ok) {
          success(json.message);
        } else {
          error("Oops! error occured during update address status");
        }
      }
    );
  };

  locationVerifySection = () => {
    let addressBackgroundColor = "";
    if (this.state.addressBackgroundColor === "red") {
      addressBackgroundColor = "bg-light-red mt2 pa2 w-90";
    } else if (this.state.addressBackgroundColor === "green") {
      addressBackgroundColor = "bg-light-green mt2 pa2 w-90";
    } else if (this.state.addressBackgroundColor === "blue") {
      addressBackgroundColor = "bg-light-blue mt2 pa2 w-90";
    } else {
      addressBackgroundColor = "bg-light-yellow mt2 pa2 w-90";
    }
    let text = [
      this.props.selectedLocation.address,
      this.props.selectedLocation.city,
      this.props.selectedLocation.state,
      this.props.selectedLocation.country,
      this.props.selectedLocation.zip,
    ]
      .filter(Boolean)
      .join(", ");
    return (
      <div className="flex flex-column w-50">
        <div className="flex items-center w-90">
          <div className="f3">{this.props.selectedLocation.stopId}</div>
          <div className="f5 ml2">{text}</div>
        </div>
        {this.state.override ? (
          <div className="mt2 pa2 w-90 flex ba">
            <div className="w-50">
              <div className="flex items-center">
                <div className="w-40 f5">Latitude</div>
                <input
                  type="number"
                  placeholder="Enter latitude"
                  onChange={(e) => {
                    this.setState({ latitide: e.target.value });
                  }}
                  style={{ width: "50%" }}
                  value={this.state.latitide}
                />
              </div>
              <div className="flex items-center mt2">
                <div className="w-40 f5">Longitude</div>
                <input
                  type="number"
                  placeholder="Enter longitude"
                  onChange={(e) => {
                    this.setState({ longitude: e.target.value });
                  }}
                  style={{ width: "50%" }}
                  value={this.state.longitude}
                />
              </div>
            </div>
            <div className="w-50">
              Be careful! Drivers will receive information over their phone when
              they get close to the coordinates you enter!
            </div>
          </div>
        ) : (
          <div className={addressBackgroundColor}>
            <div className="f5">
              Google Match - <span className="fw6"> {this.state.action} </span> {this.state.googleMatchReview}
            </div>
            <div className="f5">{this.state.googleAddress}</div>
            <div className="f5">
              Latitide: {this.state.latitide} | Longitude:{" "}
              {this.state.longitude}
            </div>
          </div>
        )}

        <div className="flex items-center mt2">
          <Button
            auto
            ghost
            color='success'
            css={{br: 0, mr:10}}
            onClick={() => {
              this.updateGoogleAddressStatus(null, null, 5);
              this.setState({
                override: false,
                addressBackgroundColor: "green",
                action: "verified",
              });
            }}
          >
            Verify
          </Button>
          <Button
            auto
            ghost
            color="error"
            css={{br:0, mr:10}}
            onClick={() => {
              this.updateGoogleAddressStatus(null, null, 6);
              this.setState({
                override: false,
                addressBackgroundColor: "red",
                action: "incorrect",
              });
            }}
          >
            Incorrect Match
          </Button>
          <Button
            auto
            ghost
            css={{br:0, mr:10}}
            onClick={() => {
              if (this.state.override) {
                this.updateGoogleAddressStatus(
                  this.state.latitide,
                  this.state.longitude,
                  7
                );
              }
              this.setState({ override: true, action: "verified" });
            }}
          >
            Override GPS Location
          </Button>
          <Button
            auto
            ghost
            color="secondary"
            css={{br:0, mr:10}}
            onClick={() => {
              this.updateGoogleAddress(null, true);
              this.setState({
                override: false,
                addressBackgroundColor: "yellow",
                action: "unverified",
              });
            }}>
              Reset
          </Button>
        </div>
      </div>
    );
  };

  deleteReview = (value) => {
    this.setState({ deleting: true });
    secureFetch(endpoint.updateDriverNotes, {
      status: 3,
      noteId: value.id,
    }).then(([ok, status, json]) => {
      if (ok) {
        success(json.message);
        this.setState({ deleting: false , visible: false });
        this.props.deleteCommentFromState(value);
      } else {
        this.setState({ deleting: false, visible: false });
        error("Oops! error occured during delete review!");
      }
    });
  };

  updateDriverLocationNote = () => {
    this.setState({ submitting: true });
    secureFetch(endpoint.updateDriverLocationNote, {
      note: this.state.note,
      stopId: this.props.selectedLocation.stopId,
    }).then(([ok, status, json]) => {
      if (ok) {
        this.setState({ submitting: false });
        success(json.message);
      } else {
        this.setState({ submitting: false });
        error("Oops! error occured during update location note!");
      }
    });
  };

  toggleDeleteVisibility = () => {
    this.setState({visible: true})
  }

  locationInfoSection = () => {
    let driverNotes = JSON.parse(this.props.selectedLocation.comments);
    return (
      <div className="flex">
        <div className="w-50">
          <div className="f3 mt2">Driver Comments To Add</div>
          <div
            style={{ height: 500 }}
            className="bg-light-gray flex flex-column f6 pa2 mt2 overflow-x-scroll"
          >
            {driverNotes ? (
              driverNotes.map((item, index) => {
                return (
                  <div
                    key={item.id}
                    style={{ width: "100%" }}
                    className="flex h4 bg-white br2 items-center pa3 mt2"
                  >
                    <div className="f6" style={{ width: "90%" }}>
                      {item.driverNote}
                    </div>
                    <div
                      style={{ width: "10%" }}
                      className="flex justify-center items-center"
                    >
                      <Button
                        auto
                        color='error'
                        css={{br: 0}}
                        iconSize={20}
                        onClick={this.toggleDeleteVisibility}
                        icon={<Delete fill="currentColor"/>}
                      />
                      <Modal
                        closeButton
                        blur
                        css={{br: 0}}
                        open={this.state.visible}
                        position='right'
                      >
                        <Modal.Header>
                          <Text id="modal-title" size={18}>
                            Delete this Driver Comment
                          </Text>
                        </Modal.Header>
                        <Modal.Body>
                          <Text b size={14}>Please confirm if you want to delete this comment? This action is irreversible.</Text>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button 
                            auto
                            css={{br: 0}}
                            color='error'
                            onClick={this.deleteReview.bind(null, item)}>
                              {this.state.deleting ? ( <Loading type="points" color="white" size="md" /> ) : ('YES ')}
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="f5 ">Comments not available</div>
            )}
          </div>
        </div>
        <div className="w-50 pl3">
          <div className="flex justify-between items-center">
            <div className="f3">Location Info</div>
          </div>
          <Textarea
            bordered 
            fullWidth 
            css={{br:0, mt: 10, br: 0}}
            rows={10}
            placeholder="Enter your comment"
            onChange={(e) => {
              this.setState({ note: e.target.value });
            }}
            value={this.state.note}
          />
          <div className="flex justify-end f6 mt2 pa2 w-100">
            <Button
              auto
              ghost
              css={{br:0, mr: 10}}
              className="ba w4"
              onClick={() => {
                this.setState({ override: false });
                this.props.toggleShowDetails();
              }}
            >
              Close
            </Button> 
            <Button
              auto
              css={{br: 0}}
              onClick={this.updateDriverLocationNote}
            >
              {this.state.submitting ? <Loading type="points" color="white" size="md"/> :'Submit'}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="pa3 w-100">
        <div className="w-100">
          <div>{this.locationVerifySection()}</div>
          <div className="bb mt3 mb2"></div>
          <div>{this.locationInfoSection()}</div>
        </div>
      </div>
    );
  }
}
