import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import debounce from 'lodash/debounce';

import { updateTitle } from '../../lib/utils';
import {
  /*notify,*/ success,
  error,
  warning
} from '../../lib/userAlerts';
import { secureFetch, fetchAuthed } from '../../lib/api';
import endpoint from '../../config/endpoint';

import Header from './Header';
import UserManagement from './UserManagement';
import PaymentInformation from './PaymentInformation';
import AccountDetails from './AccountDetails';
import Products from './Product';
import FeatureManagement from './FeatureManagement';

export default class ProductSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountDetails: { loading: true },
      products: null
    };
    this.toCancel = [];
    this.updateProducts = debounce(products => {
      secureFetch(endpoint.updateSubscription, { products })
        .then(async ([ok, status, json]) => {
          if ('json' in json) {
            json = await json.json();
          }
          if (ok) {
            this.fetchDetail()
            success(json.message)
          } else {
            error(json.message)
          }
          // (ok ? success : error)(json.message);
        })
        .catch(err => {
          console.error(err);
          error('Opps! Network error. Please try again.');
        });
    }, 700);
  }

  componentDidMount() {
    updateTitle('ProductSelection');
    if (this.props.location.pathname === '/products') {
      this.props.history.replace('/products/select');
    }
    return this.fetchDetail();
  }

  fetchDetail = () => {
    return secureFetch(endpoint.getCompanyDetails)
      .then(([ok, status, company]) => {
        if (ok) {
          sessionStorage.setItem('truckinPortal', company.products[0].is_active ? 1 : 0)
          sessionStorage.setItem('useEmployeesDriver', company.use_employees_driver)
          company.zip_code = company.zip;
          company.name = company.client_name;
          company.companyUniqueId = company.company_unique_id;
          const products = company.products;
          delete company.products;

          this.setState({
            accountDetails: company,
            products: { products }
          });
        } else {
          company.json().then(err => {
            error(err.message);
            console.error(err);
          });
        }
      })
      .catch(console.error);
  };

  componentWillUnmount() {}

  updateAccountDetails = accountDetails => {
    return secureFetch(endpoint.updateUser1, accountDetails)
      .then(([ok, status, payload]) => {
        if (ok) {
          success(payload.message);
          this.fetchDetail();
        } else {
          return payload.json().then(err => {
            error(err.message);
            console.error(err);
            return false;
          });
        }
        return false;
      })
      .catch(ex => {
        error('Network request failed. Please Try again!');
        console.error(ex);
        return false;
      });
  };

  handleLogoUpload = (file, email) => {
    const uploadPromise = fetchAuthed(
      endpoint.exstCompanyLogoUpload + '?email=' + email,
      {
        method: 'POST',
        headers: { 'content-type': file.type },
        body: file
      }
    );

    const self = this;
    return {
      sub(handlers) {
        handlers.start();
        uploadPromise
          .then(res => res.json())
          .then(j => {
            if (j.error) {
              warning('Falied to upload logo!');
              console.error(j);
              throw j;
            }
            self.setLogoUrl(j.s3_logo_url);
          })
          .then(args => {
            handlers.end(args);
          });
        uploadPromise.catch(handlers.error);
      }
    };
  };

  render() {
    return (
      <div className="w-100 bg-gray-100 h-full min-h-screen">
        <ToastContainer />
        <Header />
        <div>
          <Switch>
            <Route
              key="select"
              path="/products/select"
              component={props => (
                <Products
                  key="select"
                  {...props}
                  initialState={this.state.products}
                  onSelectionChange={this.updateProducts}
                  showLogin={true}
                />
              )}
            />
            <Route
              key="account"
              path="/products/account"
              component={props => (
                <AccountDetails
                  key="account"
                  saveBtnNm="Update Customer Details"
                  initialDetails={this.state.accountDetails}
                  onSave={this.updateAccountDetails}
                  logoUrl={this.state.accountDetails.s3_logo_url}
                  uploadCb={this.handleLogoUpload}
                  className="ml2 mt2"
                  setUrlCB={cb => {
                    this.setLogoUrl = cb;
                  }}
                />
              )}
            />
            <Route
              key="payment"
              path="/products/payment"
              component={PaymentInformation}
            />
            <Route
              key="user"
              path="/products/user"
              component={UserManagement}
            />
            <Route
              key="feature"
              path="/products/feature"
              component={FeatureManagement}
            />
          </Switch>
        </div>
      </div>
    );
  }
}
