import React, { Component } from "react";
import { Button, Tooltip, Text } from '@nextui-org/react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { fetchWithJson } from "../lib/api";
import checkAccess from '../lib/checkAccess';

import { updateTitle } from "../lib/utils";
import { error, success } from "../lib/userAlerts";
import { mstore } from "../store";
import endpoint from "../config/endpoint.json";
import { connect } from "react-redux";
import { Document } from "react-iconly";

class Developer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "ASDFGHJKLVN",
      visible: false,
      apiKey: "",
      secretKey: "",
      sdnKey:"",
      keyRegenerate: false,
      keyAge: 0,
      keyStatus: "",
      clientId: 0
    };
  }

  componentDidMount() {
    
    updateTitle("Driver Details");
    checkAccess(this.props.clientId);
      
    this.setState({clientId:this.props.clientId});
    this.getApiKey();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.clientId !== this.props.clientId) {
      checkAccess(newProps.clientId);
      this.getApiKey();
    }
  }
    
  getApiKey = () => {
    this._makeAPICall (endpoint.get_API_KEY) 
  };

  regenerateApiKey = () => {
    this._makeAPICall (endpoint.regenerate_API_KEY) 
  };

  _makeAPICall = (url) => {
    
    //
    // Convert the undefineds to false.
    //

    let isCometControl = this.props.isCometControl ? true : false;

    fetchWithJson(url, { company_id: this.state.clientId, is_comet_control: isCometControl  })
      .then(res => res.json())
      .then(data => {
        this.setState({
          apiKey: data.api_key,
          secretKey: data.api_secret,
          keyAge: data.key_age,
          keyRegenerate: data.key_regenerate,
          keyStatus: data.key_status,
          sdnKey: data.sdn_key
        });
      })
      .catch(err => {
        error("Oops! error occured. We are unable to fetch the API Key at this time.");
      });
  }

  render() {
    const clientId = mstore.get("user", "company_id");
    return (
      <div className="ml3 mt3">

        <div className="f4">
          <h4 b className={this.state.keyRegenerate ? "red font-semibold" : "black"}>It's been {this.state.keyAge} Day(s) since the API key was last generated. </h4>
            <b>My Truckin' Tech</b> recommends generating a new API key <b>once every 90 days</b>.
        </div>

        <div className="flex">

            <table
              style={{width: '95%'}}
              className={
                this.state.keyRegenerate
                  ? "mt4 ba b--light-red "
                  : "mt4 ba b--silver"
              }
            >
              <tr>
                <td className="f4" style={{ padding: "20px" }}>
                  Customer Key
                </td>
                <td
                  className="f6"
                  style={{
                    padding: "20px",
                    paddingLeft: "10px",
                    paddingRight: "0"
                  }}
                >
                  <div className="ba overflow-hidden pa1 b--gray">
                    {this.state.visible
                      ? this.state.apiKey
                      : `***********************************************************************************************${this.state.apiKey.substr(
                          76
                        )}`}
                  </div>
                </td>
                <td style={{ paddingLeft: "10px" }}>
                  <Tooltip content={"Click to Copy API Key"} rounded color="primary">
                    <CopyToClipboard text={this.state.apiKey} onCopy={this.onCopy}>
                      <Button
                        size='sm'
                        ghost
                        auto
                        css={{br:0}}
                        icon={<Document/>}
                        onClick={()=>{
                          success('Copied API Key')
                        }}
                      />
                    </CopyToClipboard>
                  </Tooltip>
                </td>
              </tr>

              <tr>
                <td className="f4" style={{ padding: "20px" }}>
                  Customer Secret
                </td>
                <td
                  className="f6"
                  style={{
                    padding: "20px",
                    paddingLeft: "10px",
                    paddingRight: "0"
                  }}
                >
                  <div className="overflow-hidden ba pa1 b--gray">
                    {this.state.visible
                      ? this.state.secretKey
                      : `***********************************************************************************************${this.state.secretKey.substr(
                          76
                        )}`}
                  </div>
                </td>
                <td style={{ paddingLeft: "10px" }}>
                  <Tooltip content={"Click to Copy Secret Key"} rounded color="primary">
                    <CopyToClipboard
                      text={this.state.secretKey}
                      onCopy={this.onCopy}
                    >
                      <Button
                        size='sm'
                        ghost
                        auto
                        css={{br:0}}
                        icon={<Document/>}
                        onClick={()=>{
                          success('Copied Secret Key')
                        }}
                      />
                    </CopyToClipboard>
                  </Tooltip>

                </td>
              </tr>

              <tr>
                <td className="f4" style={{ padding: "20px" }}>
                  SDN API Key
                </td>
                <td
                  className="f6"
                  style={{
                    padding: "20px",
                    paddingLeft: "10px",
                    paddingRight: "0"
                  }}
                >
                  <div className="overflow-hidden ba pa1 b--gray">
                    {this.state.visible
                      ? this.state.sdnKey
                      : `***********************************************************************************************${this.state.sdnKey.substr(
                          44
                        )}`}
                  </div>
                </td>
                <td style={{ paddingLeft: "10px" }}>
                  <Tooltip content={"Click to Copy SDN Key"} rounded color="primary">
                    <CopyToClipboard
                      text={this.state.sdnKey}
                      onCopy={this.onCopy}
                    >
                      <Button
                        size='sm'
                        ghost
                        auto
                        css={{br:0}}
                        icon={<Document/>}
                        onClick={()=>{
                          success('Copied SDN Key')
                        }}
                      />
                    </CopyToClipboard>
                  </Tooltip>

                </td>
              </tr>

            </table>
        
        </div>

        <div className="flex space-between mt3">
          <Button
            css={{br : 0}}
            auto
            color='primary'
            onClick={this.regenerateApiKey}
          >
            Generate new API key
          </Button>

          {this.state.visible ? (
            <Button
              css={{br:0, ml: 10}}
              ghost
              color='secondary'
              onClick={() => {
                this.setState({ visible: false });
              }}
            >
              Hide Keys
            </Button>
          ) : (
            <Button
              css={{br:0, ml: 10}}
              color='secondary'
              ghost
              onClick={() => {
                this.setState({ visible: true });
              }}
            >
              Show Keys
            </Button>
          )}
        </div>

      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    clientId: state.client ? state.client.id : mstore.getPath(["user", "company_id"]),
    Access: state.navigation.Access
  }
}

export default connect(mapStateToProps)(Developer);