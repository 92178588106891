import React, { Component } from "react";
import { Button, Input, Loading, Text, Textarea } from "@nextui-org/react";
import { Activity, CloseSquare, Document, Image2, PaperUpload } from "react-iconly";

import LinkPreview from "./LinkPreview";
import endpoint from "../../config/endpoint.json";
import { fetchWithJson, fetchAuthed } from "../../lib/api";
import { error, success, warning } from "../../lib/userAlerts";
import { mstore } from "../../store";
import LogoImage from "../../assets/doc_placeholder.png";

const MAX_TEXT_SIZE = 1000;

function getFileKey(name) {
  return Math.random().toString(32).substring(2).toUpperCase() + "_" + name;
}
export default class PostUploadModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ogImageUrl: "",
      ogTitle: "",
      ogDescription: "",
      link: "",
      postType: [],
    };
  }

  componentDidMount() {
    this.fetchPostType();
    this.fetchUrlMetadata(this.props.attachedLink);
  }

  fetchPostType = () => {
    fetchWithJson(endpoint.fetchPostType, {})
      .then((res) => res.json())
      .then((data) => {
        this.setState({ postType: data.result });
      })
      .catch((err) => {
        error("Something went wrong during fetch posts type");
        console.log(err);
      });
  };

  uploadDocument = (key, file, originalName, isPic) => {
    fetchAuthed(endpoint.signedNewsFeedDocumentUploadUrl + "?key=" + key, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((j) => {
        if (j.error) {
          warning("Failed to upload file!");
          console.error(j);
          throw j;
        }

        fetch(j.s3_logo_url, {
          method: "PUT",
          headers: {
            "content-type": "application/octet-stream",
          },
          body: file, // param #3
        });

        if (isPic) {
          let fileList = this.props.attachedPics;
          fileList.push({
            id: Math.random(),
            url: URL.createObjectURL(file),
            name: key,
            originalName: originalName,
            size: Math.round(file.size / 1024, 2),
          });
          this.props.setValueInState("uploadImageName", key);
          this.props.setValueInState("attachedPics", fileList);
        } else {
          let fileList = this.props.attachedDoc;
          fileList.push({
            id: Math.random(),
            url:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTPQEilGdtC0M-LvN_9otOWT0hauBrKfAy_Xg&usqp=CAU",
            name: key,
            originalName: originalName,
            size: Math.round(file.size / 1024, 2),
          });
          this.props.setValueInState("uploadDocName", key);
          this.props.setValueInState("attachedDoc", fileList);
        }
      })
      .catch((err) => {
        error("Oops! Could't upload documents :(");
      });
  };

  fetchUrlMetadata = (attachedLink) => {
    fetchWithJson(endpoint.fetchUrlMetadata, { url: attachedLink })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          ogTitle: data.metadata ? data.metadata.ogTitle || "" : "",
          ogDescription: data.metadata ? data.metadata.ogDescription || "" : "",
          ogImageUrl: data.metadata
            ? data.metadata.ogImage
              ? data.metadata.ogImage[0]
                ? data.metadata.ogImage[0].url
                : data.metadata.ogImage.url
              : ""
            : "",
          link: attachedLink,
        });
        this.props.setValueInState(
          "ogTitle",
          data.metadata ? data.metadata.ogTitle || "" : ""
        );
        this.props.setValueInState(
          "ogDescription",
          data.metadata ? data.metadata.ogDescription || "" : ""
        );
        this.props.setValueInState(
          "ogImageUrl",
          data.metadata
            ? data.metadata.ogImage
              ? data.metadata.ogImage[0]
                ? data.metadata.ogImage[0].url
                : data.metadata.ogImage.url
              : ""
            : ""
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          ogImageUrl: "",
          ogDescription: "",
          ogImageUrl: "",
          link: attachedLink,
        });
        this.props.setValueInState("ogTitle", "");
        this.props.setValueInState("ogDescription", "");
        this.props.setValueInState("ogImageUrl", "");
      });
  };

  picPreview = (pic) => {
    return (
      <div
        className="flex w-100 bg-white mt3 ba b--black-20 justify-between"
        style={{ height: 120, borderRadius: 10 }}
      >
        <img
          style={{
            height: 120,
            width: 140,
            objectFit: "cover",
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
          }}
          src={pic.url}
        />
        <div className="ml3 w-70 pv2">
          <div className="f4">{pic.originalName}</div>
          <div className="f5 mt3">
            {pic.size ? `${pic.size} kb` : "Not available"}
          </div>
        </div>
        <CloseSquare 
          set="bold" 
          size={28}
          onClick={() => {
            this.props.removeAttachedPic(pic);
          }}
        />
      </div>
    );
  };

  docPreview = (doc) => {
    return (
      <div
        className="flex w-100 bg-white mt3 ba b--black-20 justify-between"
        style={{ height: 120, borderRadius: 10 }}
      >
        <img
          style={{
            height: 120,
            width: 140,
            objectFit: "cover",
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
          }}
          src={LogoImage}
        />
        <div className="ml3 w-70 pv2">
          <div className="f4">{doc.originalName}</div>
          <div className="f5 mt3">
            {doc.size ? `${doc.size} kb` : "Not available"}
          </div>
        </div>
        <CloseSquare
          set="bold" 
          size={28}
          onClick={() => {
            this.props.removeAttachedDoc(doc);
          }}
        />
      </div>
    );
  };

  render() {
    const companyId = mstore.get("user", "company_id");
    return (
      <div className="pa3 overflow-y-scroll">
        <Textarea
          bordered
          onChange={(e) => {
            let str = e && e.target && e.target.value ? e.target.value.slice(0, MAX_TEXT_SIZE) : ''
            this.props.setValueInState("postText", str);
          }}
          width='100%'
          css={{br: 0}}
          placeholder="What do you want to talk about?"
          value={this.props.postText}
          defaultValue={this.props.postText}
          minRows={5}
          maxRows={10}
        />
        <div className="mt3 mb3 flex justify-between items-center">
          <Button
            flat 
            auto
            icon={<Activity set='light'/>}
            css={{br: 0}}
            onClick={this.props.toggleAddLink}
          >
            <Text color='currentColor'>Add a Link</Text>
          </Button>
          <Button
            flat
            icon={<Image2 set='light' />} 
            auto
            css={{br: 0}}
            onClick={this.props.toggleUploadImage}
          >
            <Text color='currentColor'>Add an Image</Text>
          </Button>
          <Button
            flat
            icon={<Document set='light' />} 
            auto
            css={{br: 0}}
            onClick={this.props.toggleUploadDoc}
          >
            <Text color='currentColor'>Add a Document</Text>
          </Button>
          <div>{this.props.postText.length}/{MAX_TEXT_SIZE}</div>
        </div>
        {this.props.showAddLink && (
          <Input
            clearable
            underlined
            fullWidth
            value={this.props.attachedLink}
            initialValue="Add link here"
            labelPlaceholder="Add link here"
            css={{mt: 30, mb: 10}}
            onChange={(e) => {
              console.log(e.target.value)
              this.props.setValueInState("attachedLink", e.target.value);
            }}
            onConfirm={this.fetchUrlMetadata}
          />
        )}
        {this.props.uploadImage && (
          <div className="mt3 flex">
            <label>
              <span className="flex">
                <PaperUpload size={32}/>
                <Text size={12} i css={{mt: 10, ml:5}}>
                  {this.props.uploadImageName}
                </Text>
              </span>
              <input
                onChange={(e) => {
                  if (e.target.files[0].type.split("/")[0] === "image") {
                    let key = getFileKey(e.target.files[0].name);
                    this.uploadDocument(
                      key,
                      e.target.files[0],
                      e.target.files[0].name,
                      true
                    );
                  } else {
                    error("Please select a image");
                  }
                }}
                type="file"
              />
            </label>
          </div>
        )}
        {this.props.uploadDoc && (
          <div className="mt3 flex">
            <label>
              <span className="flex">
                <PaperUpload size={32}/>
                <Text size={12} i css={{mt: 10, ml:5}}>
                  {this.props.uploadDocName}
                </Text>
              </span>
              <input
                onChange={(e) => {
                  if (e.target.files[0].type.split("/")[0] === "image") {
                    error("Please select a document");
                    return;
                  }

                  let key = getFileKey(e.target.files[0].name);
                  this.uploadDocument(
                    key,
                    e.target.files[0],
                    e.target.files[0].name,
                    false
                  );
                }}
                type="file"
              />
            </label>
          </div>
        )}

        {companyId === 1 && !this.props.showMyPost && (
          <select
            className="w-100 db h2 f6 bg-white ba b--sliver"
            value={this.props.postTypeId}
            onChange={(e) => {
              this.props.setValueInState("postTypeId", e.target.value);
            }}
          >
            <option selected value="0">
              Select post type...
            </option>
            {this.state.postType.map((item) => {
              return <option value={item.id}>{item.post_type_desc}</option>;
            })}
          </select>
        )}

        {this.props.showPreviewLinkBlock && this.props.attachedLink && (
          <LinkPreview
            showClose={true}
            closePreviewLink={this.props.closePreviewLink}
            ogTitle={this.state.ogTitle}
            ogImageUrl={this.state.ogImageUrl}
            ogDescription={this.state.ogDescription}
            link={this.state.link}
          />
        )}
        <div>
          {this.props.attachedPics.map((item) => {
            return this.picPreview(item);
          })}
          {this.props.attachedDoc.map((item) => {
            return this.docPreview(item);
          })}
        </div>
        <div className="flex mt3 w-100 justify-end items-center">
          <Button
            size='lg'
            auto
            css={{br: 0}}
            disabled={this.props.postDeleting}
            onClick={this.props.triggerUploadAndUpdate}
          >
            {
              this.props.postUploading ? 
                <Loading type='points' color='white' /> : 
                <Text color='white'>{this.props.showMyPost ? "Submit" : "Post"}</Text>
            }
          </Button>
        </div>
      </div>
    );
  }
}
