// @flow
import { createStore, compose, applyMiddleware } from 'redux'
// import thunk from 'redux-thunk'
import rootReducer, { adminReducer } from './reducer'
import { setClientList, setCurrentClient } from './action/client'
import { secureFetch } from './lib/api'
import endpoints from './config/endpoint.json'

const composeEnhanced = (
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

// let middleware = [ thunk , logger ]
let middleware = []
export const store = composeEnhanced(applyMiddleware(...middleware))(createStore)(rootReducer)

export const mstore = (function () {
  var _keyValMap = {};
  return {
    get: (...keyPath) => {
      let value = _keyValMap
      let i = 0
      const N = keyPath.length
      while (i < N && value !== undefined) {
        value = value[keyPath[i]]
        ++i
      }
      return value
    },
    getPath: (pathArray: Array<String>, defaultValue: mix = ''): mix => {
      let result = _keyValMap;
      for (var i = 0, n = pathArray.length; i < n; i++) {
        result = result[pathArray[i]]
        if (result === undefined) {
          return defaultValue;
        }
      }
      return result;
    },
    set: (key, value) => {
      _keyValMap[key] = value;
    },
    keys: () => Object.keys(_keyValMap),
    remove: (key) => {
      delete _keyValMap[key];
    }
  }
})();


export const loadClientListConditionally = userObj => {
  if (userObj.isSuperAdmin === true) {
    store.replaceReducer(adminReducer())
    secureFetch(endpoints.listAllUser)
      .then(([ok, status, json]) => {
        if (ok) {
          store.dispatch(setClientList(json))
          if(json.length > 0){
            store.dispatch(setCurrentClient({ 
              id:  json[0].id,
              name:  json[0].name,              
            }))
          }
        } else {
          json.json && json.json().then(msg => console.error('Couldn\'t load user list', ok, status, msg))
          !json.json && console.error(ok, status, json)
        }
      })
  }
}

window.$$store = store
window.$store = mstore; //ForDebug
