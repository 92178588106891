import React, { Component } from "react";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { mstore } from "../../store";
import { connect } from "react-redux";
import { Loading, Modal, Text, Button} from '@nextui-org/react';

import ManageArtifacts from "../DriverConnect/ManageArtifacts";
import ShipmentCustomFields from "../DriverConnect/ShipmentCustomFields";
import GPSInterval from "../DriverConnect/GPSInterval";
import SafetyDocumentType from "../DriverConnect/safetyDocumentType";
import AutoClose from "../DriverConnect/AutoClose";
import ReceiptArchiveDays from "../DriverConnect/ReceiptArchiveDays";
import NewsFeed from "../DriverConnect/NewsFeedSettings";

import CannedMessageSettings from "../DriverConnect/CannedMessageSettings";
import AutoLinkSettings from "../DriverConnect/AutoLinkSettings";
import DetentionManagement from "../DriverConnect/DetentionManagement";
import { fetchWithJson } from "../../lib/api";
import endpoint from "../../config/endpoint.json";
import { error } from "../../lib/userAlerts";
import dayjs from '../../lib/day';
import checkAccess from '../../lib/checkAccess';

var companyId;
class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTabId: 0,
      fetching: true,
      featureList: [],
      rawfeatureList: [],
      wantToDesableFeature: false,
      currentFeature: {},
      disablingFeature: false
    };
  }

  componentDidMount() {
    checkAccess(this.props.clientId);
    this.fetchFeatureList();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.clientId !== this.props.clientId) {
      checkAccess(newProps.clientId);
      this.fetchFeatureList();
    }
  }

  sortFeaturesList = () => {
    let filteredList = [];
    this.state.rawfeatureList.forEach((item) => {
      if (this.state.activeTabId === 0) {
        if (item.feature_id === 1003) {
          filteredList.push(item);
        }
      }
      if (this.state.activeTabId === 1) {
        if (item.feature_id === 1004 || item.feature_id === 1007) {
          filteredList.push(item);
        }
      }
      if (this.state.activeTabId === 2) {
        if (item.feature_id === 1005) {
          filteredList.push(item);
        }
      }
      if (this.state.activeTabId === 3) {
        if (item.feature_id === 1008 || item.feature_id === 1009) {
          filteredList.push(item);
        }
      }
      if (this.state.activeTabId === 4) {
        if (item.feature_id === 1010) {
          filteredList.push(item);
        }
      }
      if (this.state.activeTabId === 5) {
        if (item.feature_id === 1012) {
          filteredList.push(item);
        }
      }
      if (this.state.activeTabId === 6) {
        if (item.feature_id === 1015) {
          filteredList.push(item);
        }
      }

      //Detention
      if (this.state.activeTabId === 7) {
        if (item.feature_id === 1019) {
          filteredList.push(item);
        }
      }
    });

    this.setState({ featureList: filteredList, fetching: false });
  };

  fetchFeatureList = () => {
    fetchWithJson(endpoint.settingsProductFeatures, {
      company_id: companyId,
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ rawfeatureList: data }, () => {
          this.sortFeaturesList();
        });
      })
      .catch((err) => {
        error("Oops! error occured during fetch feature list");
        console.log(err);
      });
  };

  handleTabChange = (activeTabId) => {
    this.setState({ activeTabId }, () => {
      this.sortFeaturesList();
    });
  };

  render() {

    return (
      <div className="pa2 ml2">
        {this.state.fetching && (
          <div className="flex justify-center mt6">
            <Loading size="xl">Hang on..</Loading>
          </div>
        )}

        {!this.state.fetching && (
          <Tabs selectedIndex={this.state.activeTabId} onSelect={(index) => this.handleTabChange(index)}>
            <TabList className='f5 mb3'>
              <Tab>General</Tab>
              <Tab>Documents</Tab>
              <Tab>Receipts</Tab>
              <Tab>Location</Tab>
              <Tab>Safety</Tab>
              <Tab>News Feed</Tab>
              <Tab>Canned Messages</Tab>
              <Tab>Detention</Tab>
              <Tab>Autolink</Tab>
            </TabList>

            <TabPanel>
              <div>
                <AutoClose
                  fetching={this.state.fetching}
                  featureList={this.state.featureList}
                />
                <ShipmentCustomFields />
              </div>
            </TabPanel>

            <TabPanel>
              <ManageArtifacts
                fetching={this.state.fetching}
                featureList={this.state.featureList}
              />
            </TabPanel>

            <TabPanel>
              <ReceiptArchiveDays
                fetching={this.state.fetching}
                featureList={this.state.featureList}
              />
            </TabPanel>

            <TabPanel>
              <GPSInterval
                fetching={this.state.fetching}
                featureList={this.state.featureList}
                wantToDesableFeature={this.state.wantToDesableFeature}
              />
            </TabPanel>
            
            <TabPanel>
              <SafetyDocumentType
                fetching={this.state.fetching}
                featureList={this.state.featureList}
              />
            </TabPanel>

            <TabPanel>
              <NewsFeed
                fetching={this.state.fetching}
                featureList={this.state.featureList}
                setHandleIndustryNewsSwitch={(click) =>
                  (this.clickChildHandleIndustryNewsSwitch = click)
                }
                setHandleAppInfoSwitch={(click) =>
                  (this.clickChildHandleAppInfoSwitch = click)
                }
              />
            </TabPanel>

            <TabPanel>
              <CannedMessageSettings
                fetching={this.state.fetching}
                featureList={this.state.featureList}
              />
            </TabPanel>

            <TabPanel>
              <DetentionManagement
                fetching={this.state.fetching}
                featureList={this.state.featureList}
              />
            </TabPanel>
            
            <TabPanel>
              <AutoLinkSettings/>
            </TabPanel>

          </Tabs>
        )}

        <Modal
            closeButton
            blur
            preventClose
            aria-labelledby="Confirmation"
            open={this.state.showWarning}
            onClose={this.onCancel}
        >
            <Modal.Header>
                <Text id="modal-title" size={18}>
                 {!this.state.wantToDesableFeature ? 'Confirmation' : 'Feature Disabled'}
                </Text>
            </Modal.Header>
            <Modal.Body>
              {this.state.wantToDesableFeature ? (
                <label htmlFor="" className="f5">
                  Your data will be stored for the next <Text b> 30 days </Text>. If you would
                  like your data to be provided, send an email to
                  support@simplydatanow.com by{" "}
                  <Text b> {dayjs().add(10, "days").calendar()} </Text> with a Subject Line of <Text b>“
                  {this.state.currentFeature.feature_name} Data Extract
                  Request” </Text>. We will provide a CSV file of your data and also
                  provide a download of all pictures taken.
                </label>
              ) : (
                <label htmlFor="" className="f5">
                  This action will disable the selected feature (<Text b>{this.state.currentFeature.feature_name}</Text>) for both the 
                  Mobile and Web Portal users for your company! <Text b>You will be charged in full for the
                  current month.</Text> Continue?
                </label>
              )}
            </Modal.Body>
            <Modal.Footer>
              {!this.state.wantToDesableFeature && (
                <Button
                  auto
                  disabled={this.state.disablingFeature}
                  onClick={() => {
                    this.setState({ wantToDesableFeature: true, disablingFeature: true });

                    if (this.state.currentFeature.feature_id === 1012) {
                      this.clickChildHandleIndustryNewsSwitch(true);
                      this.clickChildHandleAppInfoSwitch(true);
                    }

                    this.settingsFeatureUpdate(this.state.currentFeature);
                    this.setState({ disablingFeature: false });
                  }}> 
                  Yes 
                </Button>
              )}

              {!this.state.wantToDesableFeature && (
                <Button
                  auto
                  onClick={() => {
                    this.onCancel();
                  }}
                >
                No
                </Button>
              )}
            </Modal.Footer>
        </Modal>

      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    clientId: state.client ? state.client.id : mstore.getPath(["user", "company_id"]),
    Access: state.navigation.Access
  }
}

export default connect(mapStateToProps)(Settings);