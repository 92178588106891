import React, { Component } from "react";
import { Button, Input, Modal, Text } from '@nextui-org/react'

import { secureFetch } from "../../lib/api";
import { success } from "../../lib/userAlerts";
import { mstore } from "../../store";
import endpoint from "../../config/endpoint.json";
import { error } from "../../lib/userAlerts";
import { connect } from "react-redux";
import cloneDeep from 'lodash/cloneDeep';
import { Loading } from '@nextui-org/react';
import { Delete, Edit } from "react-iconly";

class ShipmentCustomFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rawCustomFields: [],
      customFields: [],
      editArtifacts: false,
      loading: false,
      loadinge: false,
      fetching: true,
      editButtonClicked: false,
      isOpen: false,
      saveObject: {}
    };
  }

  componentDidMount() {
    this.getCustomFields();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.clientId !== this.props.clientId) {
      this.getCustomFields();
    }
  }

  closeDialog = () =>{
    this.setState({
      isOpen: false, loadinge: false, editButtonClicked : false
    })
  }

  getCustomFields = () => {
    secureFetch(endpoint.getCustomFields, {}).then(([ok, status, json]) => {
      if (ok) {
        let customFields = [];

        Object.keys(json.customFields[0]).forEach((element) => {
          let obj = {};
          obj.id = Math.random();
          obj.field = element;
          obj.fieldLable = json.customFields[0][element];

          if (
            element === "usr_text1_lbl" ||
            element === "usr_text2_lbl" ||
            element === "usr_text3_lbl"
          ) {
            obj.dataType = "Text";
          } else if (
            element === "usr_num1_lbl" ||
            element === "usr_num2_lbl" ||
            element === "usr_num3_lbl"
          ) {
            obj.dataType = "Number";
          } else if (
            element === "usr_date1_lbl" ||
            element === "usr_date2_lbl" ||
            element === "usr_date3_lbl"
          ) {
            obj.dataType = "Date";
          }

          customFields.push(obj);
        });

        this.setState({
          customFields: customFields,
          loading: false,
          fetching: false,
          rawCustomFields: cloneDeep(customFields),
        });
      } else {
        error("Oops! error occured during fetching custom fields!");
      }
    });
  };

  editCustomFields = (obj) => {
    this.setState({ editButtonClicked: true, saveObject: obj });
  };

  _saveCustomFields = (obj) => {
    this.setState({ loadinge: true });
    secureFetch(endpoint.updateCustomFields, {
      column: obj.field,
      value: obj.fieldLable,
    }).then(([ok, status, json]) => {
      if (ok) {
        this.getCustomFields();
        success(json.message);
      } else {
        error("Oops! error occured during save shipment fields!");
      }
    })
    .catch(e =>{
      console.log(e)
    })
    .finally(()=>{
      this.closeDialog();
    });
  };

  deleteCustomFieldLabel = (obj) => {
    let previousCustomFields = cloneDeep(this.state.rawCustomFields);
    obj.fieldLable = null
    this.setState({
      editButtonClicked: false,
      customFields: previousCustomFields,
      isOpen: true,
      saveObject: obj
    });
  }

  editCustomFieldLabel = (obj) => {
    let previousCustomFields = cloneDeep(this.state.rawCustomFields);
    obj.fieldLable = null
    this.setState({
      editButtonClicked: true,
      isOpen: false,
      customFields: previousCustomFields,
      saveObject: obj
    });
  }

  renderCustomFieldRow = (obj, idx) =>{
    return(
      <tr key={idx} >
        <td className="p-2 border-b border-blue-gray-50">
          <div className="flex items-center gap-3">
            <p className="block antialiased text-md leading-normal font-normal">{obj.dataType}</p>
          </div>
        </td>
        <td className="p-2 border-b border-blue-gray-50">
          <div className="flex items-center gap-3">
            <p className="block antialiased font-semibold text-md leading-normal text-gray-600 font-normal">{obj.fieldLable}</p>
          </div>
        </td>
        <td className="p-2 border-b border-blue-gray-50">
          <div className="w-max">
            {!obj.fieldLable
            ?
              <div className="relative grid items-center font-sans font-bold uppercase whitespace-nowrap select-none bg-red-500/20 text-red-700 py-1 px-2 text-xs rounded-md">
                <span class="">Not Assigned</span>
              </div>
            :
              <div className="relative grid items-center font-sans font-bold uppercase whitespace-nowrap select-none bg-green-500/20 text-green-600 py-1 px-2 text-xs rounded-md">
                <span className="">Name Assigned</span>
              </div>
            }
          </div>
        </td>
        <td className="p-2 border-b border-blue-gray-50">
          <button className="text-primary relative align-middle select-none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] rounded-lg text-xs" type="button">
            <Edit onClick={()=>this.editCustomFields(obj)}/>
          </button>
          <button disabled={!obj.fieldLable} className="relative align-middle select-none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] rounded-lg text-xs text-blue-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30" type="button">
            <Delete primaryColor="red" onClick={()=>{this.deleteCustomFieldLabel(obj)}}/>
          </button>
        </td>
      </tr>
    )
  }

  renderCustomTable = () =>{
    return(
      <div className="overflow-scroll w-full border-2 border-dotted my-4 p-4 ml2">
        <div className="flex justify-between">
          <div className="f4">
            Shipment Data - Custom Fields
          </div>
        </div>

        <table className="mt-4 w-full min-w-max table-auto text-left ">
          <thead>
            <tr>
              <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 px-2 py-4 transition-colors hover:bg-blue-gray-50">
                  <p className="antialiased text-blue-gray-900 flex items-center justify-between gap-2 font-bold leading-none opacity-70">Field Type 
                </p>
              </th>
              <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 px-2 py-4 transition-colors hover:bg-blue-gray-50">
                <p className="antialiased text-blue-gray-900 flex items-center justify-between gap-2 font-bold leading-none opacity-70">Field Label 
                </p>
              </th>
              <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 px-2 py-4 transition-colors hover:bg-blue-gray-50">
                <p className="antialiased text-blue-gray-900 flex items-center justify-between gap-2 font-bold leading-none opacity-70">Status 
                </p>
              </th>
              <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 px-2 py-4 transition-colors hover:bg-blue-gray-50">
                <p className="antialiased text-blue-gray-900 flex items-center justify-between gap-2 font-bold leading-none opacity-70">Actions</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.customFields.map((obj, idx) => this.renderCustomFieldRow(obj, idx))}
          </tbody>
        </table>
      </div>     
    )
  }

  render() {
    return (
      <div className="mt2">
        {this.state.fetching && (
          <div className="flex justify-center mt6">
            <Loading size="md">Loading custom fields..</Loading>
          </div>
        )}
        <div className="flex flex-row">
          {!this.state.fetching && this.state.customFields?.length !== 0 ? this.renderCustomTable(): null}
          {!this.state.fetching && this.state.customFields.length !== 0 && (
            <>
              <Modal
                closeButton
                open={this.state.isOpen}
                onClose={this.closeDialog}
                blur
                css={{br: 0}}
              >
                <Modal.Header>
                  <Text size={20}>Confirmation</Text>
                </Modal.Header>
                <Modal.Body>
                  <Text size={16}>
                    This action will remove the field label. Continue?
                  </Text>
                </Modal.Body>
                <Modal.Footer>
                  <div className="flex justify-end">
                    <Button 
                      size='sm' 
                      auto
                      flat
                      css={{br:0}}
                      onClick={()=>{this.setState({isOpen: false})}}>
                      No
                    </Button>
                    <Button
                      size='sm' 
                      auto
                      color='error'
                      css={{br:0, ml: 10}}
                      onClick={() => {
                        this._saveCustomFields(this.state.saveObject)
                      }}
                    >
                      {this.state.loadinge ? <Loading type='points' color='white'/> : <Text color='currentColor'>Yes</Text>}
                    </Button>
                  </div>
                </Modal.Footer>
              </Modal>

              <Modal
                closeButton
                open={this.state.editButtonClicked}
                onClose={this.closeDialog}
                blur
                css={{br: 0}}
              >
                <Modal.Header>
                  <Text size={18}>Update Field Name</Text>
                </Modal.Header>
                <Modal.Body>
                  <div role="alert" className="flex my-4">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    <span className="mx-2">Assign a unique name to the custom field.</span>
                  </div>

                  <Input
                    clearable
                    fullWidth
                    className="border-2"
                    css={{ $$inputBorderRadius: "0", w: '100%', h: 35 }}
                    value={this.state.saveObject?.fieldLable}
                    onChange={(e) => {
                      let value = e.target?.value
                      let saveObject = cloneDeep(this.state.saveObject)
                      // Update
                      saveObject['fieldLable'] = value
                      this.setState({saveObject: saveObject});
                    }}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <div className="flex justify-end">
                    <Button 
                      flat
                      size='sm' 
                      color='error'
                      auto
                      css={{br:0}}
                      onClick={()=>{this.closeDialog()}}>
                      Cancel
                    </Button>
                    <Button
                      size='sm' 
                      auto
                      color='primary'
                      css={{br:0, ml: 10}}
                      onClick={() => {
                        this._saveCustomFields(this.state.saveObject)
                      }}
                    >
                      {this.state.loadinge ? <Loading type='points' color='white'/> : <Text color='currentColor'>Update</Text>}
                    </Button>
                  </div>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  clientId: state.client
    ? state.client.id
    : mstore.getPath(["user", "company_id"]),
}))(ShipmentCustomFields);
