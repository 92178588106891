import React, { PureComponent } from 'react';
import { Text } from '@nextui-org/react';

export default class FileUpload extends PureComponent {

  state = {
    fileName: '',
    isError: false,
    isUploading: false
  }

  setFile = event => {
    const file = event.target.files[0]
    let email = '';

    if (!this.props || !this.props.email)
      email = '';
    else
      email = this.props.email;
      
    if(!file) return;
    this.setState({ fileName: file.name, isError: false })
    this.props.uploadCb(file , email)
      .sub({
        start: () => this.setState({isUploading: true, isError: false}),
        end: () => this.setState({ isUploading: false }),
        error: err => this.setState({ isError: true })
      })
  }

  render() {
    /** TODO: handle the isError state */
    return (
      
      <label>
        { this.props.defaultName ? <span className="flex"> <Text size={14} i css={{mt: 10}}>{this.props.defaultName}</Text></span> : null}
        <input type="file" onChange={this.setFile} className="file-input-sm file-input-bordered file-input-primary w-full max-w-xs" />
      </label>
    )
  }
}