import React, { Component } from "react";
import { Pagination, Checkbox, Loading, Modal, Text, Button as NextButton, Input } from '@nextui-org/react';
import { TimeCircle, User, Delete, Play } from 'react-iconly';
import Countdown from "react-countdown-now";
import { connect } from "react-redux";

import SubPageHeader from "../../components/SubPageHeader";
import FilterSection from "../../components/ShipmentaMnagementComponent/FilterSection";
import OrderCard from "../../components/ShipmentaMnagementComponent/OrderCard";
import ShareModal from "../../components/ShipmentaMnagementComponent/ShareModal";
import SnoozeModal from "../../components/ShipmentaMnagementComponent/SnoozeModal";
import AssignRefModal from "../../components/ShipmentaMnagementComponent/AssignRefModal";

import { fetchWithJson, secureFetch } from "../../lib/api";
import { error, success } from "../../lib/userAlerts";
import checkAccess from '../../lib/checkAccess';

import { mstore } from "../../store";
import endpoint from "../../config/endpoint.json";
import { Pause } from '../../icons/Pause'
import { updateTitle } from "../../lib/utils";

var randomColor = require("randomcolor");

class ShipmentManagement extends Component {
  clockRef = null;
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loadSnooze: false,
      loadShare: false,
      loadRef: false,
      deleteBucketLoading: false,
      snoozHr: null,
      snoozMin: null,
      clientId: 0,
      oldIndex: null,
      openAddSnoozModal: false,
      openSnoozeBucketDeletePopover: false,
      snoozBuckets: [],
      selectedOrders: [],
      selectSnoozeBucketList: [],
      phoneNoList: [],
      selectPhoneNoList: [{ value: "loading.......", label: "Loading...." }],
      selectDriverRefsList: [{ value: "loading.......", label: "Loading...." }],
      selectedPhoneNumber: null,
      selectedSnoozeBucket: null,
      selectedRef: null,
      selectedOrder: "2",
      selectedPage: 1,
      totalPage: 10,
      key: null,
      orders: [],
      timer: Date.now() + 600000,
      lateEtaOrdersFilter: false,
      snoozeOrderFilter: false,
      unassignOrdersFilter: false,
      shotByAppointmentTime: false,
      myOrdersFilter: true,
      allOrdersFilter: false,
      allOpenOrdersFilter: false,
      timerStart: true,
      openShareModal: false,
      openSnoozeModal: false,
      openRefModal: false,
      futureShipment: false,
      todaysShipment: true,
      historicShipment: false,
      loadOrderList: false,
      renderRegularView: true,
      orderNo: null,
      bolAndColorMapping: [],
      clientId: '',
      shareKey: 0                         // Force share component to re-render
    };
  }

  componentDidMount = async () => {
    updateTitle("Shipment Management");
    this.setState({ loadOrderList: true });

    /***
     * 
     * Wait till this call finishes... Just in case
     * 
     */

    await checkAccess(this.props.clientId);

    this.setState({ clientId: this.props.clientId });
    this.fetchSnoozBucket();
    this.fetchOrderList();
    this.fetchDriverRefs();
  }

  componentWillReceiveProps = async (newProps) => {

    if (newProps.clientId !== this.props.clientId) {
      await checkAccess(newProps.clientId);

      this.setState({clientId: newProps.clientId});
      this.fetchSnoozBucket();
      this.fetchOrderList();
      this.fetchDriverRefs();
    }
  }

  componentWillUnmount() {
    this.pauseClock();
  }

  modifiState = (value, field) => {
    let state = this.state;
    state[field] = value;
    this.setState(state, () => {
      if (field === "selectedPage") {
        this.fetchOrderList();
      }
      if (field === "orderNo"){
        this.fetchOrderList();
      }
    });
  };

  resetFilter = () => {
    this.setState({
      lateEtaOrdersFilter: false,
      snoozeOrderFilter: false,
      unassignOrdersFilter: false,
      shotByAppointmentTime: false,
      allOpenOrdersFilter: false,
      allOrdersFilter: false,
      myOrdersFilter: true,
    });
  };

  fetchOrderListFromEndPoint = (orderNo) =>{

    if (orderNo && orderNo.value) {
      this.setState({renderRegularView: false, orderNo: orderNo})
      return secureFetch(endpoint.gatherShipmentsBrokerOrder, {
        orderNo: orderNo.value,
        page: this.state.selectedPage - 1,
        pageSize: 10,
      })
    }
    else 
    {
      this.setState({renderRegularView: true, orderNo: null})
      return secureFetch(endpoint.gatherShipmentsBrokerMgmt, {
        myOrdersFilter: this.state.myOrdersFilter,
        allOrdersFilter: this.state.allOrdersFilter,
        allOpenOrdersFilter: this.state.allOpenOrdersFilter,
        snoozeOrderFilter: this.state.snoozeOrderFilter,
        lateEtaOrdersFilter: this.state.lateEtaOrdersFilter,
        unassignOrdersFilter: this.state.unassignOrdersFilter,
        todaysShipment: this.state.todaysShipment,
        historicShipment: this.state.historicShipment,
        futureShipment: this.state.futureShipment,
        page: this.state.selectedPage - 1,
        selectSnoozeBucketList: [],
        pageSize: 10,
      })
    }
  }

  pauseClock = () => {
    this.clockRef.pause();
    this.setState({
      timerStart: false,
      timer: Date.now() + 600000
    });
  }
  
  startClock = () => {
    if(this.clockRef && !this.state.timerStart) {
      this.clockRef.start();
      this.setState({
        timerStart: true,
        timer: Date.now() + 600000
      });
    }
  }

  fetchOrderList = () => {

    let orderNo = this.state.orderNo

    this.fetchOrderListFromEndPoint(orderNo)
      .then(([ok, status, json]) => {
      if (ok) {

        let modifiedOrderList = [];
        let bolAndColorMapping = [...this.state.bolAndColorMapping];
        let recentrandom = null;
        json.orderList.forEach((element) => {
          let mappingObject = bolAndColorMapping.find(
            (item) => item.bol === element.bol_number
          );

          if (mappingObject) {
            element.color = mappingObject.color;
            modifiedOrderList.push(element);
          } else {
            recentrandom = randomColor({ format: "hsla", alpha: 0.18 });
            element.color = recentrandom;

            modifiedOrderList.push(element);
            bolAndColorMapping.push({
              bol: element.bol_number,
              color: recentrandom,
            });
          }
        });
        if (this.state.shotByAppointmentTime) {
          modifiedOrderList.sort((a, b) => {
            let aDate = new Date(`${a.StopDate} ${a.appointmentTime1}`);
            let bDate = new Date(`${b.StopDate} ${b.appointmentTime1}`);

            return aDate - bDate;
          });
        }
        this.setState({
          bolAndColorMapping: bolAndColorMapping,
          orders: modifiedOrderList,
          key: new Date(),
          loadOrderList: false
        });
      } else {
        this.setState({ loadOrderList: false });
        error("Oops! error occured while fetching snooze buckets!");
      }
    })
    .catch((ex) => {
      this.setState({ loadOrderList: false });
      console.log(ex);
    });
  };

  fetchDriverRefs = () => {
    secureFetch(endpoint.fetchDriverRefs, {}).then(([ok, status, json]) => {
      if (ok) {
        this.setState({
          selectDriverRefsList: json.driverRefs.map((k) => ({
            label: `${k.employee_id} - ${k.name}`,
            value: k.employee_id,
          })),
        });
      } else {
        error("Oops! error occured during fetching Driver refs!");
      }
    });
  };

  fetchSnoozBucket = () => {
    secureFetch(endpoint.getSnoozBucket, {}).then(([ok, status, json]) => {
      if (ok) {
        this.setState({
          snoozBuckets: json.snoozBuckets,
          selectSnoozeBucketList: json.snoozBuckets.map((k) => ({
            label: `${k.snooze_hours} hours ${k.snooze_minutes} minutes`,
            value: k.id,
          })),
        });
      } else {
        error("Oops! error occured during fetching snooz buckets!");
      }
    });
  };

  selectAll = () => {
    let filterOrders = this.state.orders.filter(
      (item) => item.show_checkbox === 1
    );
    if (this.state.selectedOrders.length === filterOrders.length) {
      this.setState({ selectedOrders: [], key: new Date() });
    } else {
      this.setState({ selectedOrders: filterOrders, key: new Date() });
    }
  };

  select = (item) => {
    let findItem = this.state.selectedOrders.find(
      (element) => element.record_id === item.record_id
    );

    if (findItem) {
      let filteredItems = this.state.selectedOrders.filter(
        (element) => element.record_id !== item.record_id
      );
      this.setState({ selectedOrders: filteredItems, key: new Date() });
    } else {
      this.setState({
        selectedOrders: [...this.state.selectedOrders, item],
        key: new Date(),
      });
    }
  };

  snoozeMultiple = async () => {
    this.setState({ loadSnooze: true });
    let promiseArray = [];

    this.state.selectedOrders.forEach((item) => {
      promiseArray.push(
        fetchWithJson(endpoint.snoozeOrders, {
          recordId: item.record_id,
          bucketId: this.state.selectedSnoozeBucket,
        })
      );
    });
    try {
      await Promise.all(promiseArray);
      this.closeSnoozeModal();
      this.fetchOrderList();
      this.setState({ loadSnooze: false });
      success("You have successfully snoozed orders. Refreshing the shipment list..");
    } catch (err) {
      this.setState({ loadSnooze: false });
      error("Something went wrong during snooz orders");
    }
  };

  assignMultiple = async () => {
    if (!this.state.selectedRef) {
      error("Plaese select a user");
      return;
    }

    this.setState({ loadRef: true });
    let promiseArray = [];

    this.state.selectedOrders.forEach((item) => {
      promiseArray.push(
        fetchWithJson(endpoint.saveRefs, {
          recordId: item.record_id,
          employeeId: this.state.selectedRef,
        })
      );
    });
    try {
      await Promise.all(promiseArray);
      this.closeRefModal();
      this.fetchOrderList();
      this.setState({ loadRef: false });
      success("You have successfully saved driver rep");
    } catch (err) {
      this.setState({ loadRef: false });
      error("Something went wrong during save driver rep");
    }
  };

  _sendDriverLinkNotification = (driverUserId, selectedOrder) => {
    
    return secureFetch(endpoint.sendPushNotification, {
      sendUserNotification: false,
      recordId: selectedOrder.record_id,
      clientId: null,
      driverId: null,
      message: `Please track & submit POD for ${selectedOrder.company_name} BOL ${selectedOrder.bol_number} on the MTT Mobile App.`,
      orderId: selectedOrder.OrderNo,
      stopId: selectedOrder.StopId,
      driverUserId : driverUserId
    });

  };
  
  sendDriverLinkNotification = async (driverUserId, selectedOrder) => {
    let [ok, status, json, err] = await this._sendDriverLinkNotification(driverUserId, selectedOrder);
        
    if (ok) {
      success(json.message);
    } 
    else {
      error(err.message);
    }
  }

  shareShipment = (driverUserId) => {

    if (!driverUserId) {
      error("Please select a user");
      return;
    }

    /**
     * Logic Note!
     * =---------=
     */
    let selectedOrder = JSON.parse(JSON.stringify(this.state.selectedOrders[0]));  // Needed as the state gets erased!

    this.setState({ loadShare: true });
    secureFetch(endpoint.shareShipment, {
      recordId: this.state.selectedOrders[0].record_id,
      driverUserId: driverUserId,
    }).then(([ok, status, json]) => {
      if (ok) {
        this.sendDriverLinkNotification(driverUserId, selectedOrder);    // Notify the driver as well.
        this.setState({ loadShare: false });
        this.fetchOrderList();
        success("You have successfully, linked this shipment to the driver");
      } else {
        this.setState({ loadShare: false });
        error("Oops! An error occured when linking the shipment with the driver!");
      }
    });
  };

  toggleSnoozModal = () => {
    this.setState({ openAddSnoozModal: !this.state.openAddSnoozModal });
  };

  saveSnoozBucket = () => {
    let hours = this.state.snoozHr ? this.state.snoozHr : 0;
    let minutes = this.state.snoozMin ? this.state.snoozMin : 0;

    if (hours === 0 && minutes === 0) {
      error("Snooze hours and minutes required");
      return;
    }

    if (this.state.snoozMin < 0 && this.state.snoozMin > 60) {
      error("Enter snooze minutes between 0 minutes to 60 minutes");
      return;
    }

    if (this.state.snoozHr < 0 && this.state.snoozHr > 300) {
      error("Enter snooze hours between 0 to 300 hours");
      return;
    }

    this.setState({ loading: true });
    fetchWithJson(endpoint.saveSnoozBucket, {
      snoozHr: hours,
      snoozMin: minutes,
    })
      .then((res) => res.json())
      .then((data) => {
        success("You have successfully added new snooz bucket");
        this.fetchSnoozBucket();
        this.setState({ loading: false, snoozHr: null, snoozMin: null });
      })
      .catch((err) => {
        this.setState({ loading: false });
        error("Something went wrong during add new bucket");
      });
  };

  deleteSnoozBucket = (bucketId) => {
    let state = this.state;
    state["deleteBucketLoading" + bucketId] = true;
    this.setState(state);
    fetchWithJson(endpoint.deleteSnoozBucket, {
      bucketId: bucketId,
    })
      .then((res) => res.json())
      .then((data) => {
        success("You have successfully deleted snooz bucket");
        this.fetchSnoozBucket();
        state["deleteBucketLoading" + bucketId] = false;
        state["openSnoozeBucketDeletePopover" + bucketId] = false;
        this.setState(state);
      })
      .catch((err) => {
        state["openSnoozeBucketDeletePopover" + bucketId] = false;
        state["deleteBucketLoading" + bucketId] = false;
        this.setState(state);
        error("Something went wrong during delete bucket");
      });
  };

  addSnoozModal = () => {
    return (
      <Modal
          width="500px"
          closeButton
          blur
          aria-labelledby="Manage Snooze Bucket"
          open={this.state.openAddSnoozModal}
          onClose={this.toggleSnoozModal}
      >
        <Modal.Header>
          <Text size="1.25rem">
            Manage Snooze Buckets
          </Text>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="bg-near-white mt3 mb3 pa2" style={{height: 300, overflowY: "auto"}}>
              <Text size="1.1rem" css={{ mb: 20}}> 
                {this.state.snoozBuckets.length === 0 ? `No Snooze Buckets have been created yet`: `Existing Buckets`}
              </Text>
              {this.state.snoozBuckets.length > 0 ? this.renderSnoozeBuckets(): null}
            </div>

            <div className="mt2 pa2 bg-near-white">
              <Text size="1.1rem" css={{mb: 20}}> 
                Define a new snooze bucket.
              </Text>

              <div className="flex justify-between">
                <Text size="1rem">
                  Snooze bucket hours
                </Text>
                <Text size="1rem">
                  Snooze bucket minutes
                </Text>
              </div>
              <div className="flex justify-between mt2">
                <Input
                  type='number'
                  css={{ $$inputBorderRadius: "0", w: '50', h: 38, mt: 5 }}
                  shadow={false}
                  bordered={true}
                  rounded={false}
                  value={this.state.snoozHr}
                  onChange={(e) =>
                    this.setState({
                      snoozHr: e.target.value > 0 ? e.target.value : 0,
                    })
                  }
                  placeholder="Enter snooze hours"
                />
                <Input
                  type='number'
                  css={{ $$inputBorderRadius: "0", w: '50', h: 38, mt: 5 }}
                  shadow={false}
                  bordered={true}
                  rounded={false}
                  className="ml2"
                  value={this.state.snoozMin}
                  onChange={(e) => {
                    let val = 0
                    val = e.target.value > 59 ? 59 : e.target.value
                    this.setState({
                      snoozMin: e.target.value > 0 ? val : 0,
                    })
                  }}
                  placeholder="Enter snooze minutes"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <NextButton
            auto
            css={{br: 0}}
            onClick={this.saveSnoozBucket}
            loading={this.state.loading}
          >
            Create Snooze Bucket
          </NextButton>
        </Modal.Footer>
      </Modal>
    );
  };

  renderFooterCheckboxes = () => {
    return(
      <span className="flex items-center w-100 flex-wrap">
        <Checkbox
          checked={this.state.futureShipment}
          onChange={() => {
            this.setState(
              {
                todaysShipment: false,
                historicShipment: false,
                futureShipment: !this.state.futureShipment,
              },
              () => this.fetchOrderList()
            );
          }}
        >
          <Text size="1.1rem">Only show future shipments</Text>
        </Checkbox>
        <Checkbox
          css={{ml: 10}}
          checked={this.state.todaysShipment}
          onChange={() => {
            this.setState(
              {
                todaysShipment: !this.state.todaysShipment,
                historicShipment: false,
                futureShipment: false,
              },
              () => this.fetchOrderList()
            );
          }}
        >
          <Text size="1.1rem">Only show today's shipments</Text>
        </Checkbox>
        <Checkbox
          css={{ml: 10}}
          checked={this.state.historicShipment}
          onChange={() => {
            this.setState(
              {
                historicShipment: !this.state.historicShipment,
                todaysShipment: false,
                futureShipment: false,
              },
              () => this.fetchOrderList()
            );
          }}
        >
          <Text size="1.1rem">Only show historic shipments</Text>
        </Checkbox>
      </span>
    )
  }

  renderFooterSelectedOrderText = () => {
    return(
      <span className="flex items-center w-100 flex-wrap f4"> 
        You are viewing the details for :-  <strong className="ml2 bg-washed-green">{this.state.orderNo ? this.state.orderNo.value: ''}</strong>
      </span>
    )
  }

  renderFooterButtons = () => {
    return(
      <div className="flex items-center w-70 flex-wrap">

        { this.state.renderRegularView && 
          (this.renderFooterCheckboxes())
        }
        { !this.state.renderRegularView && 
          (this.renderFooterSelectedOrderText())
        }
      </div>
    )
  }

  renderCountdown = () =>{
    return(
      <Countdown
      ref={(ref) => (this.clockRef = ref)}
      date={this.state.timer}
      onStart={(data) => {
        this.setState({
          timer: Date.now() + (600000 - data.seconds * 1000),
        });
      }}
      onPause={(data) => {
        this.setState({
          timer: Date.now() + (600000 - data.seconds * 1000),
        });
      }}
      controlled={false}
      renderer={({ hours, minutes, seconds, completed }) => {
        return (
          <div className="f5 mr3 flex items-center">
            <div>Order list will refresh in </div>
            <span className="f4 b ml2 mr2">
              {hours}:{minutes}:{seconds}
            </span>
            {this.state.timerStart 
            ? 
              <span onClick={() => {this.pauseClock()}} style={{height: 24, width: 24}}><Pause/></span>
            :
              <Play onClick={()=>{this.startClock()}}  color='green'/>
            }

          </div>
        );
      }}
      onComplete={(data) => {
        this.fetchOrderList();
        this.setState(
          { timer: Date.now() + 600000 },
          () => {
            this.clockRef.start();
          }
        );
      }}
    />
    )
  }

  openShareModal = () => {
    this.setState({ openShareModal: true});
  };

  closeShareModal = () => {
    this.fetchOrderList();
    this.setState({
      openShareModal: false,
      selectedPhoneNumber: null,
      selectedOrders: [],
      key: new Date(),
    });
  };

  handleSelectPhoneNumber = (data) => {
    this.setState({ selectedPhoneNumber: data.value });
  };

  openSnoozeModal = () => {
    this.setState({ openSnoozeModal: true });
  };

  closeSnoozeModal = () => {
    this.setState({
      openSnoozeModal: false,
      selectedSnoozeBucket: null,
      selectedOrders: [],
      key: new Date(),
    });
  };

  handleSelectSnoozeBucket = (data) => {
    try{
      this.setState({ selectedSnoozeBucket: data.value });
    } 
    catch(e){
      this.setState({ selectedSnoozeBucket: null });
    }
  };

  openRefModal = () => {
    this.setState({ openRefModal: true });
  };

  closeRefModal = () => {
    this.setState({
      openRefModal: false,
      selectedRef: null,
      selectedOrders: [],
      key: new Date(),
    });
  };

  handleSelectRef = (data) => {
    this.setState({ selectedRef: data.value });
  };

  closeCannedMessageModal = () => {
    this.setState({
      openCannedMessageModal: false,
      selectedOrders: [],
      key: new Date(),
    });
  };

  openCannedMessageModal = () => {
    this.setState({ openCannedMessageModal: true });
  };

  _onPagination = (pageInd) => {
    this.setState(
      {
        selectedPage: pageInd,
        totalPage: (pageInd < 10) ? 10 : (pageInd + 1)
      },
      () => {
        this.fetchOrderList();
      }
    );
  };

  unlinkDriver = (record_id) => {
    
    secureFetch(endpoint.unlinkFreeUser, {record_id: record_id}).then(
      ([ok, status, json]) => {
        if (ok) {
          this.fetchOrderList();
          success('You have unlinked the driver from this shipment.');
        } else {
          error("Oops! error while unlinking the driver");
        }
      }
    );  
  }

  renderSnoozeBuckets = () => {
    return (
      <table className="w-100 bg-white table">
        <thead>
          <tr className="f5">
            <th>Bucket#</th>
            <th>Snooze Hours</th>
            <th>Snooze Minutes</th>
            <th>Delete?</th>
          </tr>
        </thead>
        <tbody>
        {
          this.state.snoozBuckets.map((item, index) => {
            return (
              <tr className="f5" key={item.id}>
                <td style={{textAlign: "center" }}>{item.id}</td>
                <td style={{textAlign: "center" }}>{item.snooze_hours}</td>
                <td style={{textAlign: "center" }}>{item.snooze_minutes}</td>
                <td style={{textAlign: "center" }}>
                  {!this.state["deleteBucketLoading" + item.id] 
                    ? 
                    <Delete 
                      onClick={this.deleteSnoozBucket.bind(null,item.id)}
                      set='light' 
                      size={24} 
                      color='red'/>
                    :
                    <Loading type='points'/>
                  }
                </td>
              </tr>
            )
          }
        )}
        </tbody>
      </table>
    )
  }

  render() {

    if (this.props.Access.allow_broker_shipment_management === 0 && !this.state.loading) {
      return (
        <div>
          <div className="ml3 w-100">
            <SubPageHeader>Shipment Management</SubPageHeader>
          </div>
          <div className=" ml3 mt3 f5">This feature is unavailable</div>
        </div>
      );
    }

    return (
      <div className="">
        <div className="p-4">
          <FilterSection
            selectAll={this.selectAll}
            modifiState={this.modifiState}
            lateEtaOrdersFilter={this.state.lateEtaOrdersFilter}
            snoozeOrderFilter={this.state.snoozeOrderFilter}
            unassignOrdersFilter={this.state.unassignOrdersFilter}
            shotByAppointmentTime={this.state.shotByAppointmentTime}
            myOrdersFilter={this.state.myOrdersFilter}
            allOrdersFilter={this.state.allOrdersFilter}
            allOpenOrdersFilter={this.state.allOpenOrdersFilter}
            toggleSnoozModal={this.toggleSnoozModal}
            resetFilter={this.resetFilter}
            clientId={this.state.clientId}
            fetchOrderList={this.fetchOrderList}
          />
          {this.state.loadOrderList ? (
            <div className="flex justify-center mt6">
              <Loading size="lg">Fetching shipment data..</Loading>
            </div>
          ) : (
            <div className="flex mt2">
              <div
                className="flex flex-wrap overflow-y-scroll"
                style={{
                  width: "100%",
                  height: parseInt(window.innerHeight -240),
                }}
              >
                {this.state.orders.length !== 0 ? (
                  <div
                    style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
                  >
                    {this.state.orders.map((item, index) => {
                      return (
                        <OrderCard
                          key={index}
                          color={item.color}
                          item={item}
                          selectedOrders={this.state.selectedOrders}
                          select={this.select}
                          openShareModal={this.openShareModal}
                          openSnoozeModal={this.openSnoozeModal}
                          openRefModal={this.openRefModal}
                          openCannedMessageModalfn={this.openCannedMessageModal}
                          closeCannedMessageModal={this.closeCannedMessageModal}
                          unlinkDriver={this.unlinkDriver}
                          openCannedMessageModal={
                            this.state.openCannedMessageModal
                          }
                          history={this.props.history}
                          access={this.props.Access}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div
                    className="f4 b tc pt3 w-100"
                    style={{
                      height: parseInt(window.innerHeight * 0.72),
                    }}
                  >
                    Records not found
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="bg-gray-100 py-2">
          {!this.state.loadOrderList && (
            <div className="flex justify-between items-center ml2 flex-wrap">
              {this.renderFooterButtons()}
              {this.renderCountdown()}
            </div>
          )}
          <div className="flex" style={{ marginTop: "10px" }}>
            <Pagination 
              rounded total={this.state.totalPage} 
              onChange={this._onPagination}
              initialPage={this.state.selectedPage} />
            {this.state.selectedOrders.length > 1 && (
              <div className="flex justify-around ml3">
                <NextButton
                  ghost
                  css={{br: 0}}
                  onClick={this.openSnoozeModal}
                  size="sm"
                  icon={<TimeCircle fill="currentColor" />}
                >
                  <Text color="currentColor">Snooze All</Text> 
                </NextButton>
                <NextButton
                  ghost
                  css={{br: 0, ml: 10}}
                  onClick={this.openRefModal}
                  size="sm"
                  icon={<User fill="currentColor" />}
                >
                  <Text color="currentColor">Assign All</Text> 
                </NextButton>
              </div>
            )}
          </div>
          {this.addSnoozModal()}
          <ShareModal
            openShareModalState={this.state.openShareModal}
            closeShareModal={this.closeShareModal}
            shareShipment={this.shareShipment}
            loadShare={this.state.loadShare}
            key={this.state.shareKey}
            selectedOrder={this.state.selectedOrders.length > 0 ? this.state.selectedOrders[0] : {}}
          />
          <SnoozeModal
            openSnoozeModalState={this.state.openSnoozeModal}
            closeSnoozeModal={this.closeSnoozeModal}
            selectSnoozeBucketList={this.state.selectSnoozeBucketList}
            selectedSnoozeBucket={this.state.selectedSnoozeBucket}
            handleSelectSnoozeBucket={this.handleSelectSnoozeBucket}
            snoozeMultiple={this.snoozeMultiple}
            loadSnooze={this.state.loadSnooze}
          />
          <AssignRefModal
            openRefModal={this.state.openRefModal}
            closeRefModal={this.closeRefModal}
            selectDriverRefsList={this.state.selectDriverRefsList}
            selectedRef={this.state.selectedRef}
            handleSelectRef={this.handleSelectRef}
            assignMultiple={this.assignMultiple}
            loadRef={this.state.loadRef}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    clientId: state.client ? state.client.id : mstore.getPath(["user", "company_id"]),
    Access: state.navigation.Access
  }
}

export default connect(mapStateToProps)(ShipmentManagement);