import React from "react";
import { ToastContainer } from 'react-toastify';
import { StripeProvider } from "react-stripe-elements";
import { Loading } from '@nextui-org/react';
import { Stepper, Step } from 'react-form-stepper';
import PasswordStrengthBar from 'react-password-strength-bar';

import CardInput from "../../components/CardInput";
import ContactDetails from "./ContactDetails";
import { success, notify, warning } from "../../lib/userAlerts";
import endpoint from "../../config/endpoint.json";
import LogoImage from '../../assets/mtt.png';
import StripeLogo from '../../assets/stripe.png';

let submitCalled = false;
const steps = ["co", "ad", "pi", "loading"];

const RequireLabel = props => (
  <span className="label-text">{props.lblName} <span className="fw8" style={{ color: 'red' }}>*</span></span>
);

class SignUp extends React.Component {
  state = {
    step: "co",
    complete: false,
    companyName: "",
    password: "",
    scacNumber: "",
    dotNumber: "",
    mcNumber: "",
    isValidPassword: false,
    email: 'abc@abc.com',
    userList: [],
    name: '',
    address: '',
    products: [],
    logoUrl: ""
  };

  name = "";
  cardValue = null;

  createAccount = () => {
    if (submitCalled) {
      return;
    }
    submitCalled = true;

    try {
      this.accountDetails.token = JSON.parse(localStorage.stripeToken);
    } catch (ex) {
      console.error("Error parsing stripe token", ex);
    }
    this.accountDetails.products = this.state.products;
    fetch(endpoint.signupAD, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(this.accountDetails)
    })
      .then(
        res =>
          res.ok
            ? res.json()
            : res.text().then(_ => {
                throw _;
              })
      )
      .then(_ => {
        localStorage.clear();
        sessionStorage.clear();
        submitCalled = false;
        // Yay!
        success("Your account has been created.");
        setTimeout(() => {
          this.props.history.replace("/login");
        }, 3000);
        return true;
      })
      .catch(err => {
        submitCalled = false;
        if (typeof err === "string") {
          try {
            let errjson = JSON.parse(err);
            if (errjson.description === "Strip error") {
              warning("Card rejected, Please try again!");
              setTimeout(() => {
                this.reduceState({ type: "prev" });
                this.reduceState({ type: "prev" });
              }, 1900);
            } 
            else {
            }
          } catch (ex) {}
        }
        console.error(err);
        warning("An error occured please try again");
        return false;
      });
  };

  reduceStep = (state, action) => {
    const step = state.step;
    const { type } = action;
    const stepIdx = steps.indexOf(step);
    if (stepIdx === -1) {
      state.step = steps[0];
      return state;
    }
    if (type === "next") {
      state.step = steps[stepIdx + 1];
      if (state.step === "loading") {
        state.complete = true;
        this.createAccount();
        return state;
      }
      return state;
    }
    if (type === "prev") {
      state.step = steps[Math.max(0, stepIdx - 1)];
      return state;
    }
  };

  reduceState = action => {
    let newState = this.state;
    newState = this.reduceStep(newState, action);
    this.setState(newState);
  };

  handleADSubmit = accountDetails => {
    if (!this.state.isValidPassword) {
      warning("Please increase password strength");
      return false;
    }

    if (!accountDetails.email_free) {
      warning("Please enter another email");
      return false;
    }

    if (this.state.password ) {
      accountDetails.is_active = true;
      accountDetails.password = this.state.password;
      this.name = accountDetails.primary_contact;

      this.setState({
        holderName: this.name,
        cardValue: ""
      });

      //Set the params for the API.
      this.accountDetails = {
                              ...accountDetails, 
                              name: this.state.companyName, 
                              scac_number: this.state.scacNumber, 
                              dot_number: this.state.dotNumber, 
                              mc_number: this.state.mcNumber
                            };
      this.reduceState({ type: "next" });

    } 
    else {
      warning("Please enter a password");
      return false;
    }
  };

  setCompanyName = e => {
    this.setState({ companyName: e?.target?.value });
  };

  setScacNumber = (e) => {
    this.setState({ scacNumber: e.target.value });
  };

  setDotNumber = (e) => {
    this.setState({ dotNumber: e.target.value });
  };

  setMcNumber = (e) => {
    this.setState({ mcNumber: e.target.value });
  };

  updateEmail = email => {
    
    let name   = email.substring(0, email.lastIndexOf("@"));
    let domain = email.substring(email.lastIndexOf("@") +1);
    let pat = [email, name, domain];

    this.setState ({ email: email, userList: pat });
  }

  handleCardSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();

    if (!this.stripe) {
      warning("Cannot save card using stripe.");
      return false;
    } else {
      const { token, error } = await this.stripe.createToken({
        name: this.state.holderName
      });
      localStorage.stripeToken = JSON.stringify(token);
      
      /**
       * 
       * Error on Stripe.
       * 
       */
      if (error) {

        console.error(error);
        warning("Failed to save card details!");
        return false;

      } 
      /***
       * 
       * Stripe is OK.
       * 
       */
      else {

        notify("Saved card details");
      }
    }

    this.reduceState({ type: "next" });
    return false;
  };

  handleStripeForm = ref => {
    if (ref) {
      ref.addEventListener("submit", this.handleCardSubmit);
    }
  };

  /** Set password and its strength */
  handlePasswordChange = e => {
    this.setState({
      password: e.target.value
    });
  };

  setPassword = (score, feedback) => {
    this.setState({ isValidPassword: score > 2}, ()=>{
      //console.log(this.state.isValidPassword, score)
    });
  };

  setStripeWalaRef = $ref => {
    if ($ref) {
      if (!$ref.stripe) {
        return;
      } else {
        this.stripe = $ref.stripe;
      }
    }
  };

  renderPayment = () => {
    return (
      <div className="p-6 bg-gray-100 flex items-center justify-center">
        <div className="container max-w-screen-lg mx-auto">            
          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
              <div className="text-gray-600">
                <p>Your Card details are stored in a secured environment. We use Stripe for processing your payments.</p>
                <div className="flex justify-between md:pt-8 lg:pt-24">
                  <img
                    alt="login pic"
                    className="login-content-image"
                    style={{width: 120, objectFit: 'contain', marginTop: 20}}
                    src={StripeLogo}
                  />
                  <img
                    alt="login pic"
                    className="login-content-image lg:ml-2"
                    style={{width: 100, objectFit: 'contain'}}
                    src={LogoImage}
                  />
                </div>
              </div>
              <div className="lg:col-span-2">
                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                  <div className="md:col-span-5">
                    <CardInput
                      cardProcessing={this.state.cardProcessing}
                      inputFormRef={this.setStripeWalaRef}
                      formRef={this.handleStripeForm}
                      className="px-4"
                      holderName={this.state.holderName}
                      onBack={() => this.setState({ step: 'ad' })}
                      onNameChange={n => this.setState({ holderName: n })}
                      onCardValueChange={v => (this.cardValue = v)}
                    />
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </div>
      </div>
  )};

  _renderLogoPrivacyText = () =>{
    return(
      <div className="text-gray-600">
        <p>We will send in our invoices to this entity. <br/>
            By clicking on next, we assume that that you agree to our <a className="link link-info" rel="noreferrer" href="https://www.mytruckin.tech/mtt-apps-terms" target="_blank">T&Cs</a> 
        </p>
        <img
          alt="login pic"
          className="login-content-image lg:ml-6 lg:my-12"
          style={{width: 120, objectFit: 'contain'}}
          src={LogoImage}
        />
      </div>
    )
  }

  renderCompanyDetails = () =>{
    return(
      <div className="container max-w-screen-lg mx-auto p-6 bg-gray-100 flex items-center justify-center">            
        <div className="bg-white rounded shadow-lg p-4 md:p-8 mb-6 grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">

          {this._renderLogoPrivacyText()}

          <div className="lg:col-span-2">
            <div className="grid gap-4 gap-y-2 grid-cols-1 md:grid-cols-4 pb-4">
              <div className="md:col-span-5">
                <RequireLabel lblName="Company Name" /> 
                <input 
                  type="text" 
                  name="name" 
                  id="name" 
                  className="h-10 border mt-1 rounded w-full px-4" 
                  value={this.state.companyName} 
                  onChange={this.setCompanyName}
                />
              </div>
            </div>

            <div className="grid gap-4 gap-y-2 grid-cols-1 md:grid-cols-4 py-4">
              <div className="md:col-span-2">
                <RequireLabel lblName="Your strong and complicated password" /> 
                <input 
                  type="password" 
                  name="name" 
                  id="name" 
                  className="h-10 border rounded w-full px-4" 
                  value={this.state.password} 
                  onChange={this.handlePasswordChange}
                />
                <PasswordStrengthBar minLength={6} password={this.state.password} onChangeScore={this.setPassword}/>
              </div>
              <div className="md:col-span-2">
                <RequireLabel lblName="SCAC Code" />
                <input 
                  required
                  type="text" 
                  name="scacNumber" 
                  id="scacNumber" 
                  className="h-10 border rounded w-full px-4" 
                  onChange={this.setScacNumber}
                  value={this.state.scacNumber}
                  autoComplete="off"
                  placeholder="" />
              </div>
            </div>

            <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
              <div className="md:col-span-2">
                <label for="dotNumber">DOT Number</label>
                <input 
                  required
                  type="text" 
                  name="dotNumber" 
                  id="dotNumber" 
                  className="h-10 border mt-1 rounded w-full px-4" 
                  onChange={this.setDotNumber}
                  value={this.state.dotNumber}
                  autoComplete="off"
                  placeholder="" />
              </div>
              <div className="md:col-span-2">
                <label for="mcNumber">MC Number</label>
                <input 
                  required
                  type="text" 
                  name="mcNumber" 
                  id="mcNumber" 
                  className="h-10 border mt-1 rounded w-full px-4" 
                  onChange={this.setMcNumber}
                  value={this.state.mcNumber}
                  autoComplete="off"
                  placeholder="" />
              </div>
          </div>

          <div className="lg:col-span-2 text-right mt-4">
            <div className="inline-flex items-end">
              <div>
                <button 
                  onClick={() => {this.props.history.goBack();}} 
                  className="btn bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2"> Cancel </button>
                <button 
                  disabled={this.state.scacNumber?.trim() && this.state.companyName?.trim() && this.state.isValidPassword ? false : true}
                  onClick={()=>{
                    this.reduceState({ type: "next" })
                  }} 
                  className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"> 
                  Next 
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      </div>
    )
  }

  renderCurrentStage() {

    if (this.state.step === "co"){
      return this.renderCompanyDetails()
    }

    if (this.state.step === "ad") {
      return (
        <ContactDetails
          className="mt1 br2 bg-white pa3 pt4"
          onCancel={() => {this.props.history.goBack();}}
          onSave={this.handleADSubmit}
          logoUrl={this.state.logoUrl}
          updateEmail={this.updateEmail}
        />
      );
    }

    if (this.state.step === "pi") {
      return this.renderPayment();
    }
    
    if (this.state.step === "loading")
      return (
        <div className="mt1 br2 bg-white-40 pa3 pt4 w-65 vh-75 flex flex-column justify-center items-center">
          <Loading type='points' size='lg' css={{mb: 20}}/>
          <span className="text-3xl fw3 f3 mt2">
            GETTING STARTED.
            <br />
            Please Wait
          </span>
        </div>
      );
  }

  renderSteps() {

    const step = steps.indexOf(this.state.step); 

    return (
      <Stepper 
        className='pl3 pr3 w-65'
        activeStep={step}>
        <Step label="Company Details" />
        <Step label="Contact Details" />
        <Step label="Payment Details" />
        <Step label="Finish!" />
      </Stepper>
    );
  }

  componentWillMount() {
    this.accountDetails = null;
  }

  render() {
    return (
      <div
        className="flex flex-column items-center mt3"
        style={{ minHeight: "100%" }}
      >
        <ToastContainer />
        {this.renderSteps()}
        {this.renderCurrentStage()}
      </div>
    );
  }
}

export default props => (
  <StripeProvider apiKey={process.env.REACT_APP_STRIPE_APIKEY}>
    <SignUp {...props} />
  </StripeProvider>
);
