import React, { Component } from "react";
import { Modal, Text, Button, Switch, Row} from '@nextui-org/react'
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import { mstore } from "../../store";
import checkAccess from '../../lib/checkAccess';
import { fetchWithJson } from "../../lib/api";
import endpoint from "../../config/endpoint.json";

import { error, success } from "../../lib/userAlerts";
import PostUploadModal from "../../components/NewsFeedComponent/PostUploadModal";
import LinkPreview from "../../components/NewsFeedComponent/LinkPreview";
import { Delete, Document, EditSquare } from "react-iconly";
import SubPageHeader from "../../components/SubPageHeader";

var companyId;

class newsFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPostId: null,
      postText: "",
      postTypeId: "0",
      showAddLink: false,
      uploadImage: false,
      uploadDoc: false,
      openPostDialog: false,
      openImagePreviewModal: false,
      showPreviewLinkBlock: true,
      showMyPost: false,
      attachedLink: "",
      ogImageUrl: "",
      ogTitle: "",
      ogDescription: "",
      attachedPics: [],
      attachedDoc: [],
      selectedPics: [],
      postList: [],
      uploadImageName: "Choose an image",
      uploadDocName: "Choose file...",
      hasMore: true,
      postDeleting: false
    };
  }

  async componentDidMount() {
    checkAccess(this.props.clientId);
    this.fetchPosts();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.clientId !== this.props.clientId) {
      checkAccess(newProps.clientId);
      this.fetchPosts();
    }
  }

  fetchPosts = () => {
    fetchWithJson(endpoint.fetchPosts, {
      limit: 10,
      offset: this.state.postList.length,
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ postList: [...this.state.postList, ...data.postList] });
        if (data.postList.length < 5) {
          this.setState({ hasMore: false });
        }
      })
      .catch((err) => {
        console.log(err);
        error("Unable to fetch news feed posts");
      });
  };

  fetchMyPosts = () => {
    fetchWithJson(endpoint.fetchMyPosts, {
      limit: 10,
      offset: this.state.postList.length,
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ postList: [...this.state.postList, ...data.postList] });
        if (data.postList.length < 5) {
          this.setState({ hasMore: false });
        }
      })
      .catch((err) => {
        console.log(err);
        error("Unable to fetch news feed posts");
      });
  };

  setValueInState = (stateName, value) => {
    const { state } = this;
    state[stateName] = value;
    this.setState(state);
  };

  showMyPost = () => {
    if (!this.state.showMyPost) {
      this.setState({ postList: [], hasMore: true }, () => {
        this.fetchMyPosts();
      });
    } else {
      this.setState({ postList: [], hasMore: true }, () => {
        this.fetchPosts();
      });
    }
    this.setState({ showMyPost: !this.state.showMyPost });
  };

  onCancel = () => {
    this.setState({ openPostDialog: false, openImagePreviewModal: false });
  };

  toggleAddLink = () => {
    this.setState({
      showAddLink: !this.state.showAddLink,
      uploadImage: false,
      uploadDoc: false,
    });
  };

  toggleUploadImage = () => {
    this.setState({
      uploadImage: !this.state.uploadImage,
      showAddLink: false,
      uploadDoc: false,
    });
  };

  toggleUploadDoc = () => {
    this.setState({
      uploadDoc: !this.state.uploadDoc,
      uploadImage: false,
      showAddLink: false,
    });
  };

  openEditModal = (post) => {
    this.setState({
      openPostDialog: true,
      postText: post.body ? post.body : "",
      attachedDoc: post.docArray,
      attachedPics: post.imageArray,
      attachedLink: post.hyperlink ? post.hyperlink : "",
      selectedPostId: post.id,
    });
  };

  removeAttachedPic = (pic) => {
    let filteredPicList = this.state.attachedPics.filter(
      (item) => item.id !== pic.id
    );
    this.setState({ attachedPics: filteredPicList });
  };

  removeAttachedDoc = (doc) => {
    let filteredDocList = this.state.attachedDoc.filter(
      (item) => item.id !== doc.id
    );
    this.setState({ attachedDoc: filteredDocList });
  };

  closePreviewLink = () => {
    this.setState({ showPreviewLinkBlock: false });
  };

  triggerUploadAndUpdate = async () => {
    this.setState({ postUploading: true });
    fetchWithJson(endpoint.fetchUrlMetadata, { url: this.state.attachedLink })
      .then((res) => res.json())
      .then((data) => {
        this.setState(
          {
            ogTitle: data.metadata ? data.metadata.ogTitle || "" : "",
            ogDescription: data.metadata
              ? data.metadata.ogDescription || ""
              : "",
            ogImageUrl: data.metadata
              ? data.metadata.ogImage
                ? data.metadata.ogImage[0]
                  ? data.metadata.ogImage[0].url
                  : data.metadata.ogImage.url
                : ""
              : "",
            link: this.state.attachedLink,
          },
          () => {
            if (this.state.showMyPost) {
              this.updatePost();
            } else {
              this.uploadPost();
            }
          }
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState(
          {
            ogImageUrl: "",
            ogDescription: "",
            ogImageUrl: "",
            link: this.state.attachedLink,
          },
          () => {
            if (this.state.showMyPost) {
              this.updatePost();
            } else {
              this.uploadPost();
            }
          }
        );
      });
  };

  uploadPost = () => {
    if (companyId === 1 && this.state.postTypeId === "0") {
      error("Please select post type id");
      this.setState({ postUploading: false });
      return;
    }

    if (
      this.state.attachedPics.length === 0 &&
      this.state.attachedDoc.length === 0 &&
      !this.state.postText &&
      !this.state.attachedLink
    ) {
      error("Please add any item to post");
      this.setState({ postUploading: false });
      return;
    }

    fetchWithJson(endpoint.uploadPost, {
      postTypeId: companyId === 1 ? this.state.postTypeId : 3,
      title: "",
      attachedPics: this.state.attachedPics,
      attachedDoc: this.state.attachedDoc,
      postText: this.state.postText,
      attachedLink: this.state.attachedLink,
      ogImageUrl: this.state.ogImageUrl,
      ogTitle: this.state.ogTitle,
      ogDescription: this.state.ogDescription,
    })
      .then((res) => res.json())
      .then((data) => {
        success("You have successfully uploaded the post");
        this.setState({
          postUploading: false,
          postList: [],
          hasMore: true,
          showMyPost: false,
        });
        this.fetchPosts();
        this.onCancel();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ postUploading: false });
        error("Something went wrong during upload post");
      });
  };

  updatePost = () => {
    if (
      this.state.attachedPics.length === 0 &&
      this.state.attachedDoc.length === 0 &&
      !this.state.postText &&
      !this.state.attachedLink
    ) {
      error("Please add any item to post");
      this.setState({ postUploading: false });
      return;
    }

    fetchWithJson(endpoint.updatePost, {
      postId: this.state.selectedPostId,
      title: "",
      attachedPics: this.state.attachedPics,
      attachedDoc: this.state.attachedDoc,
      postText: this.state.postText,
      attachedLink: this.state.attachedLink,
      ogImageUrl: this.state.ogImageUrl,
      ogTitle: this.state.ogTitle,
      ogDescription: this.state.ogDescription,
    })
      .then((res) => res.json())
      .then((data) => {
        success("You have successfully updated the post");
        this.setState({ postUploading: false, postList: [], hasMore: true });
        this.fetchMyPosts();
        this.onCancel();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ postUploading: false });
        error("Something went wrong during update post");
      });
  };

  deletePost = (post) => {
    this.setState({ postDeleting: true });
    fetchWithJson(endpoint.deletePost, {
      postId: post.id
    })
      .then((res) => res.json())
      .then((data) => {
        success("You have successfully removed the post");
        this.setState({ postDeleting: false, postList: [], hasMore: true });
        this.fetchMyPosts();
        this.onCancel();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ postDeleting: false });
        error("Something went wrong during remove post");
      });
  };

  postUploadModal = () => {
    var height = window.innerHeight;
    var width = window.innerWidth;
    return (
      <Modal
        blur
        css={{br: 0}}
        open={this.state.openPostDialog}
        onClose={this.onCancel}
        closeButton
        width="800px"
      >
        <Modal.Header><Text size={18}>Create a post</Text></Modal.Header>
        <Modal.Body>
          <PostUploadModal
            setValueInState={this.setValueInState}
            postText={this.state.postText}
            toggleAddLink={this.toggleAddLink}
            toggleUploadImage={this.toggleUploadImage}
            toggleUploadDoc={this.toggleUploadDoc}
            showAddLink={this.state.showAddLink}
            attachedLink={this.state.attachedLink}
            uploadImage={this.state.uploadImage}
            attachedPics={this.state.attachedPics}
            uploadImageName={this.state.uploadImageName}
            uploadDoc={this.state.uploadDoc}
            attachedDoc={this.state.attachedDoc}
            uploadDocName={this.state.uploadDocName}
            showPreviewLinkBlock={this.state.showPreviewLinkBlock}
            closePreviewLink={this.closePreviewLink}
            removeAttachedPic={this.removeAttachedPic}
            removeAttachedDoc={this.removeAttachedDoc}
            uploadPost={this.uploadPost}
            postUploading={this.state.postUploading}
            showMyPost={this.state.showMyPost}
            updatePost={this.updatePost}
            postTypeId={this.state.postTypeId}
            triggerUploadAndUpdate={this.triggerUploadAndUpdate}
            deletePost={this.deletePost}
            postDeleting={this.state.postDeleting}
          />
        </Modal.Body>
      </Modal>
    );
  };

  imagePreviewModal = () => {
    var height = window.innerHeight;
    var width = window.innerWidth;
    return (
      <Modal
        width="800px"
        height="640px"
        blur
        css={{br: 0}}
        open={this.state.openImagePreviewModal}        
        onClose={this.onCancel}
        closeButton
      >
        <Modal.Header>
          <Text size={18}>Image preview</Text>
        </Modal.Header>
        <Modal.Body>
          <div className="pa3 overflow-y-scroll">
            {this.state.selectedPics.map((item) => {
              return (
                <img
                  style={{
                    // height: 400,
                    marginRight: 10,
                    objectFit: "contain",
                    borderRadius: 5,
                    marginTop: 5,
                  }}
                  className="w-100 bg-white"
                  src={item.url}
                  alt={item.name}
                  title={item.name}
                />
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  postImagePreview = (pics) => {
    if (pics.length === 1) {
      return (
        <div
          className="flex flex-column w-100 mt3"
          onClick={() => {
            this.setState({
              selectedPics: pics,
              openImagePreviewModal: true,
            });
          }}
        >
          <div className="flex w-100 pa2" style={{ borderRadius: 5 }}>
            <img
              style={{
                height: 320,
                width: "100%",
                marginRight: 10,
                objectFit: "cover",
              }}
              className="bg-white-40 mr2"
              src={pics[0].url}
              alt={pics[0].name}
              title={pics[0].name}
            />
          </div>
        </div>
      );
    } else if (pics.length === 2) {
      return (
        <div
          className="flex flex-column w-100 mt3"
          onClick={() => {
            this.setState({
              selectedPics: pics,
              openImagePreviewModal: true,
            });
          }}
        >
          <div className="flex w-100 pa2" style={{ borderRadius: 5 }}>
            <img
              style={{
                height: 320,
                width: "50%",
                marginRight: 10,
                objectFit: "cover",
              }}
              className="bg-white-40 mr2"
              src={pics[0].url}
              alt={pics[0].name}
              title={pics[0].name}
            />
            <img
              style={{
                height: 320,
                width: "50%",
                marginRight: 10,
                objectFit: "cover",
              }}
              className="bg-white-40"
              src={pics[1].url}
              alt={pics[1].name}
              title={pics[1].name}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="flex flex-column w-100 mt3"
          onClick={() => {
            this.setState({
              selectedPics: pics,
              openImagePreviewModal: true,
            });
          }}
        >
          <div className="flex w-100 pa2" style={{ borderRadius: 5 }}>
            <img
              style={{
                height: 320,
                width: "60%",
                marginRight: 10,
                objectFit: "cover",
              }}
              className="bg-white-40 mr2"
              src={pics[0].url}
              alt={pics[0].name}
              title={pics[0].name}
            />
            <div className="flex flex-column w-2/5">
              <img
                style={{
                  height: 156,
                  width: "100%",
                  marginRight: 10,
                  objectFit: "cover",
                }}
                className="mb2 bg-white-40"
                src={pics[1].url}
                alt={pics[1].name}
                title={pics[1].name}
              />
              <img
                style={{
                  height: 156,
                  width: "100%",
                  marginRight: 10,
                  objectFit: "cover",
                }}
                className="bg-white-40"
                src={pics[2].url}
                alt={pics[2].name}
                title={pics[2].name}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  postDocPreview = (docs) => {
    return (
      <div className="flex w-100 mt3 flex-wrap">
        {docs.map((item) => {
          return (
            <a
              href={item.url}
              style={{
                textDecoration: "none",
                color: "rgba( 0, 0, 0, .8 )",
              }}
            >
              <div
                className="pointer bg-black-20 ma2 ph3 flex justify-center items-center"
                style={{
                  height: 40,
                  borderRadius: 20,
                }}
              >
                <Document set="light"primaryColor="blueviolet"/>
                <div className="tc f5 ml2">{item.originalName}</div>
              </div>
            </a>
          );
        })}
      </div>
    );
  };

  timelineView = (item) => {
    return (
      <div className="w-50-l w-100 mt3 pa3 br3 ba b--black-20">
        {this.state.showMyPost && (
          <div className="flex w-100 justify-end items-end">
            <EditSquare
              size={24}
              onClick={this.openEditModal.bind(null, item)}
              set='light'
            />
            <Delete
              size={24}
              onClick={this.deletePost.bind(null, item)}
              set="light"
              primaryColor="red"
            />
          </div>
        )}
        <div className="flex justify-between items-center">
          <div>
            <div className="f4">{item.companyName}</div>
            <div className="black-50">{item.post_type_desc}</div>
          </div>
          <div className="f5">{item.createdOn}</div>
        </div>
        {item.body && (
          <div className="mt2">{item.body ? item.body : "Not Available"}</div>
        )}
        {item.hyperlink && (
          <LinkPreview
            showClose={false}
            closePreviewLink={this.closePreviewLink}
            ogTitle={item.previewTitle}
            ogImageUrl={item.previewImage}
            ogDescription={item.previewDesc}
            link={item.hyperlink}
          />
        )}
        {item.imageArray.length > 0 && this.postImagePreview(item.imageArray)}
        {item.docArray.length > 0 && this.postDocPreview(item.docArray)}
      </div>
    );
  };
  
  render() {

    if (this.props.Access.allow_blog_posts === 0 && !this.state.loading) {
      return (
        <div className="ml3 nt3">
          <Text h4>
            News Feed feature is unavailable
          </Text>
        </div>
      );
    }

    return (
      <div className="">
        <Row justify='space-between' className="p-4 border-y-2 border-dotted">
          <SubPageHeader>
            News Feed
          </SubPageHeader>
          <div className="flex">
            <div className="mr2">
              <Switch
                checked={this.state.showMyPost}
                onChange={this.showMyPost}
              />
            </div>
            <Text size={16} b css={{py: '$2'}}>My posts</Text>
            <Button
              css={{br: 0, ml: 20}}
              auto
              size='sm'
              onClick={() => {
                this.setState({
                  openPostDialog: true,
                  attachedLink: "",
                  postText: "",
                  showMyPost: false,
                  uploadImageName: "(click here to open up the file browser)",
                  uploadDocName: "(click here to open up the file browser)",
                  selectedPostId: null,
                  attachedDoc: [],
                  attachedPics: [],
                  postList: [], 
                  hasMore: true
                }, () => {
                  this.fetchPosts();
                });
              }}
            >
              New Post
            </Button>
          </div>
        </Row>

        <InfiniteScroll
          dataLength={this.state.postList.length}
          next={this.state.showMyPost ? this.fetchMyPosts : this.fetchPosts}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: 'calc(100vh - 100px)',
          }}
          hasMore={this.state.hasMore}
          endMessage={
            <p className="tc mt2">
              <b>Yay! You have seen it all</b>
            </p>
          }
          loader={<h4 className="mt2">Loading...</h4>}
        >
          {this.state.postList.length > 0
            ? this.state.postList.map((item) => {
                return this.timelineView(item);
              })
            : null}
        </InfiniteScroll>

        {/* {this.timelineView()} */}
        {this.postUploadModal()}
        {this.imagePreviewModal()}
      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    clientId: state.client ? state.client.id : mstore.getPath(["user", "company_id"]),
    Access: state.navigation.Access
  }
}

export default connect(mapStateToProps)(newsFeed);
