/* @flow */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Password, Activity, Setting } from 'react-iconly';
import { ToastContainer } from 'react-toastify';
import { Row, Col } from '@nextui-org/react';

import HeaderComponent from '../Header';
import SideBar from './SideBar';
import { NavName } from './NavName';

import ClientSelector from '../ClientSelector';
import logo from '../../assets/mtt.png';

import { navShow, navHide } from '../../action/navigation';
import { mstore } from '../../store';
import { logoutAndClearStore } from '../../auth0';

const headerConnector = connect(
  state => ({
    isNavOpen: state.navigation.isNavOpen,
    name: mstore.get('user', 'name')
  }),
  {
    onNavShow: navShow,
    onNavHide: navHide
  }
);

const Header = headerConnector(HeaderComponent);

const MainComponent = ({
  isNavOpen,
  dispatch,
  children,
  links,
  title,
  Access,
  dcSettings
}) => {
    
    const isSuperAdmin = mstore.get('user', 'isSuperAdmin');
    const isAdmin = mstore.get('user', 'isAdmin');
    const isDriverAdmin = mstore.get('user', 'isDriverAdmin');
    const navWidth = isNavOpen ? '200px' : '54px'

    /**
     *
     * Element Styles, based on Navbar status
     *
     */

    const cntPaneClass = isNavOpen ? 'page fl pl4' : 'page fl pl2';

    const headerSettings =
        (isSuperAdmin || isAdmin || isDriverAdmin) && dcSettings ? (
        <NavLink to="/driver/settings" style={{ textDecoration: 'none' }}>
            <div style={{ textAlign: 'center'}} className="mt2 px-4 text-secondary">
                <Setting size='large' set='bold'/>
            </div>
        </NavLink>
        ) : null;

    

  return (
    <div style={{height: "100%", width: "100%"}}>
        <ToastContainer />
        <Header
            leftTitle={<img src={logo} style={{height: 45, marginTop: 3}}/>}
            centerTitle={title}
            rightItem={headerSettings}
            onLogOut={logoutAndClearStore.bind(null, dispatch)}
        />

        <Row>
            <Col
                css={{maxWidth: navWidth, minWidth: navWidth }}>
                <SideBar
                    style={{ width: navWidth }}
                    className="fl h-100 pt2 bg-gray-100 border-x"
                    bottomWidget={isNavOpen && isSuperAdmin && <ClientSelector />}>
                    {links.map((l, i) => {
                        if ((!Access.allow_receipt_management || Access.allow_receipt_management === 0) && l.name === 'Reimbursable Receipt') {
                            return false;
                        }
                        if ((!Access.allow_ratings_artifax || Access.allow_ratings_artifax === 0) && l.name === 'Shipment Artifax™') {
                            return false;
                        }
                        if ((!Access.allow_artifax || Access.allow_artifax === 0) && l.name === 'Shipment Documents') {
                            return false;
                        }
                        if ((!Access.allow_osd || Access.allow_osd === 0) && l.name === 'OS&D') {
                            return false;
                        }
                        if ((!Access.allow_location_analytics || Access.allow_location_analytics === 0) && l.name === 'Location Analytics') {
                            return false;
                        }
                        if ((!Access.allow_location_info || Access.allow_location_info === 0) && l.name === 'Location Information') {
                            return false;
                        }
                        if ((!Access.allow_safety_documents || Access.allow_safety_documents === 0) && l.name === 'Safety') {
                            return false;
                        }
                        if ((!Access.allow_broker_shipment_management || Access.allow_broker_shipment_management === 0) && l.name === 'Shipment Management') {
                            return false;
                        }
                        if ((!Access.allow_detention_management || Access.allow_detention_management === 0) && l.name === 'Detention Management') {
                            return false;
                        }
                        if (!(isSuperAdmin || isAdmin || isDriverAdmin) && l.name === 'API Keys') {
                            return true;
                        }
                        if (l.name === 'News Feed' || l.name === 'API Keys') {
                            return false;
                        }
                        return (
                            <NavLink 
                                style={{ textDecoration: 'none' }}
                                key={l.href} 
                                to={l.href} 
                                className="f5" >
                                <NavName name={l.name} isNavOpen={isNavOpen} icon={l.icon}/>
                            </NavLink>
                        );
                        })
                    }
                    <hr className="mt3 b--black-60" />
                    <NavLink 
                        style={{ textDecoration: 'none' }}
                        key='/driver/developer'
                        to='/driver/developer' 
                        className="f5" >
                        <NavName name='API Keys' isNavOpen={isNavOpen} icon={<Password set="light" filled primaryColor='gray'/>}/>
                    </NavLink>
                    {Access.allow_blog_posts === 1 && <hr className="b--black-60" />}
                    {Access.allow_blog_posts === 1 && (
                        <NavLink 
                            style={{ textDecoration: 'none' }}
                            key='/driver/news-feed'
                            to="/driver/news-feed">
                            <NavName name='News Feed' isNavOpen={isNavOpen} icon={<Activity set="light" filled primaryColor='gray'/>}/>
                        </NavLink>
                    )}
                    {isSuperAdmin && title === 'MTT CONTROL' && (<hr className="mt3 mb0 bw2 b--black-80" />)}
                </SideBar>
            </Col>
            <Col className={cntPaneClass} style={{ flex: 1, marginRight: '10px' }}>
                {children}
            </Col>
        </Row>
    </div>
  );
};

export default connect(state => ({
  isNavOpen: state.navigation.isNavOpen,
  Access: state.navigation.Access
}))(MainComponent);
