import React, { Component } from 'react';
import { connect } from 'react-redux';
import filter from 'lodash/filter';
import Select from 'react-select';

import { mstore } from '../../store';
import endpoint from '../../config/endpoint.json';

import checkAccess from '../../lib/checkAccess';
import { secureFetch } from '../../lib/api';
import { updateTitle } from '../../lib/utils';
import { error, success, warning } from '../../lib/userAlerts';
import { Loading, Button, Input } from '@nextui-org/react';

const thStyle = {
  fontSize: "1.2rem",
  fontWeight: "400",
  textAlign: 'left'
}

const tdStyle = {
  fontSize: "1.0rem",
  fontWeight: "400",
  verticalAlign: "middle"
}

class DriverDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      driverList: [],
      apiDriverList: [],
      loading: true,
      coordinatorList: [{ value: 'loading.......', label: 'Loading....' }],
    }
    this._onSave = this._onSave.bind(this)
  }

  componentDidMount() {
    updateTitle('Driver Details');
    checkAccess(this.props.clientId);

    this.getDriverList();
    this.getCoordinatorList();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.clientId !== this.props.clientId) {
      checkAccess(newProps.clientId);

      this.getDriverList();
      this.getCoordinatorList();
    }
  }

  getDriverList = () => {
    secureFetch(endpoint.getDriverList, {}).then(([ok, status, json]) => {
      if (ok) {
        
        this.setState({ driverList: json.driverList, loading: false });
        this.setState({ apiDriverList: json.driverList, loading: false });

        json.driverList.forEach((ele) => {
          if (ele.coordinator_id) {
            let coordinatorId = ele.id + 'coordinatorId'
            this.setState({ [coordinatorId]: ele.coordinator_id })
          }
        })
      } else {
        error('Oops! error occured during fetching driver list!');
      }
    });
  }
  getCoordinatorList = () => {
    secureFetch(endpoint.getCoordinatorList, {}).then(([ok, status, json]) => {
      if (ok) {
        this.setState({ coordinatorList: json.coordinatorList.map(k => ({ label: k.name, value: k.id })) })
      } else {
        error('Oops! error occured during fetching order number list!');
      }
    });
  }
  driverAndCoordinatorMap = (coordinatorId, driverId) => {
    secureFetch(endpoint.driverAndCoordinatorMap, { coordinator_Id: coordinatorId, driver_Id: driverId }).then(([ok, status, json]) => {
      if (ok) {
        success('Driver Coordinator successfully added.')
      } else {
        error('Oops! error occured during fetching order number list!');
      }
    });
  }
  //scerch according to coordinator name
  handleChange = (selectedOption, e) => {
    let coordinatorId = selectedOption + 'coordinatorId'
    // let saveBtn = selectedOption + 'btn'
    this.setState({ [coordinatorId]: e ? e.value : "" })
  }
  _onSave(driverId) {
    let coordinatorId = driverId + 'coordinatorId'
    if(this.state[coordinatorId]){
      this.driverAndCoordinatorMap(this.state[coordinatorId], driverId)      
    }else{
      warning("Please select coordinator first")
    }
  }

  filterList = (event) => {

    let filterVal = event.target.value.toLowerCase();
    let filteredDriverList = filter(this.state.apiDriverList, function(o) { 
        if ( o.lname && o.lname.toLowerCase().indexOf(filterVal) !== -1 ) {
            return true;
        }
        if ( o.fname && o.fname.toLowerCase().indexOf(filterVal) !== -1 ) {
          return true;
        } 
        if ( o.employeeID && o.employeeID.toLowerCase().indexOf(filterVal) !== -1 ) {
          return true;
        } 
        return (o.emailAddress.toLowerCase().indexOf(filterVal) !== -1 );
    });

    this.setState({ driverList: filteredDriverList }); 
  }

  renderUserRow = (obj, idx) => {
    let saveBtn =
      <Button
        ghost
        css={{br: 0}}
        size='sm'
        auto
        onClick={this._onSave.bind(null, obj.id)}
      >
        Save
      </Button>
    return (
      <tr key={idx} className="striped--near-white ">
        <td className='pv2 ph3' style={{ ...tdStyle }}>{idx + 1}</td>
        <td className='pv2 ph3' style={{ ...tdStyle }}>{obj.emailAddress}</td>
        <td className='pv2 ph3' style={{ ...tdStyle }}>{obj.employeeID}</td>
        <td className='pv2 ph3' style={{ ...tdStyle }}>{obj.fname}</td>
        <td className='pv2 ph3' style={{ ...tdStyle }}>{obj.lname}</td>
        <td className='pv2 ph3'>
          <Select
            clearable={false}
            name="form-field-name"
            style={{ width: '200px' }}
            placeholder="Search coordinator"
            value={this.state[obj.id + 'coordinatorId']}
            onChange={this.handleChange.bind(null, obj.id)}
            options={this.state.coordinatorList}
          /></td>
        <td>{saveBtn}</td>
      </tr>
    );
  }

  render() {
    return (
      <div className="ml3 mt3">
              
        <Input
            type="text"
            placeholder="Search by Name, Email or Employee ID"
            onChange={this.filterList}
            clearable
            css={{ $$inputBorderRadius: "0", w: '90%', h: 38 }}
            shadow={false}
            bordered={true}
            rounded={false}
        />
        
        {this.state.loading && <div className="flex justify-center mt6">
          <div>
            <Loading size='lg'>Fetching driver details...</Loading>
          </div>
        </div>}
        {(!this.state.loading && this.state.apiDriverList.length !== 0) && <table
          className={'table w-90 mt4'}
          style={{ border: '1px solid #c3c3c3' }}>
          <thead>
            <tr>
              <th className='pv2 ph3' style={{ ...thStyle}} >Sl No</th>
              <th className='pv2 ph3' style={{ ...thStyle}} >Email</th>
              <th className='pv2 ph3' style={{ ...thStyle}} >Employee ID</th>
              <th className='pv2 ph3' style={{ ...thStyle}} >First Name</th>
              <th className='pv2 ph3' style={{ ...thStyle}} >Last Name</th>
              <th className='pv2 ph3' style={{ ...thStyle}} >Coordinator List</th>
              <th className='pv2 ph3' style={{ ...thStyle}} >Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.driverList.map((obj, idx) => this.renderUserRow(obj, idx))}
          </tbody>
        </table>}
        {(!this.state.loading && this.state.driverList.length === 0) && <div className=" mt3 f5">No Driver Found.</div>}
      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    clientId: state.client ? state.client.id : mstore.getPath(["user", "company_id"]),
    Access: state.navigation.Access
  }
}

export default connect(mapStateToProps)(DriverDetails);