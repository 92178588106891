import React from 'react';

export const NavName = ({name, isNavOpen, icon}) => {
    return(
        <div>
            {isNavOpen ? 
                <div className='text-md text-gray-800 font-light'> 
                    {name} 
                </div> 
            :
                icon
            }       
        </div>
    )
}