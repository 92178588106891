import React, { Component } from "react";
import { Async } from "react-select";
import { debounce } from "lodash";

import endpoint from "../../config/endpoint.json";
import { error } from "../../lib/userAlerts";
import { searchOrderList } from "../../lib/utils"

import { secureFetch } from "../../lib/api";
import { Button, Text, Tooltip } from "@nextui-org/react";
import { Filter2 } from "react-iconly";

class FilterSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderNoList: [],
      orderNo: null,
      clientId: ''
    };
  }

  componentDidMount() {
  }

  componentWillReceiveProps(newProps) {
    if (newProps.clientId !== this.state.clientId) {
      this.setState({clientId: newProps.clientId});
      this.setState({
        orderNoList: []
      })
    }
  }

  getOrderNO = (input, callback) => {
    let orderNumbers = [];

    // If our state is not empty
    if(this.state.orderNoList?.length > 0){
      orderNumbers = searchOrderList(this.state.orderNoList, input)
    }

    // If order exists in our cache
    if(orderNumbers?.length > 0){
      callback(null, {
        options: orderNumbers,
        // CAREFUL! Only set this to true when there are no more options,
        // or more specific queries will not be sent to the server.
        complete: true
      });
    }
    else{
      // Now fetch orders from the API.
      secureFetch(endpoint.getOrderNO, {
        filter: input,
        os: this.state.OS,
        osFilterRequired: false,
      })
      .then(([ok, status, json]) => {
        if (ok) {
          // Update state for future search.
          this.setState({
            orderNoList: json.orderNo.map((k) => ({
              label: k.order_id,
              value: k.order_id,
            }))
          });

          orderNumbers = json.orderNo.map((k) => ({
            label: k.order_id,
            value: k.order_id,
          }))
        } else {
          error("Oops! Unable to fetch order number list.");
        }
      })
      .finally(()=>{
        callback(null, {
          options: orderNumbers,
          // CAREFUL! Only set this to true when there are no more options,
          // or more specific queries will not be sent to the server.
          complete: true
        });
      });
    }
  };

  handleChange = (item) =>{
    this.setState({orderNo: item});
    this.props.modifiState(item, "orderNo");
  }

  renderShipmentUpload() {
    return (
      <div className="pa2">
        <div className="ba b--dashed pa2">
          <table style={{ borderCollapse: "collapse" }}>
            <tr>
              <th className="f5">Filter options</th>
              <th className="br pr2"></th>
              <th className="pl2 f5">Sorting options</th>
              <th></th>
            </tr>
            <tr>
              <td>
                <div className="fw6">Primary filter</div>
                <Button
                  css={{br: 0, mt: 10}}
                  size='sm'
                  color={this.props.myOrdersFilter ? 'success' : 'primary'}
                  onClick={() => {
                    this.props.modifiState(true,"myOrdersFilter");
                    this.props.modifiState(false, "unassignOrdersFilter");
                    this.props.modifiState(false, "allOrdersFilter");
                    this.props.modifiState(false, "allOpenOrdersFilter");
                  }}
                >
                  My Orders
                </Button>
              </td>
              <td className="br pr2"></td>
              <td className="pl2">
                <Button
                  css={{br: 0, mt: 10}}
                  size='sm'
                  color={this.props.shotByAppointmentTime ? 'success' : 'primary'} 
                  onClick={() => {
                    this.props.modifiState(
                      !this.props.shotByAppointmentTime,
                      "shotByAppointmentTime"
                    );
                  }}
                >
                  by appointment time
                </Button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <Button
                  css={{br: 0, mt: 10}}
                  size='sm'
                  color={this.props.allOrdersFilter ? 'success' : 'primary'}
                  onClick={() => {
                    this.props.modifiState(true,"allOrdersFilter");
                    this.props.modifiState(false,"myOrdersFilter");
                    this.props.modifiState(false, "unassignOrdersFilter");
                    this.props.modifiState(false, "allOpenOrdersFilter");
                  }}
                >
                  All Orders
                </Button>
              </td>
              <td className="br pr2"></td>
              <td className="pl2"></td>
            </tr>
            <tr>
              <td>
                <Button
                  color={this.props.allOpenOrdersFilter ? 'success' : 'primary'}
                  css={{br: 0, mt: 10}}
                  size='sm'
                  onClick={() => {
                    this.props.modifiState(true,"allOpenOrdersFilter");
                    this.props.modifiState(false,"myOrdersFilter");
                    this.props.modifiState(false, "allOrdersFilter");
                    this.props.modifiState(false, "unassignOrdersFilter");
                  }}
                >
                  All Open Orders
                </Button>
              </td>
              <td className="br pr2"></td>
              <td className="pl2"></td>
            </tr>
            <tr>
              <td>
                <Button
                  css={{br: 0, mt: 10}}
                  size='sm'
                  color={this.props.unassignOrdersFilter ? 'success' : 'primary'}
                  onClick={() => {
                    this.props.modifiState(true,"unassignOrdersFilter");
                    this.props.modifiState(false,"myOrdersFilter");
                    this.props.modifiState(false, "allOrdersFilter");
                    this.props.modifiState(false, "allOpenOrdersFilter");
                  }}
                >
                  Unassigned Orders
                </Button>
              </td>
              <td className="br pr2"></td>
              <td className="pl2"></td>
            </tr>
            <tr>
              <td>
                <div className="fw6 mt2">Secondary filter</div>
                <Button
                  css={{br: 0, mt: 10}}
                  size='sm'
                  color={this.props.lateEtaOrdersFilter ? 'success' : 'primary'} 
                  onClick={() => {
                    this.props.modifiState(
                      !this.props.lateEtaOrdersFilter,
                      "lateEtaOrdersFilter"
                    );
                    this.props.modifiState(false, "unassignOrdersFilter");
                  }}
                >
                  Late ETA orders
                </Button>
              </td>
              <td className="br pr2"></td>
              <td className="pl2"></td>
            </tr>
            <tr>
              <td>
                <Button
                  css={{br: 0, mt: 10}}
                  size='sm'
                  color={this.props.snoozeOrderFilter ? 'success' : 'primary'} 
                  onClick={() => {
                    this.props.modifiState(
                      !this.props.snoozeOrderFilter,
                      "snoozeOrderFilter"
                    );
                    this.props.modifiState(false, "unassignOrdersFilter");
                  }}
                >
                  Snoozed Orders
                </Button>
              </td>
              <td className="br pr2"></td>
              <td className="pl2"></td>
            </tr>
          </table>
        </div>
        <div className="flex justify-between">
          <Button
            size='sm'
            ghost
            auto
            css={{br: 0, mt: 10}}
            onClick={this.props.resetFilter}
          >
            Reset Filters
          </Button>

          <Button
            size='sm'
            color='secondary'
            auto
            css={{br: 0, mt: 10}}
            onClick={() => {
              this.props.modifiState(1, "selectedPage");
            }}
          >
            Search
          </Button>
        </div>
      </div>
    );
  }

  render() {
    let orderList = this.state.orderNoList;

    // const clientId = mstore.get("user", "company_id");
    return (
      <div className="flex w-100 justify-between mt2">
        <div className="flex" key={this.props.clientId}>
          <Async
              name="form-field-name"
              style={{ width: "240px", zIndex: 1}}
              placeholder="Search order"
              value={this.state.orderNo}
              onChange={this.handleChange}
              options={orderList}
              loadOptions={debounce(this.getOrderNO, 500)}
            />
          {this.state.orderNo && this.state.orderNo.value && (
            <span className="f5 bg-washed-yellow mr2 ml2 pa2">
              Other filters disabled as a specific order has been selected. 
            </span>
          )}
          <Tooltip placement="bottom" content={this.renderShipmentUpload()} trigger='click'>
            <Button
              auto
              css={{br: 0, ml: 10, h: 35}}
              icon={<Filter2 set="light"/>}
              disabled={this.state.orderNo && this.state.orderNo.value}
              onClick={() => {}}
            >
              <Text size='1rem' color='currentColor'>Other Filters</Text>
            </Button>
          </Tooltip>
        </div>


        <div className="flex">
          <Button
            auto
            css={{br: 0, ml: 10, h: 35}}
            ghost
            onClick={this.props.selectAll}
          >
            Select All
          </Button>
          <Button
            auto
            css={{br: 0, ml: 10, h: 35}}
            ghost
            onClick={this.props.toggleSnoozModal}
          >
            Manage Snooze Buckets
          </Button>
        </div>
      </div>
    );
  }
}

export default FilterSection;
