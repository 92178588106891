import React, { Component } from "react";
import { connect } from "react-redux";

import { Loading, Modal, Text, Button, Tooltip, Textarea } from '@nextui-org/react';
import { AgGridReact } from "ag-grid-react";
import Papa from "papaparse";

import endpoint from "../../config/endpoint.json";
import { mstore } from "../../store";

import { fetchWithJson } from "../../lib/api";
import { error, success, warning } from "../../lib/userAlerts";
import dayjs from '../../lib/day';
import checkAccess from '../../lib/checkAccess';

import ReimbursableRecipetsDetails from "./ReimbursableRecipetsDetails";
import FilterReceipt from "../../components/PageSelectionComponent/FilterReceipt";
import SubPageHeader from "../../components/SubPageHeader";

const APPROVED = "Approved";
const REJECTED = "Rejected";
const RESET = "New";
const cellStyle = {textAlign: "center"};

const createColumnDefs = () => {
  return [
    {
      headerName: "Receipt Number",
      field: "receipt_number",
      filter: "agNumberColumnFilter",
      headerClass: 'tc',
      cellStyle: cellStyle,
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
      width: 90,
      resizable: true,
    },
    {
      headerName: "Order Number",
      field: "order_id",
      filter: "agTextColumnFilter",
      headerClass: 'tc',
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
      width: 150,
      resizable: true,
    },
    {
      headerName: "Status",
      field: "status",
      filter: "agTextColumnFilter",
      headerClass: 'tc',
      width: 90,
      resizable: true,
      cellClassRules: {
        "bg-light-red": function (params) {
          return params.value === REJECTED;
        },
        "bg-light-green": function (params) {
          return params.value === APPROVED;
        },
      },
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
    },
    {
      headerName: "Merchant Name",
      field: "driver_merchant_name",
      headerClass: 'tc',
      suppressMenu: true,
      minWidth: 180,
      resizable: true,
    },
    { 
      headerName: "Date Entered", 
      field: "date_entered",
      headerClass: 'tc',
      minWidth: 120,
      resizable: true,
    },
    {
      headerName: "Employee Id",
      field: "employee_id",
      filter: "agTextColumnFilter",
      headerClass: 'tc',
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
      minWidth: 120,
      resizable: true,
    },
    {
      headerName: "Employee Name",
      field: "employee_name",
      filter: "agTextColumnFilter",
      headerClass: 'tc',
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
      minWidth: 150,
      resizable: true,
    },
    { 
      headerName: "OCR Date", 
      field: "ocr_date",
      headerClass: 'tc',
      minWidth: 120,
      resizable: true,
    },
    { 
      headerName: "OCR Total", 
      field: "ocr_total", 
      suppressMenu: true,
      cellStyle: cellStyle,
      headerClass: 'tc',
      minWidth: 90,
      resizable: true,
    },
    {
      headerName: "OCR Confidence",
      field: "ocr_confidence",
      suppressMenu: true,
      cellStyle: cellStyle,
      minWidth: 90,
      resizable: true,
    },
    { 
      headerName: "Employee Date", 
      field: "employee_date",
      headerClass: 'tc',
      minWidth: 120,
      resizable: true,
    },
    {
      headerName: "Employee Total",
      field: "employee_total",
      headerClass: 'tc',
      suppressMenu: true,
      minWidth: 120,
      cellStyle: cellStyle,
      resizable: true,
    },
    {
      headerName: "Date Match",
      field: "date_match",
      filter: "agTextColumnFilter",
      headerClass: 'tc',
      cellStyle: cellStyle,
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
      minWidth: 90,
      resizable: true,
    },
    {
      headerName: "Total Match",
      field: "total_match",
      filter: "agTextColumnFilter",
      headerClass: 'tc',
      cellStyle: cellStyle,
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
      minWidth: 90,
      resizable: true,
    },
    { 
      headerName: "Final Date", 
      field: "final_date", 
      headerClass: 'tc',
      suppressMenu: true,
      minWidth: 120, 
      resizable: true,
    },
    { 
      headerName: "Final Total", 
      field: "final_amount", 
      suppressMenu: true,
      minWidth: 90, 
      cellStyle: cellStyle,
      resizable: true,
      headerClass: 'tc'
    },
  ];
};

class ReimbursableRecipetsManagement extends Component {
  constructor(props) {
    super(props);
    let gridApi;

    this.state = {
      receiptsListBackUp: [],
      receiptsList: [],
      receiptDataForCsv: [],
      normalReceipts: [],
      archiveReceipts: [],
      currentReceipt: {},
      selectedReceipt: [],
      isOpen: false,
      noOfFilter: 0,
      fetching: false,
      receiptReferenceNo: null,
      exp1: "",
      exp2: "",
      Approved: 0,
      Denyed: 0,
      New: 0,
      driverId: "",
      orderNo: "",
      DateMatch: 0,
      TotalMatch: 0,
      noOfFilter: 0,
      comment: "",
      columnDefs: createColumnDefs(),
      rowSelection: "multiple",
      showArchiveReceipt: false,
    };
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  };

  onSelectionChanged() {
    let selectedRows = this.gridApi.getSelectedRows();
    this.setState({ selectedReceipt: selectedRows });
  }

  rowDoubleClicked = (row) => {
    this.setState({ isOpen: true, currentReceipt: row.data });
  };

  onCancel = () => {
    this.setState({ isOpen: false });
    // this.fetchReceiptsList(); -- Not needed ?
  };

  componentDidMount() {
    checkAccess(this.props.clientId);
    this.fetchReceiptsList();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.clientId !== this.props.clientId) {
      checkAccess(newProps.clientId);
      this.fetchReceiptsList();
    }
  }

  removeOCRMarchantAndAddress = (data) => {
    this.setState({ receiptDataForCsv: [] });
    let csvList = [];
    data.forEach((item) => {
      csvList.push({
        date_entered: item.date_entered,
        date_match: item.date_match,
        employee_date: item.employee_date,
        employee_id: item.employee_id,
        employee_name: item.employee_name,
        employee_total: item.employee_total,
        driver_merchant_name: item.driver_merchant_name,
        final_amount: item.final_amount,
        final_date: item.final_date,
        ocr_confidence: item.ocr_confidence,
        ocr_date: item.ocr_date,
        ocr_total: item.ocr_total,
        order_id: item.order_id,
        receipt_number: item.receipt_number,
        status: item.status,
        stop_id: item.stop_id,
        stop_name: item.stop_name,
        total_match: item.total_match,
      });
    });
    this.setState({ receiptDataForCsv: csvList });
  };

  fetchReceiptsList = (
    order_id = null,
    driver_id = null,
    date_match = null,
    tot_match = null,
    is_approved = null,
    is_rejected = null,
    is_new = null,
    receipt_id = null,
    start_date = null,
    end_date = null
  ) => {
    this.setState({
      fetching: true,
      receiptsList: [],
      normalReceipts: [],
      archiveReceipts: [],
    });

    fetchWithJson(endpoint.dc_fetch_driver_receipts_portal, {
      company_id: this.props.clientId,
      order_id: order_id,
      driver_id: driver_id,
      date_match: date_match,
      tot_match: tot_match,
      is_approved: is_approved,
      is_rejected: is_rejected,
      is_new: is_new,
      receipt_id: receipt_id,
      start_date: start_date,
      end_date: end_date,
    })
      .then((res) => res.json())
      .then((data) => {
        let archiveReceipts = [];
        let normalReceipts = [];

        data.forEach((item) => {
          if (item.is_archived === 1) {
            archiveReceipts.push(item);
          } else {
            normalReceipts.push(item);
          }
        });

        this.setState({
          receiptsList: this.state.showArchiveReceipt
            ? archiveReceipts
            : normalReceipts,
          normalReceipts: normalReceipts,
          archiveReceipts: archiveReceipts,
          receiptsListBackUp: data,
          fetching: false,
        });
        this.removeOCRMarchantAndAddress(data);
      })
      .catch((err) => {
        error("Oops! error occured when fetching receipts");
        console.log(err);
      });
  };

  setStatusOnGrid = (
    receipt_number,
    status,
    final_date = null,
    final_amount = null
  ) => {
    let rowNode = this.gridApi.getRowNode(receipt_number);
    
    rowNode.setDataValue("status", status);
    rowNode.setDataValue("final_date", final_date);
    rowNode.setDataValue("final_amount", final_amount);
  };

  handleApproved = () => {
    if (this.state.selectedReceipt.length === 0) {
      warning("Please select a receipt");
    } else {
      this.state.selectedReceipt.forEach((items) => {
        fetchWithJson(endpoint.dc_approve_receipt, {
          receipt_id: items.receipt_number,
          amount: items.employee_total,
          date: dayjs(items.employee_date, "DD-MMM-YYYY").format("YYYY-MM-DD"),
          comments: this.state.comment,
        })
          .then((res) => res.json())
          .then((data) => {
            success(`Receipt number ${items.receipt_number} ${APPROVED}`);
            this.setStatusOnGrid(
              items.receipt_number,
              APPROVED,
              items.employee_date,
              items.employee_total
            );
            this.fetchReceiptsList();
          })
          .catch((err) => {
            error("Error occurred while approving the receipt");
            console.log(err);
          });
      });
    }
  };

  handleReject = () => {
    if (this.state.selectedReceipt.length === 0) {
      warning("Please select a receipt");
    } else {
      this.state.selectedReceipt.forEach((items) => {
        fetchWithJson(endpoint.dc_reject_receipt, {
          receipt_id: items.receipt_number,
          comments: this.state.comment,
        })
          .then((res) => res.json())
          .then((data) => {
            error(`Receipt number ${items.receipt_number} ${REJECTED}`);
            this.setStatusOnGrid(items.receipt_number, REJECTED);
            this.fetchReceiptsList();
          })
          .catch((err) => {
            error("Error occurred while rejecting the receipt");
            console.log(err);
          });
      });
    }
  };

  handleReset = () => {
    if (this.state.selectedReceipt.length === 0) {
      warning("Please select a receipt");
    } else {
      this.state.selectedReceipt.forEach((items) => {
        fetchWithJson(endpoint.dc_reset_receipt, {
          receipt_id: items.receipt_number,
        })
          .then((res) => res.json())
          .then((data) => {
            warning(`Receipt number ${items.receipt_number} reset`);
            this.setStatusOnGrid(items.receipt_number, RESET);
            this.fetchReceiptsList();
          })
          .catch((err) => {
            error("Error occurred while re-setting the receipt status");
            console.log(err);
          });
      });
    }
  };

  resetFilter = () => {
    this.setState({
      receiptReferenceNo: 0,
      driverId: "",
      exp1: "",
      exp2: "",
      Approved: 0,
      Denyed: 0,
      New: 0,
      DateMatch: 0,
      TotalMatch: 0,
      orderNo: "",
      noOfFilter: 0,
    });
  };

  //filter orderlist
  handleSearchButton = (
    exp1,
    exp2,
    Approved,
    Denyed,
    New,
    driverId,
    orderNo,
    receiptReference,
    DateMatch,
    TotalMatch,
    noOfFilter
  ) => {
    this.setState(
      {
        exp1: "",
        exp2: "",
        Approved: 0,
        Denyed: 0,
        New: 0,
        driverId: "",
        orderNo: "",
        receiptReferenceNo: null,
        DateMatch: 0,
        TotalMatch: 0,
        noOfFilter: 0,
      },
      () => {
        this.setState({
          noOfFilter: noOfFilter,
          exp1: exp1,
          exp2: exp2,
          Approved: Approved,
          Denyed: Denyed,
          New: New,
          driverId: driverId,
          orderNo: orderNo,
          receiptReferenceNo: receiptReference,
          DateMatch: DateMatch,
          TotalMatch: TotalMatch,
        });
      }
    );

    let p_order = orderNo ? orderNo : null;
    let p_driver = driverId ? driverId : null;
    let p_date_match = DateMatch === 1 ? true : null;
    let p_tot_match = TotalMatch === 1 ? true : null;
    let p_is_approved = Approved === 1 ? true : null;
    let p_is_rejected = Denyed === 1 ? true : null;
    let p_is_new = New === 1 ? true : null;
    let p_receipt_id = receiptReference ? parseInt(receiptReference, 10) : null;
    let p_start_date = exp1 ? exp1 : null;
    let p_end_date = exp1 && exp2 ? exp2 : null;

    this.fetchReceiptsList(
      p_order,
      p_driver,
      p_date_match,
      p_tot_match,
      p_is_approved,
      p_is_rejected,
      p_is_new,
      p_receipt_id,
      p_start_date,
      p_end_date
    );
    this.setState({ selectedReceipt: [] });
  };

  commentPopUp(action) {

    return (
      <div className="pa2">
        <label htmlFor="" className="f5 w-100">
          Comment (optional, comments entered here will overwrite the previous
          comments - IF ANY)
        </label>
        <div className="mt2 w-100">
          <Textarea
            bordered 
            fullWidth 
            css={{br:0, mt: 10, br: 0}}
            rows={4}
            value={this.state.comment}
            onChange={(e) => {
              if (e.target.value.length < 2000) {
                this.setState({ comment: e.target.value });
              }
            }}
          ></Textarea>
          <div className="flex justify-end">
            <Button
              size='sm'
              auto
              css={{br: 0, mt:10}}
              color={action === APPROVED ? "success" : "error"}
              onClick={
                action === APPROVED ? this.handleApproved : this.handleReject
              }
            >
              Submit
            </Button>
          </div>

        </div>
      </div>
    );
  }

  render() {

    var height = window.innerHeight;

    const receipt_number_text = 'Details for Receipt #' + this.state.currentReceipt.receipt_number;
    const gridContainerStyle = {
      height: (height - 150) + "px",
    };

    let config = {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
    };

    if (this.props.Access.allow_receipt_management === 1) {
      return (
        <div className="">
          <div className="flex justify-between border-y-2 border-dotted p-4">
            <SubPageHeader>Reimbursable Receipts Management</SubPageHeader>
            <div className="flex justify-end">
              <Tooltip 
                css={{br: 0, pa: 10}}
                placement="bottom" 
                content={<div>{this.commentPopUp("Reject")}</div>} 
                trigger='click'>
                <Button
                  css={{br: 0, mr: 10}}
                  auto
                  size='sm'
                  ghost
                  color='error'
                  // onClick={this.handleReject}
                >
                  Reject
                </Button>
              </Tooltip>

              <Button
                css={{br: 0, mr: 10}}
                auto
                size='sm'
                ghost
                color='primary'
                onClick={this.handleReset}
              >
                Reset status
              </Button>

              <Tooltip 
                css={{br: 0, pa: 10}}
                placement="bottom" 
                content={<div>{this.commentPopUp("Approved")}</div>} 
                trigger='click'>
                <Button
                  css={{br: 0, mr: 10}}
                  auto
                  size='sm'
                  ghost
                  color='success'
                  // onClick={this.handleApproved}
                >
                  Adopt Driver Data
                </Button>
              </Tooltip>

              {this.state.receiptsList.length !== 0 && (
                <Button
                  css={{br: 0, mr: 10}}
                  auto
                  size='sm'
                  ghost
                  color='primary'
                  onClick={() => {
                    let csv = Papa.unparse(
                      this.state.receiptDataForCsv,
                      config
                    );
                    if (csv == null) return;
                    let filename = "export_receipts.csv";
                    if (!csv.match(/^data:text\/csv/i)) {
                      csv = "data:text/csv;charset=utf-8," + csv;
                    }
                    let data = encodeURI(csv);

                    let link = document.createElement("a");
                    link.setAttribute("href", data);
                    link.setAttribute("download", filename);
                    document
                      .getElementById("ReceiptManagementContainer")
                      .appendChild(link);
                    link.click();
                  }}
                >
                  Export to CSV
                </Button>
              )}

              <Button
                css={{br: 0, mr: 10}}
                auto
                size='sm'
                color='secondary'
                onClick={() => {
                  this.setState(
                    { showArchiveReceipt: !this.state.showArchiveReceipt },
                    () => {
                      if (this.state.showArchiveReceipt) {
                        this.setState({
                          receiptsList: this.state.archiveReceipts,
                        });
                      } else {
                        this.setState({
                          receiptsList: this.state.normalReceipts,
                        });
                      }
                    }
                  );
                }}
              >
                {this.state.showArchiveReceipt? "Show Active Receipts": "Show Archived Receipts"}
              </Button>

              <div
                id="ReceiptManagementContainer"
                style={{ display: "none" }}
              />

              <Tooltip 
                css={{br: 0}}
                trigger='click'
                placement="bottom"
                content={
                  <FilterReceipt
                    handleSearchButton={this.handleSearchButton}
                    exp1={this.state.exp1}
                    exp2={this.state.exp2}
                    Approved={this.state.Approved}
                    receiptReference={this.state.receiptReferenceNo}
                    driverId={this.state.driverId}
                    Denyed={this.state.Denyed}
                    New={this.state.New}
                    DateMatch={this.state.DateMatch}
                    TotalMatch={this.state.TotalMatch}
                    orderNo={this.state.orderNo}
                    clicked={this.state.fetching}
                  />
                }
              >
                <Button
                  css={{br: 0, mr: 10}}
                  auto
                  size='sm'
                >
                  Choose Filter ({this.state.noOfFilter})
                </Button>
              </Tooltip>
            </div>
          </div>        

          {this.state.fetching ? (
            <div className="flex justify-center mt6">
              <Loading size="lg">Fetching receipts..Hang on!</Loading>
            </div>
          ) : (
            <div
              className="mr2 mt2"
            >
              {this.state.receiptsList.length === 0 ? (
                <div>
                  <div className=" mt3 f5">No receipts found.</div>
                </div>
              ) : (
                <div style={gridContainerStyle} className="ag-theme-quartz p-4">
                  <AgGridReact
                    // properties
                    columnDefs={this.state.columnDefs}
                    rowData={this.state.receiptsList}
                    rowMultiSelectWithClick={true}
                    pagination={true}
                    paginationAutoPageSize={true}
                    // events
                    onGridReady={this.onGridReady}
                    rowSelection={this.state.rowSelection}
                    onRowDoubleClicked={this.rowDoubleClicked}
                    onSelectionChanged={this.onSelectionChanged.bind(this)}
                    //ID
                    getRowId={(params) => params.data.receipt_number}
                    enableColResize
                    enableSorting
                    enableFilter
                  ></AgGridReact>
                </div>
              )}
            </div>
          )}

          <Modal 
              fullScreen
              closeButton
              aria-labelledby="receipt details"   
              aria-describedby="receipt details" 
              open={this.state.isOpen}
              onClose={this.onCancel}
          >
              <Modal.Header>
                  <Text id="modal-title" size="1.5rem" b>
                    {receipt_number_text}
                  </Text>
              </Modal.Header>
              <Modal.Body>
                <ReimbursableRecipetsDetails
                  setStatusOnGrid={this.setStatusOnGrid}
                  currentReceipt={this.state.currentReceipt}
                  fetchReceiptsList={this.fetchReceiptsList}
                  resetFilter={this.resetFilter}
                  closeAction={this.onCancel}
                />
              </Modal.Body>
          </Modal>
        </div>
      );
    } 
    else {
      return (
        <div>
          <div className="flex justify-between">
            <h4 className={"f3 fw4 mb1 pt1 pb3 ph1 mt2 "}>
              Reimbursable Receipts Management
            </h4>
          </div>
          <div className=" ml2 mt3 f5">This feature is unavailable</div>
        </div>
      );
    }
  }
}

const mapStateToProps = function(state) {
  return {
    clientId: state.client ? state.client.id : mstore.getPath(["user", "company_id"]),
    Access: state.navigation.Access
  }
}

export default connect(mapStateToProps)(ReimbursableRecipetsManagement);