import React, { Component } from "react";
import { Async } from "react-select";
import { Switch, Text, Button, Modal, Input, Loading } from "@nextui-org/react";
import { connect } from "react-redux";
import { debounce } from "lodash";

import endpoint from "../../config/endpoint.json";
import ListItems from "../../components/PageSelectionComponent/ListItems";

import { secureFetch } from "../../lib/api";
import { searchOrderList } from "../../lib/utils";
import { error } from "../../lib/userAlerts";

import { mstore } from "../../store";
import checkAccess from '../../lib/checkAccess';
import { Calendar } from "react-iconly";
import SubPageHeader from "../../components/SubPageHeader";

var height = window.innerHeight;

class Osd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderNo: "",
      exp1: "",
      exp2: "",
      OS: 1,
      stopId: "",
      stopName: "",
      driverId: "",
      bolNumber: "",
      withReview: 0,
      noOfFilter: 0,
      clicked: false,
      orderList: [],
      artifactsList: [],
      activeArtifactsList: [],
      bolList: [],
      orderNoList: [],
      loading: false,
      selectedPage: 1,
      count: 7,
      totalPages: 0,
      trimmedOrderList: [],
      allowRatingsArtifax: null,
      coordinatorId: "",
      openPopover: false,
      openPopoverUpload: false,
      openPopoverDelete: false,
      csvShipmentList: [],
      shipmentFile: null,
      uploadedCount: 0,
      failedCount: 0,
      selectedOrders: {},
      previewDoc: "",
      pdfLoading: false,
      osdDocType: "",
      partnerDocument: false,
      isBroker: 0,
      orderWithDocuments: false,
      openDateSearchModal: false
    };
  }

  componentDidMount() {
    checkAccess(this.props.clientId);
    this.getArtifactsList();
    this.fetchCompanyDetails();

    if(this.props.location.state) {
      this.setState({ partnerDocument: true }, ()=> {
        this.handleChange(null, this.props.location.state.order.bol_number)
      })
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.clientId !== this.props.clientId) {
      checkAccess(newProps.clientId);
      this.getArtifactsList();
      this.fetchCompanyDetails();

      this.setState({
        orderNoList: [],
        bolList: []
      })
    }
  }

  fetchCompanyDetails = () => {
    return secureFetch(endpoint.getCompanyDetails)
      .then(([ok, status, company]) => {
        if (ok) {
          this.setState({
            isBroker: company.is_broker,
          });
        } else {
          error("Oops! error occured during fetch company details");
        }
      })
      .catch((err) => {
        error("Oops! error occured during fetch company details");
      });
  };

  setPreview = (url) => {
    this.setState({ pdfLoading: true });

    fetch(url, {
      method: "GET",
    })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        var file = new Blob([blob], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        this.setState({ previewDoc: fileURL });
      })
      .catch((err) => console.error(err));
  };

  getOrderNO = (input, callback) => {
    let orderNumbers = [];

    // If our state is not empty
    if(this.state.orderNoList?.length > 0){
      orderNumbers = searchOrderList(this.state.orderNoList, input)
    }

    // If order exists in our cache
    if(orderNumbers?.length > 0){
      callback(null, {
        options: orderNumbers,
        // CAREFUL! Only set this to true when there are no more options,
        // or more specific queries will not be sent to the server.
        complete: true
      });
    }
    else{
      // Now fetch orders from the API.
      secureFetch(endpoint.getOrderNO, {
        filter: input,
        os: this.state.OS,
        osFilterRequired: true,
      })
      .then(([ok, status, json]) => {
        if (ok) {
          // Update state for future search.
          this.setState({
            orderNoList: json.orderNo.map((k) => ({
              label: k.order_id,
              value: k.order_id,
            }))
          });

          orderNumbers = json.orderNo.map((k) => ({
            label: k.order_id,
            value: k.order_id,
          }))
        } else {
          error("Oops! Unable to fetch order number list.");
        }
      })
      .finally(()=>{
        callback(null, {
          options: orderNumbers,
          // CAREFUL! Only set this to true when there are no more options,
          // or more specific queries will not be sent to the server.
          complete: true
        });
      });
    }
  };

  getBolList = (input, callback) => {
    let bolNumbers = [];

    // If our state is not empty
    if(this.state.bolList?.length > 0){
      bolNumbers = searchOrderList(this.state.bolList, input)
    }

    // If order exists in our cache
    if(bolNumbers?.length > 0){
      callback(null, {
        options: bolNumbers,
        // CAREFUL! Only set this to true when there are no more options,
        // or more specific queries will not be sent to the server.
        complete: true
      });
    }
    else{
      // Now fetch orders from the API.
      secureFetch(endpoint.getBolList, {
        filter: input,
        os: this.state.OS,
        osFilterRequired: true,
      })
      .then(([ok, status, json]) => {
        if (ok) {
          // Update state for future search.
          this.setState({
            bolList: json.bolList.map(k => ({
              label: k.bol_number,
              value: k.bol_number
            }))
          });

          bolNumbers = json.bolList.map((k) => ({
            label: k.bol_number,
            value: k.bol_number,
          }))
        } else {
          error("Oops! Unable to fetch BOL number list.");
        }
      })
      .finally(()=>{
        callback(null, {
          options: bolNumbers,
          // CAREFUL! Only set this to true when there are no more options,
          // or more specific queries will not be sent to the server.
          complete: true
        });
      });
    }
  };

  getArtifactsList = () => {
    secureFetch(endpoint.getArtifactsList, {}).then(([ok, status, json]) => {
      if (ok) {
        let activeArtifactsList = [];
        json.artifactsList.forEach((item) => {
          if (item.status !== 0) {
            if (item.is_osd === 1) {
              activeArtifactsList.push(item);
              this.setState({ osdDocType: item.type_name });
            }
          } else {
            return;
          }
        });
        this.setState({
          artifactsList: activeArtifactsList,
          activeArtifactsList: activeArtifactsList,
        });
      } else {
        error("Oops! error occured during fetching artifacts list!");
      }
    });
  };

  /**
   *
   * Search according to order number or filters set
   *
   */

  handleChange = (orderId, bolNumber) => {
    if (!bolNumber && !orderId && !this.state.exp1 && !this.state.exp2) {
      this.setState({
        trimmedOrderList: [],
        orderNo: "",
        bolNumber: "",
        previewDoc: "",
      });
      error("Please select any filter criteria");
      return;
    }

    if (
      (this.state.exp1 && !this.state.exp2) ||
      (!this.state.exp1 && this.state.exp2)
    ) {
      error("Please select start and end date both");
      return;
    }

    this.setState({ orderNo: orderId, bolNumber: bolNumber });
    this.setState({
      clicked: true,
      loading: true,
      csvShipmentList: [],
      previewDoc: "",
    });

    secureFetch(endpoint.searchShipmentListForCarrier, {
      exp1: this.state.exp1,
      exp2: this.state.exp2,
      bol_Number: bolNumber,
      orderNo: orderId,
      onlyCarrier: this.state.partnerDocument,
      onlyHaveDocumnet: this.state.orderWithDocuments,
      osd: true,
    }).then(([ok, status, json]) => {
      if (ok) {
        this.setState({
          selectedPage: 1,
          orderList: json.orderList,
          clicked: false,
          loading: false,
          openDateSearchModal: false,
          trimmedOrderList: json.orderList,
          totalPages: Math.ceil(json.orderList.length / 10),
          count:
            Math.ceil(json.orderList.length / 10) > 10
              ? 10
              : Math.ceil(json.orderList.length / 10),
        });
      } else {
        this.setState({ loading: false, openDateSearchModal: false })
        error("Oops! error occured during search order list!");
      }
    });
  };

  setExp1 = (e) => {
    this.setState({ exp1: e.target.value });
  };

  setExp2 = (e) => {
    this.setState({ exp2: e.target.value });
  };

  renderOrderList() {
    if (
      this.state.trimmedOrderList.length === 0 &&
      this.state.orderNo === "" &&
      this.state.exp1 === "" &&
      this.state.exp2 === "" &&
      !this.state.loading
    ) {
      return (
        <div className=" ml2 mt3 f4 tc">Please select any filter criteria</div>
      );
    }

    if (this.state.trimmedOrderList.length === 0 && !this.state.loading) {
      return <div className=" ml2 mt3 f4 tc">Order not available</div>;
    }

    return (
      <div id="scrollTop" style={{ height: height }}>
        {this.state.loading ? (
          <div className="flex justify-center mt6">
            <div>
              <Loading size='lg'> Fetching OS&D Data. Hang on.. </Loading>
            </div>
          </div>
        ) : (
          <div>
            {this.state.trimmedOrderList.map((item, index) => {
              return (
                <div
                  className={
                    this.state.selectedOrders.hasOwnProperty(index)
                      ? "bg-light-red"
                      : ""
                  }
                >
                  <ListItems
                    allowRatingsArtifax={this.state.allowRatingsArtifax}
                    itemIndex={index}
                    documents={item.document || {}}
                    os={item.os}
                    ordderNo={item.OrderNo}
                    stop={item.Stop}
                    StopType={item.StopType}
                    StopName={item.StopName}
                    address={item.Address}
                    bolNumber={item.bol_number}
                    shipmentTime={item.StopArriveTime}
                    shipmentId={item.StopId}
                    orderNoList={this.state.orderNoList}
                    artifactsList={this.state.artifactsList}
                    activeArtifactsList={this.state.activeArtifactsList}
                    searchOrderNo={this.state.orderNo}
                    searchBolNumber={this.state.bolNumber}
                    getShipmentList={this.handleChange}
                    setPreview={this.setPreview}
                    osdDocType={this.state.osdDocType}
                    checkOs={this.state.OS}
                    autoCloseInd={item.auto_close_ind}
                    autoCloseDate={item.auto_close_date}
                    driverCloseTs={item.driver_close_ts}
                    sdnRecordId={item.s_rec}
                    driver1EmpId={item.Driver1}
                    driver2EmpId={item.Driver2}
                    driver1Name={item.Driver1Name}
                    driver2Name={item.Driver2Name}
                    driver1Email={item.Driver1email}
                    driver2Email={item.Driver2email}
                    driver1Phone={item.Driver1phone}
                    driver2Phone={item.Driver2phone}
                    driver1UserId={item.driver1UserId}
                    driver2UserId={item.driver2UserId}
                    clientId={
                      item.transporter_client_id
                        ? item.transporter_client_id
                        : item.client_id
                    }
                    recordSource={item.comapnyName ? item.comapnyName : ""}
                    comments={item.Comment}
                    pushNotificationCount={item.pushNotificationCount}
                    isBroker={this.state.isBroker}
                    allowBrokersToReopenClosedStop={
                      item.allow_brokers_to_reopen_closed_stop
                    }
                    allowBrokersToSendMessage={
                      item.allow_brokers_to_send_message
                    }
                    allowBrokerPushNotification={
                      this.state.isBroker
                        ? this.props.Access.allow_broker_push_notification
                        : 1
                    }
                    partnerDocument={item.transporter_client_id ? true : false}
                    allowSendMessage={item.allow_send_message}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  renderFilterSection() {
    let orderList = this.state.orderNoList;
    let bolList = this.state.bolList;

    return (
      <div className="flex" key={this.props.clientId}>
        <div className="w-100 flex items-center flex-wrap">
          <Async
            name="Search order"
            style={{ width: "200px" }}
            placeholder="Search order"
            value={this.state.orderNo}
            onChange={(item) =>
              this.handleChange(item ? item.value : "", this.state.bolNumber)
            }
            options={orderList}
            loadOptions={debounce(this.getOrderNO, 500)}
          />
          <Async
            name="Search by BOL"
            className="px-4"
            style={{ width: "200px" }}
            placeholder="Search by BOL"
            value={this.state.bolNumber}
            onChange={(item) =>
              this.handleChange(this.state.orderNo, item ? item.value : "")
            }
            options={bolList}
            loadOptions={debounce(this.getBolList, 500)}
          />
          <Calendar
            primaryColor="blue"
            size={28}
            onClick={() => {
              this.setState({ openDateSearchModal: true });
            }}
          />
          {
            this.state.isBroker === 1 &&
            this.props.Access.allow_broker_carrier_data_docs ===
              1 && (
              <div className="flex justify-center items-center px-4">
                <Switch
                  size='sm'
                  checked={this.state.partnerDocument}
                  onChange={() => {
                    this.setState({
                      trimmedOrderList: [],
                      orderNo: "",
                      bolNumber: "",
                      partnerDocument: !this.state.partnerDocument,
                    });
                  }}
                />
                <Text size='1rem' p className="px-2">
                  Partner Document
                </Text>
              </div>
            )
          }
          <div className="flex justify-center items-center">
            <Switch
              size='sm'
              checked={this.state.orderWithDocuments}
              onChange={() => {
                this.setState({
                  trimmedOrderList: [],
                  orderNo: "",
                  bolNumber: "",
                  orderWithDocuments: !this.state.orderWithDocuments,
                });
              }}
            />
            <Text size='1rem' p className="px-2">
              Only include stop with documents
            </Text>
          </div>
        </div>
      </div>
    );
  }

  closeDateSearchModal = () => {
    this.setState({ openDateSearchModal: false, exp1: '', exp2: '' })
  }

  dateSearchModal = () => {
    return (
      <Modal
        open={this.state.openDateSearchModal}
        onClose={this.closeDateSearchModal}
        closeButton
        blur
        css={{br: 0}}
      >
        <Modal.Header>
          <Text size={18}>Search Date Range</Text>
        </Modal.Header>
        <Modal.Body>
          <div className="flex">
            <Input
              underlined
              clearable
              css={{w: '100%'}}
              type="date"
              label="From Date"
              onChange={this.setExp1}
              value={this.state.exp1}
            />
            <Input
              css={{ml: 10, w: '100%'}}
              underlined
              clearable
              label="To Date"
              type="date"
              onChange={this.setExp2}
              value={this.state.exp2}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
              auto
              css={{br: 0}}
              onClick={() => {
                this.handleChange(this.state.orderNo, this.state.bolNumber);
              }}
          >
            Search
          </Button>
        </Modal.Footer>

      </Modal>
    );
  };

  render() {
    if (this.state.allowRatingsArtifax === 0) {
      return (
        <div>
          <div className="ml3 w-100">
            <h4 className={"f3 fw4 mb1 pt1 pb3 ph1 mt2 "}>OS&D Stops</h4>
          </div>
          <div className=" ml2 mt3 f5">This feature is unavailable</div>
        </div>
      );
    }
    return (
      <div>
        <div className="p-4 flex border-y-2 border-dotted justify-between">
          <SubPageHeader>OS&D Stops</SubPageHeader>
          {this.renderFilterSection()}
        </div>
        <div className="flex">
          <div
            className={"w-50 overflow-y-auto vh-till-bottom"}
          >
            {this.renderOrderList()}
          </div>
          <div
            className={"w-50 flex flex-column items-center mt1 mr2 bg-near-white"}
          >
            <iframe
              src={this.state.previewDoc}
              className="vh-till-bottom-doc"
              onLoad={() => {
                this.setState({ pdfLoading: false });
              }}
              width={"100%"}
              style={{border : 0}}
              frameBorder="0"
            />
            {this.state.pdfLoading && <div className="f5 mt2">Loading....</div>}
          </div>
        </div>
        {this.dateSearchModal()}
      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    clientId: state.client ? state.client.id : mstore.getPath(["user", "company_id"]),
    Access: state.navigation.Access
  }
}

export default connect(mapStateToProps)(Osd);