import React, { Component } from "react";
import { Loading, Text, Button as NextButton, Switch, Input} from '@nextui-org/react';

import { mstore } from "../../store";
import { connect } from "react-redux";
import endpoint from "../../config/endpoint.json";
import { secureFetch } from "../../lib/api";
import { error, success } from "../../lib/userAlerts";
import { updateTitle } from "../../lib/utils";

class AutoLinkSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
        updating: false,
        loading: false,
        autoLinkSettings: {
            auto_link_free_users: false,
            auto_link_max_trys: 0,
            auto_link_try_delay_hrs : 1
        }
    };
  }

  componentDidMount() {
    this.getAutoLinkSettings();
    updateTitle("Autolink Settings");
   }

   componentWillReceiveProps(newProps) {
    if (newProps.clientId !== this.props.clientId) {
      this.getAutoLinkSettings();
    }
  }

  getAutoLinkSettings = () => {
    
    this.setState({loading: true});

    secureFetch(endpoint.fetchAutoLinkSettings, {
    }).then(([ok, status, json]) => {
      if (ok) {
        this.setState({autoLinkSettings: json.autoLinkSettings, loading: false});
      } 
      else {
        error("Oops! error occured when fetching your auto link settings.");
        this.setState({loading: false});
      }
    });
  };

  renderAutoLinkSetting = () => {
      return(
        <div className="flex flex-wrap mb4">
            <div className="f5 fw2 w-70 mb2">
                Enable / Disable this feature
            </div>
            <div className="flex f4 w-25">
                <Switch
                    size='sm'
                    checked={
                        this.state.autoLinkSettings.auto_link_free_users == 1
                            ? true
                            : false
                    }
                    onChange={() => {
                        this.setState({ autoLinkSettings: 
                            { ...this.state.autoLinkSettings, auto_link_free_users: !this.state.autoLinkSettings.auto_link_free_users}
                        });
                    }}
                />
                <Text size="1rem" css={{pl: 10}}>{this.state.autoLinkSettings.auto_link_free_users == 1 ? 'Active' : 'Inactive'}</Text>
            </div>
        </div>
      );
  }

    renderNumberTries = () =>{
        return(
            <div className="flex flex-wrap mb4">
                <div className="f5 fw2 w-70">
                    Number of reminder messages (for tracking)
                </div>
                <Input
                    type='number'
                    underlined
                    css={{w: '100px'}}
                    min={0}
                    max={10}
                    defaultValue={1}
                    disabled={this.state.autoLinkSettings.auto_link_free_users == 1 ? false : true}
                    value={this.state.autoLinkSettings.auto_link_max_trys}
                    onChange={(e) => {
                        let valueAsNumber = e.target.value
                        this.setState({ autoLinkSettings: 
                            { ...this.state.autoLinkSettings, auto_link_max_trys: valueAsNumber}
                        })
                    }}
                />
            </div>
        )
    }


    renderInterval = () =>{
        return(
        <div className="flex flex-wrap mb4">
            <div className="f5 fw2 w-70">
                Interval between reminder messages (in hours)
            </div>
            <Input
                type='number'
                underlined
                css={{w: '100px'}}
                min={1}
                max={23}
                defaultValue={1}
                disabled={this.state.autoLinkSettings.auto_link_free_users == 1 ? false : true}
                value={this.state.autoLinkSettings.auto_link_try_delay_hrs}
                onChange={(e) => {
                    let valueAsNumber = e.target.value
                    this.setState({ autoLinkSettings: 
                        { ...this.state.autoLinkSettings, auto_link_try_delay_hrs: valueAsNumber}
                    })
                }}
            />
        </div>
        )
    }

    updateAutoLink = () =>{

        this.setState({updating: true});

        secureFetch(endpoint.updateAutoLinkSettings, {
            ...this.state.autoLinkSettings
        }).then(([ok, status, json]) => {
          if (ok) {
            success('Updated the auto link settings.')
            this.setState({updating: false});
          } 
          else {
            error("Oops! error occured when updating your auto link settings.");
            this.setState({updating: false});
          }
        });
    }

    render() {
        return (
            <div className="w-100 flex flex-wrap justify-between mt3">
                {this.state.loading ? (
                    <div className="ml2 pa2 w-100 mt6 flex justify-center">
                        <Loading size="lg">Fetching your auto-link settings..</Loading>
                    </div>
                ) 
                :
                    <div
                        className="ml2 my-4 p-4 w-1/2 border-2 border-dotted"
                    >
                        <div className="f4 w-full border-b pb-4 mb-8">
                            Auto Link Settings
                        </div>

                        {this.renderAutoLinkSetting()}
                        {this.renderNumberTries()}
                        {this.renderInterval()}

                        <div className="fw2">
                            <NextButton
                                size='sm'
                                auto
                                color="primary"
                                css={{br:0 }}
                                disabled={this.state.updating}
                                onClick={this.updateAutoLink}
                            >
                                {this.state.updating ? <Loading type="points" color="primary"></Loading> : <Text color="currentColor" size="1rem">Update Auto-link parameters</Text> }
                            </NextButton>
                        </div>
                    </div>
                }
            </div>
        );
  }
}

export default connect((state) => ({
  Access: state.navigation.Access,
  clientId: state.client
    ? state.client.id
    : mstore.getPath(["user", "company_id"]),
}))(AutoLinkSettings);
