import React, { Component } from "react";
import { connect } from "react-redux";

import { Loading, Radio } from '@nextui-org/react';
import { AgGridReact } from "ag-grid-react";

import { error } from "../../../lib/userAlerts";
import endpoint from "../../../config/endpoint.json";
import { mstore } from "../../../store";
import { fetchWithJson } from "../../../lib/api";

const DAYS_30 = 1
const DAYS_365 = 2
const DAYS_ALL = 3

const createColumnDefs = () => {
  return [
    {
      headerName: "Driver Name",
      field: "name",
      filter: "agTextColumnFilter",
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
    },
    {
      headerName: "Employee ID",
      field: "employee_id",
      filter: "agTextColumnFilter",
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
      minWidth: 240,
    },
    {
      headerName: "Email",
      field: "user_email",
      suppressMenu: true,
      minWidth: 300,
    },
    { 
      headerName: "Phone", 
      field: "phone",
      suppressMenu: true,
      minWidth: 180,
    },
    { 
      headerName: "Stops Served", 
      field: "numStops", 
      suppressMenu: true,
      minWidth: 120,
    },
    {
      headerName: "Receipts Uploaded",
      field: "numReceipts",
      suppressMenu: true,
      minWidth: 120,
    },
    { 
      headerName: "Artifax Uploaded", 
      field: "documentsUploaded",
      suppressMenu: true,
      minWidth: 120,
    },
    {
      headerName: "Safety Docs Uploaded",
      field: "safetyDocsUploaded",
      suppressMenu: true,
      minWidth: 120,
    },
    {
        headerName: "Receipt Amount Claimed",
        field: "driverClaimedAmount",
        suppressMenu: true,
      }
  ];
};

class DriverStats extends Component {
  constructor(props) {
        super(props);
        let gridApi;

        this.state = {
            driverList: [],
            fetching: false,
            chartType: 1,
            columnDefs: createColumnDefs(),
        };
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
    };

    componentDidMount() {
        if(this.props.clientId){
            this.fetchReceiptsList(this.props.clientId);
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.clientId !== this.props.clientId) {
        this.fetchReceiptsList(newProps.clientId);
        }
    }

    fetchReceiptsList = async(clientId) => {
        this.setState({
            fetching: true,
            driverList: []
        });

        try {
            const res = await fetchWithJson(endpoint.statsDriver, {
                company_id: clientId,
                allTime: this.state.chartType === DAYS_ALL ? true : false,
                thisYear: this.state.chartType === DAYS_365 ? true : false
            });
    
            if (res.status !== 200) {
                error("Oops! error occured when fetching driver stats");
            }
            else{
                const data = await res.json();
                this.setState({
                    driverList : data.drivers
                })
            }
        } 
        catch (err) {
            console.log(err);
            error("Oops! error occured when fetching driver stats");
        }
        finally{
            this.setState({
                fetching: false
            });
        }
    };

    renderLoading = () =>{
        return(
            <div className="flex justify-center mt6">
                <Loading size="lg">Fetching driver stats..Hang on!</Loading>
            </div>
        )
    }

    renderNoDrivers = () =>{
        return (
            <div className="flex justify-center mt6 f4 fw6">You do not have any company drivers.</div>
        )
    }

    renderDriverList = () => {
        return(
            <div className='' style={{height: "calc(100vh - 250px)", width: "100%"}}>
                <Radio.Group row css={{mt: '0.75rem', padding: 10, paddingBottom: 20}}
                    size='sm'
                    value={this.state.chartType}
                >
                    <Radio
                        onChange={(e) => {
                            this.setState({ chartType: DAYS_30 }, ()=> {this.fetchReceiptsList(this.props.clientId)});
                        }} 
                        value={DAYS_30}>
                        30 Days
                        <Radio.Desc>Driver stats for last 30 days</Radio.Desc>
                    </Radio>
                    <Radio
                        onChange={(e) => {
                            this.setState({ chartType: DAYS_365 }, ()=> {this.fetchReceiptsList(this.props.clientId)});
                        }}
                        value={DAYS_365}>
                        365 Days
                        <Radio.Desc>Driver stats for last 365 days</Radio.Desc>                  
                    </Radio>
                    <Radio
                        onChange={(e) => {
                            this.setState({ chartType: DAYS_ALL }, ()=> {this.fetchReceiptsList(this.props.clientId)});
                        }}
                        value={DAYS_ALL}>
                        All Time
                        <Radio.Desc>Driver stats all times</Radio.Desc>                  
                    </Radio>
                </Radio.Group>
                <div
                    style={{height: 'calc(100vh - 300px)'}}
                    className={"ag-theme-quartz p-3"}
                    >
                    <AgGridReact
                        // properties
                        columnDefs={this.state.columnDefs}
                        rowData={this.state.driverList}
                        pagination={true}
                        paginationAutoPageSize={true}
                        // events
                        onGridReady={this.onGridReady}
                        //Others
                        enableColResize
                        enableSorting
                        enableFilter
                    />
                </div>
            </div>
        )
    }

    render() {
        var height = window.innerHeight;
        const gridContainerStyle = {
            height: (height - 160) + "px",
        };

        return (
            <div className="ml2 mt2">
                {this.state.fetching ? this.renderLoading() : 
                    <div
                    className="mr2 mt2"
                    >
                    {this.state.driverList.length === 0 
                        ? this.renderNoDrivers() 
                        : <div style={gridContainerStyle} className={"ag-theme-balham"}>{this.renderDriverList()}</div>
                    }
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = function(state) {
  return {
    clientId: state.client ? state.client.id : mstore.getPath(["user", "company_id"])
  }
}

export default connect(mapStateToProps)(DriverStats);