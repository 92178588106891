import { Card, Divider, Link, Row, Text } from '@nextui-org/react';
import React, { Component } from 'react'

import { fetchWithJson } from "../../../lib/api";
import { error } from "../../../lib/userAlerts";
import endpoint from "../../../config/endpoint.json";

export default class Seats extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            messages: 0,
            detentions: 0
        }
    }

    componentDidMount(){
        if(this.props.clientId){
            this.fetchNotificationData(this.props.clientId)
            this.fetchDetentionData(this.props.clientId)
        }
    }
    
    componentWillReceiveProps(newProps) {
        if(newProps && newProps.clientId >= 1 && newProps.clientId !== this.props.clientId){
            this.fetchNotificationData(newProps.clientId)
            this.fetchDetentionData(newProps.clientId)
        }
    }

    fetchNotificationData = async(clientId) =>{
        try {
            const res = await fetchWithJson(endpoint.statsMessages, {
                company_id: clientId,
            });
    
            if (res.status !== 200) {
                error('Unable to fetch message counts')
            }
            else{
                const data = await res.json();
                this.setState({
                    messages : data.messages.notificationCount ? data.messages.notificationCount: 0
                })
            }    
        } 
        catch (err) {
            console.log(err);
            error('Unable to fetch message counts')
        }
    }

    fetchDetentionData = async(clientId) =>{
        try {
            const res = await fetchWithJson(endpoint.statsDetentions, {
                company_id: clientId,
            });
    
            if (res.status !== 200) {
                error('Unable to fetch detention counts')
            }
            else{
                const data = await res.json();
                this.setState({
                    detentions : data.detentions.detentionCount ? data.detentions.detentionCount: 0
                })
            }    
        } 
        catch (err) {
            console.log(err);
            error('Unable to fetch detention counts')
        }
    }

    render() {
        return (
            <Card css={{br: 0}} 
                bordered 
                shadow={false}
            >
                <Card.Header>
                    <Text size={20}>Other Stats</Text>
                </Card.Header>
                <Divider/>
                <Card.Body css={{br:0, px: '$6'}} >
                    {
                        <Row justify='space-between'>
                            <Text size={18}>Driver Reminders (Last 30 Days)</Text>
                            <Text size={18} b>{this.state.messages}</Text>
                        </Row>
                    }
                    {
                        <Row justify='space-between'>
                            <Text size={18}>Open Detentions</Text>
                            <Text size={18} b><Link href='/driver/detention-management'>{this.state.detentions}</Link></Text>
                        </Row>
                    }
                </Card.Body>
            </Card>
        );
    }
}