import React, { PureComponent } from 'react';

export default class FileUploadEhanced extends PureComponent {

  state = {
    fileName: '',
    isError: false,
    isUploading: false
  }

  setFile = event => {
    const file = event.target.files[0]
    let email = '';

    if (!this.props || !this.props.email)
      email = '';
    else
      email = this.props.email;
      
    if(!file) return;
    this.setState({ fileName: file.name, isError: false })
    this.props.uploadCb(file , email)
      .sub({
        start: () => this.setState({isUploading: true, isError: false}),
        end: () => this.setState({ isUploading: false }),
        error: err => this.setState({ isError: true })
      })
  }

  render() {
    /** TODO: handle the isError state */
    return (
        <label for="uploadFile1"
            className="bg-white text-black text-base rounded w-80 h-28 flex flex-col items-center justify-center cursor-pointer border-2 border-gray-300 border-dashed mx-auto font-[sans-serif]">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 mb-2 fill-black" viewBox="0 0 32 32">
                <path
                d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                data-original="#000000" />
                <path
                d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                data-original="#000000" />
            </svg>
            Upload file
            <input type="file" onChange={this.setFile}  id='uploadFile1' className="hidden" />
            <p className="text-xs text-gray-400 mt-2">PNG, JPG less than 2 MegaBytes is allowed.</p>
        </label>
    )
  }
}