import React, { Component } from "react";
import chroma from "chroma-js";

const thStylePrint = {
  fontSize: "1.2rem",
  fontWeight: "400",
  textAlign: "center",
  border: "1px solid #c3c3c3",
};

const tdStylePrint = {
  fontSize: "1.0rem",
  fontWeight: "800",
  verticalAlign: "middle",
  textAlign: "center",
  border: "1px solid #c3c3c3",
};

const tdStylePrintReview = {
  fontSize: "1.0rem",
  fontWeight: "400",
  verticalAlign: "middle",
  textAlign: "center",
  border: "1px solid #c3c3c3",
};

var scale = chroma.scale(["red", "orange", "lightgreen"]).domain([0, 2.5, 5]);

export default class PrintDiv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // createColumnDefs: createColumnDefs()
    };
  }

  render() {
    let text = [
      this.props.locationAddress,
      this.props.stopCity,
      this.props.stopState,
      this.props.stopCountry,
      this.props.stopZip,
    ]
      .filter(Boolean)
      .join(", ");
    return (
      <div id="print-div">
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <div style={{ fontSize: "20px" }}>{this.props.locationName}</div>
              <div style={{ fontSize: "15px" }}>
                {text}
              </div>
            </div>
          </div>
          <table
            style={{
              width: "100%",
              height: "100px",
              fontSize: "12px",
              marginTop: "30px",
              borderCollapse: "collapse",
              border: "1px solid #c3c3c3",
            }}
          >
            <thead>
              <tr>
                {this.props.headerArrayElement.map((item, index) => {
                  return (
                    <th key={index} style={{ ...thStylePrint }}>
                      {item}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                {this.props.ratings.map((item, index) => {
                  return (
                    <td
                      key={index}
                      style={{
                        ...tdStylePrint,
                        backgroundColor: scale(item).hex(),
                      }}
                    >
                      {item}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>

          <table
            style={{
              width: "100%",
              height: "100px",
              fontSize: "12px",
              marginTop: "30px",
              borderCollapse: "collapse",
              border: "1px solid #c3c3c3",
            }}
          >
            <thead>
              <tr>
                <th style={{ ...thStylePrint }}>Date</th>
                <th style={{ ...thStylePrint }}>Driver 1</th>
                <th style={{ ...thStylePrint }}>Driver 2</th>
                <th style={{ ...thStylePrint }}>Comment</th>
              </tr>
            </thead>

            <tbody style={{ width: "100%" }}>
              {this.props.driverCommentList.map((item, index) => {
                return (
                  <tr key={index}>
                    <td style={{ ...tdStylePrintReview }}>{item.createdAt}</td>
                    <td style={{ ...tdStylePrintReview }}>
                      {item.driver1_name}
                    </td>
                    <td style={{ ...tdStylePrintReview }}>
                      {item.driver2_name}
                    </td>
                    <td style={{ ...tdStylePrintReview }}>{item.comment}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
