import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { Text, Button } from '@nextui-org/react';
import { User, Logout } from 'react-iconly';

import { logoutAndClearStore } from '../../auth0'
import { mstore } from '../../store'
import logo from '../../assets/mtt.png';
import { history } from '../../history';

const TopMenu = (params) => {
  return(
    <div style={{width: 200, padding: 0}}>
      <Button light auto icon={<Logout fill="currentColor" set="light" />} onClick={params.onLogOut}>
          Logout
      </Button>
    </div>
  )
}

const Box = ({ name, href, selected }) => (
  <input 
    onClick={()=>{history.push(href)}}
    checked={selected}
    className="join-item btn" 
    type="radio" 
    name="app-sections" 
    aria-label={name}/>
);

const pagesMap = {
  select: {
    name: "Product Selection",
    href: "select"
  },
  account: {
    name: "Account Details",
    href: "account"
  },
  payment: {
    name: "Payment Information",
    href: "payment"
  },
  user: {
    name: "User Management",
    href: "user"
  },
  feature: {
    name: "Subscriptions",
    href: "feature"
  },
}

class Header extends PureComponent {
  state = {
    isOpen: false
  }

  handleLogout = () => {
    logoutAndClearStore()
  }

  render () {
    const pathname = this.props.location.pathname
    const selectedRoute = pathname.substr(pathname.lastIndexOf('/') + 1)
    const name = mstore.get('user', 'name')

    const canViewSensitiveInfo = mstore.get('user', 'isPrimaryUser') || mstore.get('user', 'isSuperAdmin')
    let canSetFeatures = canViewSensitiveInfo || mstore.get('user', 'isAdmin');

    if (selectedRoute === 'products') {
      setTimeout(() => this.props.history.replace('/products/select'), 0) // FIXME: use better path
      return <div />
    }

    const sections = []
    for (const pageKey in pagesMap) {
      const pageD = pagesMap[pageKey]
      if (!canViewSensitiveInfo && pageKey === 'account') {
        continue
      } 
      else if (!canViewSensitiveInfo && pageKey === 'payment') {
        continue
      }
      else if (!canSetFeatures && pageKey === 'feature'){
        continue
      }
      sections.push(<Box key={pageKey} {...pageD} selected={selectedRoute === pageD.href} />)
    }

    return (
      <div className="flex justify-around items-center flex-wrap flex-nowrap-l p-4 bg-white">
        <div className="mb2 order-0 flex-grow-1 ml3 flex-grow-0-l ml0-l">
          <img src={logo} alt='simply data now logo' className="h3 w4"/>
        </div>

        <div className="join">
          {sections}
        </div>     

        <div className="dropdown">
          <div tabIndex={0} role="button" className="flex m-1">
            <User size='large' set='bold' />
            <Text b size={18} css={{ pl: 10}}>Welcome, {name}</Text> 
          </div>
          <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
            <li>
              <TopMenu onLogOut={this.handleLogout}/>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default withRouter(Header)
