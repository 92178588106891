// @flow
import { history, basename } from '../../history'
import { mstore, store } from '../../store'
import { makeUrl, processResponse } from './utils'

export function cometFetch(url: string, json?: Object): Promise<[boolean, number, Object | Response]> {
  if(!localStorage.cometToken){
    return Promise.reject(new Error('No comet access token'))
  }
  const options = {
    cache: 'no-cache',
    credentials: 'include',
    method: json ? 'POST' : 'GET',
    headers: {
      'Content-Type': 'application/json',
      'authorization': `Bearer ${localStorage.cometToken}`,
      'x-api-key': 'A980ccACDD72t1oaca89A3Fks4o1Cff68598rk37p8f06Das'
    }
  }
  if (json) {
    options.headers['Content-Type'] = 'application/json'
    options.body = JSON.stringify(json)
  }
  return fetch(url, options).then(processResponse)
}

export function secureFetch(url: string, json?: Object): Promise<[boolean, number, Object | Response]> {
  url = makeUrl(url, mstore.get('user', 'isSuperAdmin'), store.getState().client && store.getState().client.id)
  const options = {
    cache: 'no-cache',
    credentials: 'include',
    method: json ? 'POST' : 'GET',
    headers: {
      'Authorization': `Bearer ${localStorage.remember === "true"? localStorage.accessToken : sessionStorage.accessToken}`
    }
  }
  //console.error('Sec fetch -->', json);

  if (json) {
    options.headers['Content-Type'] = 'application/json'
    options.body = JSON.stringify(json)
  }
  return fetch(url, options).then(processResponse)
}

export function multipartFetch(url: string, params: Object): Promise<[boolean, number, Object | Response]> {
  url = makeUrl(url, mstore.get('user', 'isSuperAdmin'), store.getState().client.id)
  const formBody = new FormData()
  for (let key in params) {
    let value = params[key]
    if (Array.isArray(value)) {
      let _key = key + '[]'
      value.forEach(val => {
        formBody.append(_key, val)
      })
    } else {
      formBody.append(key, value)
    }
  }
  const options = {
    cache: 'no-cache',
    credentials: 'include',
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.remember === "true"? localStorage.accessToken : sessionStorage.accessToken}`,
      'Content-Type': 'multipart/form-data'
    },
    body: formBody
  }
  return fetch(url, options).then(processResponse)
}

// Generic api POST request
export function fetchWithJson(url: string, json: Object): Promise<Response> {
  let state = store.getState()
  url = makeUrl(url, mstore.get('user', 'isSuperAdmin'), state.client && state.client.id)

  return fetch(url, {
    cache: 'no-cache',
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.remember === "true"? localStorage.accessToken : sessionStorage.accessToken}` },
    body: JSON.stringify(json)
  }).then(res => {
    if (res.status === 403 && history.location.pathname !== '/login') {
      // localStorage.setItem("_goback", "1")
      // history.push('/login')
      // eslint-disable-next-line no-throw-literal
      throw "Not logged in"
    } else {
      return res
    }
  })
}

// Generic api request
export function fetchWithBody(url: string, method: string, json: Object): Promise<Response> {
  url = makeUrl(url, mstore.get('user', 'isSuperAdmin'), store.getState().client.id)

  return fetch(url, {
    cache: 'no-cache',
    credentials: 'include',
    method: method,
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.remember === "true"? localStorage.accessToken : sessionStorage.accessToken}` },
    body: JSON.stringify(json)
  }).then(res => {
    if (res.status === 403 && history.location.pathname !== '/login') {
      // history.push('/login')
      return Promise.reject('Not Logged in')
    } else {
      return res
    }
  })
}

// Generic api GET request
export function fetchUsingGet(url: string, params: Object): Promise<Response> {
  url = makeUrl(url, mstore.get('user', 'isSuperAdmin'), store.getState().client.id)
  let urlObj = new URL(url, window.location.href)
  Object.keys(params).forEach((key: string): void => {
    urlObj.searchParams.append(key, params[key])
  })

  return fetch(urlObj.toString(), {
    cache: 'no-cache',
    credentials: 'include',
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${localStorage.remember === "true"? localStorage.accessToken : sessionStorage.accessToken}`
    }
  }).then(res => {
    if (res.status === 403 && history.location.pathname !== '/login') {
      // history.push('/login')
      return Promise.reject('Not Logged in')
    } else {
      return res
    }
  })
}


// Generic api GET request
export function fetchUrl(url: string, params: ?Object): Promise<[boolean, number, Object | Response]> {
  if (basename !== '/') {
    url = basename + url
  }
  if(params){
    let urlObj = new URL(url, window.location.href)
    Object.keys(params).forEach((key: string): void => {
      urlObj.searchParams.append(key, params[key])
    })
    url = urlObj // FIXME: :p
  }
  return fetch(url, {
    cache: 'no-cache',
    credentials: 'include',
    method: 'GET'
  }).then(processResponse)
}

export function fetchAuthed(url, options) {
  options.headers = options.headers || {}
  options.headers['Authorization'] = `Bearer ${localStorage.remember === "true" ? localStorage.accessToken : sessionStorage.accessToken }`
  return fetch(url, options)
}