import React, { Component } from "react";
import { Input } from "@nextui-org/react";

import FeatureSelection from "../ProductSelection/FeatureSelection";
import { mstore } from "../../store";
import { connect } from "react-redux";

import endpoint from "../../config/endpoint.json";
import { success, error } from "../../lib/userAlerts";
import { secureFetch } from "../../lib/api";
import { TickSquare } from "react-iconly";

class ReceiptArchiveDays extends Component {
  constructor(prorps) {
    super(prorps);
    this.state = {
      receiptArchiveDays: 0,
      receiptDeleteDays: 0,
    };
  }

  componentDidMount() {
    this.fetchReceiptArchiveDaysRange();
    this.fetchReceiptDeleteDaysRange();
  }

  saveReceiptArchiveDays = () => {
    if (this.state.receiptArchiveDays < 0) {
      error("Please enter a positive value");
      return;
    }

    if (this.state.receiptDeleteDays < this.state.receiptArchiveDays) {
      error(
        "Receipt delete days should be greater than the receipt archive days"
      );
      return;
    }

    secureFetch(endpoint.saveReceiptArchiveDays, {
      receiptArchiveDays: this.state.receiptArchiveDays,
    }).then(([ok, status, json]) => {
      if (ok) {
        success(json.message);
        this.fetchReceiptArchiveDaysRange();
      } else {
        error(
          "Oops! error occured during save receipt archive days for this company"
        );
      }
    });
  };

  fetchReceiptArchiveDaysRange = () => {
    secureFetch(endpoint.receiptArchiveDays, {}).then(([ok, status, json]) => {
      if (ok) {
        this.setState({
          receiptArchiveDays: json.receiptArchiveDays[0].receipt_archive_days,
        });
      } else {
        error(
          "Oops! error occured during fetch receipt archive days for this company"
        );
      }
    });
  };

  setReceiptArchiveDays = (value) => {
    this.setState({ receiptArchiveDays: value });
  };

  saveReceiptDeleteDays = () => {
    if (this.state.receiptDeleteDays < 0) {
      error("Please enter a positive value");
      return;
    }

    if (this.state.receiptDeleteDays < this.state.receiptArchiveDays) {
      error(
        "Receipt delete days should be greater than the receipt archive days"
      );
      return;
    }

    secureFetch(endpoint.saveReceiptDeleteDays, {
      receiptDeleteDays: this.state.receiptDeleteDays,
    }).then(([ok, status, json]) => {
      if (ok) {
        success(json.message);
        this.fetchReceiptDeleteDaysRange();
      } else {
        error(
          "Oops! error occured during save receipt delete days for this company"
        );
      }
    });
  };

  fetchReceiptDeleteDaysRange = () => {
    secureFetch(endpoint.fetchReceiptDeleteDays, {}).then(
      ([ok, status, json]) => {
        if (ok) {
          this.setState({
            receiptDeleteDays: json.receiptDeleteDays[0].receipt_delete_days,
          });
        } else {
          error(
            "Oops! error occured during fetch receipt delete days for this company"
          );
        }
      }
    );
  };

  setReceiptDeleteDays = (value) => {
    this.setState({ receiptDeleteDays: value });
  };

  render() {
    if(this.props.Access.allow_receipt_management !== 1){
      return(
        <div role="alert" className="alert alert-warning w-1/2 mt-16 ">
          <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="white" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
          <span className="">Please subscribe to this feature</span>
        </div>
      )
    }

    return (
      <div className="my-4 flex">
        <div
          className="ml2 w-1/2 p-4 border-2 border-dotted"
        >
          <div className="f4 mb3">Receipt Archive Days</div>
          <div className="flex flex-row justify-between items-center">
            <div className="f5">Archive Days</div>
            <div className="flex flex-row ">
              <Input
                className="fw6 f4"
                placeholder="0"
                css={{ $$inputBorderRadius: "0", w: 100, h: 30, mr: 10 }}
                shadow={false}
                bordered={true}
                rounded={false}
                value={this.state.receiptArchiveDays}
                onChange={this.setReceiptArchiveDays}
              />
              <span style={{cursor: 'pointer'}}>
                <TickSquare size='large' onClick={this.saveReceiptArchiveDays} primaryColor="#0070F3"/>
              </span>
            </div>
          </div>
        </div>

        <div
          className="ml2 w-1/2 p-4 border-2 border-dotted"
        >
          <div className="f4 mb3">Receipt Delete Days</div>
          <div className="flex flex-row justify-between items-center">
            <div className="f5">Delete Days</div>
            <div className="flex flex-row ">
              <Input
                className="fw6 w-100 f4"
                placeholder="0"
                css={{ $$inputBorderRadius: "0", w: 100, h: 30, mr: 10 }}
                shadow={false}
                bordered={true}
                rounded={false}
                value={this.state.receiptDeleteDays}
                onChange={this.setReceiptDeleteDays}
              />
              <span style={{cursor: 'pointer'}}>
                <TickSquare size='large' onClick={this.saveReceiptDeleteDays} primaryColor="#0070F3"/>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  Access: state.navigation.Access,
  clientId: state.client
    ? state.client.id
    : mstore.getPath(["user", "company_id"]),
}))(ReceiptArchiveDays);
