import React, { Component } from "react";
import { Button, Loading, Switch, Modal, Text, Input } from "@nextui-org/react";
import { connect } from "react-redux";
import { PaperPlus } from "react-iconly";

import { secureFetch } from "../../lib/api";
import { success, warning } from "../../lib/userAlerts";
import { mstore } from "../../store";
import endpoint from "../../config/endpoint.json";
import { error } from "../../lib/userAlerts";

class SafetyDocumentType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documentTypeList: [],
      documentTypeListc: [],
      addDocument: false,
      editDocumentType: false,
      deleteDocuments: false,
      loading: false,
      loadinge: false,
      addDocumentsName: "",
      newArtifactsName: "",
      oldArtifactsName: "",
      deleteDocumentsName: "",
      fetching: true,
      editButtonClicked: false,
      editIndex: null,
    };
  }
  componentDidMount() {
    this.getDocumentTypeList();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.clientId !== this.props.clientId) {
      //this.getDocumentTypeList();
    }
  }

  getDocumentTypeList = () => {
    secureFetch(endpoint.getDocumentTypeList, {}).then(([ok, status, json]) => {
      if (ok) {
        this.setState({
          documentTypeList: json.documentTypeList,
          loading: false,
          fetching: false,
          documentTypeListc: JSON.parse(JSON.stringify(json.documentTypeList)),
        });
      } else {
        error("Oops! error occured during fetching safety document type list!");
      }
    });
  };

  handleCreateSafetyType = () => {
    if (this.state.addDocumentsName) {
      if (
        this.state.documentTypeList.findIndex((item) => {
          return (
            item.type_name === this.state.addDocumentsName.trim()
          );
        }) === -1
      ) 
      {
        this.addDocumentTypes();
      } else {
        warning("This artifact type is already exist");
      }
    } else {
      warning("Please enter name of artifact");
    }
  }

  // updateIsGrayScaleEnable

  handleSwitch = (obj) => {
    if (obj.status === 1) {
      this.deleteDocuments(obj);
    } else {
      this.recoverDocumentTypes(obj);
    }
  };

  handleAutoCrop = (obj) => {
    if (obj.is_scanner_enable === 1) {
      this.disableAutoCrop(obj);
    } else {
      this.enableAutoCrop(obj);
    }
  };

  disableAutoCrop = (obj) => {
    secureFetch(endpoint.disableAutoCropForSafetyDocType, {
      type_name: obj.type_name,
    }).then(([ok, status, json]) => {
      if (ok) {
        this.getDocumentTypeList();
        success(json.message);
        this.setState({
          loading: false,
        });
      } else {
        error("Oops! error occured during disable auto crop features!");
      }
    });
  };

  enableAutoCrop = (obj) => {
    secureFetch(endpoint.enableAutoCropForSafetyDocType, {
      type_name: obj.type_name,
    }).then(([ok, status, json]) => {
      if (ok) {
        this.getDocumentTypeList();
        this.setState({
          loading: false,
        });
      } else {
        error("Oops! error occured during enable auto crop features!");
      }
    });
  };

  addDocumentTypes = () => {
    this.setState({ loading: true });
    secureFetch(endpoint.addNewDocumentType, {
      type_name: this.state.addDocumentsName,
    })
    .then(([ok, status, json]) => {
      if (ok) {
        this.getDocumentTypeList();
        this.setState({ loading: false, addDocumentsName: "", addDocument: false });
      } else {
        error("Oops! error occured during add new safety document type!");
      }
    });
  };

  editDocumentType = (obj) => {
    if (obj.status !== 1) {
      return;
    } else {
      this.setState({ editButtonClicked: true, editIndex: obj.id });
    }
  };

  saveDocumentType = (obj) => {
    if (obj.status !== 1) {
      return;
    } else {
      if (
        this.state.documentTypeListc.findIndex((item) => {
          return item.type_name === obj.type_name.trim();
        }) === -1
      ) {
        this.setState({ loadinge: true });
        secureFetch(endpoint.editDocumentType, {
          new_type_name: obj.type_name,
          id: obj.id,
        }).then(([ok, status, json]) => {
          if (ok) {
            this.getDocumentTypeList();
            success(json.message);
            this.setState({ editButtonClicked: false });
          } else {
            error("Oops! error occured during save safety document type!");
          }
        });
      } else {
        warning("This artifact type already exist");
        this.setState({ editButtonClicked: false });
      }
    }
  };

  deleteDocuments = (obj) => {
    if (this.state.editButtonClicked) {
      warning("Please save first");
    } else {
      this.setState({ loading: true });
      secureFetch(endpoint.deleteDocumentType, {
        type_name: obj.type_name,
      }).then(([ok, status, json]) => {
        if (ok) {
          this.getDocumentTypeList();
          success(json.message);
          this.setState({
            loading: false,
            deleteDocumentsName: "",
            isActive: false,
          });
        } else {
          error("Oops! error occured during delete safety document type!");
        }
      });
    }
  };

  recoverDocumentTypes = (obj) => {
    this.setState({ loading: true });
    secureFetch(endpoint.recoverDocumentType, {
      type_name: obj.type_name,
    }).then(([ok, status, json]) => {
      if (ok) {
        this.getDocumentTypeList();
        success(json.message);
        this.setState({ loading: false, isActive: true });
      } else {
        error("Oops! error occured during recover safety document type!");
      }
    });
  };

  onCancel = () => {
    this.setState({ addDocument: false });
  };

  renderUserRow = (obj, idx) => {
    return (
      <tr key={idx} className="flex w-100">
        <td className="f5 fw2 flex flex-start items-center h3 w-25">
          <div>{obj.type_name}</div>
        </td>
        <td className="f5 flex justify-center items-center h3 w-25">
          {
            <Switch
              size='sm'
              checked={obj.status === 1 ? true : false}
              onChange={this.handleSwitch.bind(null, obj)}
            />
          }
        </td>
        <td className="flex justify-center items-center h3 w-25">
          {
            <Switch
              size='sm'
              disabled={obj.status === 0 ? true : false}
              // label="Auto Crop"
              checked={obj.is_scanner_enable === 1 ? true : false}
              onChange={this.handleAutoCrop.bind(null, obj)}
            />
          }
        </td>
      </tr>
    );
  };

  renderNewSafetyTypeModal = () =>{
    return(
      <Modal
          open={this.state.addDocument}
          blur
          css={{br: 0}}
          onClose={this.onCancel}
        >
          <Modal.Header>
            <Text size={18}>Add Safety Document Type</Text>
          </Modal.Header>

          <Modal.Body>
            <label htmlFor="" className="f5">
              Document Type Name
            </label>
            <div className="">
              <Input
                type="text"
                placeholder="Write New Document Type That You Want To Add"
                clearable
                onChange={(e) => {
                  this.setState({ addDocumentsName: e.target.value });
                }}
                css={{ $$inputBorderRadius: "0", w: '100%', h: 30, mt: 5 }}
                shadow={false}
                bordered={true}
                rounded={false}
                value={this.state.addDocumentsName}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              css={{br: 0}}
              auto
              onClick={this.handleCreateSafetyType}
            >
              {this.state.loading ? <Loading type='points' color='white'/> : <Text color='white'>Submit</Text>}
            </Button>
          </Modal.Footer>
      </Modal>
    )
  }

  render() {

    if(this.props.Access.allow_safety_documents !== 1){
      return(
        <div role="alert" className="alert alert-warning w-1/2 mt-16 ">
          <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="white" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
          <span className="">Please subscribe to <b>Safety Management</b> feature</span>
        </div>
      )
    }

    return (
      <div className="ml2 mt2 mb3">

        {this.renderNewSafetyTypeModal()}

        {this.state.fetching && (
          <div className="flex justify-center mt6">
            <div>
              <Loading size='lg' />
              <div style={{ textAlign: "center" }}>Loading.....</div>
            </div>
          </div>
        )}
        <div>
          {!this.state.fetching && this.state.documentTypeList.length !== 0 && (
            <div
              className="mt3 ml2 p-4 border-2 border-dotted"
            >
              <div className="flex justify-between pb-4">
                <div className="f4 mb2">
                  Safety Document Types
                </div>
                <Button
                  css={{br:0}}
                  auto
                  icon={<PaperPlus />}
                  onClick={() => {
                    this.setState({
                      addDocument: !this.state.addDocument,
                      editDocumentType: false,
                      deleteDocuments: false,
                    });
                  }}
                >
                  Create Safety Document Type
                </Button>
              </div>

              <table
                className={"w-100 flex flex-column mr2"}
              >
                <tr className="flex justify-around w-100">
                  <th className="flex flex-start items-center text-blue-gray-900 gap-2 font-bold opacity-70 w-25">
                    Document Types
                  </th>
                  <th className="flex justify-center items-center text-blue-gray-900 gap-2 font-bold opacity-70 w-25">
                    Active
                  </th>
                  <th className="flex justify-center items-center text-blue-gray-900 gap-2 font-bold opacity-70 w-25">
                    Auto Crop Image
                  </th>
                  <th className="flex justify-center items-center text-blue-gray-900 gap-2 font-bold opacity-70 w-25"></th>
                </tr>
                <tbody
                >
                  {this.state.documentTypeList.map((obj, idx) =>
                    this.renderUserRow(obj, idx)
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
        {!this.state.fetching && this.state.documentTypeList.length === 0 && (
          <div>
            <div className=" mt3 f5">No safety document type found.</div>
            <Button
              css={{br: 0}}
              auto
              icon={<PaperPlus />}
              className="mt3"
              onClick={() => {
                this.setState({
                  addDocument: !this.state.addDocument,
                  editDocumentType: false,
                  deleteDocuments: false,
                });
              }}
            >
              Create Safety Document Type
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default connect((state) => ({
  Access: state.navigation.Access,
  clientId: state.client
    ? state.client.id
    : mstore.getPath(["user", "company_id"]),
}))(SafetyDocumentType);
