import React from "react";
import { updateTitle } from "../lib/utils";
import { loadClientListConditionally } from "../store";
import driverconnect from "../driverconnect.svg";

import { Input, Button, Loading, Row, Modal, Text } from '@nextui-org/react';

import {
  loginAndSaveCreds,
  updateBasicUserInfo,
  userPasswordReset,
} from "../auth0";

import LogoImage from "./../assets/logo-final.jpg";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
      isChecking: false,
      isError: false,
      message: "",
      email: "",
      password: "",
      remember: false,
      forgotPasswordDialg: false,
      mobile: false,
    };
    this.handleLockClick = this.handleLockClick.bind(this);
    this.submitLoginForm = this.submitLoginForm.bind(this);
  }

  componentDidMount() {
    updateTitle("Login");
    if (window.innerWidth <= 699) {
      this.setState({ mobile: true });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleLockClick() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  forgotPassword = () => {
    if (this.state.email.length < 3) {
      this.setState({
        isError: true,
        message: "Please enter your email to continue!",
      });
    } else {
      userPasswordReset(this.state.email).then((msg) => {
        this.setState({ isError: false, message: msg });
      });
    }
  };

  navigateToSignup = () => {
    this.props.history.push("/signup");
  };

  submitLoginForm(e) {
    e.preventDefault();
    if (!this.state.email || !this.state.password) return;

    //console.log(this.state);
    this.setState({ isChecking: true, isError: false, message: "" });

    loginAndSaveCreds(
      this.state.email,
      this.state.password,
      this.state.remember
    )
      .then((authResult) => {
        updateBasicUserInfo()
          .then((userObj) => {
            if (userObj.isFreeUser !== 1) {
              if (userObj.companyIsActive === 1) {
                loadClientListConditionally(userObj);
                this.props.history.replace("/products");
              } else {
                this.setState({
                  isChecking: false,
                  isError: true,
                  message: "Company is not active",
                });
              }
            } else {
              this.setState({
                isChecking: false,
                isError: true,
                message: "You are not authorized to access this portal",
              });
            }
          })
          .catch((err) => {
            console.error(err);
            this.setState({
              isChecking: false,
              isError: true,
              message: "Cannot fetch user information from server!",
            });
          });
      })
      .catch((err) => {
        this.setState({
          isChecking: false,
          isError: true,
          message: err.description || "Oops! Something wrong has happned",
        });
      });
  }

  activateForgotDiaalog = () => {
    this.setState({ forgotPasswordDialg: true });
  };

  onKeyPress = (e) => {
    if(e.which === 13) {
      this.submitLoginForm(e);
    }
  }

  render() {
    return (
      <div className="login-container ">
        <div className="login-content">
          <div className="login-content-left">
            <img
              alt="login pic"
              className="login-content-image "
              src={LogoImage}
            />
          </div>

          <div className="login-content-right">
            <div className="login-box">
              <div className="login-box-head ml4 mr4">
                <span className="login-head-text">Login Credentials</span>

                <Input
                  name="email"
                  size="lg"
                  color="primary"
                  placeholder="Email (User Name)"
                  onChange={this.handleChange}
                  value={this.state.email}
                  underlined
                  fullWidth
                  clearable
                />

                <Input.Password 
                  name="password"
                  size="lg"
                  color="primary"
                  placeholder="Enter your password"
                  onChange={this.handleChange}
                  onKeyPress={this.onKeyPress}
                  value={this.state.password}
                  underlined
                  fullWidth
                  clearable
                />
                
                <Row gap={1} justify="space-between" className="mt2">
                  <Button
                    onClick={this.submitLoginForm} 
                    ghost 
                    bordered
                    color="primary" 
                    auto 
                    clickable={!this.state.isChecking}
                    disabled={this.state.isChecking}
                    size="lg">
                      {this.state.isChecking ? (
                        <Loading type="points" color="primary" size="md" /> 
                      ) : (
                        <span>Login</span>
                      )}
                  </Button>

                  <Button
                    ghost 
                    bordered
                    size="lg"
                    auto
                    color="primary" 
                    onClick={this.navigateToSignup}
                  >
                    Register
                  </Button>
                </Row>

                {this.state.isError && (
                  <span className="text-red-600 text-md font-semibold">{this.state.message}</span>
                )}

                <Row gap={1} justify="space-between" className="mt2 f5">
                  <a
                    className="pointer"
                    onClick={this.activateForgotDiaalog}
                    style={{ background: "#ffe70047" }}
                  >
                    Reset Password
                  </a>

                  <a
                    style={{ background: "#ffe70047" }}
                    href="https://www.mytruckin.tech/mtt-apps-terms"
                    target="_blank"
                  >
                    Our Terms and Conditions
                  </a>
                </Row>
              </div>

              <div className="login-box-foot">
                <span className="login-head-text">
                  Enable your fleet like never before
                </span>

                <span
                  className="max-text-width pa1"
                  style={{ borderStyle: "solid", borderWidth: "1px" }}
                >
                  Get started! Improve the life for your drivers and also improve your business!
                </span>

              </div>
            </div>
          </div>
        </div>

        <div className="login-footer">
          <span className="login-footer-text">
            Driver focused and value driven
          </span>
        </div>

        <Modal
          open={this.state.forgotPasswordDialg}
          onClose={() =>
            this.setState({
              isError: false,
              message: "",
              forgotPasswordDialg: false,
            })
          }
          closeButton
          blur
        >
          <Modal.Header>
            <Text size={24}>
              Forgot Password
            </Text>
          </Modal.Header>

          <Modal.Body>
            <Input
              disabled={false}
              underlined
              clearable
              placeholder="Email Address"
              type="email"
              labelLeft="Email"
              value={this.state.email}
              onChange={(e) => {
                this.setState({ email: e.target.value });
              }}
              required={true}
              className="w-100 mv1"
            />
            <Button
              auto
              css={{br:0}}
              className="mt3"
              onClick={this.forgotPassword}
              clickable={!this.state.isChecking}
              disabled={this.state.isChecking}
              >
                {this.state.isChecking ? (
                  <Loading type="points" color="white" size="md" /> 
                ) : (
                  <span>Send password reset email</span>
                )}
            </Button>
          </Modal.Body>
          <Modal.Footer>
            <label
              className={
                "f5 w-100 pa2 " +
                (this.state.isError || this.state.message ? "" : "invisible")
              }
            >
              <div
                style={{ display: "inline", marginLeft: "5px", color: this.state.isError ? "red" : "black" }}
              >
                {this.state.message}
              </div>
            </label>
          </Modal.Footer>
        </Modal>

        <Modal
          open={this.state.mobile}
          className="cmtenghdr mh2"
          title="Login Confirmation"
          canEscapeKeyClose={false}
          canOutsideClickClose={false}
          isCloseButtonShown={false}
        >
          <div className="mt3 h-25 mh4">
            <div>
              <div style={{ marginBottom: "5px", fontSize: "15px" }}>
                Continue using Driver Connect Portal
              </div>
              <Button
                className="w4 mr3"
                text="Click here"
                onClick={() => this.setState({ mobile: false })}
              />
            </div>
            <div>
              <img
                style={{ width: "50px", marginTop: "20px" }}
                src={driverconnect}
                alt={'Driver Connect'}
                className="w2"
              />
              <div
                style={{
                  marginBottom: "5px",
                  fontSize: "15px",
                  marginTop: "2px",
                }}
              >
                To download SDN DriverConnect App
              </div>
              <Button
                className="w4 mr3"
                text="Click here"
                onClick={() => {
                  window.location = "http://onelink.to/gb6x3q";
                }}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Login;
