// @flow
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Activity, Password, Graph, Category, Paper, Location, Search, Bookmark, People, ShieldDone, Scan } from 'react-iconly';

import { Receipt } from './components/MainComponent/Receipt';
import { Safety } from './components/MainComponent/Safety';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import MainComponent from './components/MainComponent/index';

import ProductSelection from './pages/ProductSelection';
import DriverDashboard from './pages/DriverConnect/Dashboard';
import ShipmentDispatch from './pages/DriverConnect/ShipmentDispatch';
import ShipmentDocuments from './pages/DriverConnect/ShipmentDocuments';
import LocationAnalytics from './pages/DriverConnect/LocationAnalytics';
import LocationInformation from './pages/DriverConnect/LocationInformation';
import osd from './pages/DriverConnect/Osd';
import DriverDetails from './pages/DriverConnect/DriverDetails';
import ReimbursableRecipetsDetails from './pages/DriverConnect/ReimbursableRecipetsDetails';
import ReimbursableRecipetsManagement from './pages/DriverConnect/ReimbursableRecipetsManagement';
import DetentionManagementDetails from './pages/DriverConnect/DetentionManagementDetails';

import safetyDocuments from './pages/DriverConnect/safetyDocument';
import newsFeed from './pages/DriverConnect/newsFeed';
import Settings from './pages/ProductSelection/Settings';
import Developer from './pages/Developer';
import ShipmentManagement from './pages/DriverConnect/ShipmentManagement';

const CommonRouteDefs = [
  {
    path: '/login',
    component: Login,
    exact: true
  },
  {
    path: '/',
    component: Login,
    exact: true
  },
  {
    path: '/products',
    component: ProductSelection,
    exact: false
  },
  {
    path: '/signup',
    component: SignUp,
    exact: false
  }
];

const DriverRouteDefs = [
  {
    path: '/driver/dashboard',
    component: DriverDashboard,
    exact: true
  },
  {
    path: '/driver/shipment-dispatch',
    component: ShipmentDispatch,
    exact: true
  },
  {
    path: '/driver/shipment-documents',
    component: ShipmentDocuments,
    exact: true
  },
  {
    path: '/driver/location-analytics',
    component: LocationAnalytics,
    exact: true
  },
  {
    path: '/driver/location-information',
    component: LocationInformation,
    exact: true
  },
  {
    path: '/driver/os&d',
    component: osd,
    exact: true
  },
  {
    path: '/driver/settings',
    component: Settings,
    exact: true
  },
  {
    path: '/driver/detail',
    component: DriverDetails,
    exact: true
  },
  {
    path: '/driver/developer',
    component: Developer,
    exact: true
  },
  {
    path: '/driver/reimbursable-receipts-details',
    component: ReimbursableRecipetsDetails,
    exact: true
  },
  {
    path: '/driver/reimbursable-receipts-management',
    component: ReimbursableRecipetsManagement,
    exact: true
  },
  {
    path: '/driver/detention-management',
    component: DetentionManagementDetails,
    exact: true
  },
  {
    path: '/driver/safety-documents',
    component: safetyDocuments,
    exact: true
  },
  {
    path: '/driver/news-feed',
    component: newsFeed,
    exact: true
  },
  {
    path: '/driver/shipment-management',
    component: ShipmentManagement,
    exact: true
  }
];

const DriverConnectLinks = [
  {
    name: 'Dashboard',
    href: '/driver/dashboard',
    icon: <Graph set="light" filled primaryColor='gray'/>
  },
  {
    name: 'Shipment Dispatch',
    href: '/driver/shipment-dispatch',
    icon: <Search set="light" filled primaryColor='gray'/>
  },
  {
    name: 'Shipment Documents',
    href: '/driver/shipment-documents',
    icon: <Paper set="light" filled primaryColor='gray'/>
  },
  {
    name: 'OS&D',
    href: '/driver/os&d',
    icon: <Scan set="light" filled primaryColor='gray'/>
  },
  {
    name: 'Location Analytics',
    href: '/driver/location-analytics',
    icon: <Location set="light" filled primaryColor='gray'/>
  },
  {
    name: 'Location Information',
    href: '/driver/location-information',
    icon: <Bookmark set="light" filled primaryColor='gray'/>
  },
  {
    name: 'Driver Details',
    href: '/driver/detail',
    icon: <People set="light" filled primaryColor='gray'/>
  },
  {
    name: 'Reimbursable Receipt',
    href: '/driver/reimbursable-receipts-management',
    icon: <Receipt fill='gray'/>
  },
  {
    name: 'Detention Management',
    href: '/driver/detention-management',
    icon: <ShieldDone set="light" filled primaryColor='gray'/>
  },
  {
    name: 'Safety',
    href: '/driver/safety-documents',
    icon: <Safety fill='gray' height={28} width={28}/>
  },
  {
    name: 'News Feed',
    href: '/driver/news-feed',
    icon: <Activity set="light" filled primaryColor='gray'/>
  },
  {
    name: 'Shipment Management',
    href: '/driver/shipment-management',
    icon: <Category set="light" filled primaryColor='gray'/>
  },
  {
    name: 'API Keys',
    href: '/driver/developer',
    icon: <Password set="light" filled primaryColor='gray'/>
  }
];

export function getAllRoutes() {
  return (
    <Switch>
      {CommonRouteDefs.map((pathDef) => {
        return <Route key={pathDef.path} {...pathDef} />;
      })}
      <Route
        path="/driver"
        component={props => (
          <MainComponent
            {...props}
            title="My Truckin' Portal"
            links={DriverConnectLinks}
            dcSettings={true}>
            <Switch>
              {DriverRouteDefs.map((pathDef) => {
                return <Route key={pathDef.path} {...pathDef} />;
              })}
            </Switch>
          </MainComponent>
        )}
      />
    </Switch>
  );
}
