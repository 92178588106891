import React, { Component } from "react";
import {
  Button,
  Loading,
  Modal,
  Text
} from "@nextui-org/react";
import Select from "react-select";


class AssignRefModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    // const clientId = mstore.get("user", "company_id");
    return (
      <Modal
        width='640px'
        open={this.props.openRefModal}
        onClose={this.props.closeRefModal}
        canEscapeKeyClose={true}
        closeButton
        blur
      >
        <Modal.Header>
          <Text size={18} b> Assign Broker Rep</Text>
        </Modal.Header>

        <Modal.Body>
          <Select
            name="form-field-name"
            style={{ width: "100%" }}
            placeholder="Select broker rep"
            value={this.props.selectedRef}
            onChange={(data) => {
              this.props.handleSelectRef(data)
            }}
            options={this.props.selectDriverRefsList}
            matchPos="start"
            filterProp="value"
          />

          <div className="f5 mt2">
            Note: List is populated from web portal users from company.
          </div>

        </Modal.Body>

        <Modal.Footer>
          <Button
            auto
            css={{br: 0}}
            onClick={() => {
              this.props.assignMultiple();
            }}
            loading={this.props.loadRef}
          >
            {this.props.loadRef ? <Loading type='points' color='white' /> : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AssignRefModal;
